export const ALERT_DRAWER_HEADING = "Notifications";

export const STOCK_HEADER = "Stock:";
export const UNSHIPPED_HEADER = "Unshipped:";
export const EXPECTED_EOD_HEADER = "Expected EOD:";

export const STOCK = "Stock";
export const EXPECTED_EOD = "EOD";
export const UNSHIPPED = "Unshipped";
export const SKU = "SKU";
export const ASIN = "ASIN";
export const SHIPPING_TEMPLATE = "Shipping Template";
export const RULE_APPLIED = "Rule Applied";
export const NO_ENTRIES_TEXT = "There are currently no notifications!"
export const CLOSE = 'Close';

export const FETCH_UNSHIPPED_ALERTS_TIME = 15;