import {isFunctionLikeExpression} from "eslint-plugin-react/lib/util/ast";

export const HEAD_CELLS = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Rule Name",
  },
  {
    id: "group",
    numeric: false,
    disablePadding: false,
    label: "Group Name",
  },
  {
    id: "ruleCategory",
    numeric: false,
    disablePadding: false,
    label: "Rule Category",
  },
  {
    id: "Copy",
    numeric: false,
    disablePadding: false,
    label: ""
  },
  {
    id: "Delete",
    numeric: false,
    disablePadding: false,
    label: ""
  }
];

export const MAP_ALL_LABEL = "Apply rule on all orders";
export const MAP_SKU_LABEL = "Apply rule on all orders with the following SKUs";
export const MAP_ALL_LABEL_LISTINGS = "Apply rule on all SKUs";
export const MAP_SKU_LABEL_LISTINGS = "Apply rule on all the following SKUs";
export const MAP_ALL = "mapAll";
export const MAP_SKU = "mapSku";

export const MAP_SKU_SELECT_OPTIONS = {
  ALL: "Select All",
  SPECIFIC: "Select SKU "
}

export const DELETE_RULE = "Delete Rule";
export const DELETE = "Delete";
export const DRAG = "Drag";
export const EDIT_RULE = "Edit Rule";
export const COPY_RULE = "Copy Rule";
export const DELETE_CONDITION = "Delete Condition"
export const COPY_CONDITION = "Copy Condition"
export const DELETE_FILTER = "Delete Filter"
export const COPY_FILTER = "Copy Filter"
export const DELETE_ACTION = "Delete Action"
export const UP = "Move Up";
export const DOWN = "Move Down";

export const DELETE_DIALOG_TITLE = "Delete Selected Automation Rule?"
export const DELETE_DIALOG_MESSAGE = "Deleting this rule will remove it from your system, as well as from your configured mappings. Previous applications of a deleted rule won't be reversed."

export const DEFAULT_SORT_BY_HEAD_CELL = "name";

export const DEFAULT_ROWS_PER_PAGE = 50;

export const APPLY = "Apply";
export const CANCEL = "Cancel";
export const FINISH = "Finish";
export const NEXT = "Next";
export const BACK = "Back";
export const NEW_RULE = "New rule";

export const AUTOMATION_RULES = "Automation Rules";
export const RULE_NAME = "Rule Name";
export const GROUP_NAME = "Group Name";
export const RULE_CATEGORY = "Rule Category"
export const RULE_CATEGORY_OPTIONS = ['Shipments', 'Listings', 'Transactions', 'Pricing'];
export const RULE_CATEGORY_LISTINGS = 'Listings';
export const RULE_CATEGORY_TRANSACTIONS = 'Transactions';
export const RULE_CATEGORY_PRICING = 'Pricing';
export const ADD_FILTER = "Filter";
export const ADD_ACTION = "Action";
export const ADD_CONDITION = "Condition";
export const ADD_MAPPING = 'Mapping'

export const CREATE_RULE = "Create Rule"
export const AUTOMATION_SETTINGS = "Automation Settings"

export const ANY_ALL_PART_1 = "Match";
export const ANY_ALL_PART_2A = "any";
export const ANY_ALL_PART_2B = "all";
export const ANY_ALL_PART_3 = "of the above set of conditions";

export const EXIT_ON_MATCH = "Exit on match";
export const CONTINUE_ON_MATCH = "Continue on match";
export const INFO_ON_DEFAULT_BEHAVIOUR = "This is the default behaviour.";
export const INFO_ON_EXIT_OPTION =
  "When the set of conditions above match, the assigned actions are applied and the rule will not proceed further.";
export const INFO_ON_CONTINUE_OPTION =
  "When the set of conditions above match, the assigned actions are applied and the rule will continue to the next set of conditions.";

// these types are used to indicate the type of Conditional Filters

// yes/no only - no secondary component associated with it
export const FILTER_TYPE_BOOLEAN_1 = 1;
// equals/not equals with a secondary component which has a set of predefined options
export const FILTER_TYPE_BOOLEAN_2 = 2;
// equals/not equals/more than/more than or equal to/less than/less than or equal to/between with a numeric textfield
export const FILTER_TYPE_NUMERIC = 3;
// equals/not equals/is empty/contains/does not contain/starts with/ends with/reg ex with a string textfield
export const FILTER_TYPE_TEXT = 4;

export const TABLE_TYPES = {
  FILTER: 'filter',
  ACTION: 'action',
  MAPPING: 'mapping'
}

export const ROWS_PER_PAGE_LABEL = "Rules per page"

export const AUTOMATION_RULES_PAGES = {
  TABLE: "table",
  SETTINGS: "settings"
}