import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    BACK,
    CARRIER_TABLE_CELLS,
    CUSTOMER_TABLE_CELLS,
    EDIT_LEDGER,
    FTL_CARRIER_TABLE_CELLS,
    LEDGER_ENTRIES_CELLS,
    LEDGER_PAYABLE_ENTRIES_CELLS,
    LEDGER_TABLE_CELLS,
    LEDGERS_PAGES,
    MARKETPLACE_ENTRIES_CELLS, MARKETPLACE_MAPPING,
    MARKETPLACE_TABLE_CELLS, SALES_TABLE_CELLS,
    SAVE, STOCK_IN_HAND_TABLE_CELLS,
    SUPPLIER_TABLE_CELLS,
} from './constants';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import {
    Box,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Divider
} from '@mui/material';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Button, TableCell, TextField } from '@material-ui/core';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuIcon from '@mui/icons-material/Menu';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import RefreshIcon from '@mui/icons-material/Refresh';
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import TablePage from "../../global/Table/TablePage";
import EditIcon from "@material-ui/icons/Edit";
import { format } from 'date-fns';
import Tooltip from "@material-ui/core/Tooltip";
import LedgerEntryTableRow from "./LedgerEntryTableRow";
import CloseIcon from "@material-ui/icons/Close";
import { HourglassEmptyTwoTone } from '@material-ui/icons';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CarrierEntryTableRow from './summary/CarrierEntryTableRow';
import FTLEntryTableRow from './summary/FTLEntryTableRow';
import SupplierEntryTableRow from './summary/SupplierEntryTableRow';
import StockEntryTableRow from './summary/StockEntryTableRow';
import MarketplaceEntryTableRow from './summary/MarketplaceEntryTableRow';
import Utils from '../../Utils/Utils';
import CustomerEntryTableRow from './summary/CustomerEntryTableRow';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import SalesEntryTableRow from './summary/SalesEntryTableRow';
import { Upgrade } from '@mui/icons-material';
import { PURCHASE_SUMMARY_FIELDS } from '../purchaseSummary/constants';


class EditLedger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: this.props.ledger.ledgerEntries || [],
            anchorEl: null,
            activeIndex: null,
            draggedIndex: null,
            isEditEntries: false,
            deleteId: "",
            isDeleted: false,
            listingIdBeingEdited: '',
            isEdit: false,
            isEditPayable: false,
            isShowPayable: false,
            hideFilterBox : false,
            isDialogOpen: false,
            currentNote: '',
            selectedIndex: null,
            isRefresh: false,
            carrierSummary: this.props.carrierSummary || [],
            supplierSummary: this.props.supplierSummary || [],
            customerSummary: this.props.customerSummary || [],
            ftlSummary: this.props.ftlSummary || [],
            stockSummary: this.props.stockSummary || [],
            marketplaceSummary: this.props.marketplaceSummary || [],
            isShowMarketplaceDetails: false,
            isEditMarketplace: false,
            marketplaceEntries: [],
            selectedMarketplace: null,
            isDialogFetch: false,
            salesSummary: this.props.salesSummary || [],
            isMarketplaceDialog: false,
            currentMarketplaceNote: '',
            selectedMarketplaceIndex: null,

        };
    }

    setHideFilterBox = (isHide) =>{
        this.setState({hideFilterBox : isHide})
    }

    componentDidMount() {


        if(this.props.ledger.name === "Accounts Payable" ||
          this.props.ledger.name === "Accounts Receivables" ||
          this.props.ledger.name === "Sales" ||
          this.props.ledger.name === "Cost of Sales"){
            this.setState({isShowPayable: true})
        }



        if (this.props.ledger?.ledgerEntries && this.props.ledger.ledgerEntries.length > 0) {
            this.setState({
                templates: [...this.props.ledger.ledgerEntries],
            });
        }
        const data = {
            ledgerId: this.props.ledger.ledgerId,
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency,
            isFetch: this.state.isRefresh
        }
        this.props.ledgerEntries(data)
    }

    fetchLedgerEntries = () => {
        const data = {
            ledgerId: this.props.ledger.ledgerId,
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency
        }
        this.props.fetchTransactionsLedger(data)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fetchTransactions !== this.props.fetchTransactions) {
            const { ledgerEntries } = this.props.fetchTransactions;
            if (ledgerEntries && Array.isArray(ledgerEntries)) {
                // Normalize transactions

                const flattenedTemplates = ledgerEntries.map(entry => {
                    if (entry.entries) {
                        return entry.entries; // Handle nested entries
                    }
                    return entry; // Handle top-level entries
                }).flat(); // Flatten any nested arrays


                this.setState({
                    templates: flattenedTemplates,
                }, this.updateBalances);
            }else{
                // Normalize transactions
                const flattenedTemplates = this.props.fetchTransactions.map(entry => {
                    if (entry.entries) {
                        return entry.entries; // Handle nested entries
                    }
                    return entry; // Handle top-level entries
                }).flat(); // Flatten any nested arrays


                this.setState({
                    templates: flattenedTemplates,
                }, this.updateBalances);

            }
        }


        if (prevProps.stockSummary !== this.props.stockSummary) {
            this.setState({
                stockSummary : this.props.stockSummary
            })
        }

        if (prevProps.customerSummary !== this.props.customerSummary) {
            this.setState({
                customerSummary : this.props.customerSummary
            })
        }

        if (prevProps.salesSummary !== this.props.salesSummary) {
            this.setState({
                salesSummary : this.props.salesSummary
            })
        }

        if (prevProps.supplierSummary !== this.props.supplierSummary) {
            this.setState({
                supplierSummary : this.props.supplierSummary
            })
        }
        if (prevProps.ftlSummary !== this.props.ftlSummary) {
            this.setState({
                ftlSummary : this.props.ftlSummary
            })
        }

        if (prevProps.carrierSummary !== this.props.carrierSummary) {
            this.setState({
                carrierSummary : this.props.carrierSummary
            })
        }

        if (prevProps.marketplaceSummary !== this.props.marketplaceSummary) {
            this.setState({
                marketplaceSummary : this.props.marketplaceSummary
            })
        }
    }


    onDragStart = (index) => {
        this.setState({draggedIndex: index});
    };

    onDragMarketplaceOver = (index) => {
        const {draggedIndex, marketplaceEntries} = this.state;
        if (draggedIndex === index) return;

        const updatedTemplates = [...marketplaceEntries];
        const [draggedItem] = updatedTemplates.splice(draggedIndex, 1);
        updatedTemplates.splice(index, 0, draggedItem);

        this.setState({
            marketplaceEntries: updatedTemplates,
            draggedIndex: index,
        }, this.updateMarketplaceBalances);
    }



    onDragOver = (index) => {
        const {draggedIndex, templates} = this.state;
        if (draggedIndex === index) return;

        const updatedTemplates = [...templates];
        const [draggedItem] = updatedTemplates.splice(draggedIndex, 1);
        updatedTemplates.splice(index, 0, draggedItem);

        this.setState({
            templates: updatedTemplates,
            draggedIndex: index,
        }, this.updateBalances);
    }


    onDragEnd = () => {
        this.setState({draggedIndex: null})
    }

    getContextualMenuItems = () => {
        const { setCurrentPage, listLedgers, selectedCurrency, selectedYear } = this.props;
        const { isEditEntries, isEditPayable, isShowPayable, isShowMarketplaceDetails } = this.state;
        const menuItems = [];


        // Dynamic menu items based on isShowPayable and isEditPayable


        if (isShowMarketplaceDetails) {

            if (this.state.isEditMarketplace) {
                menuItems.push({
                    title: 'Back',
                    icon: <KeyboardBackspaceIcon />,
                    onClick: () => {
                        this.setState({isEditMarketplace: false});
                    },
                })

                menuItems.push({
                    title: 'Save',
                    icon: <SaveIcon />,
                    onClick: () => this.handleMarketplaceEntriesSave(),
                });
            }else{
                menuItems.push({
                    title: 'Back',
                    icon: <KeyboardBackspaceIcon />,
                    onClick: () => {
                        this.setState({ isShowMarketplaceDetails: false })
                    },
                });

                menuItems.push({
                    title: 'Edit Entries',
                    icon: <EditIcon />,
                    onClick: () => this.setState({ isEditMarketplace: true }),
                });

            }
            return menuItems;
        }


        if (isShowPayable ) {
                if (selectedCurrency === "Consolidated CAD"){
                    menuItems.push({
                        title: 'Back',
                        icon: <KeyboardBackspaceIcon />,
                        onClick: () => {
                            setCurrentPage(LEDGERS_PAGES.TABLE, {});
                            listLedgers({ currency: selectedCurrency, year: selectedYear });
                        },
                    },
                      {
                          title: 'Export',
                          icon: <Upgrade />,
                          onClick: () => {
                              this.handlePredefinedLedgerExport()
                          }
                      })
                }else{
                    menuItems.push({
                        title: 'Back',
                        icon: <KeyboardBackspaceIcon />,
                        onClick: () => {
                            setCurrentPage(LEDGERS_PAGES.TABLE, {});
                            listLedgers({ currency: selectedCurrency, year: selectedYear });
                        },
                    })
                    menuItems.push(
                        isEditPayable
                            ? {
                                title: 'Cancel Editing',
                                icon: <CloseIcon />,
                                onClick: () => {
                                    this.setHideFilterBox(false);
                                    this.setState({ isEditPayable: false });

                                    this.setState({ listingIdBeingEdited: null }, () => {
                                        if (this.state.carrierSummary && this.state.carrierSummary.length > 0) {
                                            const newEntry = this.state.carrierSummary.filter(entry => entry.id !== '' && entry.id !== null);
                                            this.setState({ carrierSummary: newEntry });
                                        }
                                    });

                                    this.setState({ listingIdBeingEdited: null }, () => {
                                        if (this.state.supplierSummary && this.state.supplierSummary.length > 0) {
                                            const newEntry = this.state.supplierSummary.filter(entry => entry.id !== '' && entry.id !== null);
                                            this.setState({ supplierSummary: newEntry });
                                        }
                                    });

                                    this.setState({ listingIdBeingEdited: null }, () => {
                                        if (this.state.ftlSummary && this.state.ftlSummary.length > 0) {
                                            const newEntry = this.state.ftlSummary.filter(entry => entry.id !== '' && entry.id !== null);
                                            this.setState({ ftlSummary: newEntry });
                                        }
                                    });

                                    this.setState({ listingIdBeingEdited: null }, () => {
                                        if (this.state.templates && this.state.templates.length > 0) {
                                            const newEntry = this.state.templates.filter(entry => entry.transactionId !== '' && entry.transactionId !== null);
                                            this.setState({ templates: newEntry });
                                        }
                                    });

                                },
                            }
                            : {
                                title: 'Edit Entry',
                                icon: <EditIcon />,
                                onClick: () => {
                                    this.setState({ isEditPayable: true });
                                },
                            }
                    );

                    {(this.props.ledger.name === "Cost of Sales" ||
                      this.props.ledger.name === "Accounts Receivables" ||
                      this.props.ledger.name === "Sales" ||
                      this.props.ledger.name === "Accounts Payable") &&
                         menuItems.push({
                            title: 'Refresh',
                            icon: <RefreshIcon />,
                            onClick: () => {
                                const data = {
                                    ledgerId: this.props.ledger.ledgerId,
                                    year: this.props.selectedYear,
                                    currency: this.props.selectedCurrency,
                                    isFetch: true
                                }
                                this.props.ledgerEntries(data)
                            },
                        },
                           {
                               title: 'Export',
                               icon: <Upgrade />,
                               onClick: () => {
                                   this.handlePredefinedLedgerExport()
                               }
                           })
                    }
                }
        }
        else{
            if (selectedCurrency === "Consolidated CAD"){
                menuItems.push({
                    title: 'Back',
                    icon: <KeyboardBackspaceIcon />,
                    onClick: () => {
                        setCurrentPage(LEDGERS_PAGES.TABLE, {});
                        listLedgers({ currency: selectedCurrency, year: selectedYear });
                    }
                },
                  {
                      title: 'Export',
                      icon: <Upgrade />,
                      onClick: () => {
                          this.handleExport()
                      }
                  }
                  )
            }else {
                if (isEditEntries) {
                    menuItems.push({
                        title: 'Back',
                        icon: <KeyboardBackspaceIcon />,
                        onClick: () => {
                            this.setState({isEditEntries: false});
                        },
                    })

                    menuItems.push({
                        title: 'Save',
                        icon: <SaveIcon />,
                        onClick: this.handleSave,
                    });
                }
                else {
                    menuItems.push({
                        title: 'Back',
                        icon: <KeyboardBackspaceIcon />,
                        onClick: () => {
                            setCurrentPage(LEDGERS_PAGES.TABLE, {});
                            listLedgers({ currency: selectedCurrency, year: selectedYear });
                        },
                    })

                    menuItems.push({
                        title: 'Edit Entries',
                        icon: <EditIcon />,
                        onClick: () => this.setState({ isEditEntries: true }),
                    });

                    menuItems.push( {
                        title: 'Settings',
                          icon: <SettingsIcon />,
                          onClick: () => {
                             this.props.setCurrentPage(LEDGERS_PAGES.FORM, this.props.ledger)
                         }
                    })

                    menuItems.push({
                        title: 'Refresh',
                        icon: <RefreshIcon />,
                        onClick: () => {

                            this.handleOpenFetch()



                        },
                    },
                    {
                        title: 'Export',
                          icon: <Upgrade />,
                      onClick: () => {
                        this.handleExport()
                    }
                    }
                    );
                }
            }

        }

        //loading indicator
        if (this.props.loadingData) {
            menuItems.push({
                title: "Loading data...",
                icon: <HourglassEmptyTwoTone />,
                onClick: () => null,
                disabled: true
            })
        }


        // Combine back button with menu items
        return [...menuItems];
    };

    handlePredefinedLedgerExport = () => {
        let csvContent = "";

        const ledgerName = this.state.isShowMarketplaceDetails
          ? `Ledger - ${this.props.ledger.name} (${MARKETPLACE_MAPPING[this.state.selectedMarketplace.name] ?? this.state.selectedMarketplace.name})`
          : `Ledger - ${this.props.ledger.name}`;

        if (this.props.ledger.name === "Accounts Payable") {
            const { carrierSummary, ftlSummary, supplierSummary, templates } = this.state;

            // Generate CSV content for Carrier
            const headerCarrier = CARRIER_TABLE_CELLS.map(field => field.label).join(',');
            const rowsCarrier = carrierSummary
              .filter(row => row.carrierName !== "Total")
              .map(row =>
                CARRIER_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
            );

            // Calculate total for Carrier
            const totalCarrierAmount = rowsCarrier.reduce((acc, row) => {
                const [carrier, amount] = row.split(',');
                return acc + parseFloat(amount || 0);
            }, 0);
            const totalCarrierRow = `Total,${totalCarrierAmount.toFixed(2)}`;


            // Generate CSV content for FTL
            const headerFTL = FTL_CARRIER_TABLE_CELLS.map(field => field.label).join(',');
            const rowsFTL = ftlSummary
              .filter(row => row.carrierName !== "Total")
              .map(row =>
                FTL_CARRIER_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
            );

            // Calculate total for FTL
            const totalFTLAmount = rowsFTL.reduce((acc, row) => {
                const [carrier, amount] = row.split(',');
                return acc + parseFloat(amount || 0);
            }, 0);

            const totalFTLRow = `Total,${totalFTLAmount.toFixed(2)}`;

            // Generate CSV content for Supplier
            const headerSupplier = SUPPLIER_TABLE_CELLS.map(field => field.label).join(',');
            const rowsSupplier = supplierSummary
              .filter(row => row.supplierName !== "Total")
              .map(row =>
            SUPPLIER_TABLE_CELLS.map(field => {
                let value = row[field.id] ?? '';
                value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                return value;
            }).join(',')

            );

            // Calculate total for Supplier
            const totalSupplierAmount = rowsSupplier.reduce((acc, row) => {
                const [supplier, amount] = row.split(',');
                return acc + parseFloat(amount || 0);
            }, 0);
            const totalSupplierRow = `Total,${totalSupplierAmount.toFixed(2)}`;


            // Generate CSV content for Other
            const headerOther = LEDGER_PAYABLE_ENTRIES_CELLS.map(field => field.label).join(',');
            const rowsOther = templates
              .filter(row => row.description !== "Accounts Payable")
              .map(row =>
                LEDGER_PAYABLE_ENTRIES_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Supplier
            const totalOtherAmount = rowsOther.reduce((acc, row) => {
                const [description, credit] = row.split(',');
                return acc + parseFloat(credit || 0);
            }, 0);
            const totalOtherRow = `Total,${totalOtherAmount.toFixed(2)}`;


            const total = totalCarrierAmount + totalFTLAmount + totalSupplierAmount + totalOtherAmount;
            const accountsPayableTotal = `Accounts Payable Total,${total.toFixed(2)}`;

            // Combine headers, rows, and totals, ensuring "Total" is last
            csvContent = [
                headerCarrier,
                ...rowsCarrier,
                totalCarrierRow,
                '', // Empty line between tables
                headerFTL,
                ...rowsFTL,
                totalFTLRow,
                '', // Empty line between tables
                headerSupplier,
                ...rowsSupplier,
                totalSupplierRow,
                '', // Empty line between tables
                headerOther,
                ...rowsOther,
                totalOtherRow,
                '', // Empty line between tables
                accountsPayableTotal
            ].join('\n');
        }
        else if (this.props.ledger.name === "Cost of Sales") {
            const { stockSummary, carrierSummary, ftlSummary, supplierSummary, templates } = this.state;

            // Generate CSV content for Carrier
            const headerStock = STOCK_IN_HAND_TABLE_CELLS.map(field => field.label).join(',');
            const rowsStock = stockSummary
              .filter(row => row.name !== "Total")
              .map(row =>
                STOCK_IN_HAND_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Carrier
            const totalStockAmount = rowsStock.reduce((acc, row) => {
                const [name, balance] = row.split(',');
                return acc + parseFloat(balance || 0);
            }, 0);
            const totalStockRow = `Total,${totalStockAmount.toFixed(2)}`;



            // Generate CSV content for Carrier
            const headerCarrier = CARRIER_TABLE_CELLS.map(field => field.label).join(',');
            const rowsCarrier = carrierSummary
              .filter(row => row.carrierName !== "Total")
              .map(row =>
                CARRIER_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Carrier
            const totalCarrierAmount = rowsCarrier.reduce((acc, row) => {
                const [carrier, amount] = row.split(',');
                return acc + parseFloat(amount || 0);
            }, 0);
            const totalCarrierRow = `Total,${totalCarrierAmount.toFixed(2)}`;


            // Generate CSV content for FTL
            const headerFTL = FTL_CARRIER_TABLE_CELLS.map(field => field.label).join(',');
            const rowsFTL = ftlSummary
              .filter(row => row.carrierName !== "Total")
              .map(row =>
                FTL_CARRIER_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for FTL
            const totalFTLAmount = rowsFTL.reduce((acc, row) => {
                const [carrier, amount] = row.split(',');
                return acc + parseFloat(amount || 0);
            }, 0);

            const totalFTLRow = `Total,${totalFTLAmount.toFixed(2)}`;

            // Generate CSV content for Supplier
            const headerSupplier = SUPPLIER_TABLE_CELLS.map(field => field.label).join(',');
            const rowsSupplier = supplierSummary
              .filter(row => row.supplierName !== "Total")
              .map(row =>
                SUPPLIER_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Supplier
            const totalSupplierAmount = rowsSupplier.reduce((acc, row) => {
                const [supplier, amount] = row.split(',');
                return acc + parseFloat(amount || 0);
            }, 0);
            const totalSupplierRow = `Total,${totalSupplierAmount.toFixed(2)}`;


            // Generate CSV content for Other
            const headerOther = LEDGER_PAYABLE_ENTRIES_CELLS.map(field => field.label).join(',');
            const rowsOther = templates
              .filter(row => row.description !== "Cost of Sales")
              .map(row =>
                LEDGER_PAYABLE_ENTRIES_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Supplier
            const totalOtherAmount = rowsOther.reduce((acc, row) => {
                const [description, credit] = row.split(',');
                return acc + parseFloat(credit || 0);
            }, 0);
            const totalOtherRow = `Total,${totalOtherAmount.toFixed(2)}`;


            const total = totalStockAmount + totalCarrierAmount + totalFTLAmount + totalSupplierAmount + totalOtherAmount;
            const costSalesTotal = `Cost of Sales Total,${total.toFixed(2)}`;

            // Combine headers, rows, and totals, ensuring "Total" is last
            csvContent = [
                headerStock,
                ...rowsStock,
                '', // Empty line between tables
                headerCarrier,
                ...rowsCarrier,
                totalCarrierRow,
                '', // Empty line between tables
                headerFTL,
                ...rowsFTL,
                totalFTLRow,
                '', // Empty line between tables
                headerSupplier,
                ...rowsSupplier,
                totalSupplierRow,
                '', // Empty line between tables
                headerOther,
                ...rowsOther,
                totalOtherRow,
                '', // Empty line between tables
                costSalesTotal
            ].join('\n');
        }
        else if (this.props.ledger.name === "Sales") {
            const { salesSummary, customerSummary, templates } = this.state;

            // Generate CSV content for Carrier
            const headerSales = SALES_TABLE_CELLS.map(field => field.label).join(',');
            const rowsSales = salesSummary
              .filter(row => row.name !== "Total")
              .map(row =>
                SALES_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            const mappedMarketplaces = rowsSales.map(row => {
                const [key, amount] = row.split(',');
                return `${MARKETPLACE_MAPPING[key] || key},${parseFloat(amount).toFixed(2)}`;
            });


            // Calculate total for Carrier
            const totalSalesAmount = mappedMarketplaces.reduce((acc, row) => {
                const [name, totalBalance] = row.split(',');
                return acc + parseFloat(totalBalance || 0);
            }, 0);
            const totalSalesRow = `Total,${totalSalesAmount.toFixed(2)}`;



            // Generate CSV content for Carrier
            const headerCustomer = CUSTOMER_TABLE_CELLS.map(field => field.label).join(',');
            const rowsCustomer = customerSummary
              .filter(row => row.name !== "Total")
              .map(row =>
                CUSTOMER_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Carrier
            const totalCustomerAmount = rowsCustomer.reduce((acc, row) => {
                const [name, totalBalance] = row.split(',');
                return acc + parseFloat(totalBalance || 0);
            }, 0);
            const totalCustomerRow = `Total,${totalCustomerAmount.toFixed(2)}`;


            // Generate CSV content for Other
            const headerOther = LEDGER_PAYABLE_ENTRIES_CELLS.map(field => field.label).join(',');
            const rowsOther = templates
              .filter(row => row.description !== "Sales")
              .map(row =>
                LEDGER_PAYABLE_ENTRIES_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Supplier
            const totalOtherAmount = rowsOther.reduce((acc, row) => {
                const [description, credit] = row.split(',');
                return acc + parseFloat(credit || 0);
            }, 0);
            const totalOtherRow = `Total,${totalOtherAmount.toFixed(2)}`;


            const total = totalSalesAmount + totalCustomerAmount + totalOtherAmount;
            const salesTotal = `Sales Total,${total.toFixed(2)}`;

            // Combine headers, rows, and totals, ensuring "Total" is last
            csvContent = [
                headerSales,
                ...mappedMarketplaces,
                totalSalesRow,
                '', // Empty line between tables
                headerCustomer,
                ...rowsCustomer,
                totalCustomerRow,
                '', // Empty line between tables
                headerOther,
                ...rowsOther,
                totalOtherRow,
                '', // Empty line between tables
                salesTotal
            ].join('\n');
        }
        else if (this.props.ledger.name === "Accounts Receivables"){
            const { marketplaceSummary, customerSummary, templates } = this.state;

            // Generate CSV content for Carrier
            const headerMarketplace = MARKETPLACE_TABLE_CELLS.map(field => field.label).join(',');
            const rowsMarketplace = marketplaceSummary
              .filter(row => row.name !== "Total")
              .map(row =>
                MARKETPLACE_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            const mappedMarketplaces = rowsMarketplace.map(row => {
                const [key, amount] = row.split(',');
                return `${MARKETPLACE_MAPPING[key] || key},${parseFloat(amount).toFixed(2)}`;
            });


            // Calculate total for Carrier
            const totalMarketplaceAmount = mappedMarketplaces.reduce((acc, row) => {
                const [name, totalBalance] = row.split(',');
                return acc + parseFloat(totalBalance || 0);
            }, 0);
            const totalMarketplaceRow = `Total,${totalMarketplaceAmount.toFixed(2)}`;



            // Generate CSV content for Carrier
            const headerCustomer = CUSTOMER_TABLE_CELLS.map(field => field.label).join(',');
            const rowsCustomer = customerSummary
              .filter(row => row.name !== "Total")
              .map(row =>
                CUSTOMER_TABLE_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Carrier
            const totalCustomerAmount = rowsCustomer.reduce((acc, row) => {
                const [name, totalBalance] = row.split(',');
                return acc + parseFloat(totalBalance || 0);
            }, 0);
            const totalCustomerRow = `Total,${totalCustomerAmount.toFixed(2)}`;

            // Generate CSV content for Other
            const headerOther = LEDGER_PAYABLE_ENTRIES_CELLS.map(field => field.label).join(',');
            const rowsOther = templates
              .filter(row => row.description !== "Accounts Receivables")
              .map(row =>
                LEDGER_PAYABLE_ENTRIES_CELLS.map(field => {
                    let value = row[field.id] ?? '';
                    value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
                    return value;
                }).join(',')
              );

            // Calculate total for Supplier
            const totalOtherAmount = rowsOther.reduce((acc, row) => {
                const [description, credit] = row.split(',');
                return acc + parseFloat(credit || 0);
            }, 0);
            const totalOtherRow = `Total,${totalOtherAmount.toFixed(2)}`;


            const total = totalMarketplaceAmount + totalCustomerAmount + totalOtherAmount;
            const accountsReceivablesTotal = `Accounts Receivables Total,${total.toFixed(2)}`;

            // Combine headers, rows, and totals, ensuring "Total" is last
            csvContent = [
                headerMarketplace,
                ...mappedMarketplaces,
                totalMarketplaceRow,
                '', // Empty line between tables
                headerCustomer,
                ...rowsCustomer,
                totalCustomerRow,
                '', // Empty line between tables
                headerOther,
                ...rowsOther,
                totalOtherRow,
                '', // Empty line between tables
                accountsReceivablesTotal
            ].join('\n');
        }

        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${ledgerName}_${this.props.selectedYear}_${this.props.selectedCurrency}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    handleExport = () => {

        const ledgerName = this.state.isShowMarketplaceDetails
          ? `Ledger - ${this.props.ledger.name} (${MARKETPLACE_MAPPING[this.state.selectedMarketplace.name] ?? this.state.selectedMarketplace.name})`
          : `Ledger - ${this.props.ledger.name}`;

        const { templates } = this.state;
        if (!templates || templates.length === 0) {
            return;
        }

        // Generate CSV content
        const headers = LEDGER_ENTRIES_CELLS.map(field => field.label).join(',');
        const rows = templates.map(row =>
          LEDGER_ENTRIES_CELLS.map(field => {
              let value = row[field.id] ?? '';
              value = value.toString().includes(',') ? `"${value}"` : value;
              return value;
          }).join(',')
        );

        const cleanRows = rows.map(row =>
          row.split(',').map(value => value.trim() === "0.00" ? '' : value).join(',')
        );

        const csvContent = [headers, ...cleanRows].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        //Create and trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${ledgerName}_${this.props.selectedYear}_${this.props.selectedCurrency}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    updateMarketplace = () => {
        // Clear current entries
        const updatedMarketplace = { ...this.state.selectedMarketplace, entries: [] };

        // Copy entries from marketplaceEntries
        this.state.marketplaceEntries.forEach(entry => {
            updatedMarketplace.entries.push({ ...entry });
        });

        // Calculate the total balance from the last entry
        const lastEntry = this.state.marketplaceEntries[this.state.marketplaceEntries.length - 1];
        updatedMarketplace.totalBalance = lastEntry ? parseFloat(lastEntry.balance) : 0.00;

        return updatedMarketplace
    };


    handleMarketplaceEntriesSave = () => {
        const updatedMarketplace = this.updateMarketplace()
        this.props.saveMarketplaceLedger({marketplace: updatedMarketplace,
            ledgerId: this.props.ledger.ledgerId,
            year: this.props.selectedYear, currency: this.props.selectedCurrency});
        this.setState({isEditMarketplace: false});
    }

    handleSave = () => {
        this.props.handleSave(this.state.templates, this.props.ledger.ledgerId);
        this.setState({isEditEntries: false});
    }

    isSaveDisabled = () => {
        return this.state.templates.some(template =>
            !template.description || !template.debit || !template.credit || !template.balance
        );
    };

    formatDollar = (amount, forceTwoDecimals) => {

        if (amount === null || amount === undefined || amount === 0) {
            return "0.00";
        }

        let value;
        const amountStr = amount.toString(); // Convert to string to safely use match and replaceAll

        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2);
        } else {
            // If the amount has more than two decimal places, limit it to two
            if (amountStr.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2);
                } catch {
                    value = amountStr.replaceAll(',', '');
                }
            } else {
                value = amountStr.replaceAll(',', '');

            }
        }
        return value
    }

    formatAmount = (amount) => {

        if (amount === null || amount === undefined || amount === 0) {
            return "0.00";
        }

        let value;
        const amountStr = amount.toString(); // Convert to string to safely use match and replaceAll


        // If the amount has more than two decimal places, limit it to two
        if (amountStr.match(/.*\.\d{2}.*/)) {
            try {
                value = this.parseNumber(amount).toFixed(2);
            } catch {
                value = amountStr.replaceAll(',', '');
            }
        } else {
            value = amountStr.replaceAll(',', '');

        }

        return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }


    parseNumber = (value) => {
        if (typeof value === 'number') {
            return value;
        }
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''))
        }
        return NaN;
    }

    handleTemplateDateChange = (date, index, entry = null) => {

        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                "date": date,

            };

            return {templates: updatedTemplates};
        });
    };


    handleTemplateChange = (e, index, entry = null) => {
        let {name, value} = e.target;

        // Disable changes for debit and credit at index 0
        if (index === 0 && entry.description === 'Opening Balance' && (name === 'debit' || name === 'credit')) {
            return; // Prevent changes to debit or credit at index 0
        }
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: value,

            };

            return {templates: updatedTemplates};
        });
    };


    removeTemplateRow = (index) => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.filter((_, curIndex) => curIndex !== index);
            return {templates: updatedTemplates};
        }, this.updateBalances);
    };

    addTemplateRow = () => {
        const {selectedYear} = this.props;
        const date = selectedYear === new Date().getFullYear().toString()
            ? new Date(Date.now()) // Use current date for the current year
            : new Date(`${selectedYear}-01-02`); // Use January 1st of the selected year

        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates, {
                date: date, // Use the calculated date
                description: "",
                debit: "0.00",
                credit: "0.00",
                balance: "0.00",
                currency: this.props.selectedCurrency,
                transactionId: null,
                notes : ''
            }];
            return {templates: updatedTemplates};
        }, this.updateBalances);
    };

    updateBalances = () => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.map((template, index) => {
                let previousBalance;

                if (index === 0) {
                    // Set the opening balance for the first entry
                    previousBalance = this.parseNumber(template.balance);
                } else {
                    previousBalance = this.parseNumber(prevState.templates[index - 1].balance);
                }

                let debit = template.debit ? this.parseNumber(template.debit) : 0;
                let credit = template.credit ? this.parseNumber(template.credit) : 0;


                let newBalance = previousBalance;


                if (index !== 0) { // Skip balance calculation for the opening balance
                    switch (this.props.ledger.type) {
                        case 'Assets':
                        case 'Expenses':
                            newBalance += (debit - credit);
                            break;
                        case 'Income':
                        case 'Equity':
                        case 'Liabilities':
                            newBalance += (credit - debit);
                            break;
                        default:
                            console.warn(`Unknown ledger type: ${template.type}`);
                    }
                } else if (index === 0 && template.description !== 'Opening Balance') {
                    switch (this.props.ledger.type) {
                        case 'Assets':
                        case 'Expenses':
                            newBalance = (debit - credit);
                            break;
                        case 'Income':
                        case 'Equity':
                        case 'Liabilities':
                            newBalance = (credit - debit);
                            break;
                        default:
                            console.warn(`Unknown ledger type: ${template.type}`);
                    }
                }

                template.balance = this.formatDollar(newBalance.toFixed(2));
                return template;
            });

            return {templates: updatedTemplates};
        });
    }

    removeMarketplaceRow = (index) => {
        this.setState(prevState => {
            const updatedTemplates = prevState.marketplaceEntries.filter((_, curIndex) => curIndex !== index);
            return {marketplaceEntries: updatedTemplates};
        }, this.updateMarketplaceBalances);
    };

    addMarketplaceRow = () => {
        this.setState(prevState => {
            const updatedTemplates = [...prevState.marketplaceEntries, {
                description: "",
                debit: "0.00",
                credit: "0.00",
                balance: "0.00",
                currency: this.props.selectedCurrency,
                transactionId: null,
            }];
            return {marketplaceEntries: updatedTemplates};
        }, this.updateMarketplaceBalances);
    };


    handleMarketplaceChange = (e, index, entry = null) => {
        let {name, value} = e.target;

        // Disable changes for debit and credit at index 0
        if (index === 0 && entry.description === 'Opening Balance' && (name === 'debit' || name === 'credit')) {
            return; // Prevent changes to debit or credit at index 0
        }

        this.setState(prevState => {
            const updatedTemplates = [...prevState.marketplaceEntries];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: value,

            };

            return {marketplaceEntries: updatedTemplates};
        });
    };

    updateMarketplaceBalances = () => {
        this.setState(prevState => {
            const updatedTemplates = prevState.marketplaceEntries.map((template, index) => {
                let previousBalance;

                if (index === 0) {
                    // Set the opening balance for the first entry
                    previousBalance = this.parseNumber(template.balance);
                } else {
                    previousBalance = this.parseNumber(prevState.marketplaceEntries[index - 1].balance);
                }

                let debit = template.debit ? this.parseNumber(template.debit) : 0;
                let credit = template.credit ? this.parseNumber(template.credit) : 0;


                let newBalance = previousBalance;


                if (index !== 0) { // Skip balance calculation for the opening balance
                    switch (this.props.ledger.type) {
                        case 'Assets':
                        case 'Expenses':
                            newBalance += (debit - credit);
                            break;
                        case 'Income':
                        case 'Equity':
                        case 'Liabilities':
                            newBalance += (credit - debit);
                            break;
                        default:
                            console.warn(`Unknown ledger type: ${template.type}`);
                    }
                } else if (index === 0 && template.description !== 'Opening Balance') {
                    switch (this.props.ledger.type) {
                        case 'Assets':
                        case 'Expenses':
                            newBalance = (debit - credit);
                            break;
                        case 'Income':
                        case 'Equity':
                        case 'Liabilities':
                            newBalance = (credit - debit);
                            break;
                        default:
                            console.warn(`Unknown ledger type: ${template.type}`);
                    }
                }

                template.balance = this.formatDollar(newBalance.toFixed(2));
                return template;
            });

            return {marketplaceEntries: updatedTemplates};
        });
    }

    handleMarketplaceBlur = (e, index) => {
        let {name, value} = e.target;
        if (name === 'debit' || name === 'credit' || name === 'balance') {
            value = this.formatDollar(value);
        }
        this.setState(prevState => {
            const updatedTemplates = [...prevState.marketplaceEntries];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: isNaN(parseFloat(value)) ? "0.00" : this.formatDollar(value, true),
            };
            return {marketplaceEntries: updatedTemplates};
        }, this.updateMarketplaceBalances);
    };

    handleBlur = (e, index) => {
        let {name, value} = e.target;
        if (name === 'debit' || name === 'credit' || name === 'balance') {
            value = this.formatDollar(value);
        }
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: isNaN(parseFloat(value)) ? "0.00" : this.formatDollar(value, true),
            };
            return {templates: updatedTemplates};
        }, this.updateBalances);
    };

    getPayableTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        LEDGER_PAYABLE_ENTRIES_CELLS.forEach((headCell, index) => {
            if(index === 0){
                headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align,"86.5%"))
            }else{
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align,"auto"))
            }
        })
        headers.push(Layout.columnHead(null, null,null,"200px"))
        return headers
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        LEDGER_ENTRIES_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getMarketplaceEntriesTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        MARKETPLACE_ENTRIES_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, null, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getStockTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        STOCK_IN_HAND_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.null, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getCarrierTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        CARRIER_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getMarketplaceTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        MARKETPLACE_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getFTLTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        FTL_CARRIER_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getSupplierTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        SUPPLIER_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getCustomerTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        CUSTOMER_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getSalesTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        SALES_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    formatDate = (date) => {
        try {
            const parsedDate = new Date(date);
            if (isNaN(parsedDate)) {
                throw new Error('Invalid Date');
            }
            return format(parsedDate, 'yyyy-MM-dd');
        } catch (e) {
            return date;
        }
    };

    formatBalance = (balance) => {
        return parseFloat(balance)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    setLedgerEntries = (ledgerEntries) => {
        this.setState({templates: ledgerEntries})
    }

    setStockEntries = (summary) => {
        this.setState({stockSummary: summary})
    }

    getStockTableRow = (row, getRowState, setRowState) => {
        return<StockEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          stockSummary={this.state.stockSummary}
          setStockEntries={this.setStockEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }

    setCarrierEntries = (summary) => {
        this.setState({carrierSummary: summary})
    }

    getCarrierTableRow = (row, getRowState, setRowState) => {
        return<CarrierEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          carrierSummary={this.state.carrierSummary}
          setCarrierEntries={this.setCarrierEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }

    setCustomerEntries = (summary) => {
        this.setState({customerSummary: summary})
    }

    getCustomerTableRow = (row, getRowState, setRowState) => {
        return<CustomerEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          customerSummary={this.state.customerSummary}
          setCustomerEntries={this.setCustomerEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }

    setSalesEntries = (summary) => {
        this.setState({salesSummary: summary})
    }

    getSalesTableRow = (row, getRowState, setRowState) => {
        return<SalesEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          salesSummary={this.state.salesSummary}
          setSalesEntries={this.setSalesEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }


    setSupplierEntries = (summary) => {
        this.setState({supplierSummary: summary})
    }

    getSupplierTableRow = (row, getRowState, setRowState) => {
        return<SupplierEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          supplierSummary={this.state.supplierSummary}
          setSupplierEntries={this.setSupplierEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }


    setFTLEntries = (summary) => {
        this.setState({ftlSummary: summary})
    }

    getFTLTableRow = (row, getRowState, setRowState) => {
        return<FTLEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          ftlSummary={this.state.ftlSummary}
          setFTLEntries={this.setFTLEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }


    setShowMarketplaceDetails = (bool,row) =>{
        this.setState({isShowMarketplaceDetails: bool,selectedMarketplace: row, marketplaceEntries: [...row.entries]},()=>{
            this.updateMarketplaceBalances()
        })
    }

    setMarketplaceEntries = (summary) => {
        this.setState({marketplaceSummary: summary})
    }

    getMarketplaceTableRow = (row, getRowState, setRowState) => {
        return<MarketplaceEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          marketplaceSummary={this.state.marketplaceSummary}
          setMarketplaceEntries={this.setMarketplaceEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}
          setShowMarketplaceDetails={this.setShowMarketplaceDetails}

        />
    }

    getPayableTableRow = (row, getRowState, setRowState,ledgerType) => {
        return<LedgerEntryTableRow
            row={row}
            isEditPayable={this.state.isEditPayable}
            allLedgers={this.state.templates}
            setLedgerEntries={this.setLedgerEntries}
            getRowState={getRowState}
            setRowState={setRowState}
            setCurrentPage={this.props.setCurrentPage}
            listingIdBeingEdited={this.state.listingIdBeingEdited}
            setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
            year={this.props.selectedYear}
            currency={this.props.selectedCurrency}
            ledgerType={ledgerType}
            updateEntry={this.props.updateEntry}
            deleteEntry={this.props.deleteEntry}
            ledgerId={this.props.ledger.ledgerId}
            setHideFilterBox={this.setHideFilterBox}
            categories={this.props.categories}
            ledgerCategories={this.props.allLedgers}

        />
    }

    getMarketplaceDetailsTableRow = (row) => {
        return <React.Fragment>

            <TableCell style={{width: "120px",height:"90px", padding:"0", margin:"0"}}>

            </TableCell>




            <TableCell style={{textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto"}}>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    {row.description}
                </div>
            </TableCell>


            <TableCell style={{ textAlign: 'right', padding: '5px', paddingRight: '50px', width: 'auto' }}>
                {this.formatBalance(row.debit) !== '0.00' && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <span style={{ flexShrink: 0 }}>$</span>
                      <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
        {this.formatBalance(row.debit)}
      </span>
                  </div>
                )}
            </TableCell>


            <TableCell style={{ textAlign: 'right', padding: '5px', paddingRight: '50px', width: 'auto' }}>
                {this.formatBalance(row.credit) !== '0.00' && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <span style={{ flexShrink: 0 }}>$</span>
                      <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
        {this.formatBalance(row.credit)}
      </span>
                  </div>
                )}
            </TableCell>


            <TableCell style={{textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto"}}>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'left'}}>
                    <span style={{flexShrink: 0}}>$</span>
                    <span style={{marginLeft: '55px', minWidth: '80px', textAlign: 'right'}}>
                    {this.formatBalance(row.balance)}
                </span>
                </div>
            </TableCell>

        </React.Fragment>
    }

    getTableRow = (row, getRowState, setRowState) => {
        return <React.Fragment>

            <TableCell style={{width: "120px",height:"90px", padding:"0", margin:"0"}}>

            </TableCell>

            <TableCell style={{padding:"0", margin:"0"}}>
                {this.formatDate(row.date)}
            </TableCell>


            <TableCell style={{textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto"}}>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    {row.description}
                </div>
            </TableCell>


            <TableCell style={{ textAlign: 'right', padding: '5px', paddingRight: '50px', width: 'auto' }}>
                {this.formatBalance(row.debit) !== '0.00' && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <span style={{ flexShrink: 0 }}>$</span>
                      <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
        {this.formatBalance(row.debit)}
      </span>
                  </div>
                )}
            </TableCell>


            <TableCell style={{ textAlign: 'right', padding: '5px', paddingRight: '50px', width: 'auto' }}>
                {this.formatBalance(row.credit) !== '0.00' && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <span style={{ flexShrink: 0 }}>$</span>
                      <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
        {this.formatBalance(row.credit)}
      </span>
                  </div>
                )}
            </TableCell>


            <TableCell style={{textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto"}}>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'left'}}>
                    <span style={{flexShrink: 0}}>$</span>
                    <span style={{marginLeft: '55px', minWidth: '80px', textAlign: 'right'}}>
                    {this.formatBalance(row.balance)}
                </span>
                </div>
            </TableCell>

        </React.Fragment>
    }

    getTotal = () => {
        const totals = this.state.templates.reduce(
          (acc, entry) => {
              const credit = parseFloat(entry.credit) || 0; // Convert credit to a number
              const debit = parseFloat(entry.debit) || 0; // Convert credit to a number
              if (entry.description === 'Cost of Sales' ||
                entry.description === 'Accounts Payable' || entry.description === 'Sales'  ) {
                  acc.costOfSalesTotal += credit;
              }else if(entry.description === 'Accounts Receivables'){
                  acc.costOfSalesTotal += debit;
              } else {
                  acc.otherTotal += credit;
              }
              return acc;
          },
          { costOfSalesTotal: 0, otherTotal: 0 } // Initial accumulator values
        );

        const grandTotal = totals.costOfSalesTotal + totals.otherTotal; // Combine totals
        return { ...totals, grandTotal }; // Return both individual totals and grand total
    };

    handleOpenFetch = () => {
        this.setState({
            isDialogFetch: true,
        });
    };

    handleRefresh=()=>{
        const data = {
            ledgerId: this.props.ledger.ledgerId,
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency
        }

        const callback = (error, response) => {
            if (response) {
                this.props.handleSave(response, this.props.ledger.ledgerId);
                this.handleCloseFetch()
            }
        };

        this.props.fetchTransactionsLedger(data,callback)
    }

    handleCloseFetch= () => {
        this.setState({
            isDialogFetch: false,
        });
    };

    handleOpenMarketplaceNotesDialog = (index) => {

        this.setState({
            isMarketplaceDialog: true,
            currentMarketplaceNote: this.state.marketplaceEntries[index].note || '',
            selectedMarketplaceIndex: index,
        });
    };

    handleCloseMarketplaceNotesDialog = () => {
        this.setState({
            isMarketplaceDialog: false,
            currentMarketplaceNote: '',
            selectedMarketplaceIndex: null,
        });
    };

    handleOpenNotesDialog = (index) => {
        this.setState({
            isDialogOpen: true,
            currentNote: this.state.templates[index].note || '',
            selectedIndex: index,
        });
    };

    handleCloseNotesDialog = () => {
        this.setState({
            isDialogOpen: false,
            currentNote: '',
            selectedIndex: null,
        });
    };

    handleSaveNote = () => {
        const { selectedIndex, currentNote, templates } = this.state;
        if (selectedIndex !== null) {
            const updatedTemplates = [...templates];
            updatedTemplates[selectedIndex].note = currentNote;
            this.setState({ templates: updatedTemplates, isDialogOpen: false, currentNote: '', selectedIndex: null },()=>{
                this.props.handleSave(this.state.templates, this.props.ledger.ledgerId);
            });
        }


    };

    handleSaveMarketplaceNote = () => {
        const { selectedMarketplaceIndex, currentMarketplaceNote, marketplaceEntries } = this.state;
        if (selectedMarketplaceIndex !== null) {
            const updatedTemplates = [...marketplaceEntries];
            updatedTemplates[selectedMarketplaceIndex].note = currentMarketplaceNote;
            this.setState({marketplaceEntries: updatedTemplates,isMarketplaceDialog : false, currentMarketplaceNote: '', selectedMarketplaceIndex: null },()=>{
                    this.handleMarketplaceEntriesSave()
            });
        }


    };

    render() {

        const {isDialogOpen, currentNote,} = this.state;
        const pageName = this.state.isShowMarketplaceDetails
          ? `Ledger - ${this.props.ledger.name} (${MARKETPLACE_MAPPING[this.state.selectedMarketplace.name] ?? this.state.selectedMarketplace.name})`
          : `Ledger - ${this.props.ledger.name}`;


        return (
          <React.Fragment>
            <TopToolbar
              pageName={pageName}
              menuItems={this.getContextualMenuItems()}
            />

              <Dialog open={this.state.isDialogFetch} onClose={this.handleCloseFetch}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                      <DialogContentText>
                          Existing entries (except for manual entries) will be deleted and re-fetched from the source. Would you like to continue?
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>

                      <div className='save-cancel-buttons' style={{marginRight:'16px'}}>
                          <Button
                            variant='contained'
                            onClick={this.handleCloseFetch}
                          >
                              No
                          </Button>

                          <div className='save-spacing'/>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={this.handleRefresh}
                          >
                              Yes
                          </Button>
                      </div>

                  </DialogActions>
              </Dialog>

            <Dialog open={isDialogOpen} onClose={this.handleCloseNotesDialog}>
              <DialogTitle>Edit Note</DialogTitle>
              <DialogContentText style={{ width: '500px' }}></DialogContentText>
              <DialogContent>
                <TextField
                  label="Note"
                  multiline
                  minRows={4}
                  variant="outlined"
                  fullWidth
                  value={currentNote}
                  onChange={(e) =>
                    this.setState({ currentNote: e.target.value })
                  }
                />
              </DialogContent>
              <DialogActions style={{ marginBottom: '16px' }}>
                <Button
                  variant="contained"
                  onClick={this.handleCloseNotesDialog}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSaveNote}
                  style={{ marginLeft: '16px', marginRight: '16px' }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>



              <Dialog open={this.state.isMarketplaceDialog} onClose={this.handleCloseMarketplaceNotesDialog}>
                  <DialogTitle>Edit Note</DialogTitle>
                  <DialogContentText style={{ width: '500px' }}></DialogContentText>
                  <DialogContent>
                      <TextField
                        label="Note"
                        multiline
                        minRows={4}
                        variant="outlined"
                        fullWidth
                        value={this.state.currentMarketplaceNote}
                        onChange={(e) =>
                          this.setState({ currentMarketplaceNote: e.target.value })
                        }
                      />
                  </DialogContent>
                  <DialogActions style={{ marginBottom: '16px' }}>
                      <Button
                        variant="contained"
                        onClick={this.handleCloseMarketplaceNotesDialog}
                      >
                          Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSaveMarketplaceNote}
                        style={{ marginLeft: '16px', marginRight: '16px' }}
                      >
                          Save
                      </Button>
                  </DialogActions>
              </Dialog>

            {!this.state.isShowMarketplaceDetails && (
              <Box className="box-ledger">
                <FormControl variant="outlined" className="form-control-ledger">
                  <InputLabel id="year-label">Year</InputLabel>
                  <Select
                    className="select-year-ledger"
                    labelId="year-label"
                    value={this.props.selectedYear}
                    onChange={this.props.handleYearChange}
                    label="Year"
                  >
                    {this.props.years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className="form-control">
                  <InputLabel id="year-label">Currency</InputLabel>
                  <Select
                    className="select-currency-ledger"
                    labelId="currency-label"
                    value={this.props.selectedCurrency}
                    onChange={this.props.handleChange}
                    label="Currency"
                  >
                    {this.props.currency.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {(this.props.ledger.name === 'Cost of Sales' ||
              this.props.ledger.name === 'Accounts Receivables' ||
              this.props.ledger.name === 'Accounts Payable') ||
              this.props.ledger.name === 'Sales' &&
              this.props.selectedCurrency !== 'Consolidated CAD' &&
              !this.state.isShowMarketplaceDetails && (
                <div style={{ marginLeft: '32px' }}>
                  Last updated: {Utils.formatDateTime(this.props.lastUpdated)}
                </div>
              )}

            {this.state.isEditMarketplace && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  marginTop: '16px',
                  marginLeft: '24px',
                  marginRight: '24px',
                }}
              >
                <div className="transaction-form-section">
                  {this.state.marketplaceEntries.map((template, index) => (
                    <div
                      className="transaction-form-details"
                      key={index}
                      draggable={
                        ![
                          'Opening Balance',
                          'Income (Gross Sales)',
                          'Net Tax Received',
                          'Total Expenses',
                          'Remittance',
                        ].includes(template.description)
                      }
                      onDragStart={() => {
                        if (
                          ![
                            'Opening Balance',
                            'Income (Gross Sales)',
                            'Net Tax Received',
                            'Total Expenses',
                            'Remittance',
                          ].includes(template.description)
                        ) {
                          this.onDragStart(index);
                        }
                      }}
                      onDragOver={(e) => {
                        const restrictedDescriptions = [
                          'Opening Balance',
                          'Income (Gross Sales)',
                          'Net Tax Received',
                          'Total Expenses',
                          'Remittance',
                        ];
                        // Prevent dragging over restricted items
                        if (
                          !restrictedDescriptions.includes(template.description)
                        ) {
                          this.onDragMarketplaceOver(index);
                        }
                        e.preventDefault();
                      }}
                      onDragEnd={this.onDragEnd}
                      style={{
                        background:
                          this.state.draggedIndex === index
                            ? '#f0f0f0'
                            : 'white',
                      }}
                    >
                      <div style={{ width: '7%', display: 'flex' }}>
                        <Tooltip title={'Remove Entry'}>
                          <IconButton
                            onClick={() => this.removeMarketplaceRow(index)}
                          >
                            <RemoveCircleIcon fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>

                        {template.transactionId === null && (
                          <div className="ledger-circle-icon">M</div>
                        )}

                          <Tooltip title={'Notes'}>
                              {template.note ? (
                                <IconButton
                                  onClick={() => this.handleOpenMarketplaceNotesDialog(index)}
                                >
                                    <TextSnippetIcon fontSize="small" />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() => this.handleOpenMarketplaceNotesDialog(index)}
                                >
                                    <TextSnippetOutlinedIcon fontSize="small" />
                                </IconButton>
                              )}
                          </Tooltip>
                      </div>


                      <TextField
                        variant="outlined"
                        name="description"
                        label="Description"
                        style={{ width: '25%' }}
                        InputLabelProps={{ shrink: true }}
                        value={template.description || ''}
                        onChange={(e) =>
                          this.handleMarketplaceChange(
                            {
                              target: {
                                name: 'description',
                                value: e.target.value,
                              },
                            },
                            index,
                            template
                          )
                        }
                        required
                        InputProps={{
                          readOnly: [
                            'Opening Balance',
                            'Income (Gross Sales)',
                            'Net Tax Received',
                            'Total Expenses',
                            'Remittance',
                          ].includes(template.description),
                        }}
                        error={!template.description}
                      />

                      <TextField
                        variant="outlined"
                        name="debit"
                        label="Debit"
                        style={{ width: '15%' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.debit)}
                        onChange={(e) =>
                          this.handleMarketplaceChange(
                            {
                              target: {
                                name: 'debit',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleMarketplaceBlur(e, index)}
                        required
                        error={
                          template.debit === null ||
                          template.debit === undefined ||
                          template.debit === ''
                        }
                      />

                      <TextField
                        variant="outlined"
                        name="credit"
                        label="Credit"
                        style={{ width: '15%' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.credit)}
                        onChange={(e) =>
                          this.handleMarketplaceChange(
                            {
                              target: {
                                name: 'credit',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleMarketplaceBlur(e, index)}
                        required
                        error={
                          template.credit === null ||
                          template.credit === undefined ||
                          template.credit === ''
                        }
                      />

                      <TextField
                        variant="outlined"
                        name="balance"
                        style={{ width: '15%' }}
                        label="Balance"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.balance)}
                        onChange={(e) =>
                          this.handleMarketplaceChange(
                            {
                              target: {
                                name: 'balance',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleMarketplaceBlur(e, index)}
                        required
                        error={!template.balance}
                      />
                      <MenuIcon
                        style={{
                          cursor: 'grab',
                          marginLeft: '8px',
                          opacity: '0.6',
                          alignSelf: 'center',
                          visibility: [
                            'Opening Balance',
                            'Income (Gross Sales)',
                            'Net Tax Received',
                            'Total Expenses',
                            'Remittance',
                          ].includes(template.description)
                            ? 'hidden'
                            : 'visible',
                        }}
                      />
                    </div>
                  ))}
                  <div style={{ marginTop: '20px' }}>
                    <IconButton onClick={() => this.addMarketplaceRow()}>
                      <AddCircleIcon fontSize="small" color="secondary" />
                    </IconButton>
                    {/*<IconButton onClick={() => this.fetchLedgerEntries()}>*/}
                    {/*  <RefreshIcon fontSize="small" color="green" />*/}
                    {/*</IconButton>*/}
                  </div>
                </div>
              </div>
            )}

            {this.state.isShowMarketplaceDetails &&
              !this.state.isEditMarketplace && (
                <div style={{ marginTop: '-50px' }}>
                  <TablePage
                    tableName="LedgerEntriesTable"
                    tableColumnHeads={this.getMarketplaceEntriesTableHeaders()}
                    tableRows={this.state.marketplaceEntries}
                    tableRow={(row, index, getRowState, setRowState) =>
                      this.getMarketplaceDetailsTableRow(
                        row,
                        getRowState,
                        setRowState
                      )
                    }
                    getRowId={(row) => row.transactionId}
                    paginationText="Marketplace entries per page"
                  />
                </div>
              )}

            {this.state.isShowMarketplaceDetails ? null : this.state
                .isEditEntries ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  marginTop: '16px',
                  marginLeft: '24px',
                  marginRight: '24px',
                }}
              >
                <div className="transaction-form-section">
                  {this.state.templates.map((template, index) => (
                    <div
                      className="transaction-form-details"
                      key={index}
                      draggable
                      onDragStart={() => this.onDragStart(index)}
                      onDragOver={() => this.onDragOver(index)}
                      onDragEnd={this.onDragEnd}
                      style={{
                        background:
                          this.state.draggedIndex === index
                            ? '#f0f0f0'
                            : 'white',
                      }}
                    >
                      <div style={{ width: '7%', display: 'flex' }}>
                        <Tooltip title={'Remove Entry'}>
                          <IconButton
                            onClick={() => this.removeTemplateRow(index)}
                          >
                            <RemoveCircleIcon fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={'Notes'}>
                          {this.state.templates[index].note ? (
                            <IconButton
                              onClick={() => this.handleOpenNotesDialog(index)}
                            >
                              <TextSnippetIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => this.handleOpenNotesDialog(index)}
                            >
                              <TextSnippetOutlinedIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Tooltip>

                        {template.transactionId === null && (
                          <div className="ledger-circle-icon">M</div>
                        )}
                      </div>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="date"
                          label="Date"
                          value={template.date}
                          onChange={(date) => {
                            if (!date || isNaN(date.getTime())) {
                              return;
                            }

                            const options = {
                              timeZone: 'UTC',
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: false,
                            };
                            const formatter = new Intl.DateTimeFormat(
                              'en-CA',
                              options
                            );
                            const parts = formatter.formatToParts(date);
                            const formattedDate = `${
                              parts.find((p) => p.type === 'year').value
                            }-${parts.find((p) => p.type === 'month').value}-${
                              parts.find((p) => p.type === 'day').value
                            }T${parts.find((p) => p.type === 'hour').value}:${
                              parts.find((p) => p.type === 'minute').value
                            }:${
                              parts.find((p) => p.type === 'second').value
                            }.000Z`;

                            this.handleTemplateDateChange(
                              formattedDate,
                              index,
                              template
                            );
                          }}
                          format="yyyy-MM-dd"
                          variant="inline"
                          inputVariant="outlined"
                          style={{ width: '15%' }}
                          autoOk
                          required
                        />
                      </MuiPickersUtilsProvider>

                      <TextField
                        variant="outlined"
                        name="description"
                        label="Description"
                        style={{ width: '25%' }}
                        InputLabelProps={{ shrink: true }}
                        value={template.description || ''}
                        onChange={(e) =>
                          this.handleTemplateChange(
                            {
                              target: {
                                name: 'description',
                                value: e.target.value,
                              },
                            },
                            index,
                            template
                          )
                        }
                        required
                        error={!template.description}
                      />

                      <TextField
                        variant="outlined"
                        name="debit"
                        label="Debit"
                        style={{ width: '15%' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.debit)}
                        onChange={(e) =>
                          this.handleTemplateChange(
                            {
                              target: {
                                name: 'debit',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleBlur(e, index)}
                        required
                        error={!template.debit}
                      />

                      <TextField
                        variant="outlined"
                        name="credit"
                        label="Credit"
                        style={{ width: '15%' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.credit)}
                        onChange={(e) =>
                          this.handleTemplateChange(
                            {
                              target: {
                                name: 'credit',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleBlur(e, index)}
                        required
                        error={!template.credit}
                      />

                      <TextField
                        variant="outlined"
                        name="balance"
                        style={{ width: '15%' }}
                        label="Balance"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.balance)}
                        onChange={(e) =>
                          this.handleTemplateChange(
                            {
                              target: {
                                name: 'balance',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleBlur(e, index)}
                        required
                        error={!template.balance}
                      />

                      <MenuIcon
                        style={{
                          cursor: 'grab',
                          marginLeft: '8px',
                          opacity: '0.6',
                          alignSelf: 'center',
                        }}
                      />
                    </div>
                  ))}
                  <div style={{ marginTop: '30px' }}>
                    <IconButton onClick={() => this.addTemplateRow()}>
                      <AddCircleIcon fontSize="small" color="secondary" />
                    </IconButton>
                    <IconButton onClick={() => this.fetchLedgerEntries()}>
                      <RefreshIcon fontSize="small" color="green" />
                    </IconButton>
                  </div>
                </div>
              </div>
            ) : this.props.ledger.name !== 'Accounts Payable' &&
              this.props.ledger.name !== 'Accounts Receivables' &&
              this.props.ledger.name !== 'Cost of Sales' &&
              this.props.ledger.name !==  'Sales' ? (
              <div style={{ marginTop: '-7px' }}>
                <TablePage
                  tableName="LedgerEntriesTable"
                  tableColumnHeads={this.getTableHeaders()}
                  tableRows={this.state.templates}
                  tableRow={(row, index, getRowState, setRowState) =>
                    this.getTableRow(row, getRowState, setRowState)
                  }
                  getRowId={(row) => row.transactionId}
                  paginationText="Ledgers entries per page"
                />
              </div>
            ) : (
              <div style={{ marginTop: '24px', marginBottom: '50px' }}>
                {this.state.stockSummary &&
                  this.state.stockSummary.length > 0 && (
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                      <TablePage
                        type={'stockLedger'}
                        tableName="stockLedger"
                        tableColumnHeads={this.getStockTableHeaders()}
                        tableRows={this.state.stockSummary}
                        tableRow={(row, index, getRowState, setRowState) =>
                          this.getStockTableRow(row, getRowState, setRowState)
                        }
                        getRowId={(row) => row.id}
                      />
                    </div>
                  )}

                {this.state.carrierSummary &&
                  this.state.carrierSummary.length > 0 && (
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                      <TablePage
                        type={'carrierLedger'}
                        tableName="standardCarrier"
                        tableColumnHeads={this.getCarrierTableHeaders()}
                        defaultSortBy={'carrierName'}
                        tableRows={this.state.carrierSummary}
                        tableRow={(row, index, getRowState, setRowState) =>
                          this.getCarrierTableRow(row, getRowState, setRowState)
                        }
                        getRowId={(row) => row.id}
                      />

                      {this.state.isEditPayable && (
                        <div style={{ marginTop: '-18px' }}>
                          <div className="divider-ledger" />
                          <Tooltip
                            title="Add Standard Carrier"
                            style={{ marginLeft: '16px', marginTop: '8px' }}
                          >
                            <IconButton
                              onClick={() => {
                                this.setState(
                                  { listingIdBeingEdited: '' },
                                  () => {
                                    const newCarrierEntry = [
                                      ...this.state.carrierSummary,
                                      {
                                        id: '',
                                        carrierName: '',
                                        CY_SUBTOTAL_PAYABLE: '',
                                      },
                                    ];
                                    this.setState({
                                      carrierSummary: newCarrierEntry,
                                    });
                                  }
                                );
                              }}
                              disabled={this.state.carrierSummary.some(
                                (carrier) => carrier.id === ''
                              )}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                color="secondary"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}

                {this.state.ftlSummary && this.state.ftlSummary.length > 0 && (
                  <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                    <TablePage
                      defaultSortBy={'carrierName'}
                      type={'ftlLedger'}
                      tableName="FTLCarrier"
                      tableColumnHeads={this.getFTLTableHeaders()}
                      tableRows={this.state.ftlSummary}
                      tableRow={(row, index, getRowState, setRowState) =>
                        this.getFTLTableRow(row, getRowState, setRowState)
                      }
                      getRowId={(row) => row.id}
                    />

                    {this.state.isEditPayable && (
                      <div style={{ marginTop: '-18px' }}>
                        <div className="divider-ledger" />
                        <Tooltip
                          title="Add LTL/FTL Carrier"
                          style={{ marginLeft: '16px', marginTop: '8px' }}
                        >
                          <IconButton
                            onClick={() => {
                              this.setState(
                                { listingIdBeingEdited: '' },
                                () => {
                                  const newEntry = [
                                    ...this.state.ftlSummary,
                                    {
                                      id: '',
                                      carrierName: '',
                                      CY_SUBTOTAL_PAYABLE: '',
                                    },
                                  ];
                                  this.setState({ ftlSummary: newEntry });
                                }
                              );
                            }}
                            disabled={this.state.ftlSummary.some(
                              (carrier) => carrier.id === ''
                            )}
                          >
                            <AddCircleIcon fontSize="small" color="secondary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                )}

                {this.state.supplierSummary &&
                  this.state.supplierSummary.length > 0 && (
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                      <TablePage
                        type={'supplierLedger'}
                        tableName="Supplier"
                        tableColumnHeads={this.getSupplierTableHeaders()}
                        tableRows={this.state.supplierSummary}
                        tableRow={(row, index, getRowState, setRowState) =>
                          this.getSupplierTableRow(
                            row,
                            getRowState,
                            setRowState
                          )
                        }
                        getRowId={(row) => row.id}
                        defaultSortBy={'supplierName'}
                      />

                      {this.state.isEditPayable && (
                        <div style={{ marginTop: '-18px' }}>
                          <div className="divider-ledger" />
                          <Tooltip
                            title="Add Supplier"
                            style={{ marginLeft: '16px', marginTop: '8px' }}
                          >
                            <IconButton
                              onClick={() => {
                                this.setState(
                                  { listingIdBeingEdited: '' },
                                  () => {
                                    const newEntry = [
                                      ...this.state.supplierSummary,
                                      {
                                        id: '',
                                        supplierName: '',
                                        CY_SUBTOTAL_PAYABLE: '',
                                      },
                                    ];
                                    this.setState({
                                      supplierSummary: newEntry,
                                    });
                                  }
                                );
                              }}
                              disabled={this.state.supplierSummary.some(
                                (supplier) => supplier.id === ''
                              )}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                color="secondary"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}

                {this.state.marketplaceSummary &&
                  this.state.marketplaceSummary.length > 0 && (
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                      <TablePage
                        defaultSortBy={'marketplace'}
                        type={'marketplaceLedger'}
                        tableName="MarketplaceSummary"
                        tableColumnHeads={this.getMarketplaceTableHeaders()}
                        tableRows={this.state.marketplaceSummary}
                        tableRow={(row, index, getRowState, setRowState) =>
                          this.getMarketplaceTableRow(
                            row,
                            getRowState,
                            setRowState
                          )
                        }
                        getRowId={(row) => row.id}
                      />

                      {this.state.isEditPayable && (
                        <div style={{ marginTop: '-18px' }}>
                          <div className="divider-ledger" />
                          <Tooltip
                            title="Add Marketplace"
                            style={{ marginLeft: '0px', marginTop: '8px' }}
                          >
                            <IconButton
                              onClick={() => {
                                this.setState(
                                  { listingIdBeingEdited: '' },
                                  () => {
                                    const newEntry = [
                                      ...this.state.marketplaceSummary,
                                      {
                                        id: '',
                                        marketplace: '',
                                        amount: '',
                                      },
                                    ];
                                    this.setState({
                                      marketplaceSummary: newEntry,
                                    });
                                  }
                                );
                              }}
                              disabled={this.state.marketplaceSummary.some(
                                (data) => data.id === ''
                              )}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                color="secondary"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}


                  {this.state.salesSummary &&
                    this.state.salesSummary.length > 0 && (
                      <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                          <TablePage
                            type={'salesLedger'}
                            tableName="Sales"
                            tableColumnHeads={this.getSalesTableHeaders()}
                            tableRows={this.state.salesSummary}
                            tableRow={(row, index, getRowState, setRowState) =>
                              this.getSalesTableRow(
                                row,
                                getRowState,
                                setRowState
                              )
                            }
                            getRowId={(row) => row.id}
                            defaultSortBy={'name'}
                          />

                          {this.state.isEditPayable && (
                            <div style={{ marginTop: '-18px' }}>
                                <div className="divider-ledger" />
                                <Tooltip
                                  title="Add Sales"
                                  style={{ marginLeft: '0px', marginTop: '8px' }}
                                >
                                    <IconButton
                                      onClick={() => {
                                          this.setState(
                                            { listingIdBeingEdited: '' },
                                            () => {
                                                const newEntry = [
                                                    ...this.state.salesSummary,
                                                    {
                                                        id: '',
                                                        name: '',
                                                        totalBalance: '',
                                                    },
                                                ];
                                                this.setState({
                                                    salesSummary: newEntry,
                                                });
                                            }
                                          );
                                      }}
                                      disabled={this.state.salesSummary.some(
                                        (sale) => sale.id === ''
                                      )}
                                    >
                                        <AddCircleIcon
                                          fontSize="small"
                                          color="secondary"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                          )}
                      </div>
                    )
                  }



                  {this.state.customerSummary &&
                    this.state.customerSummary.length > 0 && (
                      <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                          <TablePage
                            type={'customerLedger'}
                            tableName="Customer"
                            tableColumnHeads={this.getCustomerTableHeaders()}
                            tableRows={this.state.customerSummary}
                            tableRow={(row, index, getRowState, setRowState) =>
                              this.getCustomerTableRow(
                                row,
                                getRowState,
                                setRowState
                              )
                            }
                            getRowId={(row) => row.id}
                            defaultSortBy={'name'}
                          />

                          {this.state.isEditPayable && (
                            <div style={{ marginTop: '-18px' }}>
                                <div className="divider-ledger" />
                                <Tooltip
                                  title="Add Customer"
                                  style={{ marginLeft: '0px', marginTop: '8px' }}
                                >
                                    <IconButton
                                      onClick={() => {
                                          this.setState(
                                            { listingIdBeingEdited: '' },
                                            () => {
                                                const newEntry = [
                                                    ...this.state.customerSummary,
                                                    {
                                                        id: '',
                                                        name: '',
                                                        totalBalance: '',
                                                    },
                                                ];
                                                this.setState({
                                                    customerSummary: newEntry,
                                                });
                                            }
                                          );
                                      }}
                                      disabled={this.state.customerSummary.some(
                                        (customer) => customer.id === ''
                                      )}
                                    >
                                        <AddCircleIcon
                                          fontSize="small"
                                          color="secondary"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                          )}
                      </div>
                    )
                  }


                {/*{this.props.fetchTransactions?.ledgerEntries &&*/}
                {/*  this.props.fetchTransactions.ledgerEntries.length > 0 && (*/}
                <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                  <TablePage
                    type="LedgerEntriesTable"
                    tableName="LedgerEntriesTable"
                    tableColumnHeads={this.getPayableTableHeaders()}
                    tableRows={this.state.templates}
                    tableRow={(row, index, getRowState, setRowState) =>
                      this.getPayableTableRow(row, getRowState, setRowState)
                    }
                    getRowId={(row) => row.transactionId}
                  />

                  {this.state.isEditPayable && (
                    <Tooltip
                      title="Add Entry"
                      style={{ marginLeft: '0px', marginTop: '8px' }}
                    >
                      <IconButton
                        onClick={() => {
                          this.setState({ listingIdBeingEdited: '' }, () => {
                            const newLedgerEntry = [
                              ...this.state.templates,
                              {
                                transactionId: '',
                                description: '',
                                amount: '',
                              },
                            ];
                            this.setState({ templates: newLedgerEntry });
                          });
                        }}
                        disabled={this.state.templates.some(
                          (template) => template.transactionId === ''
                        )}
                      >
                        <AddCircleIcon fontSize="small" color="secondary" />
                      </IconButton>
                    </Tooltip>
                  )}

                  {this.state.templates &&
                    Array.isArray(this.state.templates) &&
                    this.state.templates.length > 0 &&
                    this.state.templates.some(
                      (entry) =>
                        entry.description !== 'Cost of Sales' &&
                        entry.description !== 'Accounts Receivables' &&
                        entry.description !== 'Accounts Payable' &&
                        entry.description !== 'Sales'
                    ) && (
                      <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                        <TableCell>
                          <IconButton style={{ visibility: 'hidden' }}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: 'right',
                            padding: '5px',
                            paddingRight: '50px',
                            width: '100%',
                            fontWeight: 'bold',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            Total
                          </div>
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: 'right',
                            padding: '5px',
                            paddingRight: '50px',
                            width: 'auto',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ flexShrink: 0 }}>$</span>
                            <span
                              style={{
                                marginLeft: '55px',
                                minWidth: '80px',
                                textAlign: 'right',
                                fontWeight: 'bold',
                              }}
                            >
                              {this.formatBalance(this.getTotal().otherTotal)}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell>
                          <IconButton style={{ visibility: 'hidden' }}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </div>
                    )}

                  {this.state.templates &&
                    this.state.templates.length > 0 &&
                    this.state.templates.some(
                      (entry) =>
                        entry.description === 'Cost of Sales' ||
                        entry.description === 'Accounts Receivables' ||
                        entry.description === 'Accounts Payable' ||
                        entry.description === 'Sales'
                    ) && (
                      <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                        <TableCell>
                          <IconButton style={{ visibility: 'hidden' }}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: 'right',
                            padding: '5px',
                            paddingRight: '50px',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            {this.props.ledger.name + ' Total'}
                          </div>
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: 'right',
                            padding: '5px',
                            paddingRight: '50px',
                            width: 'auto',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ flexShrink: 0, fontWeight: 'bold' }}>$</span>
                            <span
                              style={{
                                marginLeft: '55px',
                                minWidth: '80px',
                                textAlign: 'right',
                                fontWeight: 'bold',
                              }}
                            >
                              {this.formatBalance(this.getTotal().grandTotal)}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell>
                          <IconButton style={{ visibility: 'hidden' }}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </div>
                    )}
                </div>
                {/*)}*/}
              </div>
            )}
          </React.Fragment>
        );
    }
}

export default EditLedger;

