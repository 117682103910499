import React from 'react'

// Table components
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import { Checkbox, Collapse, IconButton, TableHead, TablePagination } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableBody from "@material-ui/core/TableBody";
import SearchableTable from "../../global/Search/SearchableTable";

import FormControlLabel from "@material-ui/core/FormControlLabel";

// Material UI
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from '@material-ui/icons/Delete';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

// Constants

import { ASC, DESC } from "../../global/constants";
import {
    ROWS_PER_PAGE_LABEL,
    ROWS_PER_PAGE,
    INVOICE_HEAD_CELLS,
    DEFAULT_SORT_BY_HEAD_CELL
} from "./constants";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import FilterIcon from "../../shipments/FilterIcon";
import FilterTagsBar from '../../shipments/FilterTagsBar';
import { Slide } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DescriptionIcon from "@material-ui/icons/Description";
import CustomerInvoiceFiltersDialog from "./CustomerInvoiceFiltersDialog";

class CustomerInvoicesTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            searchValue: "",
            order: DESC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            shownIndex: -1,
            deleteId: "",
            selectedStatus: ["Needs Review", "Ready to Send", "Paid", "Payment Pending"],
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            filtersMap: {},
            showFilters: false,
            filtersMapBeforeOpenDialog: {},
            deletedTagsWithCookies: [],
        }
        this.onSearchValueChange = this.onSearchValueChange.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleRequestSort = this.handleRequestSort.bind(this)
        this.getDisplayed = this.getDisplayed.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.selectAll = this.selectAll.bind(this)
    }

    formatDollar = (amount) => { // Regex expression taken from https://stackoverflow.com/questions/2901102
        return amount.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    async componentDidMount() {
        let changeBack = false
        await this.setState({
            searchValue: this.props.searchText,
            filtersMap: this.props.tableSetting?.customerInvoiceFilterSetting,
            selectedStatus: this.props.tableSetting?.customerInvoiceFilterSetting?.selectedStatus ?? []
        })
        let storage = JSON.parse(localStorage.getItem("customerInvoices"))
        if (storage) {
            this.getNewDates()
            const expiry = parseInt(JSON.parse(localStorage.getItem("expiry")))
            if (storage.fromDate !== null && storage.fromDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.onFiltersClose(true, false)
                } else {
                    changeBack = true
                }
            }
            if (storage.toDate !== null && storage.toDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.onFiltersClose(true, false)
                } else {
                    changeBack = true
                }
            }
            if (changeBack) {
                localStorage.removeItem("customerInvoices")
                this.setState({
                    fromDate: this.getFromDate(),
                    toDate: this.getToDate(),
                    filtersMap: {}
                })
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.initializeSearchText()
        }
        if (prevProps.tableSetting?.customerInvoiceFilterSetting !== this.props.tableSetting?.customerInvoiceFilterSetting) {
            this.setState({
                filtersMap: this.addFilterDates(this.props.tableSetting?.customerInvoiceFilterSetting),
                selectedStatus: this.props.tableSetting?.customerInvoiceFilterSetting?.selectedStatus ?? []
            })
        }
    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.props.searchText })
    }

    onSearchValueChange(e) {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value
        });
        this.props.setPage(0)
        this.props.handleSearchChange(e)
    };

    getFromDate = () => {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    getNewDates = () => {
        let filter = {...this.state.filtersMap}
        let storage = JSON.parse(localStorage?.getItem("customerInvoices"))
        if (storage.fromDate !== null && storage.fromDate !== undefined ) {
            const temp = new Date(storage.fromDate)
            filter.fromDate = temp;
            this.setState({
                fromDate: temp
            })
        }
        if (storage.toDate !== null && storage.toDate !== undefined) {
            const temp = new Date(storage.toDate)
            filter.toDate = temp;
            this.setState({
                toDate: temp
            })
        }

        if (filter) {
            this.setState({
                filtersMap: filter,
            })
        }
    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() == defaultFromDate.getDate() &&
            someDate.getMonth() == defaultFromDate.getMonth() &&
            someDate.getFullYear() == defaultFromDate.getFullYear()
    }


    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    addFilterDates = (filters) => {
        if (filters) {
            if (!this.isDefaultFromDate(this.state.fromDate)) {
                filters['fromDate'] = this.state.fromDate
            }
            if (!this.isToday(this.state.toDate)) {
                filters['toDate'] = this.state.toDate
            }
        }
        return filters
    }

    handleChangePage = (event, newPage) => {
        this.props.setPage(newPage)
    };
    handleChangeRowsPerPage = (event) => {
        this.props.setRowsPerPage(event.target.value)
        this.props.setPage(0)
    };
    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };
    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.props.page * this.props.rowsPerPage, (this.props.page + 1) * this.props.rowsPerPage)];
    }

    openDropdown(index) {
        let val = index
        if (this.state.shownIndex === index) val = -1
        this.setState({ shownIndex: val })
    }
    handleEdit(template) {
        this.props.setEditing(template)
    }
    handleDelete() {
        this.props.deleteInvoice({ customerInvoiceId: this.state.deleteId }, () => window.location.reload())
        this.setState({ deleteId: "" })
    }
    handleCheck(e, invoice) {
        let newEntries = { ...this.props.checkedEntries, [invoice.customerInvoiceId]: e.target.checked }
        this.props.setSelected(newEntries, !!this.numSelected(newEntries))
    }
    selectAll(e) {
        let newChecked = {}
        let allEntries = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))
        allEntries.map((entry) => newChecked[entry.customerInvoiceId] = e.target.checked)
        this.props.setSelected(newChecked, !!this.numSelected(newChecked))
    }
    numSelected(checkedEntries) {
        let rows = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))
        return rows.reduceRight((accum, curr) => accum + (checkedEntries[curr.customerInvoiceId] ?? 0), 0)
    }

    toggleFiltersDialog = () => {
        this.setState({
            showFilters: !this.state.showFilters,
            filtersMapBeforeOpenDialog: { ...this.state.filtersMap }
        })
    }

    handleStatusChange = (event) => {
        let filters = { ...this.state.filtersMap };
        let checked = event.target.checked
        let name = event.target.name
        let statuses = []
        if (checked) {
            statuses = [...this.state.selectedStatus, name]
            this.setState({
                selectedStatus: statuses
            });
        }
        // remove from the list
        else {
            let index = this.state.selectedStatus.indexOf(name)
            let selected = this.state.selectedStatus
            selected.splice(index, 1)
            statuses = selected
            this.setState({
                selectedStatus: selected ?? []
            });
        }
        filters.selectedStatus = statuses
        this.saveUpdatedStatus(filters);
        //this.props.setSelected(checked, true)
        // this.onFiltersInputChange(event);
    }

    onFiltersInputChange = (name, val) => {
        const filtersMap = { ...this.state.filtersMap };

        // if filter already exists, then update
        if (val !== "" && val.length !== 0) {
            filtersMap[name] = val;
        }
        else {
            delete filtersMap[name];
        }

        this.saveUpdatedStatus(filtersMap)

        this.setState({
            filtersMap: filtersMap,
        });

    }

    saveUpdatedStatus = (filters) => {
        delete filters['fromDate']
        delete filters['toDate']
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changeCustomerInvoiceFilters: true,
            customerInvoiceFilterSetting: filters ?? []
        })
    }

    handleDateChange = (date, key) => {
        const filtersMap = { ...this.state.filtersMap };
        if (key === 'fromDate' && !this.isDefaultFromDate(date) ||
            key === 'toDate' && !this.isToday(date)) {
            filtersMap[key] = date;
        } else {
            delete filtersMap[key]
        }
        this.setState({
            filtersMap: filtersMap,
            [key]: date
        })
        this.props.handleDateChange(date, key)
    }

    onFiltersClose = (applyFilters, saveFilterSetting) => {
        // NOTE: changes are already applied as the user makes these changes,
        // but if the changes are cancelled, then the map is reverted to its
        // previous state
        this.setState({
            showFilters: false,
        })
        let filters = {}
        if (!applyFilters) {
            filters = { ...this.state.filtersMapBeforeOpenDialog }
            this.resetDateFilters(filters?.fromDate ?? this.getFromDate(), filters?.toDate ?? this.getToDate(), filters)
        } else {
            if (this.state.deletedTagsWithCookies.length > 0) {
                let storage = JSON.parse(localStorage.getItem("customerInvoices"))
                this.state.deletedTagsWithCookies.forEach((tagName) => {
                    delete storage[tagName]
                })
                localStorage.setItem("customerInvoices", JSON.stringify(storage))
                if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem("customerInvoices")
                this.setState({deletedTagsWithCookies: []})
            }
            filters = { ...this.state.filtersMap }
            const expiry = new Date().setHours(23, 59, 59, 0);
            let needRefresh = this.props.fetchedList
            if (filters.fromDate !== null && filters.fromDate !== undefined) {
                let existing = localStorage.getItem("customerInvoices")
                existing = existing ? JSON.parse(existing) : {};
                if(existing.fromDate!==filters.fromDate.toISOString()) {
                    needRefresh = true
                }
                existing.fromDate = filters.fromDate;
                localStorage.setItem("customerInvoices", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.toDate !== null && filters.toDate !== undefined) {
                let existing = localStorage.getItem("customerInvoices")
                existing = existing ? JSON.parse(existing) : {};
                if(existing.toDate!==filters.toDate.toISOString()) {
                    needRefresh = true
                }
                existing.toDate = filters.toDate;
                localStorage.setItem("customerInvoices", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            //fetching all the invoices
            if (this.props.data.length===0||needRefresh){
                this.fetchCustomerInvoices(this.state.fromDate, this.state.toDate)
                this.props.setFetchedList(false)
            }
        }
        if (saveFilterSetting) {
            this.saveUpdatedStatus(filters)
        }
    }

    resetDateFilters = (fromDate, toDate, filters) => {
        if (!this.isDefaultFromDate(fromDate)) {
            filters['fromDate'] = fromDate
        } else {
            delete filters['fromDate']
            fromDate = this.getFromDate()
        }
        if (!this.isToday(toDate)) {
            filters['toDate'] = toDate
        } else {
            delete filters['toDate']
            toDate = this.getToDate()
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            filtersMap: filters,
            deletedTagsWithCookies: [],
        })
        this.props.handleDateChange(fromDate, 'fromDate')
        this.props.handleDateChange(toDate, "toDate")
    }

    onTagClickedWhenDialogueOpen = (event, tag) => {
        const filtersMap = { ...this.state.filtersMap };
        let tagValue = filtersMap[tag]
        let deletedTagsWithCookies = this.state.deletedTagsWithCookies
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('customerInvoices'))
            if (storage) {
                if (storage.fromDate !== null && storage.fromDate !== undefined) {
                    if (new Date(storage.fromDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "fromDate"]})
                    }
                }
            }
            this.setState({
                fromDate: this.getFromDate()
            })
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('customerInvoices'))
            if (storage) {
                if (storage.toDate !== null && storage.toDate !== undefined) {
                    if (new Date(storage.toDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "toDate"]})
                    }
                }
            }
            this.setState({
                toDate: this.getToDate()
            })
        }
        this.saveUpdatedStatus(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }



    onTagClicked = (event, tag) => {

        // remove the key from the filters map
        // clone the map, delete the key, set the state
        const filtersMap = { ...this.state.filtersMap };
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('customerInvoices'))
            delete storage.fromDate
            this.setState({
                fromDate: this.getFromDate()
            }, () => this.fetchCustomerInvoices(this.state.fromDate, this.state.toDate))
            localStorage.setItem("customerInvoices", JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem("customerInvoices")
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('customerInvoices'))
            delete storage.toDate
            this.setState({
                toDate: this.getToDate()
            }, () => this.fetchCustomerInvoices(this.state.fromDate, this.state.toDate))
            localStorage.setItem("customerInvoices", JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem("customerInvoices")
        }

        this.saveUpdatedStatus(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }

    fetchCustomerInvoices = (fromDate, toDate) => {
        this.props.fetchCustomerInvoices({
            fromDate: fromDate,
            toDate: toDate
        })
    }

    applyOverviewFilters = (rows) => {
        if (this.state.selectedStatus) {
            rows = rows.filter((item) => this.state.selectedStatus.includes(item.status))
        }
        const customer = this.state.filtersMap?.customer;
        if (customer) {
            rows = rows.filter(row => {
                return this.state.filtersMap?.customer.includes(row.customerName)
            })
        }
        return rows;
    }

    removeStatusFilters = () => {
        let filters = { ...this.state.filtersMap }
        delete filters['selectedStatus']
        return filters
    }

    render() {
        let rows = this.getDisplayed(this.stableSort(this.filterBySearch(this.applyOverviewFilters(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))
        return (
            <>
                <div className='filter-tag-wrapper'>
                    <FilterTagsBar
                        filtersMap={this.removeStatusFilters()}
                        onClick={this.state.showFilters ? this.onTagClickedWhenDialogueOpen : this.onTagClicked}
                    />
                </div>
                <CustomTableHeader
                    searchText={this.props.searchText}
                    onSearchValueChange={this.onSearchValueChange}
                    pagination={
                        <TablePagination
                            className="table-pagination"
                            labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                            rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.data.length, label: "All"} ]}
                            count={!this.props.data ? 0 : this.filterBySearch(this.applyOverviewFilters(this.props.data)).length}
                            rowsPerPage={this.props.rowsPerPage}
                            page={this.props.page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }
                    showFilters={this.state.showFilters}
                    filtersComponent={
                        <Slide in={this.state.showFilters} mountOnEnter unmountOnExit timeout={300}>
                            <CustomerInvoiceFiltersDialog
                                open={this.state.showFilters}
                                onClose={this.onFiltersClose}
                                onChange={this.onFiltersInputChange}
                                listItems={this.props.customers}
                                handleDateChange={this.handleDateChange}
                                filtersMap={this.state.filtersMap}
                                clearFilters={this.clearFiltersMap}
                                onTagClicked={this.onTagClicked}
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                fieldName={'customer'}
                                fieldTitle={'Customer'}
                            />
                        </Slide>}
                    customFilterField={
                        <>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.selectedStatus ? this.state.selectedStatus.includes('Needs Review') : false}
                                        onChange={this.handleStatusChange} name="Needs Review"
                                        style={{ color: '#acd685' }} />}
                                    label="Needs Review"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.selectedStatus ? this.state.selectedStatus.includes('Ready to Send') : false}
                                        onChange={this.handleStatusChange} name="Ready to Send"
                                        style={{ color: '#acd685' }} />}
                                    label="Ready to Send"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.selectedStatus ? this.state.selectedStatus.includes('Paid') : false}
                                        onChange={this.handleStatusChange} name="Paid"
                                        style={{ color: '#acd685' }} />}
                                    label="Paid"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.selectedStatus ? this.state.selectedStatus.includes('Payment Pending') : false}
                                                       onChange={this.handleStatusChange} name="Payment Pending"
                                                       style={{ color: '#acd685' }} />}
                                    label="Payment Pending"
                                />
                            </div>
                        </>
                    }
                    filterIcon={
                        <Tooltip title={'Filter'} style={{ paddingTop: 10 }}>
                            <IconButton className="filterButton" size='small' aria-label={'Filter'} onClick={this.toggleFiltersDialog} >
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <TableContainer>
                    <Table size='medium'>
                        <GlobalTableHead
                            isCreatePurchaseOrderTable={false}
                            isShipmentTable={false}
                            isPurchaseOrderTable={false}
                            headCells={INVOICE_HEAD_CELLS}
                            onSelectAllClick={this.selectAll}
                            numSelected={this.numSelected(this.props.checkedEntries)}
                            isInvoiceTable={true}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((invoice, index) => (
                                <TableRow hover onDoubleClick={() => this.handleEdit(invoice)} key={"invoice-" + index}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => this.handleEdit(invoice)}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell style={{padding: "0.2rem"}}>
                                        <Checkbox
                                            checked={this.props.checkedEntries[invoice.customerInvoiceId] ?? false}
                                            onChange={(e) => this.handleCheck(e, invoice)}
                                        />
                                    </TableCell>
                                    {INVOICE_HEAD_CELLS.slice(0, INVOICE_HEAD_CELLS.length - 1).map((headCell, index) => (
                                        <TableCell
                                            style={{
                                                textAlign: headCell.rightAlign ? "right" : "left",
                                                padding: "5px",
                                                paddingRight: headCell.rightAlign ? "50px" : "5px",
                                                color: headCell.id === "status" && (invoice.status === "Needs Review" || invoice.status === "Duplicate") ? "red" : "black",
                                            }}
                                            key={index}
                                        >{headCell.addDollar ?
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: '3px',
                                                marginRight: '15px'
                                            }}>
                                                <span style={{flexShrink: 0}}>$</span>
                                                <span style={{marginLeft: 'auto'}}>{
                                                    // this.formatDollar(invoice.summaryCharges?.total)
                                                    this.formatDollar(invoice[headCell.id])
                                                }</span>
                                            </div>

                                            : invoice[headCell.id]}

                                        </TableCell>


                            ))}

                                    {invoice.notes ? (
                                      <TableCell>
                                          <IconButton>
                                              <Tooltip
                                                  title={invoice.notes}
                                              >
                                                <SpeakerNotesIcon />
                                              </Tooltip>
                                          </IconButton>
                                      </TableCell>
                                    ) : (
                                      <TableCell style={{ padding: "35px" }} />
                                    )}

                            {invoice.files?.length && invoice.files?.length > 0 ?
                                <TableCell>
                                    <IconButton>
                                        <DescriptionIcon />
                                            </IconButton>
                                        </TableCell>
                                        :
                                        <TableCell style={{ padding: "35px" }}>
                                        </TableCell>
                                    }
                                    <TableCell style={{ padding: "5px", width: "205px" }}>
                                        {this.state.deleteId !== invoice.customerInvoiceId ?
                                            <IconButton onClick={() => this.setState({ deleteId: invoice.customerInvoiceId })}>
                                                <DeleteIcon />
                                            </IconButton> : <div>
                                                <Button onClick={() => this.setState({ deleteId: "" })}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={this.handleDelete} variant="contained" startIcon={<DeleteIcon />}>
                                                    Delete
                                                </Button>
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

export default CustomerInvoicesTable