import React from 'react';

import ShipmentsTable from "../shipments/ShipmentsTable";

// constants
import {
    RETURN_SHIPMENTS,
} from "../shipments/constants";
import { RETURN } from "./constants";

// redux
import withShipment from "../../withShipment";
import {
    ordersSelector,
    returnsSelector
} from "../../redux/selectors/orders";
import {
    fetchAllOrders
} from "../../redux/actions/orders";
import {
    listAvailableLocations,
    listCarriers
} from "../../redux/actions/settings";
import {
    availableLocationsSelector,
    carriersSelector
} from "../../redux/selectors/settings";
import {
    userInfoSelector
} from "../../redux/selectors/auth";
import {
    saveTableSetting
} from "../../redux/actions/account";
import {
    tableSettingSelector
} from "../../redux/selectors/account";
import PropTypes from "prop-types";

class ReturnShipments extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            defaultTableDisplay: [
                { key: 0, id: 'tag', numeric: false, disablePadding: true, label: 'Tag', selected: true },
                { key: 1, id: 'name', numeric: false, disablePadding: false, label: 'Name', selected: true },
                { key: 2, id: 'address', numeric: false, disablePadding: false, label: 'Address', selected: true },
                { key: 3, id: 'orderid', numeric: false, disablePadding: false, label: 'Order ID', selected: true },
                { key: 4, id: 'city', numeric: false, disablePadding: false, label: 'City', selected: true },
                { key: 5, id: 'state', numeric: false, disablePadding: false, label: 'State', selected: true },
                { key: 6, id: 'sku', numeric: false, disablePadding: false, label: 'SKU', selected: true },
                { key: 7, id: 'shipfrom', numeric: false, disablePadding: false, label: 'Ship From', selected: true },
                { key: 8, id: 'rate', numeric: false, disablePadding: false, label: 'Rate', selected: true },
                { key: 9, id: 'carrier', numeric: false, disablePadding: false, label: 'Carrier', selected: true },
                { key: 10, id: 'orderDate', numeric: false, disablePadding: false, label: 'Order Date', selected: true },
                { key: 11, id: 'marketplace', numeric: false, disablePadding: false, label: 'Marketplace', selected: true },
                { key: 12, id: 'shipmethod', numeric: false, disablePadding: false, label: 'Ship Method', selected: true },
                { key: 13, id: 'isprime', numeric: false, disablePadding: false, label: 'Prime', selected: true },
                { key: 14, id: 'shipBy', numeric: false, disablePadding: false, label: 'Ship By', selected: true },
                { key: 15, id: 'deliverBy', numeric: false, disablePadding: false, label: 'Deliver By', selected: true },
                { key: 16, id: 'postalcode', numeric: false, disablePadding: false, label: 'Postal Code', selected: true },
                { key: 17, id: 'notes', numeric: false, disablePadding: false, label: 'Notes', selected: true },
            ],
        }
    }

    componentDidMount() {
        this.props.listCarriers({ id: this.props.userInfo.company })
        // this.props.listLocations({ id: this.props.userInfo.company })
        this.props.listAvailableLocations()
        this.props.saveTableSetting({
            userId: this.props.userInfo.userId,
            tableSetting: null,
            pageTitle: RETURN,
            defaultTable: this.state.defaultTableDisplay,
            filterSetting: null
        })
    }

    getFromDate = () => {
        var date = new Date()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 3)
        return date.toISOString()
    }

    getToDate = () => {
        var date = new Date()
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date.toISOString()
    }

    //TODO check if needed
    handleClick = (event, shipmentid, isFedex) => {
        const selectedIndex = this.state.selected.findIndex(item => item.order === shipmentid);
        let selected = [...this.state.selected];

        if (selectedIndex === -1) {
            selected = [...selected, { order: shipmentid, isFedex }];
        } else {
            selected.splice(selectedIndex, 1);
        }
        this.setState({
            selected,
            selectedShipment: selected.length == 1 ? this.props.unshipped.find(row => { return row.shipmentId == selected[0].order }) : {}
        });
    };

    updateDisplayedWithParent = (displayed) => {
        this.setState({
            displayed,
        })
    }

    handleExpand = (shipmentId, isFedex) => {
        let selected = [{ order: shipmentId, isFedex }]
        this.setState({
            selected,
            selectedShipment: selected.length == 1 ? this.props.returns.find(row => { return row.shipmentId == selected[0].order }) : {}
        });
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    render() {
        return (
            <ShipmentsTable
                title={RETURN_SHIPMENTS}
                shipmentStatus={RETURN}
                handleSelectAllClick={this.handleSelectAllClick}
                handleClick={this.handleClick}
                isSelected={this.isSelected}
                selected={this.state.selected}
                selectedShipment={this.state.selectedShipment}
                rows={this.props.returns}
                fetchAllOrders={this.props.fetchAllOrders}
                updateDisplayedWithParent={this.updateDisplayedWithParent}
                clearSelected={this.clearSelected}
                carriers={this.props.carriers}
                locations={this.props.availableLocations}
                tableSetting={this.props.tableSetting}
                defaultTableDisplay={this.state.defaultTableDisplay}
                handleExpand={this.handleExpand}
                history={this.props.history}
                searchText={this.state.searchText}
                handleSearchChange={this.handleSearchTextChange}
            />
        );
    }

}

const mapStateToProps = (state) => ({
    rows: ordersSelector(state),
    returns: returnsSelector(state),
    userInfo: userInfoSelector(state),
    carriers: carriersSelector(state),
    tableSetting: tableSettingSelector(state),
    availableLocations: availableLocationsSelector(state),
})

const actionCreators = {
    fetchAllOrders,
    listCarriers,
    saveTableSetting,
    listAvailableLocations,
}

ReturnShipments.propTypes = {
    rows: PropTypes.array,
    returns: PropTypes.array,
    userInfo: PropTypes.object,
    carriers: PropTypes.array,
    // fetchAllOrders: PropTypes.func,
    // listCarriers: PropTypes.func,
    // saveTableSetting: PropTypes.func,
    // listLocations: PropTypes.func,
    availableLocations: PropTypes.array,
}

ReturnShipments.defaultProps = {
    rows: [],
    returns: [],
    userInfo: {},
    carriers: [],
    availableLocations: [],
    // fetchAllOrders: () => { },
    // listCarriers: () => { },
    // saveTableSetting: () => { },
    // listLocations: () => { },
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ReturnShipments);
