import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import LedgerTable from "./LedgerTable";
import {LEDGERS_PAGES} from "./constants";
import EditLedger from "./EditLedger";
import {
    exportLedgerSelector,
    ledgerSelector, loadingSelector, shippingSettingSelector,
    transactionCategoriesSelector,
    transactionLedgerSelector,
} from '../../../redux/selectors/accounting';
import {
    deleteEntry,
    deleteLedger,
    deleteLedgerByYear,
    deleteLedgerByYearAndCurrency, exportLedgers,
    fetchLedgerBalance,
    fetchTransactionsLedger, ledgerEntries,
    listAllLedgers, listShippingSettings,
    listTransactionCategories,
    saveLedger, saveMarketplaceLedger,
    saveTransactionsLedger, updateEntry, updateLedger,
} from '../../../redux/actions/accounting';
import {userInfoSelector} from "../../../redux/selectors/auth";
import LedgerRuleForm from './LedgerRuleForm';

class Ledger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: LEDGERS_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            transactionCategories:this.props.transactionCategories,
            ledgerEditing: {},
            years: ['2023','2024','2025'],
            selectedYear: "2024",
            currency: ['CAD', 'USD', "Consolidated CAD"],
            selectedCurrency: 'CAD',
            supplierSummary: this.props.fetchTransactions.supplierSummary || [],
            customerSummary: this.props.fetchTransactions.customerSummary || [],
            salesSummary: this.props.fetchTransactions.salesSummary || [],
            carrierSummary: this.props.fetchTransactions.carrierSummary || [],
            ftlSummary: this.props.fetchTransactions.ftlSummary || [],
            marketplaceSummary: this.props.fetchTransactions.marketplaceSummary || [],
            carriers:[],
            accountNumbers: null,
            ledgerType: ""
        }
    }

    componentDidMount() {
        this.props.listShippingSettings()
        this.props.listAllLedgers({currency: this.state.selectedCurrency, year: this.state.selectedYear})

        this.props.listTransactionCategories()

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }

        if (prevProps.fetchTransactions.ftlSummary !== this.props.fetchTransactions.ftlSummary) {
            this.setState({
                ftlSummary : this.props.fetchTransactions.ftlSummary
            })
        }

        if (prevProps.fetchTransactions.marketplaceSummary !== this.props.fetchTransactions.marketplaceSummary) {
            this.setState({
                marketplaceSummary : this.props.fetchTransactions.marketplaceSummary
            })
        }

        if (prevProps.fetchTransactions.customerSummary !== this.props.fetchTransactions.customerSummary) {
            this.setState({
                customerSummary : this.props.fetchTransactions.customerSummary
            })
        }

        if (prevProps.fetchTransactions.salesSummary !== this.props.fetchTransactions.salesSummary) {
            this.setState({
                salesSummary : this.props.fetchTransactions.salesSummary
            })
        }


        if (prevProps.fetchTransactions.supplierSummary !== this.props.fetchTransactions.supplierSummary) {
            this.setState({
                supplierSummary : this.props.fetchTransactions.supplierSummary
            })
        }

        if (prevProps.fetchTransactions.carrierSummary !== this.props.fetchTransactions.carrierSummary) {
            this.setState({
                carrierSummary : this.props.fetchTransactions.carrierSummary
            })
        }

        if (prevProps.ledgerEditing!== this.props.ledgerEditing && this.props.ledgerEditing) {
            this.setState({
                ledgerEditing: this.props.ledgerEditing
            })
        }

        //update state transactionCategories
        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories
            })
        }
        //sort state transactionCategories by displayOrder
        if (prevState.transactionCategories !== this.state.transactionCategories) {
            this.setState({
                transactionCategories : this.state.transactionCategories.sort((a, b) => a.displayOrder - b.displayOrder)
            })
        }

    }

    handleLedgerType = (tab) =>{
        this.setState({ledgerType: tab},()=>{
            if(tab === "1"){
                this.setState({ledgerType: "Assets"})
                this.props.exportLedgers({ledgerType: "Assets",currency: this.state.selectedCurrency, year: this.state.selectedYear})
            } else if(tab === "2"){
                this.setState({ledgerType: "Liabilities"})
                this.props.exportLedgers({ledgerType: "Liabilities",currency: this.state.selectedCurrency, year: this.state.selectedYear})
            }else if(tab === "3"){
                this.setState({ledgerType: "Income"})
                this.props.exportLedgers({ledgerType: "Income",currency: this.state.selectedCurrency, year: this.state.selectedYear})
            } else if(tab === "4"){
                this.setState({ledgerType: "Expenses"})
                this.props.exportLedgers({ledgerType: "Expenses",currency: this.state.selectedCurrency, year: this.state.selectedYear})
            } else if(tab === "5"){
                this.setState({ledgerType: "Equity"})
                this.props.exportLedgers({ledgerType: "Equity",currency: this.state.selectedCurrency, year: this.state.selectedYear})
            }

        })
    }

    handleChange = (event) => {
        if(this.state.currentPage === 'Edit Ledgers'){
            this.setState({  selectedCurrency: event.target.value },()=>{
                const data = {ledgerId: this.state.ledgerEditing.ledgerId, year: this.state.selectedYear,  currency: this.state.selectedCurrency}
                this.props.ledgerEntries(data)

            })
        }else{
            this.setState({ selectedCurrency: event.target.value },()=>{
                this.props.listAllLedgers({ currency: this.state.selectedCurrency, year: this.state.selectedYear})
                this.props.exportLedgers({ledgerType: this.state.ledgerType,currency: this.state.selectedCurrency, year: this.state.selectedYear})
            })
        }

    }

    handleSave = (ledgers) => {
      this.props.saveLedger({ledgers:ledgers, currency: this.state.selectedCurrency, year: this.state.selectedYear})
      this.setCurrentPage(LEDGERS_PAGES.TABLE, {})
    }

     getLastTransactionBalance = (transactions) => {
        if (transactions.length === 0) return null;
        const lastTransaction = transactions[transactions.length - 1];
        return lastTransaction.balance;
    };

    handleSaveTransactionsLedger = (transactions,ledgerId) => {
        const lastBalance = this.getLastTransactionBalance(transactions)
        const entry = {
            year: this.state.selectedYear,
            entries: transactions.map(transaction => ({
                date: transaction.date,
                balance: parseFloat(transaction.balance),
                description: transaction.description,
                debit: parseFloat(transaction.debit),
                credit: parseFloat(transaction.credit),
                currency: this.state.selectedCurrency,
                transactionId: transaction.transactionId,
                note: transaction.note || ''
            })),
            balance: lastBalance // convert to number
        };

        const data = { ledgerEntries: entry, ledgerId: ledgerId, currency: this.state.selectedCurrency, year: this.state.selectedYear}
       this.props.saveTransactionsLedger(data,()=>{
           const ledgerData = {ledgerId: ledgerId, year: this.state.selectedYear,  currency: this.state.selectedCurrency}
           this.props.ledgerEntries(ledgerData)
       })

       // this.setCurrentPage(LEDGERS_PAGES.TABLE, {})
    }

    handleYearChange = (event) => {
        if(this.state.currentPage === 'Edit Ledgers'){
            this.setState({ selectedYear: event.target.value },()=>{
                const data = {ledgerId: this.state.ledgerEditing.ledgerId, year: this.state.selectedYear,  currency: this.state.selectedCurrency}
                this.props.ledgerEntries(data)
            })
        }else{
            this.setState({ selectedYear: event.target.value },()=>{
                this.props.listAllLedgers({ currency: this.state.selectedCurrency, year: this.state.selectedYear})
                this.props.exportLedgers({ledgerType: this.state.ledgerType,currency: this.state.selectedCurrency, year: this.state.selectedYear})

            })
        }

    }


    setCurrentPage = (newPage,data) => {
        this.setState({
            currentPage: newPage,
            ledgerEditing: data,
        })
    }

    displayForm = ()=> {
        return (
        <LedgerRuleForm
          allLedgers={this.props.ledgers}
          setCurrentPage={this.setCurrentPage}
          ledger={this.state.ledgerEditing}
          year={this.state.selectedYear}
          currency={this.state.selectedCurrency}
          updateLedger={this.props.updateLedger}
          deleteEntry={this.props.deleteEntry}
          categories={this.state.transactionCategories}
          ledgerType={this.state.ledgerType}
          fetchLedgerBalance={this.props.fetchLedgerBalance}
          deleteLedger={this.props.deleteLedger}
          deleteLedgerByYear={this.props.deleteLedgerByYear}
        />
        )
    }


    displayTable = () => {
            return (
                <LedgerTable
                    categories={this.state.transactionCategories}
                    listLedgers={this.props.listAllLedgers}
                    deleteLedgerByYearAndCurrency={this.props.deleteLedgerByYearAndCurrency}
                    years={this.state.years}
                    selectedYear={this.state.selectedYear}
                    handleYearChange={this.handleYearChange}
                    ledgers={this.props.ledgers}
                    setCurrentPage={this.setCurrentPage}
                    openFeedbackBar={this.openFeedbackBar}
                    currency={this.state.currency}
                    selectedCurrency={this.state.selectedCurrency}
                    handleChange={this.handleChange}
                    deleteLedger={this.props.deleteLedger}
                    updateLedger={this.props.updateLedger}
                    settings={this.props.shippingSettings}
                    fetchTransactionsLedger={this.props.fetchTransactionsLedger}
                    handleSave={this.handleSaveTransactionsLedger}
                    handleLedgerType={this.handleLedgerType}
                    exportAllLedgers={this.props.exportAllLedgers}
                    ledgerType={this.state.ledgerType}
                />
            )
    }

    displayEditLedger = () => {
        return (
            <EditLedger
                  allLedgers={this.props.ledgers}
                  loadingData={this.props.loadingData}
                  listLedgers={this.props.listAllLedgers}
                  years={this.state.years}
                  handleYearChange={this.handleYearChange}
                  selectedYear={this.state.selectedYear}
                  ledger={this.state.ledgerEditing}
                  handleSave={this.handleSaveTransactionsLedger}
                  setCurrentPage={this.setCurrentPage}
                  openFeedbackBar={this.openFeedbackBar}
                  fetchTransactionsLedger={this.props.fetchTransactionsLedger}
                  fetchTransactions={this.props.fetchTransactions}
                  carrierSummary={this.state.carrierSummary}
                  supplierSummary={this.state.supplierSummary}
                  customerSummary={this.state.customerSummary}
                  marketplaceSummary={this.state.marketplaceSummary}
                  ftlSummary={this.state.ftlSummary}
                  stockSummary={this.props.fetchTransactions.stockSummary}
                  ledgerEntries={this.props.ledgerEntries}
                  currency={this.state.currency}
                  selectedCurrency={this.state.selectedCurrency}
                  handleChange={this.handleChange}
                  settings={this.props.shippingSettings}
                  lastUpdated={this.props.fetchTransactions.lastUpdated}
                  updateEntry={this.props.updateEntry}
                  deleteEntry={this.props.deleteEntry}
                  categories={this.state.transactionCategories}
                  saveMarketplaceLedger={this.props.saveMarketplaceLedger}
                  salesSummary={this.state.salesSummary}
            />
        )
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case LEDGERS_PAGES.TABLE:
                return this.displayTable()
            case LEDGERS_PAGES.FORM:
              return this.displayForm()
            case LEDGERS_PAGES.EDIT:
                return this.displayEditLedger()
            default:
                console.log("Error: current transactions page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
    ledgers: ledgerSelector(state),
    fetchTransactions: transactionLedgerSelector(state),
    shippingSettings: shippingSettingSelector(state),
    loadingData: loadingSelector(state),
    user: userInfoSelector(state),
    exportAllLedgers: exportLedgerSelector(state)

})


const actionCreators = {
    clearStatus,
    listTransactionCategories,
    listAllLedgers,
    saveLedger,
    saveTransactionsLedger,
    fetchTransactionsLedger,
    deleteLedgerByYear,
    deleteLedgerByYearAndCurrency,
    ledgerEntries,
    deleteLedger,
    updateLedger,
    listShippingSettings,
    updateEntry,
    deleteEntry,
    fetchLedgerBalance,
    saveMarketplaceLedger,
    exportLedgers
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, Ledger);