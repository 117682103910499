import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import { SHIPPING_SUMMARY_PAGES} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {
    listShippingSettings,
    listShippingSummary,
} from "../../../redux/actions/accounting";
import {
    lastUpdatedDateSummarySelector,
    loadingSelector,
    shippingSettingSelector,
    shippingSummarySelector,
} from '../../../redux/selectors/accounting';
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import ShippingSummaryTable from "./ShippingSummaryTable";
import {carriersSelector} from "../../../redux/selectors/settings";
import {listCarriers} from "../../../redux/actions/settings";
import {userInfoSelector} from "../../../redux/selectors/auth";
import {tableSettingSelector} from "../../../redux/selectors/account";
import {getTableSetting, saveTableSetting} from "../../../redux/actions/account";
class ShippingSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carriers:[],
            shippingSummary: [],
            currentPage: SHIPPING_SUMMARY_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            selectedYear: new Date().getFullYear(),
            selectedQuarters: null,
            selectedCurrency: 'CAD',
            selectedCarrierType: "Standard Carrier",
            carrierTotal: 0,
            selectedMonth: ' ',
            currency: ['CAD', 'USD','Consolidated CAD'],
            consolidateByCarrier: false,
            shippingSummaryFilterSetting: null,
            isFetch: false
    }
    }

    componentDidMount() {
        this.props.listShippingSettings()
        this.props.getTableSetting(this.props.user.userId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }

        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({ shippingSummaryFilterSetting: this.props.tableSetting?.shippingSummaryFilterSetting },()=>{
                this.setState({
                    selectedCurrency: this.state.shippingSummaryFilterSetting.currency,
                    selectedYear: this.state.shippingSummaryFilterSetting.year,
                    selectedMonth: this.state.shippingSummaryFilterSetting.month,
                    selectedQuarters: this.state.shippingSummaryFilterSetting.quarters,
                    consolidateByCarrier: this.state.shippingSummaryFilterSetting.consolidateByCarrier,
                    selectedCarrierType: this.state.shippingSummaryFilterSetting.carrierType
                },()=>{
                    if (this.state.carriers && this.state.carriers.length > 0) {
                        this.listShippingApi()
                    }else{
                        this.props.listCarriers({ id: this.props.user.company })
                    }

                })
            })
        }

        if (prevProps.carriers !== this.props.carriers) {
            this.setState({
                carriers: this.props.carriers
            }, () => {
               this.listShippingApi()
            });
        }

        if (prevProps.shippingSummary !== this.props.shippingSummary) {
            this.setState({shippingSummary: this.props.shippingSummary},()=>{
                this.calculateTotal()
            })
        }
    }


    listShippingApi = () =>{
       this.setState({shippingSummary: []})

        let accountNumbers = this.state.carriers.map(carrier => carrier.accountNumber)

        this.setState({
            accountNumbers: accountNumbers
        }, () => {
            let data
            if(this.state.selectedQuarters === null){
                 data = {
                     "type": this.state.selectedCarrierType,
                    "year": this.state.selectedYear,
                    "accountNumbers": this.state.accountNumbers,
                    "quarter": [1,2,3,4],
                     "month": this.state.selectedMonth.trim(),
                     "currency": this.state.selectedCurrency,
                     "consolidateByCarrier": this.state.consolidateByCarrier,
                     "isFetch": this.state.isFetch,
                }
            }else{
                let newQuarter = [this.state.selectedQuarters]
                 data = {
                     "type": this.state.selectedCarrierType,
                    "year": this.state.selectedYear,
                    "accountNumbers": this.state.accountNumbers,
                    "quarter": newQuarter,
                     "month": this.state.selectedMonth.trim(),
                     "currency": this.state.selectedCurrency,
                     "consolidateByCarrier": this.state.consolidateByCarrier,
                     "isFetch": this.state.isFetch,
                 }
            }
            this.props.listShippingSummary(data)
            this.setState({isFetch: false})
        });
    }

    setFetch = (bool)=>{
        this.setState({isFetch: bool},()=>{
            this.listShippingApi()
        })

    }

    handleYearChange = (event) => {
        this.setState({ selectedYear: event.target.value },() => {
            this.onFiltersInputChange("year",event)
        })

    }

    handleByCarrier = (event) => {
        const isChecked = event.target.checked;
        this.setState({ consolidateByCarrier: isChecked },() => {
            this.onFiltersInputChange("consolidateByCarrier",isChecked)
        })
    }

    handleChange = (event) => {
        this.setState({ selectedCurrency: event.target.value },() => {
            this.onFiltersInputChange("currency",event)
        })
    }

    handleMonthChange = (event) => {
        this.setState({ selectedMonth: event.target.value },() => {
            this.onFiltersInputChange("month",event)
        })

    }

    handleCarrierTypeChange = (event) => {

        this.setState({ selectedCarrierType: event.target.value },() => {
            this.onFiltersInputChange("carrierType",event)
        })
    }

    handleQuarterChange = (quarter) => {
        const quarterMapping = {
            "QTR 1": 1,
            "QTR 2": 2,
            "QTR 3": 3,
            "QTR 4": 4
        }
        const quarterNumber = quarterMapping[quarter]
        const selectedQuarters = this.state.selectedQuarters === quarterNumber ? null : quarterNumber
        this.setState({ selectedQuarters }, () => {
            this.onFiltersInputChange("quarters",this.state.selectedQuarters)
        })
    }

    calculateTotal = () => {
        const { shippingSummary } = this.props;

        // Clone shippingSummary to avoid mutating props
        const newShippingSummary = [...shippingSummary];
        // Helper function to calculate total with currency adjustments
        const calculateFieldTotal = (field) =>
          newShippingSummary.reduce((total, summary) => {
              return total + (parseFloat(summary?.[field]) || 0);
          }, 0);

        // Calculate totals for all required fields
        const carrierTotal = calculateFieldTotal("CY_TOTAL_PAYABLE");
        const newItem = {
            CY_SUBTOTAL: calculateFieldTotal("CY_SUBTOTAL").toFixed(2),
            CY_TAX: calculateFieldTotal("CY_TAX").toFixed(2),
            CY_TOTAL: calculateFieldTotal("CY_TOTAL").toFixed(2),
            CY_SUBTOTAL_PAYABLE: calculateFieldTotal("CY_SUBTOTAL_PAYABLE").toFixed(2),
            CY_TAX_PAYABLE: calculateFieldTotal("CY_TAX_PAYABLE").toFixed(2),
            PY_SUBTOTAL_PAYABLE: calculateFieldTotal("PY_SUBTOTAL_PAYABLE").toFixed(2),
            PY_TAX_PAYABLE: calculateFieldTotal("PY_TAX_PAYABLE").toFixed(2),
            CY_TOTAL_PAYABLE: carrierTotal, // Set total
            location: '', // Empty field
            accountNumber: '', // Empty field
            carrierName: 'Total', // Empty field
            currencyCode: [] // Empty field (array)
        };

        // Add the new item at the last index only if newShippingSummary has data
        if (newShippingSummary.length > 0) {
            newShippingSummary.push(newItem);
        }

        // Update the state
        this.setState({ shippingSummary: newShippingSummary });
    };


    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    displayTable = () => {
        if (this.state.carriers && this.state.carriers.length > 0) {
            return (
                <ShippingSummaryTable
                    lastUpdatedAt={this.props.lastUpdatedAt}
                    setFetch={this.setFetch}
                    loadingData={this.props.loadingData}
                    currency={this.state.currency}
                    selectedMonth={this.state.selectedMonth}
                    handleMonthChange={this.handleMonthChange}
                    settings={this.props.shippingSettings}
                    selectedQuarters={this.state.selectedQuarters}
                    selectedCurrency={this.state.selectedCurrency}
                    selectedYear={this.state.selectedYear}
                    selectedCarrierType={this.state.selectedCarrierType}
                    handleQuarterChange={this.handleQuarterChange}
                    handleChange={this.handleChange}
                    handleByCarrier={this.handleByCarrier}
                    carrierTotal={this.state.carrierTotal}
                    handleYearChange={this.handleYearChange}
                    handleCarrierTypeChange={this.handleCarrierTypeChange}
                    shippingSummary={this.state.shippingSummary}
                    setCurrentPage={this.setCurrentPage}
                    openFeedbackBar={this.openFeedbackBar}
                    consolidateByCarrier={this.state.consolidateByCarrier}
                />
            );
        }
    }


    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case SHIPPING_SUMMARY_PAGES.TABLE:
                return this.displayTable()
            default:
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    onFiltersInputChange = (name,event) => {
        let filtersMap = this.props.tableSetting?.shippingSummaryFilterSetting

        if(name === "quarters" || name === 'consolidateByCarrier'){
            filtersMap[name] = event;
        }else{
            filtersMap[name] = event.target.value;
        }

        this.saveUpdatedFilters(filtersMap)
    }


    saveUpdatedFilters = (filters) => {
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changeShippingSummaryFilters: true,
            shippingSummaryFilters: filters
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    shippingSummary: shippingSummarySelector(state),
    shippingSettings: shippingSettingSelector(state),
    carriers: carriersSelector(state),
    user: userInfoSelector(state),
    loadingData: loadingSelector(state),
    tableSetting: tableSettingSelector(state),
    lastUpdatedAt: lastUpdatedDateSummarySelector(state),
})


const actionCreators = {
    clearStatus,
    listCarriers,
    listShippingSummary,
    listShippingSettings,
    saveTableSetting,
    getTableSetting,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, ShippingSummary);