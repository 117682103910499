import {clearStatus, hideError, requestStatus, showErrorMessage} from "../../actions/global";
import {
    CUSTOMERS,
    PURCHASE_ORDERS,
} from "../../../components/global/Server/endpoints";
import {SERVER_FAILED_ERROR_MESSAGE} from "../../../pages/Dashboard/DashboardFinal/constants";
import {
    put,
    call,
    takeLatest,
    delay, fork, join
} from 'redux-saga/effects';
import axios from "axios";
import {
    removeDeletedCustomerInvoices,
    removeDeletedCustomerOrder,
    storeAllCustomerInvoices,
    storeCategory,
    storeCustomerInvoiceOperationResponse,
    storeCustomerInvoicePDF,
    storeCustomerInvoicePurchaseOrder,
    storeCustomerPOOperationResponse,
    storeCustomers,
    storePipelineAttachmentPDF,
    storePipelines,
    storeUpdatedAttachmentPipeline,
    storeUpdatedCustomerInvoice,
    storeUpdatedPipeline
} from "../../actions/customer";
import {
    CREATE_CUSTOMER,
    LIST_CUSTOMERS,
    PROCESS_CUSTOMER_INVOICES,
    UPDATE_CUSTOMER,
    CUSTOMER_CANCEL_PURCHASE_ORDER,
    CUSTOMER_CREATE_NEW_PURCHASE_ORDER,
    CUSTOMER_CREATE_SEND_NEW_PURCHASE_ORDER,
    CUSTOMER_DELETE_INVENTORY_RECEIVED,
    CUSTOMER_DELETE_PACKING_SLIP,
    CUSTOMER_EDIT_PURCHASE_ORDER,
    CUSTOMER_GET_PDF_PREVIEW,
    CUSTOMER_PREVIEW_CREATED_PURCHASE_ORDER,
    CUSTOMER_FETCH_ALL_PURCHASE_ORDERS,
    CUSTOMER_PREVIEW_PACKING_SLIP_FILE,
    CUSTOMER_UNDO_CANCEL,
    CUSTOMER_UPDATE_EXPECTED_DELIVERY_DATE,
    CUSTOMER_UPDATE_PURCHASE_ORDER,
    CUSTOMER_SLIP_PDF_PREVIEW,
    GET_ALL_CUSTOMER_INVOICES,
    UPDATE_CUSTOMER_INVOICE,
    CREATE_CUSTOMER_INVOICE,
    DELETE_CUSTOMER_INVOICE,
    GET_CUSTOMER_INVOICE_PO,
    GET_CUSTOMER_INVOICE_PDF,
    UPDATE_CUSTOMER_INVOICE_STATUS,
    DELETE_CUSTOMER_INVOICE_ATTACHMENT,
    GET_CUSTOMER_INVOICE_ATTACHMENT,
    DELETE_CUSTOMER_ORDER,
    CUSTOMER_INVOICE_ADD_PAYMENT_METHOD_MESSAGE,
    SAVE_CUSTOMER_INVOICE_PAYMENT_DETAILS,
    DELETE_SALES_PIPELINE_ATTACHMENT,
    LIST_ALL_SALES_PIPELINES,
    DELETE_SALES_PIPELINE,
    SAVE_SALES_PIPELINE,
    IMPORT_SALES_PIPELINE,
    GET_SALES_PIPELINE_ATTACHMENT,
    EDIT_SALES_CATEGORY,
    LIST_SALES_CATEGORY,
    GENERATE_COMPLETE_CUSTOMER_INVOICE
} from "../../constants/customer";
import {HTTP_SUCCESS} from "../../constants/global";
import {
    storeAllPurchaseOrders,
    storeCompletedPurchaseOrders, storeCreatedPurchaseOrderPreview,
    storeNewPurchaseOrders,
    storePartialPurchaseOrders,
    storePDFpreviewReady, storeUpdatedPurchaseOrder, storeUpdatedPurchaseOrderPreview
} from "../../actions/customer";
import {
    COMPLETED_PURCHASE_ORDER,
    NEW_PURCHASE_ORDER,
    PARTIAL_PURCHASE_ORDER
} from "../../../components/stages/constants";
import {fetchAlerts} from "../../actions/alerts";
import {storeSupplierInvoicePDF} from "../../actions/invoices";
import {
    CUSTOMER_INVOICE_READY_TO_SEND_MESSAGE,
    CUSTOMER_INVOICE_MARKED_AS_PAID_MESSAGE,
    CUSTOMER_INVOICE_MARKED_NEED_REVIEW_MESSAGE,
    CUSTOMER_INVOICE_SAVE_SUCCESS_MESSAGE,
    CUSTOMER_INVOICE_SAVED_AND_VALIDATE_MESSAGE,
    NEEDS_REVIEW_STATUS,
    PAID_STATUS,
    PAYMENT_PENDING_STATUS,
    READY_SEND_STATUS,
    CUSTOMER_INVOICE_PAYMENT_PENDING_MESSAGE
} from "../../../components/customer/customerInvoices/constants";


function* postRequest(endpoint, data) {
    return yield axios.post(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}

function* createCustomerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.CREATE_CUSTOMER, action.customer))
        if (response.data.success) {
            yield put(storeCustomers(response.data.customers))
            action.refresh()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateCustomerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.UPDATE_CUSTOMER, action.updatedCustomer))
        if (response.data.success) {
            yield put(storeCustomers(response.data.customers))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* processCustomerInvoicesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.PROCESS_CUSTOMER_INVOICES, action.customer))
        if (response.data.success) {

        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* listCustomersSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(CUSTOMERS.LIST_CUSTOMERS))
        yield put(storeCustomers(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createNewPurchaseOrder(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.CREATE_ORDER, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(requestStatus(status));
        if (response.data.success) {
            yield action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createSendNewPurchaseOrder(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.CREATE_SEND_ORDER, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(requestStatus(status));

        yield action.reset()
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editPurchaseOrder(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.EDIT_PURCHASE_ORDER, action.purchaseOrderData))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (action.showPdf) {
            if (response.data.success) {
                yield put(storePDFpreviewReady(Uint8Array.from(response.data.pdf.bytes)))
            } else {
                yield put(storePDFpreviewReady(new Uint8Array(0)))
                yield put(showErrorMessage(response.data.message))
            }
        }

        yield put(requestStatus(status));

        yield action.reset()
    } catch (e) {
        if (action.showPdf) {
            yield put(showErrorMessage("Failed to load PDF"))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
        yield put(storePDFpreviewReady(new Uint8Array(0)))
    }
}

function* getPurchaseOrderPreview(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.PREVIEW_ORDER_PDF, action.pdfPreview))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(storePDFpreviewReady(Uint8Array.from(response.data.pdf.bytes)))
        } else {
            yield put(storePDFpreviewReady(new Uint8Array(0)))
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status));
    } catch (e) {
        yield put(showErrorMessage("Failed to load PDF"))
        yield put(storePDFpreviewReady(new Uint8Array(0)))
    }
}

function* getSlipPreview(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.PREVIEW_SLIP_PDF, action.pdfPreview))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(storePDFpreviewReady(Uint8Array.from(response.data.pdf.bytes)))
        } else {
            yield put(storePDFpreviewReady(new Uint8Array(0)))
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status));
    } catch (e) {
        yield put(showErrorMessage("Failed to load PDF"))
        yield put(storePDFpreviewReady(new Uint8Array(0)))
    }
}

function* fetchPurchaseOrders(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.LIST_ALL_PURCHASE_ORDERS, action.data))

        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            const newPurchaseOrders = yield [...response.data.list.filter(row => row.status === NEW_PURCHASE_ORDER)];
            const partialPurchaseOrders = yield [...response.data.list.filter(row => row.status === PARTIAL_PURCHASE_ORDER)];
            const completedPurchaseOrders = yield [...response.data.list.filter(row => row.status === COMPLETED_PURCHASE_ORDER)];

            yield put(storeNewPurchaseOrders(newPurchaseOrders))
            yield put(storePartialPurchaseOrders(partialPurchaseOrders))
            yield put(storeCompletedPurchaseOrders(completedPurchaseOrders))
            yield put(storeAllPurchaseOrders(response.data.list))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updatePurchaseOrderSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.UPDATE_PURCHASE_ORDER, action.purchaseOrder))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(fetchAlerts(action.data))
            yield put(storeUpdatedPurchaseOrder(response.data.list[0]))
            action.callback();
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteInventoryReceivedSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.DELETE_INVENTORY_RECEIVED, action.inventoryReceived))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(fetchAlerts(action.data))
            yield put(storeUpdatedPurchaseOrder(response.data.list[0]))
            action.callback();
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deletePackingSlipSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.DELETE_PACKING_SLIP, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(storeUpdatedPurchaseOrder(response.data.po))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* previewPackingSlipFileSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(CUSTOMERS.PREVIEW_PACKING_SLIP_FILE, action.previewRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(storeUpdatedPurchaseOrderPreview(response.data.po, response.data.index))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* previewCreatedPurchaseOrderSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.PREVIEW_CREATED_PURCHASE_ORDER, { poNumber: action.purchaseOrderData }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {

            yield put(storeCreatedPurchaseOrderPreview(response.data.bytes))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* cancelPurchaseOrderSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.CANCEL_PURCHASE_ORDER, action.purchaseOrderData))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(storeUpdatedPurchaseOrder(response.data.list[0]))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
        action.callback();
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* undoCancelPurchaseOrder(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.UNDO_CANCEL, action.purchaseOrderData))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };

        if (response.data.success) {
            yield put(storeUpdatedPurchaseOrder(response.data.list[0]))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
        action.callback();
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateExpectedDeliveryDate(action){
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.UPDATE_EXPECTED_DELIVERY_DATE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };

        if (response.data.success) {
            yield put(storeUpdatedPurchaseOrder(response.data.purchaseOrder))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createCustomerInvoices(action) {
    yield put(hideError())
    try {
        let response = yield call(() => postRequest(CUSTOMERS.CREATE_CUSTOMER_INVOICE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            yield put(storeUpdatedCustomerInvoice(response.data.invoice))
            yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_SAVE_SUCCESS_MESSAGE))
            action.reset();
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* updateCustomerInvoices(action) {
    yield put(hideError())
    try {
        let response
        if (action.validate) {
            response = yield call(() => postRequest(CUSTOMERS.UPDATE_VALIDATE_CUSTOMER_INVOICE, action.data))
        } else {
            response = yield call(() => postRequest(CUSTOMERS.UPDATE_CUSTOMER_INVOICE, action.data))
        }
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            yield put(storeUpdatedCustomerInvoice(response.data.invoice))
            if (action.validate) {
                yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_SAVED_AND_VALIDATE_MESSAGE))
            }
            else {
                yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_SAVE_SUCCESS_MESSAGE))
            }
            action.reset();
            // yield put(storeUpdatedCustomerInvoice(response.data.invoice))
            // yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_SAVE_SUCCESS_MESSAGE))
            // action.reset();
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getCustomerInvoices(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.GET_ALL_CUSTOMER_INVOICES, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (status.success) {
            yield put(storeAllCustomerInvoices(response.data))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteCustomerOrder(action) {
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.DELETE_CUSTOMER_ORDER, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            yield put(removeDeletedCustomerOrder(action.data?.poNumber))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* deleteCustomerInvoice(action) {
    try {
        const response = yield call(() => postRequest(CUSTOMERS.DELETE_CUSTOMER_INVOICE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            yield put(removeDeletedCustomerInvoices(action.data?.customerInvoiceId))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCustomerInvoicePO(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.GET_CUSTOMER_INVOICE_PO, { poNumber: action.purchaseOrder }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeCustomerInvoicePurchaseOrder(response.data.purchaseOrder))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* getCustomerInvoicePDF(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.GET_CUSTOMER_INVOICE_PDF,  action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeCustomerInvoicePDF(response.data.invoice))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateCustomerInvoiceStatus(action) {
    try {
        const response = yield call(() => postRequest(CUSTOMERS.UPDATE_CUSTOMER_INVOICE_STATUS, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            for (let i = 0; i < response.data.invoices.length; i++){
                yield put(storeUpdatedCustomerInvoice(response.data.invoices[i]))
            }
            switch (action.data.status){
                case NEEDS_REVIEW_STATUS:
                    yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_MARKED_NEED_REVIEW_MESSAGE))
                    break;
                case READY_SEND_STATUS:
                    yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_READY_TO_SEND_MESSAGE))
                    break;
                case PAID_STATUS:
                    yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_MARKED_AS_PAID_MESSAGE))
                    break;
                case PAYMENT_PENDING_STATUS:
                    yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_PAYMENT_PENDING_MESSAGE))
                    break;
            }
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteCustomerInvoiceAttachment(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.DELETE_CUSTOMER_INVOICE_FILE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeUpdatedCustomerInvoice(response.data.invoice))
            action.update(response.data.invoice);
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getCustomerInvoiceAttachment(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.PREVIEW_CUSTOMER_INVOICE_FILE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeSupplierInvoicePDF(response.data.bytes))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveCustomerInvoicePaymentDetailsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.SAVE_CUSTOMER_INVOICE_PAYMENT_DETAILS, action.data))
        if (response.data.success) {
            if (response.data.invoices){
                for (let i = 0; i < response.data.invoices.length; i++){
                    yield put(storeUpdatedCustomerInvoice(response.data.invoices[i]))
                }
            }
            yield put(storeCustomerInvoiceOperationResponse(CUSTOMER_INVOICE_ADD_PAYMENT_METHOD_MESSAGE))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* listCategory() {
    try {
        const response = yield call(() => getRequest(CUSTOMERS.LIST_SALES_CATEGORY))
            const categories = response.data.categories.map(cat => ({
                categoryName: cat.categoryName,
                categoryId: cat.categoryId,
                key: cat.categoryId
            }));
            yield put(storeCategory(categories))

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editCategory(action) {
    yield put(hideError())
    try {
        const request = {
            newCategory: action.newCategory,
            editedCategory: action.editedCategory,
            deletedCategory: action.deletedCategory,
        }
        const response = yield call(() => postRequest(CUSTOMERS.EDIT_SALES_CATEGORY, request))

        if (response.status === 200 && response.data.success) {
            const categories = response.data.categories.map(cat => ({
                categoryName: cat.categoryName,
                categoryId: cat.categoryId,
                key: cat.categoryId
            }));
            yield put(storeCategory(categories))
            if (action.callback && typeof action.callback === 'function') {
                action.callback(null, "Saved");
            }
        } else {
            if (action.callback && typeof action.callback === 'function') {
                action.callback('error', response.data.message);
            }
            yield put(showErrorMessage(response.data.message))
        }

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listAllPipelineSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.LIST_ALL_SALES_PIPELINES,action.data))
        if (response.data.success) {
            yield put(storePipelines(response.data.pipelines))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* savePipelineSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.SAVE_SALES_PIPELINE, action.data))
        if (response.data.success) {
            if (action.callback && typeof action.callback === 'function') {
                action.callback(null, "Saved");
            }
            yield put(storeUpdatedPipeline(response.data.updatedPipeline))
        } else {
            if (action.callback && typeof action.callback === 'function') {
                action.callback('error', response.data.message);
            }
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* importPipelineSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.IMPORT_SALES_PIPELINE, action.data))
        if (response.data.success) {
            if (action.callback && typeof action.callback === 'function') {
                action.callback(null, "Successfully Imported");
            }
            yield put(storeUpdatedPipeline(response.data.updatedPipelines))
        } else {
            if (action.callback && typeof action.callback === 'function') {
                action.callback('error', response.data.message);
            }
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deletePipelineSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.DELETE_SALES_PIPELINE, action.data))
        if (response.data.success) {
            action.callback()
            yield put(storePipelines(response.data.allPipelines))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getPipelineAttachment(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.PREVIEW_SALES_PIPELINE_FILE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storePipelineAttachmentPDF(response.data.bytes))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deletePipelineAttachment(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.DELETE_SALES_PIPELINE_FILE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeUpdatedAttachmentPipeline(response.data.pipeline))
            action.update(response.data.pipeline);
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* generateCompleteCustomerInvoice(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CUSTOMERS.GENERATE_COMPLETE_CUSTOMER_INVOICE, { poNumber: action.data }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeCustomerPOOperationResponse(response.data.message))
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* customerSagas() {
    yield takeLatest(LIST_CUSTOMERS, listCustomersSaga)
    yield takeLatest(UPDATE_CUSTOMER, updateCustomerSaga)
    yield takeLatest(CREATE_CUSTOMER, createCustomerSaga)
    yield takeLatest(PROCESS_CUSTOMER_INVOICES, processCustomerInvoicesSaga)
    yield takeLatest(CUSTOMER_CREATE_NEW_PURCHASE_ORDER, createNewPurchaseOrder);
    yield takeLatest(CUSTOMER_CREATE_SEND_NEW_PURCHASE_ORDER, createSendNewPurchaseOrder);
    yield takeLatest(CUSTOMER_FETCH_ALL_PURCHASE_ORDERS, fetchPurchaseOrders);
    yield takeLatest(GENERATE_COMPLETE_CUSTOMER_INVOICE, generateCompleteCustomerInvoice);
    yield takeLatest(CUSTOMER_GET_PDF_PREVIEW, getPurchaseOrderPreview);
    yield takeLatest(CUSTOMER_SLIP_PDF_PREVIEW, getSlipPreview);
    yield takeLatest(CUSTOMER_UPDATE_PURCHASE_ORDER, updatePurchaseOrderSaga)
    yield takeLatest(CUSTOMER_DELETE_INVENTORY_RECEIVED, deleteInventoryReceivedSaga);
    yield takeLatest(CUSTOMER_DELETE_PACKING_SLIP, deletePackingSlipSaga);
    yield takeLatest(CUSTOMER_PREVIEW_PACKING_SLIP_FILE, previewPackingSlipFileSaga);
    yield takeLatest(CUSTOMER_PREVIEW_CREATED_PURCHASE_ORDER, previewCreatedPurchaseOrderSaga);
    yield takeLatest(CUSTOMER_CANCEL_PURCHASE_ORDER, cancelPurchaseOrderSaga);
    yield takeLatest(CUSTOMER_EDIT_PURCHASE_ORDER, editPurchaseOrder);
    yield takeLatest(CUSTOMER_UNDO_CANCEL, undoCancelPurchaseOrder);
    yield takeLatest(CUSTOMER_UPDATE_EXPECTED_DELIVERY_DATE, updateExpectedDeliveryDate);
    yield takeLatest(GET_ALL_CUSTOMER_INVOICES, getCustomerInvoices);
    yield takeLatest(UPDATE_CUSTOMER_INVOICE, updateCustomerInvoices);
    yield takeLatest(CREATE_CUSTOMER_INVOICE, createCustomerInvoices);
    yield takeLatest(DELETE_CUSTOMER_INVOICE, deleteCustomerInvoice);
    yield takeLatest(DELETE_CUSTOMER_ORDER, deleteCustomerOrder);
    yield takeLatest(GET_CUSTOMER_INVOICE_PO, getCustomerInvoicePO);
    yield takeLatest(GET_CUSTOMER_INVOICE_PDF, getCustomerInvoicePDF);
    yield takeLatest(UPDATE_CUSTOMER_INVOICE_STATUS, updateCustomerInvoiceStatus);
    yield takeLatest(DELETE_CUSTOMER_INVOICE_ATTACHMENT, deleteCustomerInvoiceAttachment);
    yield takeLatest(EDIT_SALES_CATEGORY, editCategory);
    yield takeLatest(LIST_SALES_CATEGORY, listCategory);
    yield takeLatest(GET_CUSTOMER_INVOICE_ATTACHMENT, getCustomerInvoiceAttachment);
    yield takeLatest(SAVE_CUSTOMER_INVOICE_PAYMENT_DETAILS, saveCustomerInvoicePaymentDetailsSaga)
    yield takeLatest(LIST_ALL_SALES_PIPELINES, listAllPipelineSaga);
    yield takeLatest(DELETE_SALES_PIPELINE, deletePipelineSaga);
    yield takeLatest(SAVE_SALES_PIPELINE, savePipelineSaga);
    yield takeLatest(IMPORT_SALES_PIPELINE, importPipelineSaga);
    yield takeLatest(GET_SALES_PIPELINE_ATTACHMENT, getPipelineAttachment);
    yield takeLatest(DELETE_SALES_PIPELINE_ATTACHMENT, deletePipelineAttachment);


};