import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';
import axios from 'axios';

// constants for endpoints
import {
    AUTOMATION_RULES, LOCATIONS, TRANSACTIONS
} from "../../../components/global/Server/endpoints";

// constants for actions
import {
    FETCH_RULES,
    CREATE_RULE,
    STORE_RULE_TO_EDIT,
    EDIT_RULE,
    DUPLICATE_RULE,
    DELETE_RULE, RUN_RULES, UPDATE_COMPANY, GET_COMPANY
} from "../../constants/automationRules";

// secondary actions
import {
    storeRules,
    storeNewRule,
    storeAppliedOrders,
    storeToEdit,
} from "../../actions/automationRules";
import {updateOrdersBylist, updateShipmentsOpenEditRow} from "../../actions/orders";
import { CANCELLED, SHIPPED, UNSHIPPED } from "../../../components/stages/constants";
import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';
import { hideError, requestStatus, setLoadingStatus, showErrorMessage } from "../../actions/global";
import {storeAutomationSettings} from "../../actions/settings";
import {APPLY_TRANSACTIONS_RULES} from "../../constants/accounting";
import {storeUpdatedTransaction} from "../../actions/accounting";

function* getRequest(endpoint) {
    return yield axios.get(endpoint, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => res.data)
}

function* postRequest(endpoint, data) {
    return yield axios.post(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => res.data)
}

function* setRules() {
    try {
        const response = yield call(() => getRequest(AUTOMATION_RULES.LIST_ALL_RULES))
        if (response) {
            yield put(storeRules(response))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* createRule(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.CREATE_RULE, action.newRule))
        if (response) {
            if (response.success) {
                yield put(storeNewRule(response.rule, false))
            } else {
                yield put(showErrorMessage(response.message))
            }
        }
        yield call(() => setRules())
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editRule(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.EDIT_RULE, action.editedRule))
        if (response) {
            if (response.success) {
                yield put(storeNewRule(response.rule, false))
            } else {
                yield put(showErrorMessage(response.message))
            }
        }
        yield call(() => setRules())
        action.history.push('/automation-rules')
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* duplicateRule(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.COPY_RULE, action.ruleToCopy))
        if (response) {
            if (response.success) {
                yield put(storeNewRule(response.rule, false))
            } else {
                yield put(showErrorMessage(response.message))
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteRule(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.DELETE_RULE, action.ruleToDelete))
        if (response) {
            if (response.success) {
                yield put(storeNewRule(response, false))
            } else {
                yield put(showErrorMessage(response.message))
            }

        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* storeEdit(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.PREPARE_EDIT_RULE, action.ruleToEdit))
        if (response) {
            yield put(storeNewRule(response, true))
        }
        action.history.push('/rules/create')
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* runRules(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.APPLY_RULE, action.data))
        if (response?.success) {
            yield put(updateOrdersBylist(response.updatedShipments))
            yield put(updateShipmentsOpenEditRow(response.updatedShipments))
        } else {
            yield put(showErrorMessage(response?.message))
            if (response.updatedShipments) {
                yield put(updateOrdersBylist(response.updatedShipments))
                yield put(updateShipmentsOpenEditRow(response.updatedShipments))
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateProfitSettings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.UPDATE_PROFIT_SETTINGS, action))
        if (response.success) {
            yield put(storeAutomationSettings(action.data.automationSetting))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getProfitSettings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.GET_PROFIT_SETTINGS))
        yield put(storeAutomationSettings(response[0].automationSetting))

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* applyTransactionsRulesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(AUTOMATION_RULES.APPLY_TRANSACTIONS_RULES, {transactionIds: action.transactionIds, rules: action.rules}))
        if (response.success) {
            let status = {
                statusText: "Successfully run rules to Transaction(s)",
                success: true
            }
            for (let i = 0; i < response.updatedTransactions.length; i++) {
                yield put(storeUpdatedTransaction(response.updatedTransactions[i]))
            }
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* rulesSaga() {
    yield takeLatest(FETCH_RULES, setRules);
    yield takeLatest(CREATE_RULE, createRule);
    yield takeLatest(STORE_RULE_TO_EDIT, storeEdit);
    yield takeLatest(EDIT_RULE, editRule);
    yield takeLatest(DUPLICATE_RULE, duplicateRule);
    yield takeLatest(DELETE_RULE, deleteRule);
    yield takeLatest(RUN_RULES, runRules);
    yield takeLatest(UPDATE_COMPANY, updateProfitSettings);
    yield takeLatest(GET_COMPANY, getProfitSettings);
    yield takeLatest(APPLY_TRANSACTIONS_RULES, applyTransactionsRulesSaga);
}

