import React from "react";

import '../../pricingDashboard/pricingDashboardPages/PricingDashboardTableRow.css';
import Tooltip from "@material-ui/core/Tooltip";
import {  IconButton, TableCell } from '@material-ui/core';
import { LEDGERS_PAGES } from './constants';
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";
import SummarizeIcon from '@mui/icons-material/Summarize';
import { VisibilityOff } from '@material-ui/icons';



class LedgerTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            deleteId: "",
            isDeleted: false,
            deleteLedgerId: "",
            open: false
        };
    }



    getBalanceForSelectedYear = (row) => {

        const yearRate =
            this.props.settings?.yearRates?.find(
                (setting) => setting.year === this.props.year.toString()
            )?.rate || 0

        const averageRate =
          this.props.settings?.averageRates?.find(
            (setting) => setting.year === this.props.year.toString()
          )?.rate || 0

        const applicableRate = ['Equity', 'Assets', 'Liabilities'].includes(this.props.ledgerType)
          ? yearRate
          : ['Expenses', 'Income'].includes(this.props.ledgerType)
            ? averageRate
            : 1

        if (row?.name === 'Accounts Payable' || row?.name === "Cost of Sales" || row?.name === "Sales") {

            let credits = 0

            if (this.props.currency === "CAD") {
                // Calculate only CAD credits
                credits = row?.ledgerEntries
                    ?.filter((entry) => entry?.year === this.props.year) // Filter by year
                    ?.flatMap((entry) => entry?.entries) // Access nested entries
                    ?.filter((nestedEntry) => nestedEntry?.currency === "CAD") // Filter by CAD currency
                    ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)
            } else if (this.props.currency === "USD") {
                // Calculate only USD credits
                credits = row?.ledgerEntries
                    ?.filter((entry) => entry?.year === this.props.year) // Filter by year
                    ?.flatMap((entry) => entry?.entries) // Access nested entries
                    ?.filter((nestedEntry) => nestedEntry?.currency === "USD") // Filter by USD currency
                    ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)
            } else if (this.props.currency === "Consolidated CAD") {
                credits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === String(this.props.year)) // Match year
                  ?.flatMap((entry) => entry?.entries || []) // Flatten entries, default to an empty array
                  ?.reduce((total, nestedEntry) => {
                      if (nestedEntry?.currency === 'CAD') {
                          // Add CAD credits directly
                          return total + (nestedEntry?.credit || 0);
                      } else if (nestedEntry?.currency === 'USD') {
                          // Convert USD credits to CAD
                          const conversionRate = applicableRate === yearRate ? yearRate : averageRate;
                          const convertedCredit = (nestedEntry?.credit || 0) * conversionRate;
                          return total + convertedCredit;
                      }
                      return total; // Skip if currency is neither CAD nor USD
                  }, 0);

            }

            credits = credits ?? 0
            return credits
        }else if( row?.name === 'Accounts Receivables'){

            let debits = 0
              let credit = 0

            if (this.props.currency === "CAD") {
                // Calculate only CAD debit
                debits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.year) // Filter by year
                  ?.flatMap((entry) => entry?.entries) // Access nested entries
                  ?.filter((nestedEntry) => nestedEntry?.currency === "CAD") // Filter by CAD currency
                  ?.reduce((total, nestedEntry) => total + (nestedEntry?.debit || 0), 0)

              credit = row?.ledgerEntries
                ?.filter((entry) => entry?.year === this.props.year) // Filter by year
                ?.flatMap((entry) => entry?.entries) // Access nested entries
                ?.filter((nestedEntry) => nestedEntry?.currency === "CAD") // Filter by CAD currency
                ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)

              debits = debits + credit
            } else if (this.props.currency === "USD") {
                // Calculate only USD debit
                debits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.year) // Filter by year
                  ?.flatMap((entry) => entry?.entries) // Access nested entries
                  ?.filter((nestedEntry) => nestedEntry?.currency === "USD") // Filter by USD currency
                  ?.reduce((total, nestedEntry) => total + (nestedEntry?.debit || 0), 0)

              credit = row?.ledgerEntries
                ?.filter((entry) => entry?.year === this.props.year) // Filter by year
                ?.flatMap((entry) => entry?.entries) // Access nested entries
                ?.filter((nestedEntry) => nestedEntry?.currency === "USD") // Filter by USD currency
                ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)


              debits = debits + credit

            } else if (this.props.currency === "Consolidated CAD") {
                debits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === String(this.props.year)) // Match year
                  ?.flatMap((entry) => entry?.entries || []) // Flatten entries, default to an empty array
                  ?.reduce((total, nestedEntry) => {
                      if (nestedEntry?.currency === 'CAD') {
                          // Add CAD debit directly
                          return total + (nestedEntry?.debit || 0);
                      } else if (nestedEntry?.currency === 'USD') {
                          // Convert USD debit to CAD
                          const conversionRate = applicableRate === yearRate ? yearRate : averageRate;
                          const convertedDebit = (nestedEntry?.debit || 0) * conversionRate;
                          return total + convertedDebit;
                      }
                      return total; // Skip if currency is neither CAD nor USD
                  }, 0);

              credit = row?.ledgerEntries
                ?.filter((entry) => entry?.year === String(this.props.year)) // Match year
                ?.flatMap((entry) => entry?.entries || []) // Flatten entries, default to an empty array
                ?.reduce((total, nestedEntry) => {
                  if (nestedEntry?.currency === 'CAD') {
                    // Add CAD debit directly
                    return total + (nestedEntry?.credit || 0);
                  } else if (nestedEntry?.currency === 'USD') {
                    // Convert USD debit to CAD
                    const conversionRate = applicableRate === yearRate ? yearRate : averageRate;
                    const convertedDebit = (nestedEntry?.credit || 0) * conversionRate;
                    return total + convertedDebit;
                  }
                  return total; // Skip if currency is neither CAD nor USD
                }, 0);
              debits = debits + credit
            }

            debits = debits ?? 0
            return debits
        }

        const balanceForSelectedYear = (() => {
            if (this.props.currency === 'Consolidated CAD') {
                // Sum both CAD and USD balance
                const cadEntry = row?.ledgerEntries?.find(
                    (entry) => entry?.year === this.props.year && entry?.currency === 'CAD'
                );
                const usdEntry = row?.ledgerEntries?.find(
                    (entry) => entry?.year === this.props.year && entry?.currency === 'USD'
                );

                const cadAmount = cadEntry?.balance || 0; // Default to 0 if not found
                const usdAmount = usdEntry?.balance || 0;

                return { ...cadEntry, balance: cadAmount + usdAmount }; // Return consolidated result
            } else {
                return row?.ledgerEntries?.find(
                    (entry) => entry?.year === this.props.year && entry?.currency === this.props.currency
                );
            }
        })();

        return balanceForSelectedYear?.balance ?? 0;
    }



    formatBalance = (balance) => {
        return parseFloat(balance)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    displayNonEditingRow = (row) => {
        const { anchorEl } = this.state;
        const balance = this.getBalanceForSelectedYear(this.props.row)
        return (
          <React.Fragment>

              <TableCell style={{ width: '120px', height: '90px',    paddingLeft:"16px"
              }} >
                  { (row.name === 'Accounts Payable' && row.type === 'Liabilities'
                      || row.name === 'Sales' && row.type === 'Income'
                      || row.name === 'Accounts Receivables' && row.type === 'Assets'
                      || row.name === 'Cost of Sales' && row.type === 'Expenses') &&
                    <Tooltip title={"Pre-defined"}>
                        <IconButton
                          onClick={() => null}
                        >
                            <SummarizeIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                  }

                  {!row.isShowTrialBalance && row.name !== "Total" && (
                    <Tooltip title={"Hidden"}>
                        <IconButton
                          onClick={() => null}
                        >
                            <VisibilityOff fontSize="small" />
                        </IconButton>
                    </Tooltip>
                  )}

              </TableCell>




            <TableCell style={{ margin:"0", padding:"0"}}
              onClick={() => {
                  if (!this.props.isEdit  && row.name !== "Total") {
                      this.props.setCurrentPage(LEDGERS_PAGES.EDIT, row);
                  }
              }}
            >

                <div
                className={`full-width-name ${
                  !this.props.isEdit  && row.name !== "Total" ? 'ledger-name' : ''
                }`}

                style={{ fontWeight : row.name === "Total" ? "bold" : "normal" }}
              >
                {row.name}
              </div>
            </TableCell>

            <TableCell
              style={{
                textAlign: 'right',
                padding: '5px',
                paddingRight: '50px',
                width: '100px',
               fontWeight : row.name === "Total" ? "bold" : "normal"
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <span style={{ flexShrink: 0 }}>$</span>
                <span
                  style={{
                    marginLeft: '55px',
                    minWidth: '80px',
                    textAlign: 'right',
                  }}
                >
                  {row.name === "Total" ? this.formatBalance(row.balance) : this.formatBalance(balance)}
                </span>
              </div>
            </TableCell>

            {!(row.name === 'Accounts Payable' && row.type === 'Liabilities'
              || row.name === 'Sales' && row.type === 'Income'
              || row.name === 'Accounts Receivables' && row.type === 'Assets'
              || row.name === 'Cost of Sales' && row.type === 'Expenses' || row.name === "Total") ? (
              <TwoStepDelete
                rowId={row.ledgerId}
                handleDelete={(rowIdToDelete) => {
                  const data = {
                    ledgerId: row.ledgerId,
                    year: this.props.year,
                    currency: this.props.currency,
                  };

                  this.props.deleteLedgerByYearAndCurrency(data);

                  this.setState({
                    isDeleted: true,
                    deleteId: '',
                  });
                }}
                type={this.state.isDeleted}
                deleteId={this.state.deleteId}
                setDeleteId={(newDeleteId) =>
                  this.setState({ deleteId: newDeleteId })
                }
              />
            ) : (
              <TableCell style={{ width: '200px' }}></TableCell>
            )}
          </React.Fragment>
        );
    }



    render() {
        return (
            <React.Fragment>
                {this.displayNonEditingRow(this.props.row)}
            </React.Fragment>
        )
    }
}


export default LedgerTableRow;