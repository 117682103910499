import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import {TRANSACTION_PAGES} from "./constants";
import TransactionsTable from "./TransactionsTable";
import TransactionsForm from "./TransactionsForm";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {stateAndProvinceMappingSelector, statusSelector} from "../../../redux/selectors/global";
import {clearStatus, getStateAndProvinceMapping} from "../../../redux/actions/global";
import TransactionsAttachmentPreview from "./TransactionsAttachmentPreview";
import {
    transactionSelector,
    transactionCategoriesSelector,
    editingTransactionSelector, shippingSettingSelector, noteSelector,
} from '../../../redux/selectors/accounting';
import {bankAccountsSelector} from "../../../redux/selectors/settings";
import {
    listAllTransactions,
    listTransactionCategories,
    getTaxTypeWithStateProvince,
    saveAndNavigateToChildTransaction, listShippingSettings, getNote, saveNote,
} from '../../../redux/actions/accounting';
import {listAllBankAccounts} from "../../../redux/actions/settings";
import TransactionsSettings from "./TransactionsSettings";
import {fetchRules} from "../../../redux/actions/automationRules";
import {rulesSelector} from "../../../redux/selectors/automationRules";
import TransactionsCalender from "./TransactionsCalender";
import TransactionsInvoiceDetail from "./TransactionsInvoiceDetail";
import {
    carrierInvoiceOperationResponseSelector,
    supplierInvoiceOperationResponseSelector
} from "../../../redux/selectors/invoices";
import {
    clearCarrierInvoiceOperationResponse,
    storeSupplierInvoiceOperationResponse
} from "../../../redux/actions/invoices";
import {SUCCESS} from "../../settings/ManageUsers/constants";

class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: TRANSACTION_PAGES.TABLE,
            transactionEditing: {},
            fileViewing: null,
            feedbackBarOpen: false,
            feedbackBarText: "",
            transactionCategories:this.props.transactionCategories,
            selectedCurrency: null,
            selectedCategories: null,

        }
    }

    componentDidMount() {
        this.filterTransactions()
        this.props.listAllBankAccounts()
        this.props.listTransactionCategories()
        this.props.fetchRules()
        this.props.getStateAndProvinceMapping()
        this.props.getTaxTypeWithStateProvince()
        this.props.listShippingSettings()
        this.props.getNote({type: "Transactions"})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }
        //update state transactionCategories
        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories.sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            })
        }

        if (prevProps.editingTransaction !== this.props.editingTransaction && this.props.editingTransaction) {
            this.setState({
                transactionEditing: this.props.editingTransaction
            })
        }

        if (prevProps.operationResponse !== this.props.operationResponse && this.props.operationResponse !== "") {
            this.setState({
                feedbackBarOpen: true,
                feedbackBarText: this.props.operationResponse
            })
            this.props.storeSupplierInvoiceOperationResponse("")
        }

        if ( this.props.responseMessage && prevProps.responseMessage !== this.props.responseMessage) {
            this.setState({
                responseMessage: "Payment method added",
                feedBackBarOpen: true
            })
        }
    }

    setCurrentPage = (newPage, transaction, file) => {
        this.setState({
            currentPage: newPage,
            transactionEditing: transaction,
            fileViewing: file
        })
    }

    displayTable = () => {
        return (
            <TransactionsTable
                selectedCategory={this.state.selectedCategories}
                selectedCurrency={this.state.selectedCurrency}
                handleCurrencyChange={this.handleCurrencyChange}
                handleCategory={this.handleCategory}
                transactions={this.props.transactions}
                bankAccounts={this.props.bankAccounts}
                categories={this.state.transactionCategories}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                rules={this.props.rules}
                saveNote={this.props.saveNote}
                note={this.props.note}
            />
        )
    }

    displayCalender = () => {
        return (
            <TransactionsCalender
                setCurrentPage={this.setCurrentPage}
            />
        )
    }

    displayInvoice = () => {
        return (
            <TransactionsInvoiceDetail
                bankAccounts={this.props.bankAccounts}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
            />
        )
    }

    displayForm = () => {
        return (
            <TransactionsForm
                stateAndProvince = {this.props.stateAndProvinceMapping}
                transaction={this.state.transactionEditing}
                bankAccounts={this.props.bankAccounts}
                categories={this.state.transactionCategories}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                saveAndNavigateToChildTransaction={this.props.saveAndNavigateToChildTransaction}
            />
        )
    }

    displayPreview = () => {
        return (
            <TransactionsAttachmentPreview
                transaction={this.state.transactionEditing}
                file={this.state.fileViewing}
                setCurrentPage={this.setCurrentPage}
            />
        )
    }

    displaySettings = () => {
        return (
            <TransactionsSettings
                transactionCategories={this.state.transactionCategories.filter(category => category.editable !== false)}
                setCurrentPage={this.setCurrentPage}
            />
        )
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case TRANSACTION_PAGES.TABLE:
                return this.displayTable()
            case TRANSACTION_PAGES.UPDATE:
                return this.displayForm()
            case TRANSACTION_PAGES.PREVIEW:
                return this.displayPreview()
            case TRANSACTION_PAGES.SETTINGS:
                return this.displaySettings()
            case TRANSACTION_PAGES.CALENDER:
                return this.displayCalender()
            case TRANSACTION_PAGES.INVOICE:
                return this.displayInvoice()
            default:
                console.log("Error: current transactions page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    handleCurrencyChange = (currency) => {
        const newCurrency = this.state.selectedCurrency === currency ? null : currency

        this.setState({ selectedCurrency: newCurrency },()=>{
            this.filterTransactions()
        })
    }


    handleCategory = (category) => {
        this.setState({ selectedCategories: category })
    }

    filterTransactions = () => {
        const data = { "currency" : this.state.selectedCurrency, "category" : this.state.selectedCategories, shippingSettings: this.props.shippingSettings}
        this.props.listAllTransactions(data)
    }

    closeFeedbackBar = () => {
        this.setState({
            feedbackBarOpen: false,
            feedbackBarText: ""
        }, () => {
            this.props.clearStatus();
        });
        this.props.clearCarrierInvoiceOperationResponse();
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    // message={this.props.status?.statusText}
                    message={
                        this.state.feedbackBarText ||
                        this.props.status?.statusText ||
                        this.props.responseMessage
                    }
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}

Transactions.defaultProps = {
    stateAndProvinceMapping: [],
}

const mapStateToProps = (state) => ({
    status: statusSelector(state),
    transactions: transactionSelector(state),
    bankAccounts: bankAccountsSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
    rules: rulesSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
    editingTransaction: editingTransactionSelector(state),
    operationResponse: supplierInvoiceOperationResponseSelector(state),
    responseMessage: carrierInvoiceOperationResponseSelector(state),
    shippingSettings: shippingSettingSelector(state),
    note: noteSelector(state),
})

const actionCreators = {
    clearStatus,
    listAllTransactions,
    listAllBankAccounts,
    listTransactionCategories,
    fetchRules,
    getStateAndProvinceMapping,
    getTaxTypeWithStateProvince,
    saveAndNavigateToChildTransaction,
    storeSupplierInvoiceOperationResponse,
    clearCarrierInvoiceOperationResponse,
    listShippingSettings,
    getNote,
    saveNote
}

export default withShipment({
    actionCreators,
    mapStateToProps,
}, Transactions);