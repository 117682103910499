import React from 'react';

// redux
import {
    pricingListingsSelector,
} from "../../../redux/selectors/settings";
import {
    fetchPricingListings,
} from "../../../redux/actions/settings";
import {
    userInfoSelector
} from "../../../redux/selectors/auth";
import withShipment from "../../../withShipment";

// import constants
import {
    COMPONENT_SPECS,
    CONDITION_ACTIONS, CHANGE_PRICE_TO,
} from "./constants";
import {
    DELETE_ACTION, DRAG
} from "../constants";

// import styling
import "../ConditionFilters/FilterBooleanSelect.css";
import './ActionDropdownsAndInput.css';

// material components
import {Box, Checkbox, FormGroup, IconButton, MenuItem, Select, Tooltip} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

class ActionDropdownsAndInput extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            name: "",
            type: "",
            value: "",
            value2: "",
            value3: "",
            value4: "",
            value5: "",
            sendNotification: false,
        }
    }

    componentDidMount() {
        this.setState({
                ...this.props.action,
            },
            () => {
                let sendNotification;
                if (typeof this.state.sendNotification === "string") {
                    sendNotification = this.state.sendNotification.toLowerCase() === "true"
                } else {
                    sendNotification = this.state.sendNotification
                }
                this.setState({
                    sendNotification: sendNotification
                })
                this.props.fetchPricingListings();
            })
    }

    getSpec = () => {
        const action = CONDITION_ACTIONS.filter(n => n.name === this.props.name)
        return action[0].spec
    }

    getSpec2 = () => {
        const action = CONDITION_ACTIONS.filter(n => n.name === this.props.name)
        return action[0].spec2
    }

    getSpec3 = () => {
        const action = CONDITION_ACTIONS.filter(n => n.name === this.props.name)
        return action[0].spec3
    }

    displayDropdown = () => {
        let dropdownSpec = this.props.isEdit ? this.getSpec() : this.props.action.spec;
        switch (dropdownSpec) {
            case COMPONENT_SPECS.PRICE_UNIT:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
            default:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
        }
    }

    displayDropdown2 = () => {
        let dropdownSpec = this.props.isEdit ? this.getSpec2() : this.props.action.spec2;
        switch (dropdownSpec) {
            case COMPONENT_SPECS.INCREASE_DECREASE:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
            default:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
        }
    }

    displayDropdown3 = () => {
        let dropdownSpec = this.props.isEdit ? this.getSpec3() : this.props.action.spec3;
        switch (dropdownSpec) {
            case COMPONENT_SPECS.INCREASE_DECREASE:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
            default:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
        }
    }

    displayDropdown4 = () => {
        if(this.state.name == CHANGE_PRICE_TO){

            let dropdownSpec =  []
            if (this.props.filters) {
                let skuFilters = this.props.filters.filter(
                    filter => filter.name === "SKU" && filter.value2
                );

                if (skuFilters.length > 0) {
                    let skus = skuFilters.map(filter => filter.value2);

                    if (this.props.listings) {
                        let relatedProductsAsins = this.props.listings
                            .filter(listing => skus.includes(listing.sku))
                            .reduce((acc, listing) => {
                                if (listing.relatedProductsAsins) {
                                    acc = acc.concat(listing.relatedProductsAsins);
                                }
                                return acc;
                            }, []);

                        dropdownSpec = Array.from(new Set(relatedProductsAsins));
                    }
                }
            }

            return (
                dropdownSpec.map(spec => (
                    <MenuItem value={spec} key={spec}>{spec}</MenuItem>
                ))
            );
        }

        return [];
    }

    handleSendNotificationChange = () => {
        this.setState({
            sendNotification: !this.state.sendNotification
        }, () => {
            this.props.updateActionWithParent({ ...this.state });
        })
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState(
            {
                value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
                this.formatValue2()
            }
        );
    };

    handleValue2Change = (event) => {
        let newValue = event.target.value;

        if (this.state.value === "%") {
            // Allow only integer values between 0 and 100
            newValue = newValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            if (newValue !== '' && (parseInt(newValue, 10) < 0 || parseInt(newValue, 10) > 100)) {
                newValue = Math.max(0, Math.min(100, parseInt(newValue, 10))).toString(); // Ensure it's within 0-100
            }
        } else if (this.state.value === "$") {
            // Allow 2 decimal places for currency
            newValue = newValue.replace(/[^0-9.]/g, ''); // Remove non-numeric and non-decimal characters
            if (newValue.includes('.')) {
                const parts = newValue.split('.');
                parts[1] = parts[1].substring(0, 2); // Limit to 2 decimal places
                newValue = parts.join('.');
            }
        }

        this.setState({ value2: newValue },() => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    };

    formatValue2 = () => {
        let amount = this.state.value2
        let newValue2 = this.state.value2
        if(this.state.value === "$"){
            if (!amount) return '';

            // Convert to float and format to 2 decimal places
            newValue2 = parseFloat(amount).toFixed(2);
        }else if(this.state.value === "%"){
            if (!amount) return '';

            newValue2 = Math.max(0, Math.min(100, parseInt(amount, 10))).toString();
        }
        else{
            if (!amount) return '';
        }
        this.setState({ value2: newValue2 },() => {
            this.props.updateActionWithParent({ ...this.state });
        });
    };

    handleValue3Change = (event) => {
        const value3 = event.target.value;
        this.setState(
            {
                value3,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    };

    handleValue4Change = (event) => {
        const value4 = event.target.value;
        this.setState(
            {
                value4,
                value5: "",
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    };

    handleValue5Change = (event) => {
        const value5 = event.target.value;
        this.setState(
            {
                value5,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    };

    render() {
        return (
            <Box
                className='actionDropdownInput'
                display='flex'
                flexDirection='column'
                justifyContent='left'
                alignItems='center'
            >
                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                    <Box className='actionDropdownInputName'>{this.state.name}</Box>
                    <TextField
                        className='actionDropdownInputFilterTextFieldInput'
                        variant='outlined'
                        value={this.state.value2}
                        onChange={this.handleValue2Change}
                        onBlur={this.formatValue2}
                        label={this.state.value}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Select
                        className={'actionDropdownInputOptions'}
                        variant='outlined'
                        value={this.state.value}
                        onChange={this.handleChange}
                    >
                        {this.displayDropdown()}
                    </Select>
                    <Select
                        className={'actionDropdownInputOptions2'}
                        variant='outlined'
                        value={this.state.value3}
                        onChange={this.handleValue3Change}
                    >
                        {this.displayDropdown2()}
                    </Select>

                    <div className= "actionDropdownInputEmptyDiv">
                        <Tooltip title={DELETE_ACTION}>
                            <IconButton
                                aria-label={DELETE_ACTION}
                                onClick={() => this.props.deleteAction(this.state.actionId)}
                            >
                                <DeleteOutlineIcon variant='outlined' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={DRAG}>
                            <IconButton
                                aria-label='drag'
                            >
                                <DehazeIcon variant='outlined' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Box>
                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                    <Box className='actionDropdownInputName'>{"the buy box price of"}</Box>
                    <Select
                        className={'actionDropdownInputOptions3'}
                        variant='outlined'
                        value={this.state.value4}
                        onChange={this.handleValue4Change}
                    >
                        {this.displayDropdown3()}
                    </Select>
                    {this.state.value4 === "related product ASIN" ?
                        <Select
                            className={'actionDropdownInputOptions3_2'}
                            variant='outlined'
                            value={this.state.value5}
                            onChange={this.handleValue5Change}
                        >
                            {this.displayDropdown4()}
                        </Select>
                        : null
                    }
                </Box>
                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                    <Box className='actionDropdownInputName'>{"Send notification"}</Box>
                        <FormGroup>
                            <Checkbox
                                name="value"
                                checked={this.state.sendNotification}
                                onChange={this.handleSendNotificationChange}
                            />
                        </FormGroup>
                </Box>
            </Box>
        );
    }

}

const mapStateToProps = (state) => ({
    userInfo: userInfoSelector(state),
    user: userInfoSelector(state),
    listings: pricingListingsSelector(state),

})

const actionCreators = {
    fetchPricingListings,
}

ActionDropdownsAndInput.propTypes = {
    action: PropTypes.array,
    name: PropTypes.string,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

ActionDropdownsAndInput.defaultProps = {
    action: {},
    name: "",
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: true,
    listings:[],

}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ActionDropdownsAndInput);