export const DEFAULT_SORT_BY_HEAD_CELL = "invoiceDate"
export const ROWS_PER_PAGE = 50
export const ROWS_PER_PAGE_LABEL = "Carrier invoices per page"

export const CARRIER_INVOICE_HEAD_CELLS = [
    { id: 'carrierName', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Carrier Name'},
    { id: 'invoiceDate', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Invoice Date'},
    { id: 'invoiceNumber', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Invoice Number'},
    { id: 'total', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Total'},
    { id: 'accountNumber', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Account Number'},
    { id: 'totalShipments', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Total Shipments'},
    { id: 'previouslyBilledShipments', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Previously Billed Shipments'},
    { id: 'failedShipments', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Failed Entries'},
    { id: 'status', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Status'},
    { id: 'paymentInfo', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Payment Info'},
    { id: 'note', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Notes'},
]

export const NEEDS_REVIEW_STATUS = "Needs Review"
export const APPROVE_STATUS = "Approved for Payment"
export const PAID_STATUS = "Paid"
export const REPROCESSING_STATUS = "Reprocessing"
export const INFO_REQUIRED = "Info Required"
export const DISPUTE_STATUS = "Dispute"

export const PROCESS_FAILED_TOOLTIP = "This invoice item was not processed successfully"
export const PROCESS_SUCCESS_TOOLTIP= "This invoice item was processed successfully"
export const ITEM_DETAILS= "Item Details"
export const ITEM_DETAILS_ERROR="Detail Information not available"
export const CHARGES = "Charges"
export const CHARGES_ERROR="Charges not available"
export const RECIPIENT_INFO = "Recipient Information"
export const RECIPIENT_INFO_ERROR = "Recipient Information not available"
export const SENDER_INFO = "Sender Information"
export const SENDER_INFO_ERROR = "Sender Information not available"


export const ITEM_DETAILS_OPTIONS = [
    "Actual Weight",
    "Billed Weight",
    "Customer Reference",
    "D",
    "Delivered",
    "Dept Number",
    "H",
    "L",
    "Manifest Number",
    "Packages",
    "Payor",
    "PO Number",
    "Rated Weight",
    "Reported Weight",
    "Service Type",
    "Ship Date",
    "Tendered Date",
    "Zone",
]

export const CHARGES_OPTIONS = [
    "Additional Handling",
    "Address Correction",
    "Freight",
    "Residential",
    "Fuel Surcharge",
    "HST",
    "GST",
    "QST",
    "Declared Value",
    "Transportation Charge",
    "Performance Pricing",
    "Earned Discount",
    "Performance Pricing Discount",
    "Grace Discount",
    "Peak Surcharges",
    "Residential Delivery",
    "DAS Commercial",
    "DAS Extended Commercial",
    "DAS Resi",
    "DAS Extended Resi",
    "Declared Value Charge",
    "Remote Rural",
]

export const RECIPIENT_OPTIONS = [
    "Name",
    "Street Address 1",
    "Province",
    "City",
    "Postal Code",
]

export const SENDER_OPTIONS = [
    "City",
    "Company",
    "Department",
    "Postal Code",
    "Province",
    "Street Address 1",
]

export const CARRIER_INVOICE_INVOICE_ADD_PAYMENT_METHOD_MESSAGE = "Payment method added"

export const CARRIER_TYPES = [
    "Standard Carrier",
    "LTL/FTL Carrier"
]

export const STANDARD_CARRIER = "Standard Carrier"
export const LTL_FTL_CARRIER = "LTL/FTL Carrier"

export const CANPAR_CHARGES = [
    {charge: "Ground Freight",      order: 0,   mapsTo: "Ground Freight"    },
    {charge: "Net Freight Charge",  order: 1,   mapsTo: "Net Freight Charge"},
    {charge: "Fuel Surcharge",      order: 2,   mapsTo: "Fuel Surcharge"    },
    {charge: "Carbon Surcharge",    order: 3,   mapsTo: "Carbon Surcharge"  },
    {charge: "Pick Up Tag",         order: 4,   mapsTo: "Pick Up Tag"       },
    {charge: "Declared Value",      order: 5,   mapsTo: "Declared Value"    },
    {charge: "Residential",         order: 6,   mapsTo: "Residential"       },
    {charge: "Additional Charges",  order: 7,   mapsTo: "Additional Charges"},
    {charge: "Sub Total",           order: 8,   mapsTo: "Sub Total"         },
    {charge: "GST",                 order: 9,   mapsTo: "GST"               },
    {charge: "ON HST",              order: 10,  mapsTo: "ON HST"            },
    {charge: "HST",                 order: 11,  mapsTo: "HST"               },
    {charge: "Tax Sub Total",       order: 12,  mapsTo: "Tax Sub Total"     },
    {charge: "This Invoice Total",  order: 13,  mapsTo: "Total"             },
]

export const FedEx_CHARGES = [
    {charge: "Base Charges",                    order: 0,   mapsTo: "Base Charges"                   },
    {charge: "Performance Pricing Discount",    order: 1,   mapsTo: "Performance Pricing Discount"   },
    {charge: "Net Transportation Charges",      order: 2,   mapsTo: "Net Transportation Charges"     },
    {charge: "Declared Value Charge",           order: 3,   mapsTo: "Declared Value Charge"          },
    {charge: "Additional Handling Surcharge",   order: 4,   mapsTo: "Additional Handling Surcharge"  },
    {charge: "Fuel Surcharge",                  order: 5,   mapsTo: "Fuel Surcharge"                 },
    {charge: "Weekly Service Chg",              order: 6,   mapsTo: "Weekly Service Chg"             },
    {charge: "Weekly Service Charge",           order: 7,   mapsTo: "Weekly Service Charge"          },
    {charge: "Remote Rural Surcharge",          order: 8,   mapsTo: "Remote Rural Surcharge"         },
    {charge: "Demand Surcharges",               order: 9,   mapsTo: "Demand Surcharges"              },
    {charge: "Additional weight charge",        order: 10,   mapsTo: "Additional Weight Charge"      },
    {charge: "Subtotal",                        order: 11,   mapsTo: "Subtotal"                      },
    {charge: "Canada GST",                      order: 12,   mapsTo: "Canada GST"                    },
    {charge: "Canada HST",                      order: 13,   mapsTo: "Canada HST"                    },
    {charge: "Canada QST",                      order: 14,   mapsTo: "Canada QST"                    },
    {charge: "Tax Subtotal",                    order: 15,   mapsTo: "Tax Subtotal"                  },
    {charge: "Other Charges",                   order: 16,   mapsTo: "Other Charges"                  },
    {charge: "TOTAL",                           order: 17,   mapsTo: "Total"                         },
    {charge: "Total",                           order: 17,   mapsTo: "Total"                         },
    {charge: "TOTAL THIS INVOICE",              order: 17,   mapsTo: "Total"                         },
]

export const GLS_CHARGES = [
    {charge: "base",                order: 0,   mapsTo: "Base"              },
    {charge: "weight",              order: 1,   mapsTo: "Weight"            },
    {charge: "zone",                order: 2,   mapsTo: "Zone"              },
    {charge: "over_36_inches",      order: 3,   mapsTo: "Over 36 Inches"    },
    {charge: "PS:_comm_dens",       order: 4,   mapsTo: "PS Comm Dens"      },
    {charge: "adrs_correction",     order: 5,   mapsTo: "Adrs Correction"   },
    {charge: "carbon_surchrg",      order: 6,   mapsTo: "Carbon Surchrg"    },
    {charge: "insurance",           order: 7,   mapsTo: "Insurance"         },
    {charge: "fuel",                order: 8,   mapsTo: "Fuel"              },
    {charge: "paper_invoice",       order: 9,   mapsTo: "Paper Invoice"     },
    {charge: "backup_docs",         order: 10,  mapsTo: "Backup Docs"       },
    {charge: "GST",                 order: 11,  mapsTo: "GST"               },
    {charge: "HST_NB",              order: 12,  mapsTo: "HST NB"            },
    {charge: "HST_NFL",             order: 13,  mapsTo: "HST NFL"           },
    {charge: "HST_NS",              order: 14,  mapsTo: "HST NS"            },
    {charge: "HST_ON",              order: 15,  mapsTo: "HST ON"            },
    {charge: "HST_PE",              order: 16,  mapsTo: "HST PE"            },
    {charge: "totalCharge",         order: 17,  mapsTo: "Total"             },
]

export const TOTAL_CHARGES = [
    "total",
    "subtotal",
    "subTotal",
    "totalCharge",
    "TOTAL",
    "TOTAL THIS INVOICE",
    "Tax Subtotal",
    "Sub Total",
    "Tax Sub Total",
    "This Invoice Total",
    "Total",
]

export const CHARGES_MAP = [
    {charge: "2nd_delivery", mapsTo: "2nd Delivery"},
    {charge: "Additional Charges", mapsTo: "Additional Charges"},
    {charge: "Alternate Address Pickup", mapsTo: "Alternate Address Pickup"},
    {charge: "GST", mapsTo: "GST"},
    {charge: "HST", mapsTo: "HST"},
    {charge: "HST_NB", mapsTo: "HST_NB"},
    {charge: "HST_NFL", mapsTo: "HST_NFL"},
    {charge: "HST_NS", mapsTo: "HST_NS"},
    {charge: "HST_ON", mapsTo: "HST_ON"},
    {charge: "HST_PE", mapsTo: "HST_PE"},
    {charge: "HST_PEI", mapsTo: "HST_PEI"},
    {charge: "Items shipped", mapsTo: "Items shipped"},
    {charge: "Net Freight Charge", mapsTo: "Net Freight Charge"},
    {charge: "Oversize", mapsTo: "Oversize"},
    {charge: "Oversize Extra Service Fee", mapsTo: "Oversize Extra Service Fee"},
    {charge: "PS_comm_dens", mapsTo: "PS COMM DENS"},
    {charge: "PS_nonconvey", mapsTo: "PS NON CONVEY"},
    {charge: "QST", mapsTo: "QST"},
    {charge: "Remote Rural Surcharge", mapsTo: "Remote Rural Surcharge"},
    {charge: "Residential", mapsTo: "Residential"},
    {charge: "Shipping API", mapsTo: "Shipping API"},
    {charge: "Unauthorized OversizeComm", mapsTo: "Unauthorized OversizeComm"},
    {charge: "additionalCoverage", mapsTo: "Additional Coverage"},
    {charge: "additionalHandling", mapsTo: "Additional Handling"},
    {charge: "additionalCharges", mapsTo: "Additional Charges"},
    {charge: "additionalHandlingChargeDimension", mapsTo: "Additional Handling Charge Dimension"},
    {charge: "additionalHandlingChargeDimensions", mapsTo: "Additional Handling Charge Dimensions"},
    {charge: "additionalHandlingChargePackaging", mapsTo: "Additional Handling Charge Packaging"},
    {charge: "additionalHandlingLength+Girth", mapsTo: "Additional Handling Length + Girth"},
    {charge: "additionalHandlingSurcharge", mapsTo: "Additional Handling Surcharge"},
    {charge: "additionalWeightCharge", mapsTo: "Additional Weight Charge"},
    {charge: "addressCorrection", mapsTo: "Address Correction"},
    {charge: "ahsDimensions", mapsTo: "Ahs Dimensions"},
    {charge: "ahsWeight", mapsTo: "Ahs Weight"},
    {charge: "autobilling", mapsTo: "Auto Billing"},
    {charge: "backupDocs", mapsTo: "Backup Docs"},
    {charge: "base", mapsTo: "Base"},
    {charge: "callTag", mapsTo: "Call Tag"},
    {charge: "carbon", mapsTo: "Carbon"},
    {charge: "credit", mapsTo: "Credit"},
    {charge: "dasComm", mapsTo: "DAS Comm"},
    {charge: "dasCommercial", mapsTo: "DAS Commercial"},
    {charge: "dasExtendedComm", mapsTo: "DAS Extended Comm"},
    {charge: "dasExtendedCommercial", mapsTo: "DAS Extended Commercial"},
    {charge: "dasExtendedResi", mapsTo: "DAS Extended Resi"},
    {charge: "dasExtendedResidential", mapsTo: "DAS Extended Residential"},
    {charge: "dasRemoteComm", mapsTo: "Das Remote Comm"},
    {charge: "dasRemoteResi", mapsTo: "Das Remote Resi"},
    {charge: "dasRes", mapsTo: "DAS Res"},
    {charge: "dasResi", mapsTo: "DAS Resi"},
    {charge: "dasResidential", mapsTo: "DAS Residential"},
    {charge: "declaredValue", mapsTo: "Declared Value"},
    {charge: "deliveryAreaSurcharge", mapsTo: "Delivery Area Surcharge"},
    {charge: "deliveryAreaSurchargeCommercial", mapsTo: "Delivery Area Surcharge Commercial"},
    {charge: "deliveryAreaSurchargeExtended", mapsTo: "Delivery Area Surcharge Extended"},
    {charge: "deliveryAreaSurchargeRemote", mapsTo: "Delivery Area Surcharge Remote"},
    {charge: "deliveryAreaSurchargeResidential", mapsTo: "Delivery Area Surcharge Residential"},
    {charge: "demandAdditionalHandling", mapsTo: "Demand Additional Handling"},
    {charge: "demandAdditionalHandlingSurcharge", mapsTo: "Demand Additional Handling Surcharge"},
    {charge: "demandAhsCharge", mapsTo: "Demand Ahs Charge"},
    {charge: "demandOversizeCharge", mapsTo: "Demand Oversize Charge"},
    {charge: "demandSurcharge", mapsTo: "Demand Surcharge"},
    {charge: "demandSurchargeaddlHandling", mapsTo: "Demand Surcharge Addl Handling"},
    {charge: "demandSurchargecom", mapsTo: "Demand Surcharge Com"},
    {charge: "demandSurchargeresi", mapsTo: "Demand Surcharge Resi"},
    {charge: "demandSurcharges", mapsTo: "Demand Surcharges"},
    {charge: "directSignature", mapsTo: "Direct Signature"},
    {charge: "earnedDisCount", mapsTo: "Earned Discount"},
    {charge: "extendedArea", mapsTo: "Extended Area"},
    {charge: "extendedAreaTotal", mapsTo: "Extended Area Total"},
    {charge: "extraCare", mapsTo: "Extra Care"},
    {charge: "fuel", mapsTo: "Fuel"},
    {charge: "graceDiscount", mapsTo: "Grace Discount"},
    {charge: "HST", mapsTo: "HST"},
    {charge: "invalidAccountNumber", mapsTo: "Invalid Account Number"},
    {charge: "large_package", mapsTo: "Large Package"},
    {charge: "lateFee", mapsTo: "Late Fee"},
    {charge: "netTransportationCharges", mapsTo: "Net Transportation Charges"},
    {charge: "nonconveyable", mapsTo: "Non Conveyable"},
    {charge: "otherCharges", mapsTo: "Other Charges"},
    {charge: "over_36_inches", mapsTo: "Over 36 Inches"},
    {charge: "over_44_inches", mapsTo: "Over 44 Inches"},
    {charge: "oversize", mapsTo: "Oversize"},
    {charge: "oversizeCharge", mapsTo: "Oversize Charge"},
    {charge: "paperInvoice", mapsTo: "Paper Invoice"},
    {charge: "payment", mapsTo: "Payment"},
    {charge: "peakAdditionalHandling", mapsTo: "Peek Additional Handling"},
    {charge: "peakAhsCharge", mapsTo: "Peak Ahs Charge"},
    {charge: "peakOversizePackage", mapsTo: "Peak Oversize Package"},
    {charge: "peakSurcharges", mapsTo: "Peak Surcharges"},
    {charge: "peakUnauthorizedPackage", mapsTo: "Peak Unauthorized Package"},
    {charge: "performancePricing", mapsTo: "Performance Pricing"},
    {charge: "performancePricingDiscount", mapsTo: "Performance Pricing Discount"},
    {charge: "pickUpTag", mapsTo: "PickUp Tag"},
    {charge: "remoteAreaSurcharge", mapsTo: "Remote Area Surcharge"},
    {charge: "remoteRuralSurcharge", mapsTo: "Remote Rural Surcharge"},
    {charge: "remoteRuralcanadazone61", mapsTo: "Remote Rural Canada Zone 61"},
    {charge: "remoteRuralcanadazone62", mapsTo: "Remote Rural Canada Zone 62"},
    {charge: "residential", mapsTo: "Residential"},
    {charge: "residentialSurcharge", mapsTo: "Residential Surcharge"},
    {charge: "signature", mapsTo: "Signature"},
    {charge: "subtotal", mapsTo: "Subtotal"},
    {charge: "taxSubtotal", mapsTo: "Tax Subtotal"},
    {charge: "total", mapsTo: "Total"},
    {charge: "unauthorizedOversizeCommercial", mapsTo: "Unauthorized Oversize Commercial"},
    {charge: "unauthorizedOversizeSurcharge", mapsTo: "Unauthorized Oversize Surcharge"},
    {charge: "weeklyServiceCharge", mapsTo: "Weekly Service Charge"},
    {charge: "weeklyServiceChg", mapsTo: "Weekly Service Charge"},
    {charge: "weight", mapsTo: "Weight"},
    {charge: "youSaved", mapsTo: "Saved"},
    {charge: "zone", mapsTo: "Zone"},
    {charge: "GST", mapsTo: "GST"},
    {charge: "deliveryCharge", mapsTo: "Delivery Charge"},
    {charge: "adjustmentCharge", mapsTo: "Adjustment Charge"},
]

export const PREVIEW = "Preview Cheque"
export const BACK = "Back to Menu"

export const ADD_CHEQUE = "Print Cheque"
export const PRINT_CHEQUE = "Print Cheque"