import React from 'react';
import {
  Box, FormControl,
  IconButton, InputLabel, MenuItem, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import './TrialBalance.css';
import TopToolbar from '../../global/subcomponents/topToolbar/TopToolbar';
import { TRIAL_BALANCE } from './constants';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import { TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { EDIT_LEDGER } from '../ledger/constants';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Upgrade } from '@mui/icons-material';

class TrialBalanceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draggedRow: { sectionIndex: null, rowIndex: null },
      sections: this.props.sections,
      hideFilterBox : false,
      editableRow: null,
      updatedName: '',
      dragDisabled: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Check if sections prop has changed
    if (prevProps.sections !== this.props.sections) {
      this.setState({ sections: this.props.sections });
    }
  }

  handleNameChange = (event) => {
    this.setState({ updatedName: event.target.value });
  };

  handleEditClick = (sectionIndex, rowIndex, name) => {
    this.setState({ editableRow: { sectionIndex, rowIndex }, updatedName: name });
  };

  handleSaveClick = () => {
    const { editableRow, updatedName, sections } = this.state;
    if (editableRow) {
      const updatedSections = [...sections];
      updatedSections[editableRow.sectionIndex].rows[editableRow.rowIndex].name = updatedName;
      this.setState({ sections: updatedSections, editableRow: null, updatedName: '' },()=>{
        this.props.saveTrialBalance({
          trialBalanceId: this.props.trialBalanceId,
          sections: updatedSections,
          year: this.props.selectedYear,
          currency: this.props.selectedCurrency,
        });
      });
    }

  };


  onDragStart = (sectionIndex, rowIndex) => {
      const draggedRow = this.state.sections[sectionIndex].rows[rowIndex];
      this.draggedRow = { sectionIndex, rowIndex, data: draggedRow };
  };



  onDrop = (event, sectionIndex, targetRowIndex) => {
    const draggedRow = this.draggedRow;
    const { sections } = this.state;
    const { sectionIndex: draggedSectionIndex, rowIndex: draggedRowIndex } = draggedRow;
    if (!draggedRow) return;
    const existingSections = [...this.state.sections];

    const targetSection = this.state.sections[sectionIndex];
    const targetRow = targetSection.rows[targetRowIndex];


    const isTargetValid = targetRow && targetRow.shiftCredit !== '0.00' || targetRow.shiftDebit !== '0.00'

    if (isTargetValid) {

      const isDraggedRowValid = draggedRow?.data && draggedRow?.data.shiftCredit !== '0.00' || draggedRow?.data.shiftDebit !== '0.00'


      if(isDraggedRowValid){
        const updatedSections = [...sections];
        const draggedDataRow = updatedSections[draggedSectionIndex].rows[draggedRowIndex];
        // Remove dragged row
        updatedSections[draggedSectionIndex].rows.splice(draggedRowIndex, 1);
        // Insert dragged row at the target position
        updatedSections[sectionIndex].rows.splice(targetRowIndex, 0, draggedDataRow);

        this.setState({ sections: updatedSections });
        return;
      }
      else {

        const updatedSections = [...this.state.sections];
        const updatedSection = { ...updatedSections[sectionIndex] };
        updatedSection.rows = [...updatedSection.rows];

        // Remove the dragged row from its original position
        const originalRowIndex = draggedRow.rowIndex;

        if (originalRowIndex !== -1) {
          updatedSection.rows.splice(originalRowIndex, 1);
        }

        // Update the dragged row's shift values
        const draggedData = { ...draggedRow.data }; // Copy dragged row data

        draggedData.shiftDebit = draggedData.debit;
        draggedData.debit = "0.00";

        draggedData.shiftCredit = draggedData.credit;
        draggedData.credit = "0.00";

        targetRowIndex = targetRowIndex -1

        if(targetRowIndex === -1){
          targetRowIndex = 1
        }

        let targetTotalRow = null;
        for (let i = targetRowIndex; i < updatedSection.rows.length; i++) {
          const row = updatedSection.rows[i];
          if (row.type === 'total') {
            targetTotalRow = row;
            break; // Found the first 'total' row below
          }
        }


        if (targetTotalRow) {

          if (!targetTotalRow.totalIds.includes(draggedData.ledgerId)) {
            targetTotalRow.totalIds.push(draggedData.ledgerId);
          }

          const totalRowIndex = updatedSection.rows.findIndex(row =>
            row.type === 'total' &&
            row.totalIds
            && row.totalIds.includes(draggedRow.data.ledgerId)
          );


          if (totalRowIndex !== -1) {
            updatedSection.rows.splice(totalRowIndex, 0, draggedData);
          }

          // Recalculate totals for all "Total" rows in the section
          updatedSection.rows.forEach((row) => {
            if (row.type === 'total') {
              let totalShiftDebit = 0;
              let totalShiftCredit = 0;

              row.totalIds.forEach((id) => {
                const matchingRow = updatedSection.rows.find((r) => r.ledgerId === id);
                if (matchingRow) {
                  totalShiftDebit += parseFloat(matchingRow.shiftDebit || 0);
                  totalShiftCredit += parseFloat(matchingRow.shiftCredit || 0);
                }
              });

              // Update total row values
              row.shiftDebit =  "0.00"
              row.debit = totalShiftDebit.toFixed(2)
              row.shiftCredit =   "0.00"
              row.credit = totalShiftCredit.toFixed(2)
            }
          });

          // Update the state
          updatedSections[sectionIndex] = updatedSection;
          this.setState({ sections: updatedSections });
          this.draggedRow = null;

        }
        return;

      }

    }
    else{
      const isValidTotalTarget =
        targetRow && targetRow.type === 'total' &&
        draggedRow?.data && draggedRow?.data.shiftCredit === '0.00' && draggedRow?.data.shiftDebit === '0.00'
      if(isValidTotalTarget){
          const updatedSections = [...this.state.sections];
          const updatedSection = { ...updatedSections[sectionIndex] };
          updatedSection.rows = [...updatedSection.rows];

          // Remove the dragged row from its original position
          const originalRowIndex = draggedRow.rowIndex;

          if (originalRowIndex !== -1) {
            updatedSection.rows.splice(originalRowIndex, 1);
          }

          // Update the dragged row's shift values
          const draggedData = { ...draggedRow.data }; // Copy dragged row data

          draggedData.shiftDebit = draggedData.debit;
          draggedData.debit = "0.00";

          draggedData.shiftCredit = draggedData.credit;
          draggedData.credit = "0.00";

          targetRowIndex = targetRowIndex -1

          if(targetRowIndex === -1){
            targetRowIndex = 1
          }

          let targetTotalRow = null;
          for (let i = targetRowIndex; i < updatedSection.rows.length; i++) {
            const row = updatedSection.rows[i];
            if (row.type === 'total') {
              targetTotalRow = row;
              break; // Found the first 'total' row below
            }
          }


          if (targetTotalRow) {

            if (!targetTotalRow.totalIds.includes(draggedData.ledgerId)) {
              targetTotalRow.totalIds.push(draggedData.ledgerId);
            }

            const totalRowIndex = updatedSection.rows.findIndex(row =>
              row.type === 'total' &&
              row.totalIds
              && row.totalIds.includes(draggedRow.data.ledgerId)
            );


            if (totalRowIndex !== -1) {
              updatedSection.rows.splice(totalRowIndex, 0, draggedData);
            }

            // Recalculate totals for all "Total" rows in the section
            updatedSection.rows.forEach((row) => {
              if (row.type === 'total') {
                let totalShiftDebit = 0;
                let totalShiftCredit = 0;

                row.totalIds.forEach((id) => {
                  const matchingRow = updatedSection.rows.find((r) => r.ledgerId === id);
                  if (matchingRow) {
                    totalShiftDebit += parseFloat(matchingRow.shiftDebit || 0);
                    totalShiftCredit += parseFloat(matchingRow.shiftCredit || 0);
                  }
                });

                // Update total row values
                row.shiftDebit =  "0.00"
                row.debit = totalShiftDebit.toFixed(2)
                row.shiftCredit =   "0.00"
                row.credit = totalShiftCredit.toFixed(2)
              }
            });

            // Update the state
            updatedSections[sectionIndex] = updatedSection;
            this.setState({ sections: updatedSections });
            this.draggedRow = null;

          }
          return;
      }else{
        const isTargetValid = targetRow && targetRow.shiftCredit !== '0.00' || targetRow.shiftDebit !== '0.00'
        ||
          draggedRow?.data && draggedRow?.data.shiftCredit !== '0.00' || draggedRow?.data.shiftDebit !== '0.00'
        if(!isTargetValid){
          const updatedSections = [...sections];
          const draggedDataRow = updatedSections[draggedSectionIndex].rows[draggedRowIndex];
          // Remove dragged row
          updatedSections[draggedSectionIndex].rows.splice(draggedRowIndex, 1);
          // Insert dragged row at the target position
          updatedSections[sectionIndex].rows.splice(targetRowIndex, 0, draggedDataRow);

          this.setState({ sections: updatedSections });
          return;
        }else{
          if (draggedRow.data.shiftDebit !== "0.00") {
            draggedRow.data.debit = (Number(draggedRow.data.debit) + Number(draggedRow.data.shiftDebit)).toFixed(2).toString();
            draggedRow.data.shiftDebit = "0.00";
            draggedRow.data.type = '';

            // Find the relevant group where the totalRow exists
            let startIndex = 0;

            let sections = targetSection
            let endIndex = sections.rows.length;
            const rowIndex = draggedRowIndex

            for (let i = rowIndex - 1; i >= 0; i--) {
              if (Array.isArray(sections.rows[i].totalIds)) {
                startIndex = i + 1;
                break;
              }
            }
            for (let i = rowIndex + 1; i < sections.rows.length; i++) {
              if (Array.isArray(sections.rows[i].totalIds)) {
                endIndex = i;
                break;
              }
            }

            // Remove the total row if its debit is zero
            const relevantTotal = sections.rows[endIndex];
            if (relevantTotal && Array.isArray(relevantTotal.totalIds)) {
              const index = relevantTotal.totalIds.indexOf(draggedRow.data.ledgerId);
              if (index > -1) {
                relevantTotal.totalIds.splice(index, 1); // Removes the element at the found index
              }

              // Update the debit for the relevant total row
              relevantTotal.debit = (Number(relevantTotal.debit) - Number(draggedRow.data.debit)).toFixed(2).toString();

              // Remove the Total row if debit is zero
              if (relevantTotal.debit === "0.00") {
                sections.rows.splice(endIndex, 1); // Remove the total row if the debit is zero
              }
            }

            // Find the position of the Total row
            let totalRowIndex = -1;
            for (let i = rowIndex + 1; i < sections.rows.length; i++) {
              if (Array.isArray(sections.rows[i].totalIds)) {
                totalRowIndex = i;
                break;
              }
            }

            // If we found the Total row, insert the row right below it
            if (totalRowIndex !== -1) {
              sections.rows.splice(rowIndex, 1);
              sections.rows.splice(totalRowIndex, 0, draggedRow.data); // Insert after the Total row
            }

          }
          else if (draggedRow.data.shiftCredit !== "0.00") {

            draggedRow.data.credit = (Number(draggedRow.data.credit) + Number(draggedRow.data.shiftCredit)).toFixed(2).toString();
            draggedRow.data.shiftCredit = "0.00";
            draggedRow.data.type = '';

            // Find the relevant group where the totalRow exists
            let startIndex = 0;

            let sections = targetSection
            let endIndex = sections.rows.length;
            const rowIndex = draggedRowIndex

            for (let i = rowIndex - 1; i >= 0; i--) {
              if (Array.isArray(sections.rows[i].totalIds)) {
                startIndex = i + 1;
                break;
              }
            }
            for (let i = rowIndex + 1; i < sections.rows.length; i++) {
              if (Array.isArray(sections.rows[i].totalIds)) {
                endIndex = i;
                break;
              }
            }

            // Remove the total row if its credit is zero
            const relevantTotal = sections.rows[endIndex];
            if (relevantTotal && Array.isArray(relevantTotal.totalIds)) {
              const index = relevantTotal.totalIds.indexOf(draggedRow.data.ledgerId);
              if (index > -1) {
                relevantTotal.totalIds.splice(index, 1); // Removes the element at the found index
              }

              // Update the credit for the relevant total row
              relevantTotal.credit = (Number(relevantTotal.credit) - Number(draggedRow.data.credit)).toFixed(2).toString();

              // Remove the Total row if credit is zero
              if (relevantTotal.credit === "0.00") {
                sections.rows.splice(endIndex, 1); // Remove the total row if the credit is zero
              }
            }


            // Find the position of the Total row
            let totalRowIndex = -1;
            for (let i = rowIndex + 1; i < sections.rows.length; i++) {
              if (Array.isArray(sections.rows[i].totalIds)) {
                totalRowIndex = i;
                break;
              }
            }

            if (totalRowIndex !== -1) {
              sections.rows.splice(rowIndex, 1);
              sections.rows.splice(totalRowIndex, 0, draggedRow.data);
            }

          }
        }
      }
      this.setState({ sections: existingSections });
      return;
    }



    // Make a deep copy of sections to update
    const updatedSections = [...this.state.sections];
    const updatedSection = { ...updatedSections[sectionIndex] };
    updatedSection.rows = [...updatedSection.rows];

    // Remove the dragged row from its original position
    const originalRowIndex = draggedRow.rowIndex;

    if (originalRowIndex !== -1) {
      updatedSection.rows.splice(originalRowIndex, 1);
    }

    // Update the dragged row's shift values
    const draggedData = { ...draggedRow.data }; // Copy dragged row data
    draggedData.shiftDebit = draggedData.debit;
    draggedData.debit = "0.00";

    draggedData.shiftCredit = draggedData.credit;
    draggedData.credit = "0.00";

    // Ensure the dragged row's ledgerId is added to the correct total row's totalIds
    if (!targetRow.totalIds.includes(draggedData.ledgerId)) {
      targetRow.totalIds.push(draggedData.ledgerId);
    }


    const totalRowIndex = updatedSection.rows.findIndex(row =>
      row.type === 'total' &&
      row.totalIds
      && row.totalIds.includes(draggedRow.data.ledgerId)
    );


    if (totalRowIndex !== -1) {
      updatedSection.rows.splice(totalRowIndex, 0, draggedData);
    }

    // Recalculate totals for all "Total" rows in the section
    updatedSection.rows.forEach((row) => {
      if (row.type === 'total') {
        let totalShiftDebit = 0;
        let totalShiftCredit = 0;

        row.totalIds.forEach((id) => {
          const matchingRow = updatedSection.rows.find((r) => r.ledgerId === id);
          if (matchingRow) {
            totalShiftDebit += parseFloat(matchingRow.shiftDebit || 0);
            totalShiftCredit += parseFloat(matchingRow.shiftCredit || 0);
          }
        });

        // Update total row values
        row.shiftDebit =  "0.00"
        row.debit = totalShiftDebit.toFixed(2)
        row.shiftCredit =   "0.00"
        row.credit = totalShiftCredit.toFixed(2)
      }
    });

    // Update the state
    updatedSections[sectionIndex] = updatedSection;
    this.setState({ sections: updatedSections });
    this.draggedRow = null;
  };




  onDragOver = (event) => {
    event.preventDefault();
  };


  handleShift = (sectionIndex, rowIndex, direction, type) => {
    const updatedSections = [...this.state.sections];
    const section = updatedSections[sectionIndex];
    const row = section.rows[rowIndex];


    if (type === 'debit') {
      if (direction === 'shift') {
        // Shift left: Add the current row's debit to shiftDebit and reset debit
        if (row.debit !== "0.00") {
          row.shiftDebit = (Number(row.shiftDebit) +  Number(row.debit)).toFixed(2).toString();
          row.debit = "0.00";
          row.type = 'manual';

          if (!row.groupId) {
            row.groupId = `group-${sectionIndex}-${rowIndex}-${Date.now()}`;
          }

          const totalRow = {
            totalIds: [row.ledgerId],
            name: 'Total',
            shiftDebit: "0.00",
            debit: row.shiftDebit,
            shiftCredit: "0.00",
            credit: row.shiftCredit,
            type: "total",
            groupId: row.groupId
          };

          updatedSections[sectionIndex].rows.splice(rowIndex + 1, 0, totalRow); // Insert totalRow below the current row
        }
      } else if (direction === 'back') {
        // Shift right: Move shiftDebit back to debit
        if (row.shiftDebit !== "0.00") {
          row.debit = (Number(row.debit) + Number(row.shiftDebit)).toFixed(2).toString();
          row.shiftDebit = "0.00";
          row.type = '';

          // Find the relevant group where the totalRow exists
          let startIndex = 0;
          let endIndex = section.rows.length;

          for (let i = rowIndex - 1; i >= 0; i--) {
            if (Array.isArray(section.rows[i].totalIds)) {
              startIndex = i + 1;
              break;
            }
          }
          for (let i = rowIndex + 1; i < section.rows.length; i++) {
            if (Array.isArray(section.rows[i].totalIds)) {
              endIndex = i;
              break;
            }
          }

          // Remove the total row if its debit is zero
          const relevantTotal = section.rows[endIndex];
          if (relevantTotal && Array.isArray(relevantTotal.totalIds)) {
            const index = relevantTotal.totalIds.indexOf(row.ledgerId);
            if (index > -1) {
              relevantTotal.totalIds.splice(index, 1); // Removes the element at the found index
            }

            // Update the debit for the relevant total row
            relevantTotal.debit = (Number(relevantTotal.debit) - Number(row.debit)).toFixed(2).toString();

            // Remove the Total row if debit is zero
            if (relevantTotal.debit === "0.00") {
              section.rows.splice(endIndex, 1); // Remove the total row if the debit is zero
            }
          }

          // Find the position of the Total row
          let totalRowIndex = -1;
          for (let i = rowIndex + 1; i < section.rows.length; i++) {
            if (Array.isArray(section.rows[i].totalIds)) {
              totalRowIndex = i;
              break;
            }
          }

          // If we found the Total row, insert the row right below it
          if (totalRowIndex !== -1) {
            section.rows.splice(rowIndex, 1);
            section.rows.splice(totalRowIndex, 0, row); // Insert after the Total row
          }

        }
      }
    } else if (type === 'credit') {
      if (direction === 'shift') {
        // Shift left: Add the current row's credit to shiftCredit and reset credit
        if (row.credit !== "0.00") {
          row.shiftCredit = (Number(row.shiftCredit) + Number(row.credit)).toFixed(2).toString();
          row.credit = "0.00";
          row.type = 'manual';

          if (!row.groupId) {
            row.groupId = `group-${sectionIndex}-${rowIndex}-${Date.now()}`;
          }

          const totalRow = {
            totalIds: [row.ledgerId],
            name: 'Total',
            shiftDebit: "0.00",
            debit: row.shiftDebit,
            shiftCredit: "0.00",
            credit: row.shiftCredit,
            type: "total",
            groupId: row.groupId
          };

          updatedSections[sectionIndex].rows.splice(rowIndex + 1, 0, totalRow); // Insert totalRow below the current row
        }
      } else if (direction === 'back') {
        if (row.shiftCredit !== "0.00") {
          row.credit = (Number(row.credit) + Number(row.shiftCredit)).toFixed(2).toString();
          row.shiftCredit = "0.00";
          row.type = '';

          // Find the relevant group where the totalRow exists
          let startIndex = 0;
          let endIndex = section.rows.length;

          for (let i = rowIndex - 1; i >= 0; i--) {
            if (Array.isArray(section.rows[i].totalIds)) {
              startIndex = i + 1;
              break;
            }
          }
          for (let i = rowIndex + 1; i < section.rows.length; i++) {
            if (Array.isArray(section.rows[i].totalIds)) {
              endIndex = i;
              break;
            }
          }

          // Remove the total row if its credit is zero
          const relevantTotal = section.rows[endIndex];
          if (relevantTotal && Array.isArray(relevantTotal.totalIds)) {
            const index = relevantTotal.totalIds.indexOf(row.ledgerId);
            if (index > -1) {
              relevantTotal.totalIds.splice(index, 1); // Removes the element at the found index
            }

            // Update the credit for the relevant total row
            relevantTotal.credit = (Number(relevantTotal.credit) - Number(row.credit)).toFixed(2).toString();

            // Remove the Total row if the credit is zero
            if (relevantTotal.credit === "0.00") {
              section.rows.splice(endIndex, 1); // Remove the total row if the credit is zero
            }
          }

          // Find the position of the Total row
          let totalRowIndex = -1;
          for (let i = rowIndex + 1; i < section.rows.length; i++) {
            if (Array.isArray(section.rows[i].totalIds)) {
              totalRowIndex = i;
              break;
            }
          }

          // If we found the Total row, insert the row right below it
          if (totalRowIndex !== -1) {
            section.rows.splice(rowIndex, 1);
            section.rows.splice(totalRowIndex, 0, row); // Insert after the Total row
          }

        }
      }
    }

    // Update the state with the modified rows
    this.setState({ sections: updatedSections });
  };



  formatBalance = (balance, removeCommas = false) => {
    let formattedBalance = parseFloat(balance)
      .toFixed(2)
      .toString();

    // If removeCommas is true, return the balance without commas
    if (removeCommas) {
      return formattedBalance;
    }

    // Otherwise, add commas to the balance
    return formattedBalance.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }


  setHideFilterBox = (isHide) =>{
    this.setState({hideFilterBox : isHide})
  }

  handleExport = () => {
    const { sections } = this.props;
    if (!sections || sections.length === 0) {
      return;
    }

    // Define the headers for the CSV
    const headers = 'Name, ,Debit, ,Credit';

    const totalDebit = this.state.sections.reduce((sum, section) => {
      return sum + section.rows.reduce((rowSum, row) => rowSum + parseFloat(row.debit || 0), 0);
    }, 0);

    const totalCredit = this.state.sections.reduce((sum, section) => {
      return sum + section.rows.reduce((rowSum, row) => rowSum + parseFloat(row.credit || 0), 0);
    }, 0);

    const difference = Math.abs(totalDebit - totalCredit);
    const adjustedDebit = totalDebit < totalCredit ? totalDebit + difference : totalDebit;
    const adjustedCredit = totalCredit < totalDebit ? totalCredit + difference : totalCredit;

    const rows = sections.flatMap(section => {
      const sectionNameRow = `${section.type},,,,,`;

      const sectionRows = section.rows.map(row => {
        let name = row.name ?? '';
        if (name.includes(',')) {
          name = `"${name}"`
        }
        const shiftDebit = row.shiftDebit ?? '';
        const debit = row.debit ?? '';
        const shiftCredit = row.shiftCredit ?? '';
        const credit = row.credit ?? '';

        return `${name},${shiftDebit},${debit},${shiftCredit},${credit}`;
      });

      const emptyRow = ',,,,,';

      return [sectionNameRow, ...sectionRows, emptyRow];
    });




    const debitDifference = Math.min(totalDebit, totalCredit) === totalDebit
        ? this.formatBalance(Math.abs(
          this.state.sections.reduce(
            (sum, section) =>
              sum +
              section.rows.reduce(
                (subtotal, row) => subtotal + parseFloat(row.debit || 0),
                0
              ),
            0
          ) -
          this.state.sections.reduce(
            (sum, section) =>
              sum +
              section.rows.reduce(
                (subtotal, row) => subtotal + parseFloat(row.credit || 0),
                0
              ),
            0
          )
        ).toFixed(2),true)
        : ''

    const creditDifference = Math.min(totalDebit, totalCredit) === totalCredit
        ? this.formatBalance(Math.abs(
          this.state.sections.reduce(
            (sum, section) =>
              sum +
              section.rows.reduce(
                (subtotal, row) => subtotal + parseFloat(row.debit || 0),
                0
              ),
            0
          ) -
          this.state.sections.reduce(
            (sum, section) =>
              sum +
              section.rows.reduce(
                (subtotal, row) => subtotal + parseFloat(row.credit || 0),
                0
              ),
            0
          )
        ).toFixed(2),true)
        : ''

    const differenceRow = `Difference,,${debitDifference},,${creditDifference}`;

    const totalRow = `Total,,${adjustedDebit.toFixed(2)},,${adjustedCredit.toFixed(2)}`;

    const cleanRows = rows.map(row =>
      row.split(',').map(value => value.trim() === "0.00" ? '' : value).join(',')
    );

    const csvContent = [headers, ...cleanRows.flat(), differenceRow, totalRow].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Trial Balance${this.props.selectedYear}_${this.props.selectedCurrency}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  getContextualMenuItems = () => {
    const { trialBalanceId, saveTrialBalance, fetchTrialBalance } = this.props;
    const { sections } = this.state;

    if (this.props.selectedCurrency === "Consolidated CAD") {
      return [
        {
          title: 'Export',
          icon: <Upgrade />,
          onClick: () => {
            this.handleExport()
          }
        }
      ]
    }

    return [
      {
        title: 'Save',
        icon: <SaveIcon />,
        onClick: () => {
          saveTrialBalance({
            trialBalanceId,
            sections,
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency,
          });

          this.props.setIsEdit(false)
        },
      },
      this.props.isEdit
        ? {
          title: 'Cancel Editing',
          icon: <CloseIcon />,
          onClick: () => {
            this.setHideFilterBox(false);
            this.props.setIsEdit(false)
          },
        }
        : {
          title: 'Edit',
          icon: <EditIcon />,
          onClick: () => {
            this.props.setIsEdit(true)
          },
        },
      {
        title: 'Refresh',
        icon: <RefreshIcon />,
        onClick: () => {
          fetchTrialBalance({
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency,
          });
        },
      },
      {
        title: 'Export',
        icon: <Upgrade />,
        onClick: () => {
          this.handleExport()
        }
      }
    ];
  };



  render() {

    const { sections, editableRow, updatedName } = this.state;

    const totalDebit = this.state.sections.reduce((sum, section) => {
      return sum + section.rows.reduce((rowSum, row) => rowSum + parseFloat(row.debit || 0), 0);
    }, 0);

    const totalCredit = this.state.sections.reduce((sum, section) => {
      return sum + section.rows.reduce((rowSum, row) => rowSum + parseFloat(row.credit || 0), 0);
    }, 0);

    const difference = Math.abs(totalDebit - totalCredit);
    const adjustedDebit = totalDebit < totalCredit ? totalDebit + difference : totalDebit;
    const adjustedCredit = totalCredit < totalDebit ? totalCredit + difference : totalCredit;


    return (
      <React.Fragment>
        <TopToolbar pageName={TRIAL_BALANCE}
                    menuItems={this.getContextualMenuItems()}
        />

        <Box className="box-trialBalance" style={{ visibility: this.state.hideFilterBox ? 'hidden' : 'visible' }}>
          <FormControl variant="outlined" className="form-control-ledger">
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              className="select-year-ledger"
              labelId="year-label"
              value={this.props.selectedYear}
              onChange={this.props.handleYearChange}
              label="Year"
            >
              {this.props.years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className="form-control">
            <InputLabel id="currency-label">Currency</InputLabel>
            <Select
              className="select-currency-ledger"
              labelId="currency-label"
              value={this.props.selectedCurrency}
              onChange={this.props.handleCurrencyChange}
              label="Currency"
            >
              {this.props.currency.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>


        <TableContainer className="MuiTable-root-trail">
          <Table>
            <TableHead className="MuiTableHead-root-trail">
              <TableRow>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'left', width: '60px' }}
                ></TableCell>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'left' }}
                >
                  Name
                </TableCell>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'center' }}
                ></TableCell>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'center' }}
                >
                  Debit
                </TableCell>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'center' }}
                ></TableCell>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'center' }}
                >
                  Credit
                </TableCell>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'center' }}
                >
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="MuiTableBody-root-trail">
              {this.state.sections.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      style={{
                        fontWeight: 'bold',
                        backgroundColor: '#f5f5f5',
                        textAlign: 'left',
                      }}
                    >
                      {section.type}
                    </TableCell>
                  </TableRow>

                  {section.rows.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      style={{
                        cursor: row?.ledgerId != null &&
                        // row.shiftDebit === "0.00" &&
                        // row.shiftCredit === "0.00" &&
                        this.props.isEdit
                          ? 'grab'
                          : 'default',
                      }}
                      draggable={
                        row?.ledgerId != null &&
                        // row.shiftDebit === "0.00" &&
                        // row.shiftCredit === "0.00" &&
                        this.props.isEdit
                      }
                      onDragStart={(event) => {
                        if(this.props.isEdit){
                          this.onDragStart(sectionIndex, rowIndex)}
                        }
                      }
                      onDragOver={this.onDragOver}
                      onDrop={(event) => {
                        if(this.props.isEdit){
                          this.onDrop(event, sectionIndex, rowIndex)
                        }
                      }}
                    >

                     {/*&& (row.shiftDebit === "0.00" && row.shiftCredit === "0.00"*/}
                      {row?.ledgerId != null && this.props.isEdit ? (
                        <TableCell className="MuiTableCell-root-trail">
                          <MenuIcon
                            style={{ cursor: 'grab', marginRight: 8,  }}
                          />
                        </TableCell>
                      ) : (

                        <TableCell className="MuiTableCell-root-trail">
                          {row?.ledgerId == null && this.props.isEdit ? (
                            <Tooltip title={"Edit Entry"}>
                              <IconButton
                                onClick={() => {
                                  this.handleEditClick(sectionIndex, rowIndex, row.name);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <DragHandleIcon
                              style={{ cursor: 'grab', marginRight: 8, visibility: 'hidden' }}
                            />
                          )}


                        </TableCell>

                      )}







                      <TableCell
                        className="MuiTableCell-root-trail"
                        style={{
                          height:'75px',
                          fontWeight: Array.isArray(row?.totalIds) && row.totalIds.length > 0 ? 'bold' : 'normal',
                        }}
                      >
                        {editableRow &&
                        editableRow.sectionIndex === sectionIndex &&
                        editableRow.rowIndex === rowIndex ? (

                          <TextField
                            variant="outlined"
                            name="name"
                            label="Name"
                            value={updatedName}
                            onChange={this.handleNameChange}
                            style={{ width: '100%' }}
                            error={updatedName === '' || updatedName === null ||updatedName === undefined}
                          />
                        ) : (
                          row.name
                        )}
                      </TableCell>


                      <TableCell
                        style={{ fontWeight:  Array.isArray(row?.totalIds) && row.totalIds.length > 0 ? 'bold' : 'normal', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail"
                      >

                        {row.debit !== '0.00' && !Array.isArray(row?.totalIds) && this.props.isEdit ? (
                          <div style={{height:'40px',marginRight:'40px'}}>
                            <IconButton onClick={() => this.handleShift(sectionIndex, rowIndex, 'shift', 'debit')}>
                              <ArrowBackIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div style={{height:'0px'}}>
                            <IconButton style={{visibility:'hidden'}} onClick={null}>
                              <ArrowBackIcon />
                            </IconButton>
                          </div>
                        )}

                        {row.shiftDebit !== '0.00' ?
                          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>
                            <span style={{ flexShrink: 0 }}>$</span>
                            <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right', }}>
                                 {this.formatBalance(row.shiftDebit)}
                               </span>
                          </div>
                          : ''
                        }
                      </TableCell>



                      <TableCell style={{ fontWeight:  Array.isArray(row?.totalIds) && row.totalIds.length > 0 ? 'bold' : 'normal', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">

                        {row.debit === '0.00' ? '' :
                          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <span style={{ flexShrink: 0 }}>$</span>
                            <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
                                 {this.formatBalance(row.debit)}
                            </span>
                          </div>
                        }

                        {!Array.isArray(row?.totalIds) && row.shiftDebit !== '0.00' && this.props.isEdit ? (
                          <div style={{height:'40px',marginRight:'40px'}}>
                            <IconButton
                              onClick={() =>
                                this.handleShift(
                                  sectionIndex,
                                  rowIndex,
                                  'back',
                                  'debit'
                                )
                              }
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div style={{height:'0px'}}>
                            <IconButton></IconButton>
                          </div>
                        )}
                      </TableCell>

                      <TableCell style={{ fontWeight:  Array.isArray(row?.totalIds) && row.totalIds.length > 0 ? 'bold' : 'normal', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">
                        {row.credit !== "0.00" && !Array.isArray(row?.totalIds) && this.props.isEdit ? (
                          <div style={{ height: '40px',marginRight:'40px' }}>
                            <IconButton
                              onClick={() =>
                                this.handleShift(
                                  sectionIndex,
                                  rowIndex,
                                  'shift',
                                  'credit',
                                )
                              }
                            >
                              <ArrowBackIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div style={{height:'0px'}}>
                            <IconButton></IconButton>
                          </div>
                        )}
                        {row.shiftCredit === '0.00' ? '' :

                          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <span style={{ flexShrink: 0 }}>$</span>
                            <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
                                 {  this.formatBalance(row.shiftCredit)}
                            </span>
                          </div>
                        }
                      </TableCell>

                      <TableCell style={{ fontWeight: Array.isArray(row?.totalIds) && row.totalIds.length > 0 ? 'bold' : 'normal', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">

                        {row.credit === '0.00' ? '' :

                          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <span style={{ flexShrink: 0 }}>$</span>
                            <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
                                 { this.formatBalance(row.credit)}
                            </span>
                          </div>
                        }
                        {!Array.isArray(row?.totalIds) && row.shiftCredit !== '0.00' && this.props.isEdit ? (
                          <div style={{ height: '40px',marginRight:'40px' }}>
                            <IconButton
                              onClick={() =>
                                this.handleShift(
                                  sectionIndex,
                                  rowIndex,
                                  'back',
                                  'credit',
                                )
                              }
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div style={{ height: '0px' }}>
                            <IconButton></IconButton>
                          </div>
                        )}
                      </TableCell>

                      <TableCell>
                        {editableRow &&
                          editableRow.sectionIndex === sectionIndex &&
                          editableRow.rowIndex === rowIndex && (
                            <>
                              <Tooltip title="Save Entry" style={{ marginLeft: '-8px' }}>
                                <IconButton onClick={this.handleSaveClick}>
                                  <CheckCircleIcon fontSize="small" color="secondary" />
                                </IconButton>
                              </Tooltip>

                              {/*<Tooltip title="Delete" style={{ marginLeft: '-8px' }}>*/}
                              {/*  <IconButton*/}
                              {/*    onClick={() => {*/}
                              {/*      this.handleShift(sectionIndex, rowIndex, '', '');*/}
                              {/*      this.handleSaveClick();*/}
                              {/*    }}*/}
                              {/*  >*/}
                              {/*    <CloseIcon fontSize="small" color="secondary" />*/}
                              {/*  </IconButton>*/}
                              {/*</Tooltip>*/}
                            </>
                          )}
                      </TableCell>


                    </TableRow>
                  ))}
                </React.Fragment>
              ))}


              {this.state.sections && this.state.sections.length > 0 &&
                <>
                  {/* Difference Row */}
                  <TableRow>
                    <TableCell
                      className="MuiTableCell-root-trail"
                    >
                      <DragHandleIcon
                        style={{ cursor: 'grab', marginRight: 8, visibility: 'hidden' }}
                      />

                    </TableCell>

                    <TableCell
                      style={{
                        height:'75px',
                        fontWeight:'bold',
                      }}
                      className="MuiTableCell-root-trail"
                    >
                      Difference
                    </TableCell>

                    <TableCell
                      style={{ fontWeight:'bold', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail"
                    >

                    </TableCell>



                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">


                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ flexShrink: 0 }}>{Math.min(totalDebit, totalCredit) === totalDebit ? '$' : ''}</span>
                        <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
                   {
                     Math.min(totalDebit, totalCredit) === totalDebit
                       ? this.formatBalance(Math.abs(
                         this.state.sections.reduce(
                           (sum, section) =>
                             sum +
                             section.rows.reduce(
                               (subtotal, row) => subtotal + parseFloat(row.debit || 0),
                               0
                             ),
                           0
                         ) -
                         this.state.sections.reduce(
                           (sum, section) =>
                             sum +
                             section.rows.reduce(
                               (subtotal, row) => subtotal + parseFloat(row.credit || 0),
                               0
                             ),
                           0
                         )
                       ).toFixed(2))
                       : ''
                   }

                    </span>
                      </div>

                    </TableCell>

                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">

                    </TableCell>

                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">

                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ flexShrink: 0 }}> {Math.min(totalDebit, totalCredit) === totalCredit ? '$' : ''}</span>
                        <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
                      {
                        Math.min(totalDebit, totalCredit) === totalCredit
                          ? this.formatBalance(Math.abs(
                            this.state.sections.reduce(
                              (sum, section) =>
                                sum +
                                section.rows.reduce(
                                  (subtotal, row) => subtotal + parseFloat(row.debit || 0),
                                  0
                                ),
                              0
                            ) -
                            this.state.sections.reduce(
                              (sum, section) =>
                                sum +
                                section.rows.reduce(
                                  (subtotal, row) => subtotal + parseFloat(row.credit || 0),
                                  0
                                ),
                              0
                            )
                          ).toFixed(2))
                          : ''
                      }
                    </span>
                      </div>


                    </TableCell>

                    <TableCell></TableCell>
                  </TableRow>

                  {/* Total Row */}
                  <TableRow>
                    <TableCell
                      className="MuiTableCell-root-trail"
                    >
                      <DragHandleIcon
                        style={{ cursor: 'grab', marginRight: 8, visibility: 'hidden' }}
                      />

                    </TableCell>

                    <TableCell
                      style={{
                        height:'75px',
                        fontWeight:'bold',
                      }}
                      className="MuiTableCell-root-trail"
                    >
                      Total
                    </TableCell>

                    <TableCell
                      style={{ fontWeight:'bold', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail"
                    >

                    </TableCell>



                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">


                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ flexShrink: 0 }}>$</span>
                        <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
                                     {this.formatBalance( adjustedDebit.toFixed(2))}
                            </span>
                      </div>

                    </TableCell>

                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">

                    </TableCell>

                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '50px', width: '15%', }} className="MuiTableCell-root-trail">

                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <span style={{ flexShrink: 0 }}>$</span>
                        <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
                                  {this.formatBalance( adjustedCredit.toFixed(2))}
                            </span>
                      </div>


                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              }





            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }

}

export default TrialBalanceTable;
