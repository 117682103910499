export const LIST_ALL_TRANSACTIONS = 'LIST_ALL_TRANSACTIONS';
export const LIST_SHIPPING_SUMMARY = 'LIST_SHIPPING_SUMMARY';
export const LIST_FTL_SUMMARY = 'LIST_FTL_SUMMARY';
export const LIST_STATEMENT_TAXES = 'LIST_STATEMENT_TAXES';
export const SAVE_SHIPPING_SETTINGS = 'SAVE_SHIPPING_SETTINGS';
export const SAVE_STATEMENT_TAXES_TEMPLATE = 'SAVE_STATEMENT_TAXES_TEMPLATE';
export const STORE_STATEMENT_TAXES_TEMPLATE = 'STORE_STATEMENT_TAXES_TEMPLATE';
export const LIST_STATEMENT_TAXES_TEMPLATE = 'LIST_STATEMENT_TAXES_TEMPLATE';
export const STORE_STATEMENT_TAXES_SUMMARY = 'STORE_STATEMENT_TAXES_SUMMARY';
export const LIST_ALL_LEDGERS = 'LIST_ALL_LEDGERS';
export const FETCH_TRANSACTIONS_LEDGER = 'FETCH_TRANSACTIONS_LEDGER';
export const FETCH_LEDGER_BALANCE = 'FETCH_LEDGER_BALANCE';
export const LEDGER_ENTRIES = 'LEDGER_ENTRIES';
export const UPDATE_LEDGER = 'UPDATE_LEDGER';
export const DELETE_LEDGER = 'DELETE_LEDGER';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const DELETE_ENTRY = 'DELETE_ENTRY';
export const DELETE_LEDGER_BY_YEAR = 'DELETE_LEDGER_BY_YEAR';
export const DELETE_LEDGER_BY_YEAR_AND_CURRENCY = 'DELETE_LEDGER_BY_YEAR_AND_CURRENCY';
export const LIST_ALL_CHEQUES = 'LIST_ALL_CHEQUES';
export const STORE_ALL_LEDGERS = 'STORE_ALL_LEDGERS';
export const STORE_TRANSACTIONS_LEDGER = 'STORE_TRANSACTIONS_LEDGER';
export const STORE_LEDGER_ENTRY = 'STORE_LEDGER_ENTRY';
export const STORE_PREDEFINED_ENTRY = 'STORE_PREDEFINED_ENTRY';
export const VALIDATE_CHEQUE_NUMBER = 'VALIDATE_CHEQUE_NUMBER';
export const STORE_ALL_CHEQUES = 'STORE_ALL_CHEQUES';
export const SAVE_LEDGER = 'SAVE_LEDGER';
export const SAVE_MARKETPLACE_LEDGER = 'SAVE_MARKETPLACE_LEDGER';
export const SAVE_TRANSACTION_LEDGER = 'SAVE_TRANSACTION_LEDGER';
export const SAVE_CHEQUE = 'SAVE_CHEQUE';
export const IMPORT_CHEQUE = 'IMPORT_CHEQUE';
export const DELETE_CHEQUES = 'DELETE_CHEQUES';
export const LIST_SHIPPING_SETTINGS = 'LIST_SHIPPING_SETTINGS';
export const STORE_SHIPPING_SUMMARY = 'STORE_SHIPPING_SUMMARY';
export const STORE_FTL_SUMMARY = 'STORE_FTL_SUMMARY';
export const STORE_SHIPPING_SUMMARY_SETTINGS = 'STORE_SHIPPING_SUMMARY_SETTINGS';
export const SAVE_TRANSACTION = 'SAVE_TRANSACTION';
export const STORE_ALL_TRANSACTIONS = 'STORE_ALL_TRANSACTIONS';
export const DELETE_TRANSACTIONS = 'DELETE_TRANSACTIONS';
export const ASSIGN_CATEGORY = 'ASSIGN_CATEGORY';
export const ADD_TAX_INFO = 'ADD_TAX_INFO';
export const APPLY_TRANSACTIONS_RULES = 'APPLY_TRANSACTIONS_RULES';
export const STORE_UPDATED_TRANSACTION = 'STORE_UPDATED_TRANSACTION';
export const STORE_UPDATED_TRANSACTIONS = 'STORE_UPDATED_TRANSACTIONS';
export const STORE_UPDATED_CHEQUES = 'STORE_UPDATED_CHEQUES';
export const STORE_EDITING_TRANSACTION = 'STORE_EDITING_TRANSACTION';
export const STORE_UPDATED_TAXINFO = 'STORE_UPDATED_TAXINFO';
export const STORE_TAXTYPE_WITH_STATE_PROVINCE = 'STORE_TAXTYPE_WITH_STATE_PROVINCE';
export const REMOVE_DELETED_TRANSACTION = 'REMOVE_DELETED_TRANSACTION';
export const IMPORT_TRANSACTION = 'IMPORT_TRANSACTION';
export const SET_PROCESSING = 'SET_PROCESSING';
export const GET_INVOICE_BY_INVOICE_NUMBER = 'GET_INVOICE_BY_INVOICE_NUMBER';
export const STORE_FOUND_INVOICE = 'STORE_FOUND_INVOICE';
export const GET_TRANSACTION_ATTACHMENT = 'GET_TRANSACTION_ATTACHMENT';
export const STORE_TRANSACTION_ATTACHMENT = 'STORE_TRANSACTION_ATTACHMENT';
export const DELETE_TRANSACTION_ATTACHMENT = 'DELETE_TRANSACTION_ATTACHMENT';
export const GET_SUPPLIER_REFERENCES = 'GET_SUPPLIER_REFERENCES';
export const GET_TRANSACTION_TAX_AMOUNT = 'GET_TRANSACTION_TAX_AMOUNT';
export const GET_TAXTYPE_WITH_STATE_PROVINCE = "GET_TAXTYPE_WITH_STATE_PROVINCE";
export const STORE_SUPPLIER_REFERENCES = 'STORE_SUPPLIER_REFERENCES';
export const LIST_TRANSACTION_CATEGORIES = 'LIST_TRANSACTION_CATEGORIES';
export const STORE_ALL_TRANSACTION_CATEGORIES = 'STORE_ALL_TRANSACTION_CATEGORIES';
export const SAVE_TRANSACTION_CATEGORY = 'SAVE_TRANSACTION_CATEGORY';
export const DELETE_TRANSACTION_CATEGORY = 'DELETE_TRANSACTION_CATEGORY';
export const SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION = 'SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION';
export const NAVIGATE_TO_PARENT_TRANSACTION = 'NAVIGATE_TO_PARENT_TRANSACTION';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const STORE_PURCHASE_SUMMARY = 'STORE_PURCHASE_SUMMARY';
export const LIST_PURCHASE_SUMMARY = 'LIST_PURCHASE_SUMMARY';
export const GET_NOTE = "GET_NOTE";
export const STORE_NOTE = 'STORE_NOTE';
export const SAVE_NOTE = "SAVE_NOTE";
export const LIST_ALL_TRIAL_BALANCE = 'LIST_ALL_TRIAL_BALANCE';
export const FETCH_TRIAL_BALANCE = 'FETCH_TRIAL_BALANCE';
export const STORE_TRIAL_BALANCE = 'STORE_TRIAL_BALANCE';
export const SAVE_TRIAL_BALANCE = 'SAVE_TRIAL_BALANCE';
export const IMPORT_MARKETPLACE_SUMMARY = 'IMPORT_MARKETPLACE_SUMMARY';
export const LIST_MARKETPLACE_SUMMARY = 'LIST_MARKETPLACE_SUMMARY';
export const STORE_MARKETPLACE_SUMMARY = 'STORE_MARKETPLACE_SUMMARY';
export const MARKETPLACE_PROGRESS_SUMMARY = 'MARKETPLACE_PROGRESS_SUMMARY';
export const STORE_MARKETPLACE_PROGRESS_SUMMARY = 'STORE_MARKETPLACE_PROGRESS_SUMMARY';
export const STORE_MARKETPLACE_PROGRESS_SESSION = 'STORE_MARKETPLACE_PROGRESS_SESSION';
export const LIST_ALL_INCOME_STATEMENT = 'LIST_ALL_INCOME_STATEMENT';
export const FETCH_INCOME_STATEMENT = 'FETCH_INCOME_STATEMENT';
export const STORE_INCOME_STATEMENT = 'STORE_INCOME_STATEMENT';
export const SAVE_INCOME_STATEMENT = 'SAVE_INCOME_STATEMENT';
export const LIST_INCOME_STATEMENT_MAPPING_TEMPLATE = 'LIST_INCOME_STATEMENT_MAPPING_TEMPLATE'
export const SAVE_INCOME_STATEMENT_MAPPING_TEMPLATE = 'SAVE_INCOME_STATEMENT_MAPPING_TEMPLATE'
export const STORE_INCOME_STATEMENT_MAPPING_TEMPLATE = 'STORE_INCOME_STATEMENT_MAPPING_TEMPLATE';
export const EXPORT_LEDGERS = 'EXPORT_LEDGERS';
export const STORE_EXPORT_LEDGERS = 'STORE_EXPORT_LEDGERS';
export const CLEAR_EXPORT_LEDGERS = 'CLEAR_EXPORT_LEDGERS';
