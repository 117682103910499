import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
// material components
import {
    Box,
    Checkbox,
    Button,
    IconButton,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormHelperText,
    Tooltip,
    CircularProgress, Link
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import MapIcon from '@material-ui/icons/Map';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// constants
import {
    INTERNATIONAL_TITLE,
    COMMODITY_TITLE,
    ORDER_INFORMATION_TITLE,
    PACKAGE_DETAILS,
    SHIPMENT_FORM_TITLE,
    SHIPMENT_FORM_TO,
    TEST_ACCOUNTS,
    CANCELLED_SHIPMENTS,
    COMMERCIAL_INVOICE,
    WEIGHT_UNIT_OPTIONS,
    QUANTITY_UNIT_OPTIONS,
    CURRENCY_OPTIONS,
    COUNTRY_CODE_OPTIONS,
    DUTIES_PAYMENT_TYPE_OPTIONS,
    REASON_FOR_EXPORT_OPTIONS,
    TERM_OF_SHIPMENT_OPTIONS,
    RETURN_SHIPMENT,
    CLONED_SHIPMENT,
    DELETE_REDIRECT_ORDER_ID,
    ADD_REDIRECT_ORDER_ID,
    EDIT,
    SAVE,
    CANCEL,
    NOTES,
    COMPTACT_LABEL, GOOGLE_MAPS_TITLE, DISPLAY_EVENT_LOG_MESSAGES,
} from "./constants";
import {
    FEDEX_SERVICE_OPTIONS,
    CARRIER_SERVICE_OPTIONS,
    CARRIER_OPTIONS
} from "../automationrules/ConditionFilters/constants";
import {
    COUNTRY_OPTIONS,
    SIGNATURE_OPTIONS,
} from "../automationrules/ConditionActions/constants";

// custom components
import PackageDetails from "./packageDetails/PackageDetails";
import RenderPurchaseOrderPDFPreview from "../purchaseOrders/RenderPurchaseOrderPDFPreview";

// styling
import './ShipmentForm.css';
import PropTypes from "prop-types";
import FilterTagsBar from "./FilterTagsBar";
import {CANCELLED, RETURN, SHIPPED} from '../stages/constants';
import { AssignmentReturn, FileCopy } from "@material-ui/icons";
import PrimeIcon from "../../media/images/Amazon-Prime.png";
import DeleteIcon from "@material-ui/icons/Delete";
import {BUY_SHIPPING} from "../settings/ManageCarriers/constants";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import styles from "../settings/ManageCarriers/CreateCarrierDialog.module.css";
import CompactTableSwitch from "./CompactTableSwitch";
import Switch from "@material-ui/core/Switch";
import withShipment from "../../withShipment";
import {validateAddress, getSampleCommercialInvoicePDF, resetPdfData, getMessage} from "../../redux/actions/orders";
import {
    commercialInvoicePDFSelector, curLogsSelector, isSampleCommercialInvoiceSelector,
    printedCountSelector,
    testZplSelector
} from "../../redux/selectors/orders";
import {rulesSelector} from "../../redux/selectors/automationRules";
import {
    createClonedOpenSelector,
    createOrderOpenSelector,
    createReturnOpenSelector,
    loadingSelector,
    openSelector, stateAndProvinceMappingSelector,
    statusSelector, voidOpenSelector, voidStatusSelector
} from "../../redux/selectors/global";
import {carriersSelector, printersSelector, tagsSelector} from "../../redux/selectors/settings";
import ShipmentFormInternationalDialogue from "./ShipmentFormInternationalDialogue";
import RenderSampleCommercialInvoicePdf from "./RenderSampleCommercialInvoicePDF";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import GetAppIcon from "@material-ui/icons/GetApp";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import CustomToolbar from "../global/CustomToolbar";
import {CREATE_PURCHASE_ORDER, PURCHASE_ORDER} from "../purchaseOrders/constants";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {CURRENCIES} from "./packageDetails/constants";
import {getStateAndProvinceMapping} from "../../redux/actions/global";
const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    m: 1,

    border: 1,
    style: { width: '100%', height: '100%' },
};

function ShipmentForm(props) {
    const [state, setState] = useState({
        preSelected: !props.orderInfo.fromAccount ? false : true,
        showingCommercialInvoice: false,
        addRedirectToOrderIdOpen: false,
        deleteDialogOpen: false,
        editingNotesField: false,
        selectedTags: [],
        // scale: 1.25
    });
    const [initializeValidation, setInitializeValidation] = useState(true)
    const [isAddressValidationOn , setIsAddressValidationOn] = useState()
    const [editingAddressValidationOpen, setEditingAddressValidationOpen] = useState(true)
    const [width_of_field, setWidth] = React.useState();
    const [isValidated, setIsValidated] = useState(props.orderInfo.isValidated)
    const [carrierValidate, setCarrierValidate] = useState(false)
    const [editingAddressValidation, setEditingAddressValidation] = useState(false)
    const [googleMapsLink, setGoogleMapsLink] = useState('')
    const [pdfData, setPdfData] = useState([])
    const [displayEventLog, setDisplayEventLog] = useState(false)
    const containerRef = React.useRef();
    const observer = React.useRef(
        new ResizeObserver(entries => {
            const { width: containerWidth } = entries[0].contentRect;
            setWidth(containerWidth);
        })
    );
    React.useEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current.getBoundingClientRect().width);
        }
    }, []);

    React.useEffect(() => {
        if ( props.orderInfo) {
            props.getMessage(props.orderInfo?.shipmentId)
        }
    }, [ props.orderInfo?.shipmentId]);


    React.useEffect(() => {
        setPdfData(props.commercialInvoicePDF)
    }, [props.commercialInvoicePDF])

    React.useEffect(() => {
        if (containerRef.current) {
            observer.current.observe(containerRef.current);
        }
    }, [observer]);

    React.useEffect(() => {
        let validate
      if(containerRef.current) {
          for (let i = 0; i < props.carriers.length; ++i) {
              if (props.carriers[i].accountNumber == props.orderInfo?.fromAccount?.fromAddressAccountNumber) {
                  validate = props.carriers[i].addressValidation
                  setCarrierValidate(validate)
              }
          }
      }

    },[]);


    // React.useEffect(() => {
    //     setEditingAddressValidation(carrierValidate)
    // },[carrierValidate])
    // React.useEffect(() => {
    //     if (carrierValidate) {
    //         const ids = [{id: props.row.shipmentId}]
    //         const setState = () => {
    //             setEditingAddressValidationOpen(false)
    //             // setEditingAddressValidation(false)
    //         }
    //         props.handleValidateAddress(ids, setState)
    //     }
    // })



    const [num, setnum] = useState("-");

    const [redirectToOrderId, setRedirectToOrderId] = useState('')

    useEffect(() => {
        setState(state => ({ ...state, selectedTags: getTagsSelected() }));
    }, []);

    const filterCarrierService = () => {
        if (props.orderInfo.fromAccount && props.orderInfo.fromAccount.carrier && props.orderInfo.fromAccount.carrier !== "Amazon") {
            return CARRIER_SERVICE_OPTIONS.filter(service => service.startsWith(props.orderInfo.fromAccount.carrier))
        } else {
            return CARRIER_SERVICE_OPTIONS
        }
    }

    const formatDate = (date) => {
        if (date != null) {
            return date.split("T")[0].split('-').join('/')
        }
        return ""
    }


    const getTaxAmount = (orderItems) => {
        let total = 0;
        if (orderItems) {
            orderItems.forEach(item => total += parseFloat(item.itemTaxAmount));
        }
        return total.toFixed(2)
    }

    const getDiscountAmount = (orderItems) => {
        let total = 0
        if (orderItems) {
            orderItems.forEach(item =>
            total += parseFloat(item.promotionDiscountAmount))
        }
        return total.toFixed(2)
    }

    const isPreselect = () => {
        return state?.preSelected && (props?.orderInfo?.fromAccount ? props?.orderInfo?.fromAccount?.fromAddressAlias : false)
    }

    const isCreateShip = () => {


        return props.isCreateShip

    }

    const onTagClicked = (event, tag) => {
        let selectedTags = [...state.selectedTags]
        props.handleAutocompleteChange(tag.tagId, "rtags", false, 0)
        if (!isCreateShip()) {
            props.handleAutocompleteSave(tag.tagId, "rtags", false, 0)
        }
        selectedTags.splice(selectedTags.findIndex(tags => tags.tagName === tag.tagName), 1)
        setState({
            ...state,
            selectedTags
        })
    }

    const tagClickedAgain = (event, currentTags) => {
        let tagsBeforeDelete = state.selectedTags
        let result = tagsBeforeDelete.filter(o1 => !currentTags.some(o2 => o1.tagName === o2.tagName));
        onTagClicked(event, result[0])
    }

    const getTagsSelected = () => {
        let tagNames = props.tags ? props.tags.map(tag => tag.tagName) : []
        let appliedTags = props.row?.tags.map(tag => tag.tagName) ?? []
        if (appliedTags.length === 0) {
            return []
        } else {
            let selectedTags = []
            for (let i = 0; i < appliedTags.length; i++) {

                selectedTags.push(props.tags[tagNames.indexOf(appliedTags[i])])
            }
            return selectedTags
        }
    }

    const getOrderDate = () => {
        if (props.row?.orderDate) {
            return formatDate(props.row.orderDate)
        }
        return props.row?.orders ? props.row.orders.length > 0 ? formatDate(props.row.orders[0].purchaseDate) : '-' : '-'
    }

    const downloadFile = () => {
        let blob = new Blob([Uint8Array.from(props.commercialInvoice)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = props.orderInfo.orderId;
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    };

    const formatShipDate = (date) => {
        if (date != null) {
            let newDate = new Date(date)
            let times = newDate?.toString().split(' ')
            return times.length > 4 ? `${times[1]} ${times[2]}, ${times[3]}` : ""
        }
        return ""
    }

    const cancelDeleteDialog = () => {
        setState({
            ...state,
            deleteDialog: false
        })
        setRedirectToOrderId('')
    }

    const closeDeleteDialog = () => {
        setState({
            ...state,
            deleteDialog: false
        })
        setRedirectToOrderId('')
        props.deleteRedirectToOrderId(props?.shipmentId)
    }

    const openDeleteDialog = () => {
        setState({
            ...state,
            deleteDialog: true
        })
    }

    const openAddRedirectToOrderId = () => {
        setState({
            ...state,
            addRedirectToOrderIdOpen: true
        })
    }

    const handleRedirectToOrderIdChange = (e) => {
        setRedirectToOrderId(e.target.value)
    }

    const cancelRedirectToOrderId = () => {
        setState({
            ...state,
            addRedirectToOrderIdOpen: false
        })
        setRedirectToOrderId('')
    }

    const saveRedirectToOrderId = () => {
        setState({
            ...state,
            addRedirectToOrderIdOpen: false
        })
        //TODO change to feedback bar
        const logMessage = (message) => {
        }
        props.saveRedirectToOrderId(props?.shipmentId, redirectToOrderId, logMessage)
    }

    const handleNotesCancel = () => {
        setState({
            ...state,
            editingNotesField: false
        })
        props.handleChange(state.notesFieldBeforeEdit, NOTES, false, -1)
    }

    const handleNotesSave = () => {
        setState({
            ...state,
            editingNotesField: false
        })
        if (props.orderInfo.notes !== state.notesFieldBeforeEdit && !isCreateShip()) {
            props.handleSave({ target: { value: props.orderInfo.notes, name: NOTES } }, false, -1, true)
        }
    }

    const handleNotesEdit = () => {
        setState({
            ...state,
            editingNotesField: true,
            notesFieldBeforeEdit: props.orderInfo.notes
        })
    }

    const [newShipmentAddress, setNewShipmentAddress] = useState({})

    const handleNewShipmentAddress = (value, name) => {
        props.handleChange(value, name, false, 0)
        setNewShipmentAddress({name, value})
    }

    const handleValidate = () => {
            const ids =[props.row.shipmentId]
            const setState = () => {
                setEditingAddressValidationOpen(false)
                // setEditingAddressValidation(false)
            }
            props.handleValidateAddress(ids, setState)
    }

    const getDefaultCurrencyCode = () => {
        if (props.orderInfo.shippingAddressCountry === "Canada")
            return CURRENCIES[1]
        return CURRENCIES[0]
    }

    // debugger;
    const handleToggle =  async (event) => {
        setEditingAddressValidation(event.target.checked)
        setInitializeValidation(false)

        if (event.target.checked) {
            const ids =[props.row.shipmentId]
            const setState = () => {
                setEditingAddressValidationOpen(false)
            }
                // setEditingAddressValidation(false)
            await props.updateShipments({fromDate: props.fromDate, toDate: props.toDate, shipmentIds: ids, setState: setState})
            // props.getShipments({fromDate: this.props.fromDate, toDate: this.props.toDate})
        }
    }

    //building link out
    const mapSearch = () => {
        let base = 'https://google.com/maps/place/'
        let address = props.orderInfo.shippingAddressAddressLine1 ? props.orderInfo.shippingAddressAddressLine1.trim().replace(' ', '+') + ',+' : ""
        let city = props.orderInfo.shippingAddressCity ? props.orderInfo.shippingAddressCity.trim() + ',+' : ""
        let stateOrRegion = props.orderInfo.shippingAddressStateOrRegion ? props.orderInfo.shippingAddressStateOrRegion.trim() + ',+' : ""
        let postalCode = props.orderInfo.shippingAddressPostalCode ? props.orderInfo.shippingAddressPostalCode.trim().replace(' ', '+') : ""
        setGoogleMapsLink(base+address+city+stateOrRegion+postalCode)
    }

    const pdfPreview = async () => {
        let currDate = new Date()
        let month = currDate.toLocaleString('default', { month: 'long' })
        let day = currDate.getUTCDate()
        let year = currDate.getUTCFullYear()

             let dateString = month + " " + day + ", " + year
        props.getSampleCommercialInvoicePDF({...props.orderInfo, shipDate: dateString}, 0);
        setPdfData(props.commercialInvoicePDF)
    }

    const handleDisplayEventLogMessage = () => {
        setDisplayEventLog(!displayEventLog)
    }

    const displayEventLogMessage = () => {
        const lines = props.eventLog?.message?.split('\n')

        return (
           <div>
               {lines?.map((line, index) => {
                   if (line.includes('changed to')) {
                       const colonIndex = line.indexOf(":")
                       const prefix = line.substring(0, colonIndex)
                       const message = line.substring(colonIndex + 1)
                       const [originalText, newText] = message?.split('was changed to')
                       const origWords = originalText.split(/\s+/)
                       const newWords = newText.split(/\s+/)
                       const highlightOldIndexes = []
                       const highlightNewIndexes = []

                       for (let i = 0; i < origWords.length; i++) {
                           const lowercaseOrigWord = origWords[i].toLowerCase().trim()
                           if (!newWords.some(newWord => newWord.toLowerCase().trim() === lowercaseOrigWord)) {
                               highlightOldIndexes.push(i)
                           }
                       }

                       for (let i = 0; i < newWords.length; i++) {
                           const lowercaseNewWord = newWords[i].toLowerCase()
                           if (!origWords.some(origWord => origWord.toLowerCase().trim() === lowercaseNewWord)) {
                               highlightNewIndexes.push(i)
                           }
                       }

                       return (
                           <div key={index}>
                               <span>{prefix}:</span>
                               {origWords.map((word, i) => {
                                   return (
                                       <>
                                            <span key={i} className={highlightOldIndexes.includes(i) ? "highlighted-text" : ""}>
                                                 {word.trim()}
                                            </span>
                                            <span> </span>
                                       </>

                                   )
                               })}
                               {' was changed to '}
                               <span>
                            {newWords.map((word, i) => {
                                return (
                                    <>
                                        <span key={i} className={highlightNewIndexes.includes(i) ? "highlighted-text" : ""}>
                                             {word.trim()}
                                        </span>
                                        <span> </span>
                                    </>

                                )
                            })}
                        </span>
                               <br />
                           </div>
                       )
                   } else {
                       return (
                           <div key={index}>
                               {line}
                               <br />
                           </div>
                       )
                   }
               })}
           </div>
        )
    }

    const toTwoDecimals = (inputValue) => {
        const valueString = inputValue.toString()
        let value
        try {
            value = parseFloat(valueString).toFixed(2)
        } catch (ignored) {
            return valueString
        }
        return value
    }

    return (
        <>
            {!props.showCommercialInvoice ?
                <form className="shipment-form">
                    <div className="row-col" style={{width: "780px"}}>
                        {/* Tag */}
                        <table>
                            <tr>
                                <td>
                                    <div className="shipment-tag-box">
                                        <Autocomplete
                                            multiple
                                            limitTags={0}
                                            options={props.tags}
                                            getOptionLabel={(option) => option.tagName}
                                            autoHighlight={true}
                                            className={'input-spec'}
                                            value={state.selectedTags}
                                            renderOption={(option, {selected}) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                                        checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                                        style={{marginRight: 8}}
                                                        checked={selected}
                                                    />
                                                    {option.tagName}
                                                </React.Fragment>
                                            )}
                                            name="addTag"
                                            // value={!props.orderInfo ? '': props.orderInfo.signatureConfirmation}
                                            onChange={(event, value) => {
                                                if (value == null || value == undefined) {
                                                    props.handleAutocompleteChange('', "tags", false, 0)
                                                    if (!isCreateShip()) {
                                                        props.handleAutocompleteSave('', "tags", false, 0)
                                                    }
                                                } else {
                                                    if (value.length < state.selectedTags.length) {
                                                        tagClickedAgain(event, value)
                                                    } else {
                                                        props.handleAutocompleteChange(value[value.length - 1].tagId, "tags", false, 0)
                                                        if (!isCreateShip()) {
                                                            props.handleAutocompleteSave(value[value.length - 1].tagId, "tags", false, 0)
                                                        }
                                                        setState({
                                                            ...state,
                                                            disabled: true,
                                                            selectedTags: value
                                                        })
                                                    }
                                                }
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Tag"
                                                    InputLabelProps={{shrink: true}}
                                                    variant="outlined"
                                                    name="addTag"
                                                />
                                            }
                                        />
                                        {/*<Button onClick={props.handleTrackShipment} >*/}
                                        {/*    <FileCopy />*/}
                                        {/*</Button>*/}

                                <div>
                                    {(props.orderInfo?.isReturnShipment || props.orderInfo?.redirectRecipient) ?
                                        <Tooltip title={RETURN_SHIPMENT}>
                                            <IconButton aria-label={RETURN_SHIPMENT}>
                                                <AssignmentReturn/>
                                            </IconButton>
                                        </Tooltip> : null
                                    }
                                    {props.orderInfo?.isCloneShipment ?
                                        <Tooltip title={CLONED_SHIPMENT}>
                                            <IconButton aria-label={CLONED_SHIPMENT}>
                                                <FileCopy/>
                                            </IconButton>
                                        </Tooltip> : null
                                    }
                                </div>
                                <div className="shipment-tagbar">
                                    <FilterTagsBar
                                        tags={props.orderInfo.tags}
                                        onClick={onTagClicked}
                                        isShipmentTags={true}/>
                                </div>
                            </div>
                        </td>
                        <td style={{"width": "100%"}}>
                            <Typography className="titleTypography" variant="h6" id="tableTitle">
                                {props.shipmentStatus === SHIPPED ?
                                    <p style={{
                                        textAlign: "right",
                                        paddingRight: 30,
                                        marginBottom: 0
                                    }}>{"Shipped: " + formatShipDate(props.row.shipDate)}</p>
                                    : null}
                                {!isCreateShip() ?
                                    <p style={{
                                        textAlign: "right",
                                        paddingRight: 30,
                                        marginBottom: 0
                                    }}>{(props.row?.trackingNumber ? props.row.trackingNumber : props.row?.rate ? "Rate - " : "") + (props.row?.trackingNumber && props.row?.rate ? " - " : "") + (props.row?.rate ? "$" + parseFloat(props.row?.rate).toFixed(2) + " " : "") + (props.row?.rateCurrency ? props.row?.rateCurrency : "")}</p>
                                    : null}
                            </Typography>
                        </td>
                    </tr>
                </table>
                {/* From */}
                <Box borderRadius="borderRadius" {...defaultProps} style={{marginRight: '20px'}}>
                    <div className="input-form">
                        <Typography variant="h6" gutterBottom component="div" className="form-header">
                            {SHIPMENT_FORM_TITLE}
                            <hr/>
                        </Typography>
                        <div className="row-spec">
                            <Autocomplete
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                options={props.locations.filter(location => location.enabled).map(location => {
                                    return location.name
                                })}
                                getOptionLabel={(option) => option}
                                className="input-spec"
                                name="fromAddressName"
                                value={props.orderInfo?.fromAccount?.fromAddressName}
                                onChange={(event, value) => {

                                            if (value == null || value == undefined) {
                                                if (isCreateShip()) {
                                                    props.handleAccountSelect({}, isCreateShip(), true);
                                                } else {
                                                    props.handleAccountSelect({}, isCreateShip(), true);
                                                }
                                            } else {
                                                if (isCreateShip()) {
                                                    props.handleAutocompleteChange(value, "fromAddressName", false, 0)
                                                } else {
                                                    props.handleAutocompleteSave(value, "fromAddressName", false, 0)
                                                }
                                            }
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Location"
                                                InputLabelProps={{shrink: true}}
                                                variant="outlined"
                                                name="fromAddressName"
                                            />

                                }
                            />
                            {/*for tracking shipment purposes*/}
                            {/*<Button onClick={props.handleTrackShipment} >*/}
                            {/*    <FileCopy />*/}
                            {/*</Button>*/}
                        </div>
                        <div className="row-spec">
                            <Autocomplete
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                options={props.orderInfo.accounts.filter(account => {
                                    if (account.isBuyShipping && (!props.orderInfo.orders || props.orderInfo.orders[0].salesChannel != "Amazon.com"))
                                        return false

                                    return !props.orderInfo.fromAccount ||
                                        !props.orderInfo.fromAccount.fromAddressName ||
                                        props.orderInfo.fromAccount.fromAddressName == '' ||
                                        account.fromAddressName == props.orderInfo.fromAccount.fromAddressName
                                })}
                                getOptionLabel={(option) => {return option.marketplace + option.fromAddressAccountNumber}}
                                className="input-spec"
                                value={!props.orderInfo.fromAccount ? {} : props.orderInfo.accounts.find((account) => {
                                    if (props.orderInfo.isBuyShipping) {
                                        return account.fromAddressAccountNumber === props.orderInfo.fromAccount.fromAddressAccountNumber &&
                                            account.carrier === props.orderInfo.fromAccount.carrier &&
                                            account.fromAddressAlias === props.orderInfo.fromAccount.fromAddressName &&
                                            account.marketplace === props.orderInfo.fromAccount?.marketplace
                                    } else {
                                        return account.fromAddressAccountNumber === props.orderInfo.fromAccount.fromAddressAccountNumber &&
                                            account.carrier === props.orderInfo.fromAccount.carrier &&
                                            account.fromAddressAlias === props.orderInfo.fromAccount.fromAddressName
                                    }
                                    // return account.fromAddressAccountNumber === props.orderInfo.fromAccount.fromAddressAccountNumber
                                })}
                                onChange={(event, value) => {
                                    setInitializeValidation(true)
                                    if (value == null || value == undefined) {
                                        props.handleAccountSelect({}, isCreateShip(), false);
                                    } else {
                                        props.handleAccountSelect(value, isCreateShip(), false, true);
                                    }
                                    if (value?.fromAddressAccountNumber && value?.carrier) {
                                        for (let i = 0; i < props.carriers.length; ++i) {
                                            if (props.carriers[i].accountNumber == value.fromAddressAccountNumber && props.carriers[i].name == value.carrier) {
                                                if (value.isBuyShipping) {
                                                    if (props.carriers[i]?.marketplace === value?.marketplace) {
                                                        setCarrierValidate(props.carriers[i].addressValidation)
                                                    }
                                                } else {
                                                    setCarrierValidate(props.carriers[i].addressValidation)
                                                }
                                            }
                                        }
                                    }
                                }}
                                name="Account Number"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={!props.orderInfo.fromAccount.fromAddressAccountNumber && props.shipmentStatus !== SHIPPED && props.shipmentStatus !== CANCELLED}
                                        label="Account Number"
                                        required
                                        InputLabelProps={{shrink: true}}
                                        variant="outlined"
                                        name="AccountNumber"
                                    />
                                }
                            />
                            <Autocomplete
                                disabled={!props.orderInfo.fromAccount ? {} : props.orderInfo.fromAccount.isBuyShipping ?
                                    props.orderInfo.fromAccount.isBuyShipping : props.orderInfo.fromAccount.carrier}
                                options={CARRIER_OPTIONS}
                                getOptionLabel={(option) => option}
                                className="input-spec"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.carrier ?? ""}
                                onChange={(event, value) => {
                                    props.handleCarrierChange(value, isCreateShip())
                                }}
                                name="Carrier"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={!props.orderInfo.fromAccount.fromAddressAccountNumber && props.shipmentStatus !== SHIPPED && props.shipmentStatus !== CANCELLED}
                                        label="Carrier"
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="carrier"
                                    />
                                }
                            />
                            {/*<TextField*/}
                            {/*    disabled*/}
                            {/*    required*/}
                            {/*    name="carrier"*/}
                            {/*    value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.carrier}*/}
                            {/*    variant="outlined"*/}
                            {/*    label="Carrier"*/}
                            {/*    InputLabelProps={{shrink: true}}*/}
                            {/*    className="input-spec"*/}
                            {/*    onFocus={props.handleFocus}*/}
                            {/*    onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}*/}
                            {/*    onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}*/}
                            {/*/>*/}
                            <Autocomplete
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                options={filterCarrierService()}
                                getOptionLabel={(option) => option}
                                className="input-spec"
                                name="service"
                                value={!props.orderInfo?.service ? '' : props.orderInfo.service}
                                onChange={(event, value) => {
                                    if (value == null || value == undefined) {
                                        if (isCreateShip()) {
                                            props.handleAutocompleteChange('', "service", false, 0)
                                        } else {
                                            props.handleAutocompleteSave('', "carrierService", false, 0)
                                        }
                                    } else {
                                        if (isCreateShip()) {
                                            props.handleAutocompleteChange(value, "service", false, 0)
                                        } else {
                                            props.handleAutocompleteSave(value, "carrierService", false, 0)
                                        }
                                    }
                                }}
                                onFocus={props.handleFocus}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={!props.orderInfo?.service && props.shipmentStatus !== SHIPPED && props.shipmentStatus !== CANCELLED}
                                        label="Service"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="service"
                                        required
                                    />
                                }
                            />
                        </div>


                        <div className="row-spec">
                            <TextField
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="fromAddressContactName"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.fromAddressContactName}
                                error={!props.orderInfo.fromAccount?.fromAddressContactName && props.shipmentStatus !== SHIPPED && props.shipmentStatus !== CANCELLED}
                                variant="outlined"
                                label="Contact Name"
                                InputLabelProps={{ shrink: true }}
                                className="input-spec"
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                            <TextField
                                required={!props.orderInfo.isReturnShipment}
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="fromAddressCompanyName"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.fromAddressCompanyName}
                                error={!props.orderInfo.fromAccount?.fromAddressCompanyName && !props.orderInfo.isReturnShipment}
                                variant="outlined"
                                label="Company Name"
                                InputLabelProps={{ shrink: true }}
                                className="input-spec"
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                            <TextField
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="fromAddressPhone"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.fromAddressPhone}
                                error={!props.orderInfo.fromAccount?.fromAddressPhone}
                                variant="outlined"
                                label="Phone"
                                InputLabelProps={{ shrink: true }}
                                className="input-spec"
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />

                        </div>
                        <div className="row-col">
                            <TextField
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="fromAddressAddressLine1"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.fromAddressAddressLine1}
                                error={!props.orderInfo.fromAccount?.fromAddressAddressLine1}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                label="Address Line 1"
                                className="input-spec-single"
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="fromAddressAddressLine2"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.fromAddressAddressLine2}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                label="Address Line 2"
                                className="input-spec-single"
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />

                        </div>
                        <div className="row-spec">
                            <TextField
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="fromAddressCity"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.fromAddressCity}
                                error={!props.orderInfo.fromAccount?.fromAddressCity}
                                variant="outlined"
                                label="City"
                                InputLabelProps={{ shrink: true }}
                                className="input-spec"
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                            <Autocomplete
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                onFocus={props.handleFocus}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                options={props.orderInfo.fromAccount?.fromAddressCountry ?
                                    props.stateAndProvinceMapping.filter(state => state.country.toLowerCase() === props.orderInfo.fromAccount?.fromAddressCountry.toLowerCase()).map(state => state.state) :
                                    props.stateAndProvinceMapping.map(state => state.state)
                                }
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                className='input-spec'
                                value={!props.orderInfo.fromAccount ? '' : props.stateAndProvinceMapping.map(state => state.state).includes(props.orderInfo.fromAccount.fromAddressState) ? props.orderInfo.fromAccount.fromAddressState : "" }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        {option + " (" + props.stateAndProvinceMapping.find(state => state.state === option)?.displayName + ")"}
                                    </React.Fragment>
                                )}
                                name="fromAddressState"
                                onChange={(e, value) => props.handleFromAddressChange(value, "fromAddressState")}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="State or Province"
                                        required
                                        error={!(!props.orderInfo.fromAccount ? '' : props.stateAndProvinceMapping.map(state => state.state).includes(props.orderInfo.fromAccount.fromAddressState) ? props.orderInfo.fromAccount.fromAddressState : "")}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="fromAddressState"
                                    />
                                }
                            />
                            <TextField
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="fromAddressPostalCode"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.fromAddressPostalCode}
                                error={!props.orderInfo.fromAccount?.fromAddressPostalCode}
                                variant="outlined"
                                label="Zip or PostalCode"
                                InputLabelProps={{ shrink: true }}
                                className="input-spec"
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                        </div>
                        <div className="row-spec">
                            <Autocomplete
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                options={COUNTRY_OPTIONS.map(n => { return n.name })}
                                getOptionLabel={(option) => option}
                                className="input-spec"
                                name="fromAddressCountry"
                                value={!props.orderInfo.fromAccount?.fromAddressCountry ? '' : props.orderInfo.fromAccount.fromAddressCountry}
                                onChange={(event, value) => {
                                    if (value == null || value == undefined) {
                                        if (isCreateShip()) {
                                            props.handleFromAddressChange('', "fromAddressCountry")
                                        } else {
                                            props.handleAutocompleteSave('', "fromAddressCountry", false, 0)
                                        }
                                    } else {
                                        if (isCreateShip()) {
                                            props.handleFromAddressChange(value, "fromAddressCountry")
                                        } else {
                                            props.handleAutocompleteSave(value, "fromAddressCountry", false, 0)
                                        }
                                    }
                                }}
                                onFocus={props.handleFocus}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Country"
                                        error={!props.orderInfo.fromAccount?.fromAddressCountry}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="fromAddressCountry"
                                        required
                                    />
                                }
                            />
                            <TextField
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="fromAddressEmail"
                                value={!props.orderInfo.fromAccount ? '' : props.orderInfo.fromAccount.fromAddressEmail}
                                error={!props.orderInfo.fromAccount?.fromAddressEmail}
                                variant="outlined"
                                label="Email"
                                InputLabelProps={{ shrink: true }}
                                className="input-spec-two-col"
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleFromAddressChange(e.target.value, e.target.name)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }} />
                    </div>
                </Box>
                {/* To */}
                <Box borderRadius="borderRadius" {...defaultProps} style={{ marginRight: '20px' }}>
                    <div className="input-form">
                        <Typography variant="h6" gutterBottom component="div" className="form-header">
                            <div style={{clear: 'both'}}>
                            {SHIPMENT_FORM_TO} &nbsp;
                                <FormControlLabel control={
                                    <Switch
                                        disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                        checked={editingAddressValidation}
                                        // checked={carrierValidate==true && editingAddressValidation == true ? true : (carrierValidate==false && editingAddressValidation==false ? false : (carrierValidate==true && editingAddressValidation==false && initializeValidation==true ? true : (carrierValidate==false && editingAddressValidation==true && initializeValidation==true ? false : (carrierValidate==true && editingAddressValidation==false && initializeValidation==false ? false : (carrierValidate==false && editingAddressValidation==true && initializeValidation==false ? true : false)))) )}
                                            onChange={(e) => {handleToggle(e)}}
                                />} label={'Address Validation'}
                                   style={{marginTop: '4px'}} />
                                {/*event log messages*/}
                                {props.eventLog  ?
                                    props.eventLog.classification === 'critical' ?
                                        <Tooltip title={DISPLAY_EVENT_LOG_MESSAGES} style={{ width: 21, height: 21, float: 'top' }}>
                                            <IconButton
                                                aria-label='previous'
                                                onClick={() => handleDisplayEventLogMessage()}
                                            >
                                                <ErrorOutlineIcon style={{fill: 'red'}} variant='outlined' fontSize='large' />
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title={DISPLAY_EVENT_LOG_MESSAGES} style={{ width: 21, height: 21, float: 'top' }}>
                                            <IconButton
                                                aria-label='previous'
                                                onClick={() => handleDisplayEventLogMessage()}
                                            >
                                                <HelpOutlineIcon  variant='outlined' fontSize='large' />
                                            </IconButton>
                                        </Tooltip>
                                    : ''}
                                {/*google maps link*/}
                                <Tooltip title={GOOGLE_MAPS_TITLE} style={{ width: 21, height: 21, float: 'right', marginTop: '10px' }}>
                                    <IconButton
                                        aria-label='previous'
                                        onClick={() => {mapSearch()}}
                                    >
                                    <a href={googleMapsLink} target='_blank' >
                                        <MapIcon style={{color: '#808080'}} variant='outlined' fontSize='large' />
                                    </a>
                                    </IconButton>
                                </Tooltip>
                                <br />
                                <div style={{fontSize: 'medium'}}>
                                    {props.eventLog != null && displayEventLog ?
                                        displayEventLogMessage() : ''
                                    }
                                </div>
                            </div>
                            <hr />
                        </Typography>
                        {/*<CompactTableSwitch checked={this.state.dense}*/}
                        {/*                    onChange={(e) => {*/}
                        {/*                        this.handleChangeDense(e);*/}
                        {/*                    }}*/}
                        {/*                    label={COMPTACT_LABEL}*/}
                        {/*                    name="dense"/>*/}
                        <div className="row-spec">
                            <TextField
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                error={!props.orderInfo.shippingAddressName}
                                className="input-spec"
                                variant="outlined"
                                name="shippingAddressName"
                                label="Name"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.shippingAddressName}
                                onFocus={props.handleFocus}
                                onChange={(e) => {
                                    props.handleChange(e.target.value, e.target.name, false, 0)
                                }}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                className="input-spec-two-col"
                                variant="outlined"
                                name="shippingAddressCompanyName"
                                label="Company Name"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.shippingAddressCompanyName ? props.orderInfo.shippingAddressCompanyName : ""}
                                onFocus={props.handleFocus}
                                onChange={(e) => {
                                    props.handleChange(e.target.value, e.target.name, false, 0)
                                }}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                        </div>
                        <div className="row-spec">
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                required
                                error={!props.orderInfo.shippingAddressPhone||props.shippingPhoneNumberFormatError}
                                className="input-spec"
                                variant="outlined"
                                name="shippingAddressPhone"
                                label="Phone Number"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.shippingAddressPhone}
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                className="input-spec"
                                variant="outlined"
                                name="shippingAddressPhoneExtension"
                                label="Phone Extension"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.shippingAddressPhoneExtension}
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                        </div>
                        <div className="row-col">
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                required
                                error={!props.orderInfo.shippingAddressAddressLine1}
                                className="input-spec-single"
                                variant="outlined"
                                name="shippingAddressAddressLine1"
                                label="Address Line 1"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.shippingAddressAddressLine1}
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                // onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0, "", editingAddressValidation)}
                            />
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                className="input-spec-single"
                                variant="outlined"
                                name="shippingAddressAddressLine2"
                                label="Address Line 2"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.shippingAddressAddressLine2}
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                onBlur={ (e) => isCreateShip() ? null : props.handleSave(e, false, 0, "", editingAddressValidation)}
                            />
                        </div>
                        <div className="row-spec">
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                required
                                error={!props.orderInfo.shippingAddressCity}
                                className="input-spec"
                                variant="outlined"
                                name="shippingAddressCity"
                                label="City"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.shippingAddressCity}
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                onBlur={(e) =>isCreateShip() ? null : props.handleSave(e, false, 0,"", editingAddressValidation)}
                            />
                            <Autocomplete
                                required
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                onFocus={props.handleFocus}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                options={props.orderInfo && COUNTRY_OPTIONS.find((country) => {return country.code === props.orderInfo.shippingAddressCountry}) ?
                                    props.stateAndProvinceMapping.filter(state => state.country.toLowerCase() === COUNTRY_OPTIONS.find((country) => {return country.code === props.orderInfo.shippingAddressCountry}).code.toLowerCase()).map(state => state.state) :
                                    props.stateAndProvinceMapping.map(state => state.state)
                                }
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                className='input-spec'
                                value={props.stateAndProvinceMapping.map(state => state.state).includes(props.orderInfo.shippingAddressStateOrRegion) ? props.orderInfo.shippingAddressStateOrRegion : "" }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        {option + " (" + props.stateAndProvinceMapping.find(state => state.state === option)?.displayName + ")"}
                                    </React.Fragment>
                                )}
                                name="shippingAddressStateOrRegion"
                                onChange={(e, value) => props.handleChange(value, "shippingAddressStateOrRegion", false, 0)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0, "", editingAddressValidation)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="State or Province"
                                        required
                                        error={!(props.stateAndProvinceMapping.map(state => state.state).includes(props.orderInfo.shippingAddressStateOrRegion) ? props.orderInfo.shippingAddressStateOrRegion : "" )}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="shippingAddressStateOrRegion"
                                    />
                                }
                            />
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                required
                                error={!props.orderInfo.shippingAddressPostalCode}
                                className="input-spec"
                                variant="outlined"
                                name="shippingAddressPostalCode"
                                label="Zip or Postal Code"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.shippingAddressPostalCode}
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0, "", editingAddressValidation)}
                            />
                        </div>
                        <div className="row-spec">
                            <Autocomplete
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                options={COUNTRY_OPTIONS}
                                getOptionLabel={(option) => option.name}
                                className="input-spec"
                                name="shippingAddressCountry"
                                value={!props.orderInfo ? '' : COUNTRY_OPTIONS.find((country) => {
                                    return country.code === props.orderInfo.shippingAddressCountry
                                })}
                                onChange={(event, value) => {
                                    if (value == null || value == undefined) {
                                        props.handleAutocompleteChange(null, "shippingAddressCountry", false, 0)
                                        if (!isCreateShip()) {
                                            props.handleAutocompleteSave(null, "shippingAddressCountry", false, 0)
                                        }
                                    } else {
                                        props.handleAutocompleteChange(value.code, "shippingAddressCountry", false, 0)
                                        if (!isCreateShip()) {
                                            props.handleAutocompleteSave(value.code, "shippingAddressCountry", false, 0)
                                        }
                                    }
                                }}
                                onFocus={props.handleFocus}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Country"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="shippingAddressCountry"
                                        required
                                        error={!props.orderInfo.shippingAddressCountry}
                                    />
                                }
                            />
                            <TextField
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                className="input-spec-two-col"
                                variant="outlined"
                                name="email"
                                label="Buyer Email"
                                InputLabelProps={{ shrink: true }}
                                value={props.orderInfo.email}
                                onFocus={props.handleFocus}
                                onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                            />
                        </div>
                        <div className="row-spec">
                            <Autocomplete
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                options={SIGNATURE_OPTIONS}
                                getOptionLabel={(option) => option}
                                className="input-spec"
                                name="signatureConfirmation"
                                value={!props.orderInfo ? '' : props.orderInfo.signatureConfirmation}
                                onChange={(event, value) => {
                                    if (value == null || value == undefined) {
                                        props.handleAutocompleteChange('', "signatureConfirmation", false, 0)
                                        if (!isCreateShip()) {
                                            props.handleAutocompleteSave('', "signatureConfirmation", false, 0)
                                        }
                                    } else {
                                        props.handleAutocompleteChange(value, "signatureConfirmation", false, 0)
                                        if (!isCreateShip()) {
                                            props.handleAutocompleteSave(value, "signatureConfirmation", false, 0)
                                        }
                                    }
                                }}
                                onFocus={props.handleFocus}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Signature Confirmation"
                                        error={!props.orderInfo?.signatureConfirmation}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="signatureConfirmation"
                                        required
                                    />
                                }
                            />
                            <FormControlLabel
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                name="isResidential"
                                className="input-spec"
                                control={<Checkbox
                                    checked={props.orderInfo.isResidential ? props.orderInfo.isResidential : false}
                                    onChange={() => props.handleCheck("isResidential")}
                                />}
                                label="Residential"
                            />
                            <FormControlLabel
                                disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                className={styles.formTwoInput}
                                control={
                                    <Checkbox
                                        checked={props.orderInfo?.isValidated ? props.orderInfo.isValidated : false}
                                        onChange={() => props.handleCheck("isValidated")}
                                    />
                                }
                                label="Address Validated"
                            />
                        </div>
                        <div style={{ marginBottom: 10 }} />
                    </div>
                </Box>
            </div>
            <div className="row-col" style={{width: 700}}>
                {/* Packages */}
                <Box borderRadius="borderRadius" {...defaultProps}
                     style={{marginRight: '20px', height: 'auto'}}>
                    <PackageDetails
                        toggleCommercialInvoiceView={props.toggleCommercialInvoiceView}
                        getSampleCommercialInvoicePdf={pdfPreview}
                        shipmentId={props?.shipmentId}
                        shipmentStatus={props.shipmentStatus}
                        filterCarrierService={filterCarrierService}
                        carrierServices={CARRIER_SERVICE_OPTIONS}
                        required={false}
                        errorChecking={false}
                        packages={props?.orderInfo?.packages}
                        totalItems={props.orderInfo?.total}
                        handleChange={props.handleChange}
                        handleFocus={props.handleFocus}
                        handleSave={props.handleSave}
                        addPackage={props.addPackage}
                        copyPackage={props.copyPackage}
                        deletePackage={props.deletePackage}
                        editOrder={props.editOrder}
                        loading={props.loading}
                        isCreateShip={props.isCreateShip}
                        handleCreateShipPackageAdd={props.handleCreateShipPackageAdd}
                        handleCreateShipDeletePackage={props.handleCreateShipDeletePackage}
                        handleCreateShipPackageCopy={props.handleCreateShipPackageCopy}
                        handleAutocompleteChange={props.handleAutocompleteChange}
                        reprintPackage={props.reprintPackage}
                        boxes={props.boxes}
                        handleBoxChange={props.handleBoxChange}
                        scanInfos = {props.row?.scanInfos}
                        // for commodities
                        isShipmentReady={props.isShipmentReady}
                        commericialInvoice={props.commercialInvoice}
                        getShipmentCommercialInvoice={props.getShipmentCommercialInvoice}
                        handleAutocompleteSave={props.handleAutocompleteSave}
                        orderInfo={props.orderInfo}
                        handleCheck={props.handleCheck}
                        commodityEditing={props.commodityEditing}
                        romoveCommodity={props.romoveCommodity}
                        addCommodity={props.addCommodity}
                    />

                    {/* International */}
                    {/*    {props.orderInfo.fromAccount.fromAddressCountry && props.orderInfo.shippingAddressCountry && props.orderInfo.fromAccount.fromAddressCountry != props.orderInfo.shippingAddressCountry ?*/}
                    {/*        <ShipmentFormInternationalDialogue*/}
                    {/*            commericialInvoice={props.commercialInvoice}*/}
                    {/*            getShipmentCommercialInvoice={props.getShipmentCommercialInvoice}*/}
                    {/*            shipmentId={props?.shipmentId}*/}
                    {/*            handleAutocompleteSave={props.handleAutocompleteSave}*/}
                    {/*            handleAutocompleteChange={props.handleAutocompleteChange}*/}
                    {/*            orderInfo={props.orderInfo}*/}
                    {/*            handleCheck={props.handleCheck}*/}
                    {/*            commodityEditing={props.commodityEditing}*/}
                    {/*            romoveCommodity={props.romoveCommodity}*/}
                    {/*            handleFocus={props.handleFocus}*/}
                    {/*            isCreateShip={props.isCreateShip}*/}
                    {/*            handleChange={props.handleChange}*/}
                    {/*            handleSave={props.handleSave}*/}
                    {/*            addCommodity={props.addCommodity}*/}
                    {/*            shipmentStatus={props.shipmentStatus}/>*/}
                    {/*        : null}*/}
                </Box>
                {/* Order Information */}
                {!isCreateShip() && props.orderInfo?.orders ?
                    <Box borderRadius="borderRadius" {...defaultProps} style={{ marginRight: '20px' }}>
                        <div className="input-form-order">
                            <div>
                                <Typography variant="h6" gutterBottom component="div" className="form-header">
                                    {ORDER_INFORMATION_TITLE}
                                    <span style={{ float: 'right', textAlign: 'right', fontSize: '12px' }}>
                                        Ship by: <b>{props?.orderInfo?.modifiedEarliestShipDate} - {props?.orderInfo?.modifiedLatestShipDate}</b> <br />
                                        {props.title === CANCELLED_SHIPMENTS ? '' : 'Deliver by: '}
                                        <b>{props.title === CANCELLED_SHIPMENTS ? '' : (props?.orderInfo?.modifiedEarliestDeliveryDate ?? '') + ' - ' + (props?.orderInfo?.modifiedLatestDeliveryDate ?? '')}</b>
                                    </span>
                                        </Typography>
                                    </div>
                                    <hr style={{marginTop: '0', paddingTop: '0', marginBottom: '10px'}}/>

                            <table className="product-table">
                                <tr>
                                    <td className="column-1" width="50%"/>
                                    <td className="column-2" width="20%"/>
                                    <td className="column-3" width="10%"/>
                                    <td className="column-4" width="20%">{props.row?.isPrime ?
                                        <img src={PrimeIcon} style={{
                                            marginRight: 5,
                                            height: 17,
                                            width: 60,
                                        }}/> : null}
                                        {props.row?.shipmentServiceLevel}
                                    </td>
                                </tr>
                                <tr className="order-table-row">
                                    <td className="column-1">
                                        Order ID: <b>{props.orderInfo?.orderId}</b>
                                        <br/>
                                        Order date: <b>{getOrderDate()}</b></td>
                                    <td className="column-2"/>
                                    <td className="column-3" width="30%">
                                        Total:
                                        <br/>
                                        Tax:
                                        {(getDiscountAmount(props.orderInfo.orders[0].orderItems) !== "NaN" && getDiscountAmount(props.orderInfo.orders[0].orderItems) !== "0.00") && <><br/>Discount:</>}
                                        {props.orderInfo.orders[0].totalShippingFeeAmount &&
                                            <>
                                                <br/>
                                                Shipping:
                                            </>
                                        }
                                        {props.orderInfo.orders[0].totalShippingFeeTaxAmount &&
                                            <>
                                                <br/>
                                                Shipping Tax:
                                            </>
                                        }
                                    </td>
                                    <td className="column-4">
                                        <b>${props?.orderInfo?.orders[0]?.orderTotalAmount ? toTwoDecimals(props.orderInfo.orders[0].orderTotalAmount) : "0.00"} {props?.orderInfo?.orders[0]?.orderTotalCurrencyCode ? props.orderInfo.orders[0].orderTotalCurrencyCode : getDefaultCurrencyCode()}</b>
                                        <br/>
                                        ${getTaxAmount(props.orderInfo?.packages[0]?.orderItems) !== "NaN" ? toTwoDecimals((getTaxAmount(props.orderInfo.packages[0].orderItems) ?? 0)) : "0.00"}{" "}{props.orderInfo?.orders[0]?.orderTotalCurrencyCode ? props.orderInfo.orders[0].orderTotalCurrencyCode : getDefaultCurrencyCode()}
                                        {(getDiscountAmount(props.orderInfo.orders[0].orderItems) !== "NaN" && getDiscountAmount(props.orderInfo.orders[0].orderItems) !== "0.00") &&
                                            <><br/>-${toTwoDecimals(getDiscountAmount(props.orderInfo.orders[0].orderItems))} {props?.orderInfo?.orders[0]?.orderTotalCurrencyCode ? props.orderInfo.orders[0].orderTotalCurrencyCode : getDefaultCurrencyCode()}</>}
                                        {props.orderInfo.orders[0].totalShippingFeeAmount &&
                                            <>
                                            <br/>
                                        ${props.orderInfo.orders[0].totalShippingFeeAmount ? toTwoDecimals(props.orderInfo.orders[0].totalShippingFeeAmount) : null} {props?.orderInfo?.orders[0]?.totalShippingFeeCurrencyCode ? props.orderInfo.orders[0].totalShippingFeeCurrencyCode : null}
                                            </>
                                        }
                                        {props.orderInfo.orders[0].totalShippingFeeTaxAmount &&
                                            <>
                                                <br/>
                                                ${props.orderInfo.orders[0].totalShippingFeeTaxAmount ? toTwoDecimals(props.orderInfo.orders[0].totalShippingFeeTaxAmount) : null} {props?.orderInfo?.orders[0]?.totalShippingFeeTaxCurrencyCode ? props.orderInfo.orders[0].totalShippingFeeTaxCurrencyCode : null}
                                            </>
                                        }


                                    </td>
                                </tr>
                                {props.orderInfo?.packages[0]?.orderItems.map(item => (
                                    <>
                                        <tr className="top-row-border">
                                            <td className="column-1" colSpan={4} style={{ paddingTop: '10px' }}>
                                                <Tooltip title={item.orderItemId} arrow>
                                                    <p style={{
                                                        width: '200px',
                                                        margin: '0'
                                                    }}>Sku: <b>{item?.sellerSku}</b></p>
                                                </Tooltip>
                                            </td>

                                        </tr>
                                        <tr className="bottom-row-border">
                                            <td className="column-1">{item?.title}</td>
                                            <td className="column-2"></td>
                                            <td className="column-3">Price: <br/> Quantity: </td>
                                            <td className="column-4"><b
                                                ref={containerRef}>${item.itemPriceAmount ? toTwoDecimals(item.itemPriceAmount) : "0.00"}{" "}{item.itemPriceCurrencyCode ? item.itemPriceCurrencyCode : getDefaultCurrencyCode()}</b><br/><b
                                                style={{paddingRight: width_of_field - 10}}>{item.quantityOrdered}</b>
                                            </td>
                                        </tr>
                                    </>
                                ))
                                }
                            </table>
                        </div>
                    </Box>
                    : null}
                {/* Cloned Information */}
                {!isCreateShip() && props?.orderInfo?.isCloneShipment && props.orderInfo?.clonedOrderId ?
                    <Box borderRadius="borderRadius" {...defaultProps} style={{ marginRight: '20px' }}>
                        <div className="input-form-cloned-order">
                            <Typography variant="h6" gutterBottom component="div" className="form-header">
                                Cloned from: <Link href="#" onClick={() => {
                                    props.openLinkedOrder(props.orderInfo?.clonedOrderId)
                                }}> {props.orderInfo?.clonedOrderId}</Link>
                            </Typography>
                        </div>
                    </Box>
                    : null}
                {/* Redirected Information */}
                {!isCreateShip() && props?.orderInfo?.isReturnShipment ?
                    <div>
                        <Box borderRadius="borderRadius" {...defaultProps} style={{ marginRight: '20px' }}>
                            <div className="input-form-cloned-order">
                                <div>
                                    <table>
                                        <tr>
                                            {props?.orderInfo?.redirectToOrderId ?
                                                <div>
                                                    <td style={{ minWidth: 400 }}>
                                                        <Typography variant="h6" gutterBottom component="div"
                                                            className="form-header">
                                                            <table>
                                                                <tr>
                                                                    <td style={{ minWidth: 370 }}>
                                                                        Redirected From: <Link href="#"
                                                                                onClick={() => {
                                                                                    props.openLinkedOrder(props.orderInfo.redirectFromOrderId)
                                                                                }}> {props?.orderInfo?.redirectFromOrderId}</Link>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ minHeight: 55 }}>
                                                                    <td>
                                                                        Redirected To: <Link href="#"
                                                                                onClick={() => {
                                                                                    props.openLinkedOrder(props.orderInfo.redirectToOrderId)
                                                                                }}> {props?.orderInfo?.redirectToOrderId}</Link>
                                                                    </td>
                                                                    <td style={{ minWidth: 370 }}>
                                                                        {props?.shipmentStatus === RETURN ?
                                                                            <td>
                                                                                {state?.deleteDialog ?
                                                                                    <div align="left">
                                                                                        <Button
                                                                                            variant='contained'
                                                                                            onClick={cancelDeleteDialog}
                                                                                        >
                                                                                            Cancel
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color='primary'
                                                                                            onClick={closeDeleteDialog}
                                                                                        >
                                                                                            Delete
                                                                                        </Button>
                                                                                    </div>
                                                                                    : <Tooltip
                                                                                        title={DELETE_REDIRECT_ORDER_ID}>
                                                                                        <IconButton
                                                                                            aria-label={DELETE_REDIRECT_ORDER_ID}
                                                                                            onClick={openDeleteDialog}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                }
                                                                            </td>
                                                                            : null}
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                                </Typography>
                                                            </td>
                                                        </div>
                                                        : <td style={{minWidth: 400}}>
                                                            <Typography variant="h6" gutterBottom component="div"
                                                                        className="form-header">
                                                                Return From: <Link href="#" onClick={() => {
                                                                props.openLinkedOrder(props.orderInfo.redirectFromOrderId)
                                                            }}> {props?.orderInfo?.redirectFromOrderId}</Link>
                                                            </Typography>
                                                        </td>}
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </Box>
                                {props.shipmentStatus === RETURN && !props?.orderInfo?.redirectToOrderId ?
                                    state.addRedirectToOrderIdOpen ?
                                        <div>
                                            <TextField
                                                className="input-spec"
                                                variant="outlined"
                                                name="redirectedOrderId"
                                                label="Redirected Order ID"
                                                InputLabelProps={{shrink: true}}
                                                value={redirectToOrderId}
                                                onFocus={props?.handleFocus}
                                                onChange={handleRedirectToOrderIdChange}
                                            />
                                            <div align="left" style={{paddingTop: 18}}>
                                                <Button
                                                    onClick={cancelRedirectToOrderId}
                                                    variant='contained'
                                                    style={{}}
                                                >
                                                    {CANCEL}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color='primary'
                                                    onClick={saveRedirectToOrderId}
                                                    style={{}}
                                                >
                                                    {SAVE}
                                                </Button>
                                            </div>
                                        </div>
                                        : <div className="row-spec">
                                            <Button variant='contained' color='primary' onClick={openAddRedirectToOrderId}
                                                    style={{marginLeft: 10}}>
                                                {ADD_REDIRECT_ORDER_ID}
                                            </Button>
                                        </div>
                                    : null}
                            </div>
                            : null}
                        {/* Notes section */}
                        <Box borderRadius="borderRadius" {...defaultProps} style={{marginRight: '20px'}}>
                            <div className="input-form-cloned-order">
                                <div>
                                    <Typography variant="h6" gutterBottom component="div" className="form-header">
                                        Notes
                                    </Typography>
                                    <hr style={{marginTop: '0', paddingTop: '0', marginBottom: '10px'}}/>
                                    <TextField
                                        disabled={!state.editingNotesField}

                                        name="notes"
                                        value={!props?.orderInfo?.notes ? '' : props?.orderInfo?.notes}
                                        variant="outlined"
                                        multiline={true}
                                        rows={5}
                                        // style={ }
                                        InputLabelProps={{shrink: true}}
                                        className="notes-field"
                                        onFocus={props.handleFocus}
                                        onChange={(e) => props.handleChange(e.target.value, e.target.name, false, -1)}
                                        onBlur={handleNotesSave}
                                        InputProps={{style: {color: 'black'}}}
                                    />
                                    {state.editingNotesField ?
                                        <div className="row-spec">
                                            <Button variant='contained' onClick={handleNotesCancel}
                                                    style={{marginLeft: 10, marginBottom: 10}}>
                                                {CANCEL}
                                            </Button>
                                            <Button variant='contained' color='primary' onClick={handleNotesSave}
                                                    style={{marginLeft: 10, marginBottom: 10}}>
                                                {SAVE}
                                            </Button>
                                        </div>
                                        :
                                        <div className="row-spec">
                                            <Button variant='contained' color='primary' onClick={handleNotesEdit}
                                                    style={{marginLeft: 10, marginBottom: 10}}>
                                                {EDIT}
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Box>
                    </div>
                </form> :
                <>
                    <RenderPurchaseOrderPDFPreview
                        data={pdfData}
                        scale={props.scale}
                        handlePdfLoadError={props.handleCommercialInvoicePdfLoadError}
                    />
                </>
            }
        </>
    )
}

const actionCreators = {
    getMessage,
    validateAddress,
    getSampleCommercialInvoicePDF,
    resetPdfData,
    getStateAndProvinceMapping,
}

const mapStateToProps = (state) => ({
    eventLog:curLogsSelector(state),
    open: openSelector(state),
    carriers: carriersSelector(state),
    tags: tagsSelector(state),
    commercialInvoicePDF: commercialInvoicePDFSelector(state),
    isSampleCommercialInvoice: isSampleCommercialInvoiceSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
})

ShipmentForm.propTypes = {
    shipmentId: PropTypes.string,
    orderInfo: PropTypes.object,
    handleSave: PropTypes.func,
    handleChange: PropTypes.func,
    handleFromAddressChange: PropTypes.func,
    handleResCheck: PropTypes.func,
    handleFocus: PropTypes.func,
    handleAccountSelect: PropTypes.func,
    errorChecking: PropTypes.bool,
    required: PropTypes.bool,
    copyPackage: PropTypes.func,
    deletePackage: PropTypes.func,
    addPackage: PropTypes.func,
    addCommodity: PropTypes.func,
    deleteCommodity: PropTypes.func,
    editOrder: PropTypes.func,
    loading: PropTypes.bool,
    isCreateShip: PropTypes.bool,
    saveRedirectToOrderId: PropTypes.func,
    deleteRedirectToOrderId: PropTypes.func,
    openLinkedOrder: PropTypes.func,
    reprintPackage: PropTypes.func,
    boxes: PropTypes.array,
}

ShipmentForm.defaultProps = {
    shipmentId: "",
    orderInfo: {},
    handleSave: () => { },
    handleChange: () => { },
    handleFromAddressChange: () => { },
    handleResCheck: () => { },
    handleFocus: () => { },
    handleAccountSelect: () => { },
    errorChecking: false,
    required: false,
    copyPackage: () => { },
    deletePackage: () => { },
    addPackage: () => { },
    addCommodity: () => { },
    deleteCommodity: () => { },
    editOrder: () => { },
    loading: false,
    isCreateShip: false,
    saveRedirectToOrderId: ()=>{},
    deleteRedirectToOrderId: ()=>{},
    openLinkedOrder: ()=>{},
    reprintPackage: ()=>{},
    boxes: [],
    stateAndProvinceMapping: [],
    modifyShipOrDeliverDate: () => {},
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ShipmentForm);