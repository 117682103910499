import {
    FETCH_ALL_ORDERS,
    FETCH_ALL_SHIPMENTS,
    FETCH_BY_ORDER_ID, FETCH_BY_TRACKING_ID,
    UPDATE_SHIPMENTS,
    DELETE_SHIPMENTS,
    RECREATE_ORDERS,
    STORE_SHIPMENTS,
    PRINT_ORDER,
    UPDATE_PRINTED_COUNT,
    EDIT_ORDER,
    FILTER_CANCELLED,
    FILTER_SHIPPED,
    FILTER_UNSHIPPED,
    CANCEL_ORDER,
    CREATE_ORDER,
    REPRINT_ORDER,
    VOID_ORDER,
    ADD_PACKAGE,
    COPY_PACKAGE,
    DELETE_PACKAGE,
    UPDATE_ORDERS,
    UPDATE_ORDERS_BY_LIST,
    LOADING_SHIPMENT_REQUEST,
    EDIT_FROM_ADDRESS,
    RATE_SHIPMENT,
    TRACK_SHIPMENT,
    STORE_RATES,
    SET_RATE_LOADING,
    CLEAR_RATES,
    TEST_PRINT,
    IMPORT_ORDERS,
    SET_IMPORT_STATUS,
    APPLY_DEFAULT_SERVICE,
    APPLY_CHEAPEST_SERVICE,
    VALIDATE_ADDRESS_SERVICE,
    EDIT_SHIPMENT_CARRIER,
    ADD_COMMODITY,
    DELETE_COMMODITY,
    GET_SHIPMENT_COMMERCIAL_INVOICE,
    STORE_SHIPMENT_COMMERCIAL_INVOICE,
    UPDATE_BOX_TYPE,
    MARK_AS_SHIPPED,
    MARK_AS_REDIRECTED,
    MARK_AS_CANCELLED,
    MARK_AS_UNCANCELLED,
    CREATE_RETURN_SHIPMENTS,
    FILTER_RETURNS,
    FILTER_CLONED,
    MARK_AS_UNSHIPPED,
    SAVE_REDIRECT_ORDER_ID,
    DELETE_REDIRECT_ORDER_ID,
    OPEN_LINKED_ORDER,
    STORE_LINKED_SHIPMENT,
    CLEAR_LINKED_SHIPMENT,
    STORE_SHIPMENT,
    PRINT_TO_PDF,
    REPRINT_PACKAGE,
    FETCH_BUY_SHIPPING_SERVICES,
    SAVE_BUY_SHIPPING_SERVICE, STORE_BUY_SHIPPING_SERVICES,
    GET_SUGGESTED_BOX_TYPES,
    STORE_SUGGESTED_BOX_TYPES,
    IGNORE_BOX_SUGGESTION,
    EDIT_MULTIPLE_ORDER,
    GET_MESSAGES,
    STORE_MESSAGES,
    GET_SHIPMENTS,
    STORE_MESSAGES_FROM_SHIPMENTS,
    GET_SAMPLE_COMMERCIAL_INVOICE_PDF,
    STORE_SAMPLE_COMMERCIAL_INVOICE_PDF,
    RESET_PDF_DATA,
    UPDATE_AND_VALIDATE_SHIPMENTS,
    SET_SHIPMENT_SMART_SEARCH_ROWS,
    GET_ALL_MESSAGES, STORE_ALL_MESSAGES, ARCHIVE_LABEL,
    SET_SHIPMENTS_OPEN_EDIT_ROW,
    UPDATE_SHIPMENTS_OPEN_EDIT_ROW,
    EXPORT_SHIPMENT_SUMMARY_TO_EXCEL,
    SET_SHIPMENT_SUMMARY_ORDER,
    REMOVE_SHIPMENT, GET_MESSAGE, STORE_MESSAGE, Mark_Orders_As_Unshipped, UPDATE_LATEST_SHIPPED_QUERY_DATE,
} from "../../constants/orders";

export const fetchAllOrders = (orderStatus, fromDate, toDate, shipByDate = null, locationId) => ({
    type: FETCH_ALL_ORDERS,
    orderStatus,
    fromDate,
    toDate,
    shipByDate,
    locationId,
});

export const fetchAllShipments = (orderStatus) => ({
    type: FETCH_ALL_SHIPMENTS,
    orderStatus,
});

export const fetchByOrderId = (orderStatus, orderId) => ({
    type: FETCH_BY_ORDER_ID,
    orderStatus,
    orderId
})
export const fetchByTrackingId = (orderStatus, trackingId) => ({
    type: FETCH_BY_TRACKING_ID,
    orderStatus,
    trackingId
})

export const deleteShipments = (shipmentIds, status, setState) => ({
    type: DELETE_SHIPMENTS,
    shipmentIds,
    status,
    setState
});

export const updateShipments = (shipmentIds, status, setState) => ({
    type: UPDATE_SHIPMENTS,
    shipmentIds,
    status,
    setState
});

export const reCreate = (status, setState) => ({
    type: RECREATE_ORDERS,
    status,
    setState
});

export const storeOrders = (rows) => ({
    type: STORE_SHIPMENTS,
    rows,
});

export const updateLatestShippedQueryDate = (fromDate, toDate) => ({
    type: UPDATE_LATEST_SHIPPED_QUERY_DATE,
    fromDate,
    toDate
})

export const removeShipment = (shipmentId, orderStatus) => ({
    type: REMOVE_SHIPMENT,
    shipmentId,
    orderStatus
})

export const printOrder = (orders, setShipOpen, exitShipment, setPrintCount, allowPrintWithoutRate) => ({
    type: PRINT_ORDER,
    orders,
    setShipOpen,
    exitShipment,
    setPrintCount,
    allowPrintWithoutRate
});

export const updatePrintedCount = (clear) => ({
    type: UPDATE_PRINTED_COUNT,
    clear,
});

export const editOrder = (order) => ({
    type: EDIT_ORDER,
    order,
});

export const editMultipleOrder = (orders) => ({
    type: EDIT_MULTIPLE_ORDER,
    orders
})

export const updateBoxType = (order) => ({
    type: UPDATE_BOX_TYPE,
    order,
});

export const filterUnshipped = (unshipped, locationId = null) => ({
    type: FILTER_UNSHIPPED,
    unshipped,
    locationId
});

export const filterShipped = (shipped, locationId = null) => ({
    type: FILTER_SHIPPED,
    shipped,
    locationId
});

export const filterCancelled = (cancelled, locationId = null) => ({
    type: FILTER_CANCELLED,
    cancelled,
    locationId
});

export const cancelOrder = (ordersToCancel, fromDate, toDate, shipByDate, locationId) => ({
    type: CANCEL_ORDER,
    ordersToCancel,
    fromDate,
    toDate,
    shipByDate,
    locationId
});

export const createOrder = (newOrder, fromDate, toDate, shipByDate, locationId) => ({
    type: CREATE_ORDER,
    newOrder,
    fromDate,
    toDate,
    shipByDate,
    locationId
});

export const reprintOrder = (ordersToPrint, downloadPdfs, fromDate, toDate, locationId) => ({
    type: REPRINT_ORDER,
    ordersToPrint,
    downloadPdfs,
    fromDate,
    toDate,
    locationId
});

export const archiveLabel = (ordersToArchive) => ({
    type: ARCHIVE_LABEL,
    ordersToArchive
});

export const voidOrder = (ordersToVoid, fromDate, toDate, locationId) => ({
    type: VOID_ORDER,
    ordersToVoid,
    fromDate,
    toDate,
    locationId
});

export const addPackage = (toAdd) => ({
    type: ADD_PACKAGE,
    toAdd,
});

export const copyPackage = (toCopy) => ({
    type: COPY_PACKAGE,
    toCopy,
});

export const deletePackage = (toDelete) => ({
    type: DELETE_PACKAGE,
    toDelete,
});

export const updateOrders = (order) => ({
    type: UPDATE_ORDERS,
    order,
});

export const updateOrdersBylist = (orders) => ({
    type: UPDATE_ORDERS_BY_LIST,
    orders,
});

export const markOrderAsUnshipped = (shipmentIds) => ({
    type: Mark_Orders_As_Unshipped,
    shipmentIds,
});

export const loadingShipmentRequest = (loading) => ({
    type: LOADING_SHIPMENT_REQUEST,
    loading,
});

export const editFromAddress = (fromAddress) => ({
    type: EDIT_FROM_ADDRESS,
    fromAddress,
});

export const rateShipment = (shipment, setState) => ({
    type: RATE_SHIPMENT,
    shipment,
    setState
});

export const trackShipment = (shipment) => ({
    type: TRACK_SHIPMENT,
    shipment,
});


export const storeRates = (rates) => ({
    type: STORE_RATES,
    rates,
});

export const setRateLoading = (rateLoading) => ({
    type: SET_RATE_LOADING,
    rateLoading,
});

export const clearRates = () => ({
    type: CLEAR_RATES,
});

export const testPrint = (printedZPL) => ({
    type: TEST_PRINT,
    printedZPL
})

export const importOrders = (files, fromDate, toDate, shipByDate, locationId) => ({
    type: IMPORT_ORDERS,
    files,
    fromDate,
    toDate,
    shipByDate,
    locationId
})

export const setImportStatus = (status) => ({
    type: SET_IMPORT_STATUS,
    status
})

export const rateToDefault = (orderIds, setState) => ({
    type: APPLY_DEFAULT_SERVICE,
    orderIds,
    setState
})

export const rateToCheapest = (orderIds, setState) => ({
    type: APPLY_CHEAPEST_SERVICE,
    orderIds,
    setState
})

export const validateAddress = (orderIds, setState) => ({
    type: VALIDATE_ADDRESS_SERVICE,
    orderIds,
    setState,
    // newShipmentAddress
})

export const editShipmentCarrier = (id, accountNumber, service, rateTotal, packageRates, alias) => ({
    type: EDIT_SHIPMENT_CARRIER,
    id,
    accountNumber,
    service,
    rateTotal,
    packageRates,
    alias
})

export const addCommodity = (shipmentId, packageId) => ({
    type: ADD_COMMODITY,
    shipmentId,
    packageId
});

export const deleteCommodity = (shipmentId, index, setLoading) => ({
    type: DELETE_COMMODITY,
    shipmentId,
    index,
    setLoading
});

export const getShipmentCommercialInvoice = (shipmentId) => ({
    type: GET_SHIPMENT_COMMERCIAL_INVOICE,
    shipmentId
});

export const storeShipmentCommercialInvoicePDF = (bytes) => ({
    type: STORE_SHIPMENT_COMMERCIAL_INVOICE,
    bytes
});

export const markAsShipped = (shipmentIds, shipmentDate, shipmentNote) => ({
    type: MARK_AS_SHIPPED,
    shipmentIds,
    shipmentDate,
    shipmentNote
})

export const markAsCancelled = (shipmentIds, cancelDate, cancelNote, cancelTagName) => ({
    type: MARK_AS_CANCELLED,
    shipmentIds,
    cancelDate,
    cancelNote,
    cancelTagName
})

export const markAsRedirected = (shipmentIds) => ({
    type: MARK_AS_REDIRECTED,
    shipmentIds
})

export const markAsUnshipped = (shipmentIds) => ({
    type: MARK_AS_UNSHIPPED,
    shipmentIds
})

export const markAsUncancelled = (shipmentIds) => ({
    type: MARK_AS_UNCANCELLED,
    shipmentIds
})

export const filterReturns = (returns, locationId = null) => ({
    type: FILTER_RETURNS,
    returns,
    locationId
})

export const filterCloned = (cloned, locationId = null) => ({
    type: FILTER_CLONED,
    cloned,
    locationId
})

export const createReturnShipments = (shipments, shipmentType) => ({
    type: CREATE_RETURN_SHIPMENTS,
    shipments,
    shipmentType
})

export const saveRedirectToOrderId = (shipmentId, redirectToOrderId, setState) => ({
    type: SAVE_REDIRECT_ORDER_ID,
    shipmentId,
    redirectToOrderId,
    setState
})

export const deleteRedirectToOrderId = (shipmentId) => ({
    type: DELETE_REDIRECT_ORDER_ID,
    shipmentId
})

export const openLinkedOrder = (orderId, setState) => ({
    type: OPEN_LINKED_ORDER,
    orderId,
    setState
})

export const storeLinkedShipment = (shipmentId) => ({
    type: STORE_LINKED_SHIPMENT,
    shipmentId
})

export const clearLinkedShipment = () => ({
    type: CLEAR_LINKED_SHIPMENT
})

export const storeShipments = (orderStatus, shipments) => ({
    type: STORE_SHIPMENT,
    orderStatus,
    shipments
})

export const printToPdf = (orders, setShipOpen, downloadPdfs,setPrintCount) => ({
    type: PRINT_TO_PDF,
    orders,
    setShipOpen,
    downloadPdfs,
    setPrintCount
});

export const reprintPackage = (data, downloadPdfs) => ({
    type: REPRINT_PACKAGE,
    data,
    downloadPdfs
})


export const fetchBuyShippingServices = (shipmentId) => ({
    type: FETCH_BUY_SHIPPING_SERVICES,
    shipmentId
})

export const saveBuyShippingService = (shipmentId, shippingService, packageToListMap) => ({
    type: SAVE_BUY_SHIPPING_SERVICE,
    shipmentId,
    shippingService,
    packageToListMap,
})

export const storeBuyShippingServices = (buyShippingServices, packageToListMap) => ({
    type: STORE_BUY_SHIPPING_SERVICES,
    buyShippingServices,
    packageToListMap
})

export const getSuggestedBoxTypes = (packages) => ({
    type: GET_SUGGESTED_BOX_TYPES,
    packages
})

export const storeSuggestedBoxTypes = (boxTypes) => ({
    type: STORE_SUGGESTED_BOX_TYPES,
    boxTypes
})

export const ignoreBoxSuggestion = (data) => ({
    type: IGNORE_BOX_SUGGESTION,
    data
})

export const getMessages = (data) => ({
    type: GET_MESSAGES,
    data
})

export const getMessage = (shipmentId) =>({
    type: GET_MESSAGE,
    shipmentId
})

export const getAllMessages = () => ({
    type: GET_ALL_MESSAGES,
})

export const storeAllMessages = (data) => ({
    type: STORE_ALL_MESSAGES,
    data
})

export const storeMessage = (data) => ({
    type: STORE_MESSAGE,
    data
})

export const storeMessages = (data) => ({
    type: STORE_MESSAGES,
    data
})

export const getShipments = (data) => ({
    type: GET_SHIPMENTS,
    data
})

export const storeShipmentsFromMessages = (data) => ({
    type: STORE_MESSAGES_FROM_SHIPMENTS,
    data
})

export const getSampleCommercialInvoicePDF = (pdfData, setPdfError) => ({
    type: GET_SAMPLE_COMMERCIAL_INVOICE_PDF,
    pdfData,
    setPdfError
})

export const storeSampleCommercialInvoicePDF = (bytes) => ({
    type: STORE_SAMPLE_COMMERCIAL_INVOICE_PDF,
    bytes
})

export const resetPdfData = () => ({
    type: RESET_PDF_DATA
})

export const updateAndValidateShipments = (data) => ({
    type: UPDATE_AND_VALIDATE_SHIPMENTS,
    data
})

export const setShipmentsOpenEditRow = (editRow) => ({
    type: SET_SHIPMENTS_OPEN_EDIT_ROW,
    editRow
})

export const updateShipmentsOpenEditRow = (newRows) => ({
    type: UPDATE_SHIPMENTS_OPEN_EDIT_ROW,
    newRows
})

export const exportShipmentSummaryToExcel = (sortedSummary, shipmentStatus) => ({
    type: EXPORT_SHIPMENT_SUMMARY_TO_EXCEL,
    sortedSummary,
    shipmentStatus
})

export const setShipmentSummaryOrderDirection = (direction, property) => ({
    type: SET_SHIPMENT_SUMMARY_ORDER,
    direction,
    property
})

export const setShipmentsSmartSearchRows = (newRows) => ({
    type: SET_SHIPMENT_SMART_SEARCH_ROWS,
    newRows
})