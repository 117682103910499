import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import {PURCHASE_SUMMARY_PAGES} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {listPurchaseSummary, listShippingSettings} from "../../../redux/actions/accounting";
import {
    lastUpdatedDateSummarySelector,
    loadingSelector,
    purchaseSummarySelector,
    shippingSettingSelector,
} from '../../../redux/selectors/accounting';
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import {userInfoSelector} from "../../../redux/selectors/auth";
import PurchaseSummaryTable from "./PurchaseSummaryTable";
import {getTableSetting, saveTableSetting} from "../../../redux/actions/account";
import {tableSettingSelector} from "../../../redux/selectors/account";

class PurchaseSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseSummary: [],
            currentPage: PURCHASE_SUMMARY_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            selectedYear: new Date().getFullYear(),
            selectedQuarters: null,
            selectedCurrency: "CAD",
            selectedMonth:  " ",
            purchaseSummaryFilterSetting: null,
            isFetch: false
    }
    }


    async componentDidMount() {
         this.props.listShippingSettings()
        this.props.getTableSetting(this.props.user.userId)

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }


        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({ purchaseSummaryFilterSetting: this.props.tableSetting?.purchaseSummaryFilterSetting },()=>{
                this.setState({
                    selectedCurrency: this.state.purchaseSummaryFilterSetting.currency,
                    selectedYear: this.state.purchaseSummaryFilterSetting.year,
                    selectedMonth: this.state.purchaseSummaryFilterSetting.month,
                    selectedQuarters: this.state.purchaseSummaryFilterSetting.quarters,
                },()=>{
                    this.listPurchaseAPI()
                })
            })
        }

        if (prevProps.purchaseSummary !== this.props.purchaseSummary) {
            this.setState({purchaseSummary: this.props.purchaseSummary},()=>{
                this.calculateTotal()
            })
        }
    }


    listPurchaseAPI = () =>{
        let data
        if(this.state.selectedQuarters === null){
            data = {
                "year": this.state.selectedYear,
                "quarter": [1,2,3,4],
                "month": this.state.selectedMonth.trim(),
                "currency": this.state.selectedCurrency,
                "isFetch": this.state.isFetch,
            }
        }else{
            let newQuarter = [this.state.selectedQuarters]
            data = {
                "year": this.state.selectedYear,
                "quarter": newQuarter,
                "month": this.state.selectedMonth.trim(),
                "currency": this.state.selectedCurrency,
                "isFetch": this.state.isFetch,
            }
        }
        this.props.listPurchaseSummary(data)
        this.setState({isFetch: false})
    }

    handleYearChange = (event) => {
        this.setState({ selectedYear: event.target.value },() => {
            this.onFiltersInputChange("year",event)
        })

    }

    handleMonthChange = (event) => {
        this.setState({ selectedMonth: event.target.value },() => {
            this.onFiltersInputChange("month",event)
        })

    }

    handleCurrencyChange = (event) => {
        this.setState({ selectedCurrency: event.target.value },() => {
            this.onFiltersInputChange("currency",event)
        })


    }


    handleQuarterChange = (quarter) => {
        const quarterMapping = {
            "QTR 1": 1,
            "QTR 2": 2,
            "QTR 3": 3,
            "QTR 4": 4
        }
        const quarterNumber = quarterMapping[quarter]
        const selectedQuarters = this.state.selectedQuarters === quarterNumber ? null : quarterNumber
        this.setState({ selectedQuarters, selectedMonth: "" }, () => {
            this.onFiltersInputChange("quarters",this.state.selectedQuarters)
            this.onFiltersMonth("month",this.state.selectedMonth)
        })

    }


    calculateTotal = () => {
        let supplierTotal;
        const newPurchaseSummary = [...this.props.purchaseSummary];

        if (this.state.selectedCurrency === "Consolidated CAD" &&
            this.props.shippingSettings.averageRates &&
            this.props.shippingSettings.averageRates.length > 0) {

            supplierTotal = newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary?.CY_TOTAL_PAYABLE);
            }, 0);

            }else {
            supplierTotal = newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary.CY_TOTAL_PAYABLE) || 0;
            }, 0);
        }


        const newItem = {
            CY_SUBTOTAL: newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary.CY_SUBTOTAL || 0)
            }, 0).toFixed(2),

            CY_TAX: newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary.CY_TAX || 0);
            }, 0).toFixed(2),

            CY_TOTAL: newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary.CY_TOTAL || 0);
            }, 0).toFixed(2),

            CY_SUBTOTAL_PAYABLE: newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary.CY_SUBTOTAL_PAYABLE || 0) ;
            }, 0).toFixed(2),

            CY_TAX_PAYABLE: newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary.CY_TAX_PAYABLE || 0) ;
            }, 0).toFixed(2),

            PY_SUBTOTAL_PAYABLE: newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary.PY_SUBTOTAL_PAYABLE || 0);
            }, 0).toFixed(2),

            PY_TAX_PAYABLE: newPurchaseSummary.reduce((total, summary) => {
                return total + parseFloat(summary.PY_TAX_PAYABLE || 0);
            }, 0).toFixed(2),
            CY_TOTAL_PAYABLE: supplierTotal,
            supplierName: 'Total',
        };


        if (newPurchaseSummary.length > 0) {
            newPurchaseSummary.push(newItem);
        }

        this.setState({ purchaseSummary: newPurchaseSummary });

    }

    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    setFetch = (bool)=>{
        this.setState({isFetch: bool},()=>{
            this.listPurchaseAPI()
        })

    }

    displayTable = () => {
        return (
          <PurchaseSummaryTable
            lastUpdatedAt={this.props.lastUpdatedAt}
            setFetch={this.setFetch}
            loadingData={this.props.loadingData}
            selectedQuarters={this.state.selectedQuarters}
            selectedYear={this.state.selectedYear}
            handleQuarterChange={this.handleQuarterChange}
            handleYearChange={this.handleYearChange}
            selectedCurrency={this.state.selectedCurrency}
            handleCurrencyChange={this.handleCurrencyChange}
            selectedMonth={this.state.selectedMonth}
            handleMonthChange={this.handleMonthChange}
            purchaseSummary={this.state.purchaseSummary}
            setCurrentPage={this.setCurrentPage}
            openFeedbackBar={this.openFeedbackBar}
          />
        );
    };



    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case PURCHASE_SUMMARY_PAGES.TABLE:
                return this.displayTable()
            default:
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    onFiltersMonth = (name,value) => {
        let filtersMap = this.props.tableSetting?.purchaseSummaryFilterSetting
            filtersMap[name] = value;
        this.saveUpdatedFilters(filtersMap)
    }


    onFiltersInputChange = (name,event) => {
        let filtersMap = this.props.tableSetting?.purchaseSummaryFilterSetting

        if(name === "quarters"){
            filtersMap[name] = event;
        }else{
            filtersMap[name] = event.target.value;
        }

        this.saveUpdatedFilters(filtersMap)
    }


    saveUpdatedFilters = (filters) => {
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changePurchaseSummaryFilters: true,
            purchaseSummaryFilters: filters
        })
    }


    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    purchaseSummary: purchaseSummarySelector(state),
    lastUpdatedAt: lastUpdatedDateSummarySelector(state),
    user: userInfoSelector(state),
    loadingData: loadingSelector(state),
    shippingSettings: shippingSettingSelector(state),
    tableSetting: tableSettingSelector(state),

})


const actionCreators = {
    clearStatus,
    listPurchaseSummary,
    listShippingSettings,
    saveTableSetting,
    getTableSetting,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, PurchaseSummary);