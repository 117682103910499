import {
    call,
    put,
    takeLatest,
    fork,
    take,
    cancel
} from 'redux-saga/effects';
import axios from 'axios';

// constants for endpoints
import {
    SYSTEM_EVENT_LOG,
    BOX_SUGGESTIONS,
    ORDERS
} from "../../../components/global/Server/endpoints";

// constants for actions
import {
    OF,
    RATED,
    ADD_PACKAGE,
    CANCEL_ORDER,
    DELETE_SHIPMENTS,
    COPY_PACKAGE,
    CREATE_ORDER,
    UPDATE_SHIPMENTS,
    DELETE_PACKAGE,
    EDIT_ORDER,
    EDIT_MULTIPLE_ORDER,
    FETCH_ALL_ORDERS,
    FETCH_ALL_SHIPMENTS,
    RECREATE_ORDERS,
    PRINT_ORDER,
    REPRINT_ORDER,
    VOID_ORDER,
    EDIT_FROM_ADDRESS,
    RATE_SHIPMENT,
    TRACK_SHIPMENT,
    IMPORT_ORDERS,
    APPLY_DEFAULT_SERVICE,
    APPLY_CHEAPEST_SERVICE,
    VALIDATE_ADDRESS_SERVICE,
    EDIT_SHIPMENT_CARRIER,
    ADD_COMMODITY,
    GET_SHIPMENT_COMMERCIAL_INVOICE,
    DELETE_COMMODITY,
    REPRINT_PACKAGE,
    PRINT_TO_PDF,
    UPDATE_BOX_TYPE,
    MARK_AS_SHIPPED,
    MARK_AS_CANCELLED,
    MARK_AS_UNCANCELLED,
    MARK_AS_REDIRECTED,
    CREATE_RETURN_SHIPMENTS,
    MARK_AS_UNSHIPPED,
    SAVE_REDIRECT_ORDER_ID,
    DELETE_REDIRECT_ORDER_ID,
    OPEN_LINKED_ORDER,
    CLEAR_LINKED_SHIPMENT,
    FETCH_BUY_SHIPPING_SERVICES, SAVE_BUY_SHIPPING_SERVICE,
    GET_SUGGESTED_BOX_TYPES,
    IGNORE_BOX_SUGGESTION, FETCH_BY_ORDER_ID,
    VALIDATED, GET_MESSAGES, GET_SHIPMENTS, UPDATE_AND_VALIDATE_SHIPMENTS, GET_ALL_MESSAGES,
    EXPORT_SHIPMENT_SUMMARY_TO_EXCEL,
    STORE_SAMPLE_COMMERCIAL_INVOICE_PDF,
    GET_SAMPLE_COMMERCIAL_INVOICE_PDF,
    STORE_SHIPMENT_COMMERCIAL_INVOICE,
    ARCHIVE_LABEL, FETCH_BY_TRACKING_ID, GET_MESSAGE,
} from "../../constants/orders";

// constants for messages
import {
    SHIP_FAILED,
    SERVER_ERROR
} from "../../../messages/errorMessages";

// secondary actions
import {
    storeOrders,
    updatePrintedCount,
    filterShipped,
    filterCancelled,
    filterUnshipped,
    updateOrders,
    updateOrdersBylist,
    storeShipmentCommercialInvoicePDF,
    loadingShipmentRequest,
    setRateLoading,
    storeRates,
    testPrint,
    setImportStatus,
    filterReturns,
    filterCloned,
    storeLinkedShipment,
    storeShipments,
    storeBuyShippingServices,
    storeSuggestedBoxTypes,
    storeMessages,
    storeShipmentsFromMessages,
    storeSampleCommercialInvoicePDF,
    setShipmentsSmartSearchRows,
    storeAllMessages,
    updateShipmentsOpenEditRow,
    removeShipment, storeMessage, markOrderAsUnshipped, updateLatestShippedQueryDate
} from "../../actions/orders";
import {
    setLoadingStatus,
    setOpen,
    clearStatus,
    requestStatus,
    setSavingStatus,
    setSavingOpen,
    setCreateOrderOpen,
    setVoidStatus,
    setVoidOpen,
    setCreateReturnOpen,
    setCreateClonedOpen,
    showErrorMessage, hideError, setArchiveOpen, setArchiveStatus
} from "../../actions/global";
import {
    getTableSetting
} from "../../actions/account";

// constants
import {ALL, CANCELLED, CLONED, RETURN, SHIPPED, UNSHIPPED} from "../../../components/stages/constants";
import { SERVER_FAILED_ERROR_MESSAGE } from "../../../pages/Dashboard/DashboardFinal/constants";
import { RATE_FAILED } from "../../../components/shipments/constants";
import {HTTP_SUCCESS} from "../../constants/global";
import {storePDFpreviewReady} from "../../actions/purchaseOrders";
import * as XLSX from "xlsx";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const takeLatestByOrder = (pattern, saga) => fork(function* () {
    let lastTasks = {}
    while (true) {
        const action = yield take(pattern);
        let orderIds = ''
        action.orderIds.forEach((order) => orderIds += order.id)
        if (lastTasks[orderIds]) {
            yield cancel(lastTasks[orderIds])
        }

        lastTasks[orderIds] = yield fork(saga, action)
    }
})

function* fetchAllOrdersSaga(action) {
    yield put(hideError());
    yield put(setLoadingStatus(true))
    try {
        let timezone
        try {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        } catch (ignored) {
            timezone = ""
        }

        const request = {
            fromDate: action.fromDate,
            toDate: action.toDate,
            shipByDate: action.shipByDate,
            timezone: timezone,
            locationId: action.locationId,
        }
        var endpoint
        switch (action.orderStatus) {
            case UNSHIPPED:
                endpoint = ORDERS.LIST_UNSHIPPED
                break;
            case SHIPPED:
                endpoint = ORDERS.LIST_SHIPPED
                break;
            case CANCELLED:
                endpoint = ORDERS.LIST_CANCELLED
                break;
            case RETURN:
                endpoint = ORDERS.LIST_RETURNS
                break;
            case CLONED:
                endpoint = ORDERS.LIST_CLONED
                break;
            default:
                yield put(setLoadingStatus(false))
                return
        }
        const response = yield call(() => postRequest(endpoint, request));
        if (response.data.success) {
            if (response.data.labelArchivedShipmentIds?.length > 0) {
                response.data.result.forEach(shipment => {
                    if (response.data.labelArchivedShipmentIds.includes(shipment.shipmentId))
                        shipment.labelArchived = true
                })
            }
            yield put(getTableSetting());

            const statuses = {
                [UNSHIPPED]: filterUnshipped,
                [SHIPPED]: filterShipped,
                [CANCELLED]: filterCancelled,
                [RETURN]: filterReturns,
                [CLONED]: filterCloned
            };

            if (action.orderStatus == SHIPPED) {
                yield put(updateLatestShippedQueryDate(action.fromDate, action.toDate))
            }
            // loop through each status
            for (const [status, actionCreator] of Object.entries(statuses)) {
                // Filter data if the status matches the current one, otherwise pass an empty array
                const filtered = status === action.orderStatus
                    ? [...response.data.result.filter(row => row.orderStatus === status)]
                    : [];

                // Dispatch the appropriate action with the filtered or cleared data
                yield put(actionCreator(filtered, action.locationId));
            }

            yield put(storeOrders(response.data.result));

        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(setLoadingStatus(false))
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}

function* fetchAllShipments(action) {
    yield put(setLoadingStatus(true))
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.FETCH_ALL_SHIPMENTS, { orderStatus: action.orderStatus }));
        const unshipped = yield [...response.data.filter(row => row.orderStatus === UNSHIPPED)];
        const shipped = yield [...response.data.filter(row => row.orderStatus === SHIPPED)];
        const cancelled = yield [...response.data.filter(row => row.orderStatus === CANCELLED)];
        yield put(filterUnshipped(unshipped));
        yield put(filterShipped(shipped));
        yield put(filterCancelled(cancelled));
        yield put(storeOrders(response.data));
    } catch (error) {
        yield put(setLoadingStatus(false))
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}

function* fetchByOrderIdSaga(action) {
    yield put(setLoadingStatus(true))
    yield put(hideError());
    try {
        const request = {
            orderStatus: action.orderStatus,
            orderId: action.orderId
        }
        const response = yield call( () => postRequest(ORDERS.LIST_BY_ORDER_ID, request));
        if (response.data.success) {
            const unshipped = yield [...response.data.result.filter(row => row.orderStatus === UNSHIPPED)];
            const shipped = yield [...response.data.result.filter(row => row.orderStatus === SHIPPED)];
            const cancelled = yield [...response.data.result.filter(row => row.orderStatus === CANCELLED)];
            const returns = yield [...response.data.result.filter(row => row.orderStatus === RETURN)];
            const cloned = yield [...response.data.result.filter(row => row.orderStatus === CLONED)];
            yield put(setShipmentsSmartSearchRows(response.data.result))
            yield put(getTableSetting());
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
    } catch (error) {
        yield put(setLoadingStatus(false))
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}
function* fetchByTrackingIdSaga(action) {
    yield put(setLoadingStatus(true))
    yield put(hideError());
    try {
        const request = {
            orderStatus: action.orderStatus,
            trackingId: action.trackingId
        }
        const response = yield call( () => postRequest(ORDERS.LIST_BY_TRACKING_ID, request));
        if (response.data.success) {
            const unshipped = yield [...response.data.result.filter(row => row.orderStatus === UNSHIPPED)];
            const shipped = yield [...response.data.result.filter(row => row.orderStatus === SHIPPED)];
            const cancelled = yield [...response.data.result.filter(row => row.orderStatus === CANCELLED)];
            const returns = yield [...response.data.result.filter(row => row.orderStatus === RETURN)];
            const cloned = yield [...response.data.result.filter(row => row.orderStatus === CLONED)];
            yield put(setShipmentsSmartSearchRows(response.data.result))
            yield put(getTableSetting());
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
    } catch (error) {
        yield put(setLoadingStatus(false))
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}

function* reCreate(action) {
    yield put(setLoadingStatus(true))
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.RECREATE_ORDERS, { status: action.status }));
        action.setState(response.status === 200 && response.data.length > 0 ? true : false)
        if (response.status === 200) {
            switch (action.status) {
                case UNSHIPPED:
                    const unshipped = yield [...response.data.filter(row => row.orderStatus === UNSHIPPED)];
                    yield put(filterUnshipped(unshipped));
                    break
                case SHIPPED:
                    const shipped = yield [...response.data.filter(row => row.orderStatus === SHIPPED)];
                    yield put(filterShipped(shipped));
                    break
                case CANCELLED:
                    const cancelled = yield [...response.data.filter(row => row.orderStatus === CANCELLED)];
                    yield put(filterCancelled(cancelled));
                    break
                default:
                    break
            }
            yield put(storeOrders(response.data));
        } else {
            action.setState(false)
        }
    } catch (error) {
        action.setState(false)
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}

function* updateShipments(action) {
    yield put(setLoadingStatus(true))
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.UPDATE_SHIPMENTS, action.shipmentIds));
        action.setState(response && response.data && response.data.success ? true : false)

        if (response.status === 200) {
            if (response.data.success) {
                yield call(() => fetchAllShipments({ orderStatus: action.status }));
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        } else {
            action.setState(false)
        }
    } catch (error) {
        action.setState(false)
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}

function* deleteShipments(action) {
    yield put(setLoadingStatus(true))
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.DELETE_SHIPMENTS, action.shipmentIds));
        action.setState(response && response.data && response.data.success ? true : false)
        if (response.status === 200) {
            if (response.data.success) {
                if (action.shipmentIds) {
                    for (let i = 0; i < action.shipmentIds.length; i++) {
                        yield put(removeShipment(action.shipmentIds[i], action.status))
                    }
                }
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        } else {
            action.setState(false)
        }
    } catch (error) {
        action.setState(false)
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}

function* printOrder(action) {
    yield put(hideError());
    let value = 0;
    let errors = []
    yield put(setLoadingStatus(true))
    let orderMutated = yield call(() => postRequest(ORDERS.SORT_ORDERS_BEFORE_PRINT, {orders: action.orders}));
    let unprintableTag=action.orders.length- orderMutated['data']['sortedList'].length;
    let printableTag=orderMutated['data']['sortedList'].length;
    for (let i = 0; i <printableTag; i++) {
        let response = yield call(() => printSpecificOrder(orderMutated['data']['sortedList'][i], action.setShipOpen, action.exitShipment, action.allowPrintWithoutRate));
        value += response.shippedQuantity
        if (response.shippedQuantity == 0 && response.message) {
            errors.push(response.message)
        }
        action.setPrintCount(true, value + " OF " + action.orders.length + " PRINTED")
    }

    if(parseInt(action.orders.length) - parseInt(value) !== 0) {
        if(unprintableTag !== 0){
            action.setPrintCount(false, "")
            action.setShipOpen(true, printableTag + " OF " + parseInt(action.orders.length)+"  NOT PRINTED, PRINT NOT ALLOWED TAG" )
        }
        else{
        action.setPrintCount(false, "")
        action.setShipOpen(true, parseInt(action.orders.length) - parseInt(value) + " NOT PRINTED\n" +
            (errors.length >= 1 ? errors.join("\n") : ""))}
    } else {
        action.setPrintCount(true, value + " OF " + action.orders.length + " PRINTED")
        yield new Promise(resolve => setTimeout(resolve, 3000));
        action.setPrintCount(false, "")

    }
    yield put(updatePrintedCount(true))
    yield put(setLoadingStatus(false))
}

function* printSpecificOrder(order, setShipOpen, exitShipment, allowPrintWithoutRate) {
    yield put(hideError());
    const request = {
        shipment: order,
        allowPrintWithoutRate: allowPrintWithoutRate,
    };
    let response
    try {
        response = yield call(() => postRequest(ORDERS.PRINT_ORDER, request));
        if (response.data.success) {
            yield put(updatePrintedCount(false))
            yield put(updateOrders(response.data.shipment))
            yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            exitShipment()
            return {shippedQuantity: 1}
        } else {
            return {shippedQuantity: 0, message: response.data.message}
        }
    } catch (error) {
        setShipOpen(true, SHIP_FAILED+SERVER_ERROR)
        yield put(setLoadingStatus(false))
        return {shippedQuantity: 0, message: response?.data?.message}
    }
}

function* printToPdf(action) {
    yield put(setLoadingStatus(true))
    let value = 0;
    let errors = []
    for (let i = 0; i < action.orders.length; i++) {
        let response = yield call(() => printSpecificPdf(action.orders[i].order, action.setShipOpen, action.downloadPdfs));
        value += response.shippedQuantity
        if (!response.shippedQuantity && response.message) {
            errors.push(response.message)
        }
        action.setPrintCount(true, value + " OF " + action.orders.length + " PRINTED")
    }
    if(parseInt(action.orders.length) - parseInt(value) !== 0) {
        action.setPrintCount(false, "")
        action.setShipOpen(true, parseInt(action.orders.length) - parseInt(value) + " NOT PRINTED\n" +
            (errors.length >= 1 ? errors.join("\n") : ""))
    } else {
        action.setPrintCount(true, value + " OF " + action.orders.length + " PRINTED")
        yield new Promise(resolve => setTimeout(resolve, 3000));
        action.setPrintCount(false, "")
    }
    yield put(updatePrintedCount(true))
    yield put(setLoadingStatus(false))
}

function* printSpecificPdf(order, setShipOpen, downloadPdfs) {
    const request = {
        shipmentId: order,
    };
    try {
        const response = yield call(() => postRequest(ORDERS.PRINT_TO_PDF, request));
        if (response.data.success) {
            downloadPdfs(response.data.pdf, response.data.shipment)
            yield put(updatePrintedCount(false))
            yield put(updateOrders(response.data.shipment))
            yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            return {shippedQuantity: 1}
        } else {
            return {shippedQuantity: 0, message: response.data.message}
            /*setShipOpen(true, SHIP_FAILED + response.data.message)*/
        }
    } catch (error) {
        setShipOpen(true, SHIP_FAILED + SERVER_ERROR)
        yield put(setLoadingStatus(false))
    }
}

function* editOrder(action) {
    yield call(clearStatus)
    yield put(hideError());
    yield put(setSavingOpen(true));
    yield put(setSavingStatus(true));
    try {
        let request = {order: action.order, editMultiple: false}
        const response = yield call(() => postRequest(ORDERS.EDIT_ORDER, request))
        if (response.data.success) {
            yield put(updateOrders(response.data.shipment))
            yield put(updateShipmentsOpenEditRow([response.data.shipment]))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(setSavingStatus(false))
        yield put(setSavingOpen(false))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        yield put(setSavingOpen(true))
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editMultipleOrders(action) {
    yield put(hideError());
    try {
        let request = {orders: action.orders}
        const response = yield call(() => postRequest(ORDERS.EDIT_MULTIPLE_ORDER, request))
        const shipments = response.data.shipments
        const messages = response.data.messages
        if (response.data.success) {
            yield put(updateOrdersBylist(shipments))
        } else {
            yield put(showErrorMessage(messages))
        }
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
            messages: messages
        };
        yield put(setSavingOpen(true))
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editBoxType(action) {
    yield call(clearStatus)
    yield put(setSavingOpen(true));
    yield put(setSavingStatus(true));
    const response = yield call(() => postRequest(ORDERS.UPDATE_BOX_TYPE, action.order))
    if (response.status === 200) {
        yield put(updateOrders(response.data))
        yield put(updateShipmentsOpenEditRow([response.data]))
    }
    yield put(setSavingStatus(false))
    yield put(setSavingOpen(false))
    const status = {
        status: response.status,
        statusText: response.statusText,
        success: response.status === 200,
    };
    yield put(setSavingOpen(true))
    yield put(requestStatus(status));
}

function* cancelOrder(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.CANCEL_ORDER, action.ordersToCancel));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        yield put(setOpen(true));
        yield put(requestStatus(status));
        yield call(() => fetchAllOrdersSaga({ orderStatus: UNSHIPPED, fromDate: action.fromDate, toDate: action.toDate, shipByDate: action.shipByDate, locationId: action.locationId}));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createOrder(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.CREATE_ORDER, action.newOrder));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if (response.data.success) {
            yield put(setCreateOrderOpen(true));
            yield call(() => fetchAllOrdersSaga({ orderStatus: UNSHIPPED, fromDate: action.fromDate, toDate: action.toDate, shipByDate: action.shipByDate, locationId: action.locationId}));
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* reprintOrder(action) {
    yield call(clearStatus)
    yield put(setLoadingStatus(true))
    yield put(hideError());
    try {
        for (let i = 0; i < action.ordersToPrint.length; i++) {
            const response = yield call(() => postRequest(ORDERS.REPRINT_ORDER, action.ordersToPrint[i]));
            if (response.data.success) {
                if (response.data.pdf?.length !== 0) {
                    action.downloadPdfs(response.data.pdf, response.data.shipment)
                }
                yield put(updatePrintedCount(false))
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        }

        yield put(updatePrintedCount(true))
        const today = new Date();
        yield call(() => fetchAllOrdersSaga({ orderStatus: SHIPPED, fromDate: action.fromDate, toDate: action.toDate, locationId: action.locationId }))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(setLoadingStatus(false))
    }
}

function* archiveLabel(action) {
    yield put(hideError());
    try {
        for (let i = 0; i < action.ordersToArchive.length; i++) {
            const response = yield call(() => postRequest(ORDERS.ARCHIVE_LABEL, action.ordersToArchive[i]));
            if (response.data.success) {
                const archiveStatus = {
                    success: response.data.success,
                    message: "Successfully archived",
                    severity: "success"
                }
                yield put(setArchiveStatus(archiveStatus));
                yield put(setArchiveOpen(true))

                response.data.shipment.labelArchived = true
                yield put(updateOrders(response.data.shipment))
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* voidOrder(action) {
    yield put(hideError());
    try {
        for (let i = 0; i < action.ordersToVoid.length; i++) {
            const request = { isFedex: action.ordersToVoid[i].isFedex, id: action.ordersToVoid[i].order }
            const response = yield call(() => postRequest(ORDERS.VOID_ORDER, request));
            const voidStatus = {
                success: response.data.success,
                message: response.data.message,
                severity: response.data.severity
            }
            yield put(setVoidStatus(voidStatus));
            yield put(setVoidOpen(true))
        }
        const today = new Date();
        yield call(() => fetchAllOrdersSaga({ orderStatus: SHIPPED, fromDate: action.fromDate, toDate: action.toDate, locationId: action.locationId }))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* addPackage(action) {
    yield put(loadingShipmentRequest(true))
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.ADD_PACKAGE, action.toAdd));
        if (response.status === 200) {
            if (response.data.success) {
                yield put(updateOrders(response.data.shipment))
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        }
    } catch (error) {
        yield put(loadingShipmentRequest(false))
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(loadingShipmentRequest(false))
}

function* copyPackage(action) {
    yield put(loadingShipmentRequest(true))
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.COPY_PACKAGE, action.toCopy));
        if (response.status === 200) {
            if (response.data.success) {
                yield put(updateOrders(response.data.shipment))
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        }
    } catch (error) {
        yield put(loadingShipmentRequest(false))
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(loadingShipmentRequest(false))
}

function* deletePackage(action) {
    yield put(loadingShipmentRequest(true))
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.DELETE_PACKAGE, action.toDelete));
        if (response.status === 200) {
            if (response.data.success) {
                yield put(updateOrders(response.data.shipment))
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        }
    } catch (error) {
        yield put(loadingShipmentRequest(false))
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(loadingShipmentRequest(false))
}

function* editFromAddress(action) {
    yield call(clearStatus)
    yield put(setSavingOpen(true));
    yield put(setSavingStatus(true));
    yield put(hideError());
    const response = yield call(() => postRequest(ORDERS.EDIT_FROM_ADDRESS, action.fromAddress));
    if (response.status === 200) {
        if (response.data.success) {
            yield put(updateOrders(response.data.shipment))
            yield put(updateShipmentsOpenEditRow([response.data.shipment]))
        } else {
            if (response.data.shipment) {
                yield put(updateOrders(response.data.shipment))
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            }
            yield put(showErrorMessage(response.data.message))
        }
    } else {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

    yield put(setSavingStatus(false))
    yield put(setSavingOpen(false))
    const status = {
        status: response.status,
        statusText: response.statusText,
        success: response.status === 200,
    };
    yield put(setSavingOpen(true))
    yield put(requestStatus(status));
}

function* rateShipment(action) {
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.RATE_SHIPMENT, action.shipment));
        let success = JSON.parse(response.data[0].success);
        if(!success) {
            if (response.data[0].rates[0].severity == "Failure")
                yield put(showErrorMessage(response.data[0].rates[0].message))
            else
                yield put(showErrorMessage(RATE_FAILED + response.data[0].rates[0].message))
        } else {
            action.setState(response.data);
            yield put(storeRates(response.data));
            if (response.data?.length >= 1) {
                yield put(updateShipmentsOpenEditRow(response.data))
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* trackShipment(action) {
    yield put(setRateLoading(true));
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.TRACK_SHIPMENT, action.shipment));
        if (response.status === 200) {
            yield put(storeRates(response.data));
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(setRateLoading(false))
    }
}

function* importOrders(action) {
    yield put(hideError());
    const response = yield call(() => postRequest(ORDERS.UPLOAD_SHIPMENT, action.files));
    if (response.status === 200) {
        yield put(setImportStatus(response.data.orders));
    } else {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield call(() => fetchAllOrdersSaga({ orderStatus: UNSHIPPED, fromDate: action.fromDate, toDate: action.toDate, shipByDate: action.shipByDate, locationId: action.locationId }))
}

function* rateToDefault(action) {
    yield put(hideError());
    try {
        let counter = 0
        for(let j = 0; j < action.orderIds.length; j++) {
            let orderId = []
            orderId.push(action.orderIds[j])
            const response = yield call(() => postRequest(ORDERS.RATE_SHIPMENT_DEFAULT, orderId));
            if (response.status === 200) {
                const shipments = response.data.shipments
                var  messages = response.data.messages
                yield put(updateOrdersBylist(shipments))
                if (response.data.shipments?.length >= 1) {
                    yield put(updateShipmentsOpenEditRow(response.data.shipments))
                }
                const rated = shipments.filter(shipment => {
                    return shipment.rate
                }).length
                counter += rated
                const shipmentsNumber = action.orderIds.length
                // var allMessages = ""
                // for (var i =0; i < messages.length; ++i ) {
                //     allMessages += messages[i] + "$"
                // }
                const status = {
                    status: response.status,
                    statusText: counter + " " + OF + " " + shipmentsNumber + " " + RATED + "$",
                    success: response.status === 200,
                    messages: counter == shipmentsNumber? "" : messages
                };
                yield put(requestStatus(status));
                action.setState(shipments, messages)
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        // action.setState([])
        window.location.reload()
    }
}

// debugger;
function* validateAddress(action) {
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.VALIDATE_ADDRESS, action.orderIds))
        if (response.status === 200) {
            const shipments = response.data.shipments
            const messages = response.data.messages
            yield put(updateOrdersBylist(shipments))
            yield put(updateShipmentsOpenEditRow(shipments))
            // const validated = shipments.filter(shipment => {return shipment.rate}).length
            var validated = 0
            for (var i = 0; i < shipments.length; ++i) {
                if (shipments[i].isValidated == true) {
                    validated++
                }
            }
            var allMessages = ""
            if (messages[0] == 'Not Validated') {
                allMessages += 'Not Validated' + "$"
            } else if (messages != undefined || messages != null || messages?.length > 0) {
                for (var i = 0; i < messages.length; ++i) {
                    allMessages += messages[i][0][0] + ": " + messages[i][0][3] + "$"
                }
            }
            const shipmentsNumber = action.orderIds.length
            const status = {
                status: response.status,
                statusText: validated + " " + OF + " " + shipmentsNumber + " " + VALIDATED + "$",
                success: response.status === 200,
                messages: allMessages
            };
            yield put(requestStatus(status));
                action.setState(shipments, messages)
        }
    } catch (error) {
        // action.setState([])
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        // window.location.reload()
    }
}

function* rateToCheapest(action) {
    yield put(hideError());
    try {
        let counter = 0
        for(let j = 0; j < action.orderIds.length; j++) {
            let orderId = []
            orderId.push(action.orderIds[j])
            const response = yield call(() => postRequest(ORDERS.RATE_SHIPMENT_CHEAPEST, orderId));
            const shipments = response.data.shipments
            const messages = response.data.messages
            yield put(updateOrdersBylist(shipments))
            yield put(updateShipmentsOpenEditRow(shipments))
            const rated = shipments.filter(shipment => {
                return shipment.rate
            }).length
            counter += rated
            const shipmentsNumber = action.orderIds.length
            var allMessages = ""
            if (messages != undefined || messages != null || messages?.length > 0) {
                for (var i = 0; i < messages.length; ++i) {
                    allMessages += messages[i] + "$"
                }
            }
            const status = {
                status: response.status,
                statusText: counter + " " + OF + " " + shipmentsNumber + " " + RATED + "$",
                success: response.status === 200,
                messages: allMessages
            };
            yield put(requestStatus(status));
            action.setState(shipments, messages)
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editShipmentCarrier(action) {
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.EDIT_SHIPMENT_CARRIER, {
            id: action.id,
            accountNumber: action.accountNumber,
            service: action.service,
            rateTotal: action.rateTotal,
            packageRates: action.packageRates,
            alias: action.alias
        }));
        if (response.data.success) {
            yield put(updateOrders(response.data.shipment))
            yield put(updateShipmentsOpenEditRow([response.data.shipment]))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        const status = {
            status: response.status,
            statusText: response.data.status,
            success: response.status === 200,
        };
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* addCommodity(action) {
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.ADD_COMMODITY, { shipmentId: action.shipmentId, packageId: action.packageId }));
        if (response.status === 200) {
            if (response.data.success) {
                yield put(updateOrders(response.data.shipment))
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteCommodity(action) {
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.DELETE_COMMODITY, {
            shipmentId: action.shipmentId,
            index: action.index
        }));
        if (response.status === 200) {
            if (response.data.success) {
                yield put(updateOrders(response.data.shipment))
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            } else {
                yield put(showErrorMessage(response.data.message))
            }
        }
    } catch (error) {
        action.setLoading(false)
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    action.setLoading(false)
}

function* getShipmentCommercialInvoice(action) {
    yield put(hideError());
    try {
        yield call(clearStatus)
        const response = yield call(() => postRequest(ORDERS.GET_SHIPMENT_COMMERCIAL_INVOICE, { shipmentId: action.shipmentId }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if (response.data.success) {
            yield put(storeShipmentCommercialInvoicePDF(response.data.bytes))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* markAsShipped(action) {
    yield put(hideError());
    try {
        yield put(setLoadingStatus(true))
        const response = yield call(() => postRequest(ORDERS.MARK_AS_SHIPPED,
            { shipmentIds: action.shipmentIds,  shipmentDate: action.shipmentDate, shipmentNote: action.shipmentNote}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(updateOrdersBylist(response.data.shipment))
        yield put(setLoadingStatus(false))
        yield put(requestStatus(status))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* markAsCancelled(action) {
    yield put(hideError());
    try {
        yield put(setLoadingStatus(true))
        const response = yield call(() => postRequest(ORDERS.MARK_AS_CANCELLED,
    { shipmentIds: action.shipmentIds,
            cancelDate: action.cancelDate,
            cancelNote: action.cancelNote,
            cancelTagName: action.cancelTagName}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(updateOrdersBylist(response.data.shipment))
        yield put(setLoadingStatus(false))
        yield put(requestStatus(status))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* markAsUnshipped(action) {
    try {
        yield put(setLoadingStatus(true))
        const response = yield call(() => postRequest(ORDERS.MARK_AS_UNSHIPPED, { shipmentIds: action.shipmentIds }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message))
        }
        else {
            yield put(markOrderAsUnshipped(action.shipmentIds))
        }
        yield put(setLoadingStatus(false))
        yield put(requestStatus(status))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* markAsUncancelled(action) {
    try {
        yield put(setLoadingStatus(true))
        const response = yield call(() => postRequest(ORDERS.MARK_AS_UNCANCELLED, { shipmentIds: action.shipmentIds }))
        const status = {
                status: response.status,
                statusText: response.statusText,
                success: response.status === 200,
            };
        if (!response.data.success) {
                yield put(showErrorMessage(response.data.message))
            }
        yield put(updateOrdersBylist(response.data.shipment))
        yield put(setLoadingStatus(false))
        yield put(requestStatus(status))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* markAsRedirected(action) {
    yield put(hideError());
    try {
        yield put(setLoadingStatus(true))
        const response = yield call(() => postRequest(ORDERS.MARK_AS_REDIRECTED, { shipmentIds: action.shipmentIds }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(updateOrdersBylist(response.data.shipment))
        yield put(setLoadingStatus(false))
        yield put(requestStatus(status))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createReturnShipments(action) {
    const failStatus = {
        status: 500,
        statusText: 'FAILED',
        success: false,
    }
    yield put(hideError());
    yield call(clearStatus)
    try {
        const response = yield call(() => postRequest(ORDERS.CREATE_RETURN_SHIPMENTS, action.shipments));

        if (response.data.success) {
            const status = {
                status: response.status,
                statusText: response.statusText,
                success: response.status === 200,
            };
            yield put(requestStatus(status));
            switch (action.shipmentType) {
                case ('Return'):
                    yield put(setCreateReturnOpen(true));
                    break
                case ('Cloned'):
                    yield put(setCreateClonedOpen(true));
                    break
            }
            yield put(storeShipments(action.shipmentType, response.data.shipments))
        } else {
            switch (action.shipmentType) {
                case ('Return'):
                    yield put(setCreateReturnOpen(true));
                    break
                case ('Cloned'):
                    yield put(setCreateClonedOpen(true));
                    break
            }
            yield put(requestStatus(failStatus));
        }
    } catch (error) {
        switch (action.shipmentType) {
            case ('Return'):
                yield put(setCreateReturnOpen(true));
                break
            case ('Cloned'):
                yield put(setCreateClonedOpen(true));
                break
        }
        yield put(requestStatus(failStatus));
    }
}

function* saveRedirectToOrderId(action) {
    try {
        const response = yield call(() => postRequest(ORDERS.SAVE_REDIRECT_ORDER_ID, {
            shipmentId: action.shipmentId,
            orderId: action.redirectToOrderId
        }));
        if (response.status === 200) {
            const status = {
                status: response.status,
                statusText: response.statusText,
                success: response.status === 200,
            };
            if (response.data.success) {
                yield put(storeShipments(response.data.shipment.orderStatus, [response.data.shipment]));
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            } else {
                action.setState("Order could not be found")
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteRedirectToOrderId(action) {
    try {
        const response = yield call(() => postRequest(ORDERS.DELETE_REDIRECT_ORDER_ID, { shipmentId: action.shipmentId }));
        if (response.status === 200) {
            const status = {
                status: response.status,
                statusText: response.statusText,
                success: response.status === 200,
            };
            if (response.data.success) {
                yield put(storeShipments(response.data.shipment.orderStatus, [response.data.shipment]));
                yield put(updateShipmentsOpenEditRow([response.data.shipment]))
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* openLinkedOrder(action) {
    try {
        const response = yield call(() => postRequest(ORDERS.FIND_LINKED_ORDER, { orderId: action.orderId }));
        if (response.status === 200) {
            const status = {
                status: response.status,
                statusText: response.statusText,
                success: response.status === 200,
            };
            if (response.data.success) {
                //Have to store the shipment id to open and the updated shipment then push to the correct path
                yield put(storeShipments(response.data.orderStatus, [response.data.shipment]));
                yield put(storeLinkedShipment(response.data.shipmentId))
                switch (response.data.orderStatus) {
                    case (UNSHIPPED):
                        action.setState('/shipments/pending')
                        break
                    case (RETURN):
                        action.setState('/shipments/returns')
                        break
                    case (CLONED):
                        action.setState('/shipments/cloned')
                        break
                    case (SHIPPED):
                        action.setState('/shipments/shipped')
                        break
                    case (CANCELLED):
                        action.setState('/shipments/cancelled')
                        break
                }

            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* clearLinkedShipment(action) {
    try {
        yield put(storeLinkedShipment(''))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* reprintPackage(action) {
    yield call(clearStatus)
    yield put(setLoadingStatus(true))
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(ORDERS.REPRINT_PACKAGE, action.data))
        if (response.data.success) {
            if (response.data.pdf?.length !== 0) {
                action.downloadPdfs(response.data.pdf)
            }
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(setLoadingStatus(false))
    }
}

function* fetchBuyShippingServices(action) {
    try {
        yield put(storeBuyShippingServices([], {}))
        const response = yield call(()=>postRequest(ORDERS.FETCH_BUY_SHIPPING_SERVICES, {shipmentId: action.shipmentId}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if(response.data.success){
            yield put(storeBuyShippingServices(response.data.convertedList, response.data.packageToListMap))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveBuyShippingService(action) {
    try {
        const response = yield call(()=>postRequest(ORDERS.SAVE_BUY_SHIPPING_SERVICE, {shipmentId: action.shipmentId, shippingService: action.shippingService, packageToListMap: action.packageToListMap}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if(response.data.success){
            yield put(updateOrders(response.data.shipment))
            yield put(updateShipmentsOpenEditRow([response.data.shipment]))
        }
    } catch (error) {
    }
}

function* getSuggestedBoxTypes(action) {
    try {
        const response = yield call(() => postRequest(BOX_SUGGESTIONS.getSuggestions, {packages: action.packages}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        if (status.success) {
            yield put(storeSuggestedBoxTypes(response.data))
        }
/*
        yield put(requestStatus(status))
*/
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* ignoreBoxSuggestion(action) {
    try {
        const response = yield call(() => postRequest(BOX_SUGGESTIONS.ignoreSuggestion, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        }
        if (!status.success) {
            alert("Error code: " + status.status)
        } else if (!response.data.success) {
            alert(response.data.message)
        }
    } catch (e) {
        alert("Something went wrong")
    }
}

function* getMessages(action) {
    try {
        const data = {
            fromDate: action.data.fromDate,
            toDate: action.data.toDate
        }
        const response = yield call(() => postRequest(SYSTEM_EVENT_LOG.GET_SYSTEM_LOG_EVENTS, data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        }
        if (status.success) {
            yield put(storeMessages(response.data.eventLogs));
            yield put(storeShipmentsFromMessages(response.data.shipments))
        }
    } catch(e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* getAllMessages() {
    try {
        const response = yield call(() => postRequest(SYSTEM_EVENT_LOG.GET_ALL_MESSAGES))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        }
        if (status.success) {
            yield put(storeAllMessages(response.data));
        }
    } catch(e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getMessage(action) {
    const data = {
        shipmentId: action.shipmentId
    }
    try {
        const response = yield call(() => postRequest(SYSTEM_EVENT_LOG.GET_MESSAGE, data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        }
        if (status.success) {
            if (response.data != null)
                yield put(storeMessage(response.data.eventLog));
            else
                yield put(storeMessage(null));
        }
    } catch(e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getSampleCommercialInvoicePDF(action) {
    try {
        const response = yield call(() => postRequest(ORDERS.PREVIEW_SAMPLE_COMMERCIAL_INVOICE, action.pdfData))
        yield put(storeSampleCommercialInvoicePDF(response.data.bytes))
    } catch (e) {

    }
}

function* updateAndValidateShipments(action) {
    try {
        const data = {
            fromDate: action.data.fromDate,
            toDate: action.data.toDate,
            shipmentIds: action.data.shipmentIds,
        }
        const response = yield call(() => postRequest(ORDERS.UPDATE_AND_VALIDATE_SHIPMENTS, data))
        if (response.status === 200) {
            const shipments = response.data.validatedShipments
            const messages = response.data?.messages
            yield put(updateOrdersBylist(shipments))
            yield put(updateShipmentsOpenEditRow(shipments))
            // const validated = shipments.filter(shipment => {return shipment.rate}).length
            var validated = 0
            for (var i = 0; i < shipments.length; ++i) {
                if (shipments[i].isValidated == true) {
                    validated++
                }
            }
            var allMessages = ""
            if (messages) {
                if (messages[0] == 'Not Validated') {
                    allMessages += 'Not Validated' + "$"
                } else if (messages != undefined || messages != null || messages?.length > 0) {
                    for (var i = 0; i < messages.length; ++i) {
                        allMessages += messages[i][0][0] + ": " + messages[i][0][3] + "$"
                    }
                }
            }
            if (response.data.eventLog) {
                yield put(storeMessage(response.data.eventLog));
            }

            const shipmentsNumber = action.data.shipmentIds.length
            const status = {
                status: response.status,
                statusText: validated + " " + OF + " " + shipmentsNumber + " " + VALIDATED + "$",
                success: response.status === 200,
                messages: allMessages
            };
            yield put(requestStatus(status));
            action.data.setState(shipments, messages)
        }

    } catch (e) {
        console.log(e)
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* exportShipmentSummaryToExcel(action) {
    try {
        const timestamp = new Date().toLocaleString() + ""

        var workbook = XLSX.utils.book_new()
        var worksheet = XLSX.utils.json_to_sheet(action.sortedSummary)
        worksheet['A1'].v = "Product"
        worksheet['B1'].v = "# of packages"

        XLSX.utils.book_append_sheet(workbook, worksheet, "Summary")
        XLSX.writeFile(workbook, action.shipmentStatus + " Shipment Summary (" + timestamp + ").xlsx")
    } catch (e) {
        yield put(showErrorMessage("Failed to export summary"))
    }
}

export default function* ordersSagas() {
    yield takeLatest(FETCH_ALL_ORDERS, fetchAllOrdersSaga);
    yield takeLatest(FETCH_ALL_SHIPMENTS, fetchAllShipments);
    yield takeLatest(FETCH_BY_ORDER_ID, fetchByOrderIdSaga)
    yield takeLatest(FETCH_BY_TRACKING_ID, fetchByTrackingIdSaga)
    yield takeLatest(PRINT_ORDER, printOrder);
    yield takeLatest(PRINT_TO_PDF, printToPdf)
    yield takeLatest(EDIT_ORDER, editOrder);
    yield takeLatest(EDIT_MULTIPLE_ORDER, editMultipleOrders)
    yield takeLatest(CANCEL_ORDER, cancelOrder);
    yield takeLatest(UPDATE_SHIPMENTS, updateShipments);
    yield takeLatest(CREATE_ORDER, createOrder);
    yield takeLatest(CREATE_RETURN_SHIPMENTS, createReturnShipments);
    yield takeLatest(DELETE_SHIPMENTS, deleteShipments);
    yield takeLatest(REPRINT_ORDER, reprintOrder);
    yield takeLatest(ARCHIVE_LABEL, archiveLabel);
    yield takeLatest(VOID_ORDER, voidOrder);
    yield takeLatest(ADD_PACKAGE, addPackage);
    yield takeLatest(COPY_PACKAGE, copyPackage);
    yield takeLatest(DELETE_PACKAGE, deletePackage);
    yield takeLatest(EDIT_FROM_ADDRESS, editFromAddress);
    yield takeLatest(RATE_SHIPMENT, rateShipment);
    yield takeLatestByOrder(VALIDATE_ADDRESS_SERVICE, validateAddress);
    yield takeLatest(TRACK_SHIPMENT, trackShipment);
    yield takeLatest(IMPORT_ORDERS, importOrders);
    yield takeLatestByOrder(APPLY_DEFAULT_SERVICE, rateToDefault);
    yield takeLatest(APPLY_CHEAPEST_SERVICE, rateToCheapest);
    yield takeLatest(EDIT_SHIPMENT_CARRIER, editShipmentCarrier);
    yield takeLatest(RECREATE_ORDERS, reCreate);
    yield takeLatest(ADD_COMMODITY, addCommodity);
    yield takeLatest(DELETE_COMMODITY, deleteCommodity);
    yield takeLatest(GET_SHIPMENT_COMMERCIAL_INVOICE, getShipmentCommercialInvoice);
    yield takeLatest(MARK_AS_SHIPPED, markAsShipped);
    yield takeLatest(MARK_AS_REDIRECTED, markAsRedirected);
    yield takeLatest(MARK_AS_CANCELLED, markAsCancelled);
    yield takeLatest(MARK_AS_UNCANCELLED, markAsUncancelled);
    yield takeLatest(MARK_AS_UNSHIPPED, markAsUnshipped);
    yield takeLatest(SAVE_REDIRECT_ORDER_ID, saveRedirectToOrderId)
    yield takeLatest(DELETE_REDIRECT_ORDER_ID, deleteRedirectToOrderId)
    yield takeLatest(OPEN_LINKED_ORDER, openLinkedOrder)
    yield takeLatest(CLEAR_LINKED_SHIPMENT, clearLinkedShipment)
    yield takeLatest(REPRINT_PACKAGE, reprintPackage);
    yield takeLatest(FETCH_BUY_SHIPPING_SERVICES, fetchBuyShippingServices);
    yield takeLatest(SAVE_BUY_SHIPPING_SERVICE, saveBuyShippingService);
    yield takeLatest(UPDATE_BOX_TYPE, editBoxType);
    yield takeLatest(GET_SUGGESTED_BOX_TYPES, getSuggestedBoxTypes);
    yield takeLatest(IGNORE_BOX_SUGGESTION, ignoreBoxSuggestion);
    yield takeLatest(GET_MESSAGES, getMessages);
    yield takeLatest(GET_SAMPLE_COMMERCIAL_INVOICE_PDF, getSampleCommercialInvoicePDF);
    yield takeLatest(STORE_SAMPLE_COMMERCIAL_INVOICE_PDF, storeSampleCommercialInvoicePDF);
    yield takeLatest(STORE_SHIPMENT_COMMERCIAL_INVOICE, storeShipmentCommercialInvoicePDF);
    yield takeLatest(GET_ALL_MESSAGES, getAllMessages);
    yield takeLatest(GET_MESSAGE, getMessage);
    yield takeLatest(UPDATE_AND_VALIDATE_SHIPMENTS, updateAndValidateShipments)
    yield takeLatest(EXPORT_SHIPMENT_SUMMARY_TO_EXCEL, exportShipmentSummaryToExcel)
}
