import React from "react";
import '../../pricingDashboard/pricingDashboardPages/PricingDashboardTableRow.css';
import Tooltip from "@material-ui/core/Tooltip";
import {CANCEL_EDIT_ENTRY} from "../../pricingDashboard/constants";
import EditIcon from "@material-ui/icons/Edit";
import { FormHelperText, IconButton, TableCell, TextField, Button, Checkbox } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CARRY_FORWARD_OPTIONS, EDIT_LEDGER, SOURCE_OPTIONS, SUM_RULE_OPTIONS } from './constants';
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";
import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {CANCEL, SAVE} from "../../global/constants";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteIcon from '@material-ui/icons/Delete';



class LedgerEntryTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            deleteId: "",
            isDeleted: false,
            open: false,
            isDialogOpen: false,
            deleteTransactionId: "",
        };
    }

    componentDidMount() {
        let newState = {
            deleteListing: false,
            description: '',
            credit: '0.00',
            source: '',
            category: '',
            carryForwardBalance: 'No',
            sumRule: '',
        }
        this.props.setRowState(newState)


    }

    handleOpen = () => {
        this.setState({ isDialogOpen: true });
    };

    handleClose = () => {
        this.setState({ isDialogOpen: false });
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listingIdBeingEdited !== this.props.listingIdBeingEdited) {
            if (this.props.listingIdBeingEdited === this.props.row.transactionId) {
                this.props.setRowState({
                    transactionId: this.props.row.transactionId,
                    description: this.props.row.description,
                    credit: this.props.row.credit,
                    source: this.props.row.source,
                    category: this.props.row.category,
                    carryForwardBalance: this.props.row.carryForwardBalance || 'No',
                    sumRule: this.props.row.sumRule,

                })
            }
        }
    }


    handleCancel = (row) => {
        if (row.transactionId === '') {
            const newLedgers = this.props.allLedgers.filter(ledger => ledger.transactionId !== '');

            // Update the ledger state with the filtered list
            this.setState(prevState => {
                this.props.setLedgerEntries(newLedgers);
            });
        }

        this.props.setListingIdBeingEdited("");
        this.props.setRowState({
            transactionId: '',
            description: '',
            credit: '',
            source: '',
            category: '',
            carryForwardBalance: '',
            sumRule: '',
        });

    }


    handleConfirm = (row) => {

        let state = this.props.getRowState()

        if (!state.description || !state.carryForwardBalance) {
            return;
        }

        this.props.setListingIdBeingEdited("")
        const request = {
            source: state.source,
            category: state.category,
            carryForwardBalance: state.carryForwardBalance,
            sumRule: state.sumRule,
            transactionId: row.transactionId,
            description: state.description,
            balance: String(state.credit).replace(/,/g, ''),
            ledgerId: this.props.ledgerId,
            currency: this.props.currency,
            year: this.props.year,
        };
        this.props.setRowState({
            description: '',
            credit: '',
        })

      this.props.updateEntry(request);
    }


    handleDeleteEntry = () => {


        const data = {
            ledgerId: this.props.ledgerId,
            transactionId: this.state.deleteId,
            year: this.props.year,
            currency: this.props.currency,
        };


        this.setState({ isDialogOpen: false, deleteId: ""},()=>{
           this.props.deleteEntry(data);
        });
    }


    getEditingCancelConfirm = (row) => {
        return <>
            <div style={{display:"flex", justifyContent:"left"}}>
                <Tooltip title={CANCEL_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleCancel(row)}>
                        <ClearIcon
                            fontSize='small'
                            color='error'
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </>
    }



    formatBalance = (balance) => {
        const validBalance = parseFloat(balance);
        if (isNaN(validBalance)) {
            return '0.00';
        }
        return validBalance
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    };

    onBlurHandle = (name, value) => {

        const formattedValue = this.formatBalance(value);
        if (name === 'credit') {
            this.props.setRowState({ credit: formattedValue });
        } else if (name === 'balance') {
            this.props.setRowState({ balance: formattedValue });
        }
    };

    displayBalanceEditingTextField = () => {
        const state = this.props.getRowState();

        return (
          <TextField
            style={{ marginLeft: "-8px", padding: "0" }}
            className="full-width-ledger"
            variant="outlined"
            name={state.row === "Opening Balance" ? "balance" : "credit"}
            label="Amount"
            value={state.row === "Opening Balance" ? state.balance : state.credit || ''}
            onBlur={(e) => this.onBlurHandle(e.target.name, e.target.value)}
            onChange={(e) => {
                const value = e.target.value.replace(/,/g, '');
                if (state.row === "Opening Balance") {
                    this.props.setRowState({ balance: value });
                } else {
                    this.props.setRowState({ credit: value });
                }
            }}
            onPaste={(e) => {
                e.preventDefault(); // Prevent default paste action
                const pastedValue = e.clipboardData.getData('Text').replace(/,/g, ''); // Remove commas
                if (state.row === "Opening Balance") {
                    this.props.setRowState({ balance: pastedValue });
                } else {
                    this.props.setRowState({ credit: pastedValue });
                }
            }}
            error={
              state.row !== "Opening Balance" &&
              (state.credit === '' || state.credit === null || state.credit === undefined)
            }
          />
        );
    };


    displayNameEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            style={{marginLeft:"-8px",padding:"0"}}
            className="full-width-ledger"
            variant="outlined"
            name="description"
            label="Name"
            value={state.description}
            onChange={(e) => this.props.setRowState({description: e.target.value})}
            error={state.description === '' || state.description === null || state.description === undefined}
        />
    }

    displayCategoryEditingDropdown = () => {
        let state = this.props.getRowState();

        if (state.source === 'Transactions') {
            return (
              <Autocomplete

                className="full-width-ledger-category"
                multiple
                open={this.state.open}
                value={
                    Array.isArray(state.category)
                      ? state.category.map((cat) =>
                        this.props.categories.find((category) => category.name === cat) // Find category objects from names
                      ).filter(Boolean) // Ensure no undefined values
                      : [] // Fallback to an empty array if state.category is not an array
                }
                options={(this.props.categories || []).sort((a, b) => a.displayOrder - b.displayOrder)} // Sorting categories
                getOptionLabel={(option) => option.name || ''}
                autoHighlight={true}
                renderTags={(value) => {
                    if (value.length > 1) {
                        return (
                          <div style={{ width:'60px', display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'center' }}>

                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label={value[0].name} // Display the first category
                              />
                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label="..." // Display "..." for additional categories
                              />

                          </div>
                        );
                    }
                    return value.map((option) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                        label={option.name}
                      />
                    ));
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                  </React.Fragment>
                )}
                onOpen={() => { this.setState({ open: true }) }}
                onClose={(event, reason) => {
                    reason === 'blur' ? this.setState({ open: false }) : this.setState({ open: true })
                }}
                name="category"
                onChange={(event, value) => {
                    this.props.setRowState({
                        category: value.map((cat) => cat?.name), // Store just the category names (strings) in the state
                    })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="category"
                  />
                )}
              />

            );
        }

        return null;
    };

    displayLedgersEditingDropdown = () => {
        let state = this.props.getRowState();

        if (state.source === 'Ledgers') {
            return (
              <Autocomplete

                className="full-width-ledger-category"
                multiple
                open={this.state.open}
                value={
                    Array.isArray(state.category)
                      ? state.category.map((cat) =>
                        this.props.ledgerCategories.find((ledger) => ledger.name === cat) // Find category objects from names
                      ).filter(Boolean) // Ensure no undefined values
                      : [] // Fallback to an empty array if state.category is not an array
                }

                options={(this.props.ledgerCategories || [])
                  .filter(ledger => ledger.isShowTrialBalance === false)
                  .sort((a, b) => a.displayOrder - b.displayOrder)}
                getOptionLabel={(option) => option.name || ''}
                autoHighlight={true}
                renderTags={(value) => {
                    if (value.length > 1) {
                        return (
                          <div style={{ width:'60px', display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'center' }}>

                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label={value[0].name} // Display the first category
                              />
                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label="..." // Display "..." for additional categories
                              />

                          </div>
                        );
                    }
                    return value.map((option) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                        label={option.name}
                      />
                    ));
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                  </React.Fragment>
                )}
                onOpen={() => { this.setState({ open: true }) }}
                onClose={(event, reason) => {
                    reason === 'blur' ? this.setState({ open: false }) : this.setState({ open: true })
                }}
                name="category"
                onChange={(event, value) => {
                    this.props.setRowState({
                        category: value.map((cat) => cat?.name), // Store just the category names (strings) in the state
                    })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="category"
                  />
                )}
              />

            );
        }

        return null;
    };

    displaySourceEditingDropdown = () => {
        let state = this.props.getRowState();
        return (
          <Autocomplete
            className="full-width-ledger"
            options={SOURCE_OPTIONS}
            getOptionLabel={(option) => option}
            value={state.source || null}
            onChange={(e,value) => this.props.setRowState({ source: value })}
            renderInput={(params) =>
              <TextField
                {...params}
                name="source"
                label="Source"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            }
          />
        );
    }

    displaySumRuleEditingDropdown = () => {
        let state = this.props.getRowState();

        // Only render the dropdown if state.source is 'transactions'
        if (state.source === 'Transactions') {
            return (
              <Autocomplete
                className="full-width-ledger"
                options={SUM_RULE_OPTIONS}
                getOptionLabel={(option) => option}
                value={state.sumRule || null}
                onChange={(e, value) => this.props.setRowState({ sumRule: value })}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    name="sumRule"
                    label="Sum Rule"
                    variant="outlined"
                    required={true}
                    error={state?.sumRule === '' || state?.sumRule === null || state?.sumRule === undefined}
                    InputLabelProps={{ shrink: true }}
                  />
                }
              />
            );
        }

        // Return null or an empty fragment if the condition is not met
        return null;
    }

    displayCarryForwardBalanceEditingDropdown = () => {

        let state = this.props.getRowState();
        return (
          <Autocomplete
            className="full-width-ledger"
            options={CARRY_FORWARD_OPTIONS}
            getOptionLabel={(option) => option}
            value={state.carryForwardBalance || null}

            onChange={(e, value) => this.props.setRowState({ carryForwardBalance: value })}
            renderInput={(params) =>
              <TextField
                {...params}
                name="carryForwardBalance"
                label="Carry Forward Balance"
                variant="outlined"
                required={true}
                error={state.carryForwardBalance === '' || state.carryForwardBalance === null || state.carryForwardBalance === undefined}
                InputLabelProps={{ shrink: true }}
              />
            }
          />
        );
    }



    displayEditingRow = (row) => {
        let state = this.props.getRowState();


        if (row.description === "Accounts Payable" ||
          row.description === "Sales" ||
          row.description === "Accounts Receivables" ||
          row.description === "Cost of Sales") {
            return null
        }

        return (
          <React.Fragment>
            <TableCell
              style={{
                width: '70px',
                height: '77px',
                padding: '0',
                margin: '0',
              }}
            >
              {this.getEditingCancelConfirm(row)}
            </TableCell>

            <TableCell>
              <div className="rowSpecLedger">
                {this.displayNameEditingTextField()}
                {this.displaySourceEditingDropdown()}
                {this.displayCategoryEditingDropdown()}
                  {this.displayLedgersEditingDropdown()}
                {this.displaySumRuleEditingDropdown()}
                {this.displayCarryForwardBalanceEditingDropdown()}
              </div>
            </TableCell>

            <TableCell>
              <div className="rowSpecLedger">
                {(state.source === 'Transactions' || state.source === 'Ledgers')? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ flexShrink: 0 }}>$</span>
                    <span
                      style={{
                        marginLeft: '55px',
                        minWidth: '80px',
                        textAlign: 'right',
                      }}
                    >
                   {state.row === "Opening Balance" ?  this.formatBalance(state.balance) :  this.formatBalance(state.credit)  || ''}
                    </span>
                  </div>
                ) : (
                  this.displayBalanceEditingTextField()
                )}
              </div>
            </TableCell>

            <TableCell>
              <Tooltip title="Save Entry">
                <IconButton onClick={() => this.handleConfirm(row)}>
                  <CheckCircleIcon fontSize="small" color="secondary" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </React.Fragment>
        );
    }

    displayNonEditingRow = (row) => {

        if (row.description === "Accounts Payable" ||
          row.description === "Accounts Receivables"  ||
          row.description === "Sales"  ||
          row.description === "Cost of Sales") {
            return null
        }

        return (
            <React.Fragment>
                {this.props.isEditPayable && row.description !== "Accounts Payable" ? (
                    <TableCell>
                        <Tooltip title={EDIT_LEDGER}>
                            <IconButton
                                onClick={() => {
                                    this.props.setHideFilterBox(true);
                                    this.props.setListingIdBeingEdited(row.transactionId);
                                }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                ) : (
                  <TableCell>
                      <IconButton  style={{ visibility: 'hidden' }}
                      >
                          <DeleteIcon fontSize="small" />
                      </IconButton>
                  </TableCell>
                )}


                <TableCell style={{ textAlign: "right", padding: "5px", paddingRight: "50px", width: "100%"}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        {row.description}
                    </div>
                </TableCell>


                <TableCell style={{  textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto"}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <span style={{flexShrink: 0}}>$</span>
                        <span style={{marginLeft: '55px', minWidth: '80px', textAlign: 'right'}}>
                 {row.description === "Opening Balance" ? this.formatBalance(row.balance) :  this.formatBalance(row.credit)}
                </span>
                    </div>
                </TableCell>


                {this.props.isEditPayable && row.description !== "Accounts Payable" ? (
                  <TableCell>
                      <Tooltip title="Delete Entry">
                          <IconButton
                            onClick={() => {
                                this.setState({
                                    isDeleted: true,
                                    deleteId: row.transactionId,
                                },()=>{
                                    this.handleOpen()
                                });

                            }}
                          >
                              <DeleteIcon fontSize="small" />
                          </IconButton>
                      </Tooltip>

                  </TableCell>

                ) : (
                  <TableCell>
                      <IconButton  style={{ visibility: 'hidden' }}
                      >
                          <DeleteIcon fontSize="small" />
                      </IconButton>
                  </TableCell>
                )}
            </React.Fragment>
        );
    };


    displayListingRow = (listingIdBeingEdited) => {
        if (listingIdBeingEdited === this.props.row.transactionId) {
            return this.displayEditingRow(this.props.row)
        } else {
            return <>
                {this.displayNonEditingRow(this.props.row)}
            </>
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog open={this.state.isDialogOpen} onClose={this.handleClose}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this entry?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <div className='save-cancel-buttons'>
                            <Button
                                variant='contained'
                                onClick={this.handleClose}
                            >
                                {CANCEL}
                            </Button>
                            <div className='save-spacing'/>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={this.handleDeleteEntry}
                            >
                                Delete
                            </Button>
                        </div>

                    </DialogActions>
                </Dialog>
                {this.displayListingRow(this.props.listingIdBeingEdited)}
            </React.Fragment>
        )
    }
}


export default LedgerEntryTableRow;