import React from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// custom components
import ConditionDialog from './ConditionDialog';
import FilterBooleanSelect from './ConditionFilters/FilterBooleanSelect';
import FilterBooleanPredefinedSelect from './ConditionFilters/FilterBooleanPredefinedSelect';
import FilterNumericField from './ConditionFilters/FilterNumericField';
import FilterNumericWithPredefined from './ConditionFilters/FilterNumericWithPredefined';
import FilterTextFieldWithPredefined from './ConditionFilters/FilterTextFieldWithPredefined';
import FilterTextField from './ConditionFilters/FilterTextField';
import ActionBoolean from "./ConditionActions/ActionBoolean";
import ActionDropdown from "./ConditionActions/ActionDropdown";
import ActionDropdownsAndInput from "./ConditionActions/ActionDropdownsAndInput";
import ActionDoubleDropdown from "./ConditionActions/ActionDoubleDropdown";
import ActionText from "./ConditionActions/ActionText";
import ActionNumericDropdown from "./ConditionActions/ActionNumericDropdown";
import SingleNumericalAction from "./ConditionActions/SingleNumericalAction";
import DoubleNumericalAction from "./ConditionActions/DoubleNumericalAction";
import TripleNumericalAction from "./ConditionActions/TripleNumericalAction";
import DragAndDrop from "../global/Dnd/DragAndDrop";

// material components
import {
  Paper,
  Switch,
  FormControlLabel,
  Tooltip,
  IconButton,
  Divider,
  Toolbar,
  Button
} from '@material-ui/core';

// material icons
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

// import constants
import {
  ADD_FILTER,
  ADD_ACTION,
  DELETE,
  EXIT_ON_MATCH,
  CONTINUE_ON_MATCH,
  INFO_ON_EXIT_OPTION,
  INFO_ON_CONTINUE_OPTION,
  INFO_ON_DEFAULT_BEHAVIOUR,
  ANY_ALL_PART_1,
  ANY_ALL_PART_2A,
  ANY_ALL_PART_2B,
  ANY_ALL_PART_3,
  FILTER_TYPE_BOOLEAN_1,
  FILTER_TYPE_BOOLEAN_2,
  FILTER_TYPE_NUMERIC,
  FILTER_TYPE_TEXT,
  TABLE_TYPES,
  UP,
  DOWN,
  COPY_RULE, DELETE_CONDITION, COPY_CONDITION,
} from './constants';
import {
  COMPONENT_TYPES, SET_NUMBER_OF_PACKAGES
} from "./ConditionActions/constants";

// importing styling
import './AutomationRulesStepper.css';
import PropTypes from "prop-types";
import ConditionFiltersRulesTable from "./ConditionFilters/ConditionFiltersTable";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PackageActions from "./ConditionActions/PackageActions";
import {
  FILTER_TYPE_MATCH_CATEGORY,
  FILTER_TYPE_NUMERIC_PREDEFINED, FILTER_TYPE_TEXT_PREDEFINED
} from "./ConditionFilters/constants";
import FilterMatchCategory from "./ConditionFilters/FilterMatchCategory";


/**
 * Automations Rules Condition
 */
class AutomationRulesCondition extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      conditionId: '',
      exitOnMatch: true,
      applyAllFilters: false,
      filters: [],
      actions: {
        prePackageActions: [],
        packageActions: [],
        postPackageActions: [],
        listingsActions: [],
        transactionsActions:[],
        pricingActions: []
      },
      isFilterDialogOpen: false,
      isActionDialogOpen: false,
      hasSetPackageNumber: false,
      hasPredefinedPackageValue: false,
      numberOfPackages: 1,
      numberOfTabs: 1,
    };
  }

  componentDidMount = () => {
    this.setState({
      // on component mount, the parent component stores this child's state.
      // We restore it here
      ...this.props.condition,
    });
    if (this.props.condition.actions) {
      const setNumberOfPackagesAction = this.props.condition.actions.prePackageActions.find(action => action.name === SET_NUMBER_OF_PACKAGES)
      if (setNumberOfPackagesAction) {
        this.setState({
          hasPredefinedPackageValue: setNumberOfPackagesAction.preDefinedValue === "null",
          hasSetPackageNumber: true
        })
      }
    }
  };

  handleContinueOnMatch = (event) => {
    this.setState(
      {
        exitOnMatch: event.target.checked,
      },
      () => {
        this.props.updateConditionWithParent({ ...this.state });
      }
    );
  };

  updateFilter = (newFilter) => {
    const index = this.state.filters.findIndex(
      (filter) => filter.filterId === newFilter.filterId
    );

    if (index !== -1) {
      const filters = [...this.state.filters];
      filters.splice(index, 1, { ...newFilter });
      this.setState(
        {
          filters,
        },
        () => {
          // after we update the filters we also have to update the parent since the state changed
          this.props.updateConditionWithParent({ ...this.state });
        }
      );
    }
  };

  updateFilters = (newFilters) => {
    this.setState(
        {
          filters: newFilters,
        },
        () => {
          // after we update the filters we also have to update the parent since the state changed
          this.props.updateConditionWithParent({ ...this.state });
        }
    );
  };

  deleteFilter = (filterId) => {
    const index = this.state.filters.findIndex((filter) => filter.filterId === filterId);
    if (index !== -1) {
      const filters = [...this.state.filters];
      filters.splice(index, 1);
      this.setState(
        {
          filters,
        },
        () => {
          // after we update the filters we also have to update the parent since the state changed
          this.props.updateConditionWithParent({ ...this.state });
        }
      );
    }
  };

  updateAction = (newAction) => {
    const index = this.state.actions[newAction.step].findIndex(
        (action) => action.actionId === newAction.actionId
    );
    if (newAction.name === SET_NUMBER_OF_PACKAGES) {
      if (newAction.preDefinedValue !== "Order item quantity") {
        this.setState({
          hasPredefinedPackageValue: true,
          numberOfPackages: newAction.value
        })
      } else {
        this.setState({
          hasPredefinedPackageValue: false,
          numberOfPackages: 1
        })
      }
    }

    if (index !== -1) {
      const actions = [...this.state.actions[newAction.step]];
      actions.splice(index, 1, { ...newAction });
      let newActions = {...this.state.actions}
      newActions[newAction.step] = actions
      this.setState(
          {
            actions: newActions
          },
          () => {
            // after we update the filters we also have to update the parent since the state changed
            this.props.updateConditionWithParent({ ...this.state });
          }
      );
    }
  }

  updateActions = (newActions) => {
    let actions = {...this.state.actions}
    // actions.packageActions = newActions
    if (newActions.length > 0 && newActions[0].step === "packageActions") {
      actions.packageActions = actions.packageActions.filter(action => action.package !== newActions[0].package)
      actions.packageActions = actions.packageActions.concat(newActions)
    } else {
      actions[newActions[0].step] = newActions
    }
    this.setState(
        {
          actions: actions,
        },
        () => {
          // after we update the filters we also have to update the parent since the state changed
          this.props.updateConditionWithParent({ ...this.state });
        }
    );
  }

  deleteAction = (actionId) => {
    let index = this.state.actions.prePackageActions.findIndex(filter => filter.actionId === actionId)
    let action = this.state.actions.prePackageActions.find(filter => filter.actionId === actionId)
    if (index === -1) {
      index = this.state.actions.packageActions.findIndex(filter => filter.actionId === actionId)
      action = this.state.actions.packageActions.find(filter => filter.actionId === actionId)
    }
    if (index === -1) {
      index = this.state.actions.postPackageActions.findIndex(filter => filter.actionId === actionId)
      action = this.state.actions.postPackageActions.find(filter => filter.actionId === actionId)
    }
    if (index === -1) {
      index = this.state.actions.listingsActions.findIndex(filter => filter.actionId === actionId)
      action = this.state.actions.listingsActions.find(filter => filter.actionId === actionId)
    }
    if (index === -1) {
      index = this.state.actions.transactionsActions.findIndex(filter => filter.actionId === actionId)
      action = this.state.actions.transactionsActions.find(filter => filter.actionId === actionId)
    }
    if (index === -1) {
      index = this.state.actions.pricingActions.findIndex(filter => filter.actionId === actionId)
      action = this.state.actions.pricingActions.find(filter => filter.actionId === actionId)
    }
    if (action.name === SET_NUMBER_OF_PACKAGES) {
      this.setState({
        hasPredefinedPackageValue: false,
        hasSetPackageNumber: false,
        numberOfPackages: 1
      })
    }
    if (index !== -1) {
      const actions = [...this.state.actions[action.step]];
      actions.splice(index, 1);
      let newActions = {...this.state.actions}
      newActions[action.step] = actions
      this.setState({
        actions: newActions,
      },() => {
        this.props.updateConditionWithParent({...this.state})
      })
    }
  }

  deleteActions = (actionIds) => {
    const actions = {...this.state.actions};
    actionIds.forEach((actionId) => {
      let index = this.state.actions.prePackageActions.findIndex(filter => filter.actionId === actionId)
      let action = this.state.actions.prePackageActions.find(filter => filter.actionId === actionId)
      if (index === -1) {
        index = this.state.actions.packageActions.findIndex(filter => filter.actionId === actionId)
        action = this.state.actions.packageActions.find(filter => filter.actionId === actionId)
      }
      if (index === -1) {
        index = this.state.actions.postPackageActions.findIndex(filter => filter.actionId === actionId)
        action = this.state.actions.postPackageActions.find(filter => filter.actionId === actionId)
      }
      if (index === -1) {
        index = this.state.actions.listingsActions.findIndex(filter => filter.actionId === actionId)
        action = this.state.actions.listingsActions.find(filter => filter.actionId === actionId)
      }
      if (index === -1) {
        index = this.state.actions.transactionsActions.findIndex(filter => filter.actionId === actionId)
        action = this.state.actions.transactionsActions.find(filter => filter.actionId === actionId)
      }
      if (index === -1) {
        index = this.state.actions.pricingActions.findIndex(filter => filter.actionId === actionId)
        action = this.state.actions.pricingActions.find(filter => filter.actionId === actionId)
      }
      if (index !== -1) {
        actions[action.step].splice(index, 1);
      }
      if (action.name === SET_NUMBER_OF_PACKAGES) {
        this.setState({
          hasPredefinedPackageValue: false,
          hasSetPackageNumber: false,
          numberOfPackages: 1
        })
      }
    })
    this.setState({
      actions: actions,
    },() => {
      this.props.updateConditionWithParent({...this.state})
    })
  }

  handleAnyOrAllToggle = (event) => {
    this.setState(
      {
        applyAllFilters: event.target.checked,
      },
      () => {
        this.props.updateConditionWithParent({ ...this.state });
      }
    );
  };

  /**
   * Invoked when the apply button is clicked on the ConditionDialog
   */
  onFilterApply = (filters, copyFilters = false) => {
    // iterate over the filters that are being added and assign an filterId property
    const setUUIDs = (filterUUIDs) => {
      var count = 0
      let newFilters = []
      filters.forEach((item) => {
        // FILTER_TYPE_BOOLEAN_1 types only have value while others have value1 and value2
        if(filterUUIDs.length > count){
          if (!copyFilters) {
            let filter = item.type === FILTER_TYPE_BOOLEAN_1
                ? { ...item, filterId: filterUUIDs[count], value: '' }
                : { ...item, filterId: filterUUIDs[count], value1: '', value2: '', value3: '', preDefinedValue: '' }

            newFilters.push(filter)
          } else {
            let filter = { ...item, filterId: filterUUIDs[count]}
            newFilters.push(filter)
          }
        }
        count++
      })

      this.setState(
        {
          isFilterDialogOpen: false,
          filters: [...this.state.filters, ...newFilters],
        },
        () => {
          this.props.updateConditionWithParent({ ...this.state });
        }
      );
    }
    this.props.getUUIDs("filter", filters.length, setUUIDs)
  };

  onActionsApply = (actions, copyActions = false) => {
    const setUUIDs = (actionUUIDs) => {
      let count = 0
      let newActions = {
        prePackageActions: [],
        packageActions: [],
        postPackageActions: [],
        listingsActions: [],
        transactionsActions: [],
        pricingActions: []
      }
      actions.forEach(item => {
        if(actionUUIDs.length > count){
          if (item.name === SET_NUMBER_OF_PACKAGES) {
            this.setState({
              hasSetPackageNumber: true
            })
          }
          if (!copyActions) {
            switch (item.type) {
              case COMPONENT_TYPES.BOOLEAN_1:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                  value: true,
                  package: -1
                })
                break
              case COMPONENT_TYPES.NUMERICAL_INPUT_2:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                  value1: '',
                  value2: '',
                  package: -1
                })
                break
              case COMPONENT_TYPES.DROPDOWN_2:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                  value1: '',
                  value2: '',
                  package: -1
                })
                break
              case COMPONENT_TYPES.NUMERICAL_INPUT_3:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                  value1: '',
                  value2: '',
                  value3: '',
                  package: -1
                })
                break
              default:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                  value: '',
                  package: -1
                })
            }
          } else {
            switch (item.type) {
              case COMPONENT_TYPES.BOOLEAN_1:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                })
                break
              case COMPONENT_TYPES.NUMERICAL_INPUT_2:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                })
                break
              case COMPONENT_TYPES.DROPDOWN_2:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                })
                break
              case COMPONENT_TYPES.NUMERICAL_INPUT_3:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                })
                break
              default:
                newActions[item.step].push({
                  ...item,
                  actionId: actionUUIDs[count],
                })
            }
          }
        }
        count++
      })
      newActions.prePackageActions = [...this.state.actions.prePackageActions].concat(newActions.prePackageActions)
      newActions.packageActions = [...this.state.actions.packageActions].concat(newActions.packageActions)
      newActions.postPackageActions = [...this.state.actions.postPackageActions].concat(newActions.postPackageActions)
      newActions.listingsActions = [...this.state.actions.listingsActions].concat(newActions.listingsActions)
      newActions.transactionsActions = [...this.state.actions.transactionsActions].concat(newActions.transactionsActions)
      newActions.pricingActions = [...this.state.actions.pricingActions].concat(newActions.pricingActions)
      this.setState(
        {
          isActionDialogOpen: false,
          actions: newActions,
        },
        () => {
          this.props.updateConditionWithParent({ ...this.state });
        }
      );
    }
    this.props.getUUIDs("action", actions.length, setUUIDs)
  }

  showFilterDialog = (show) => {
    this.setState({
      isFilterDialogOpen: show,
    });
  };

  showActionDialog = (show) => {
    this.setState({
      isActionDialogOpen: show,
    })
  }

  copyFilter = (filterId) => {
    //Get filter from filterId and just copy it
    let filterToCopy = this.state.filters.find(filter => filter.filterId === filterId)
    let filterCopy = filterToCopy.type === FILTER_TYPE_BOOLEAN_1
        ? { ...filterToCopy, filterId: '', value: filterToCopy.value }
        : { ...filterToCopy, filterId: '', value1: filterToCopy.value1, value2: filterToCopy.value2, value3 : filterToCopy?.value2, preDefinedValue : filterToCopy?.preDefinedValue}
    this.onFilterApply([filterCopy], true)

  }
  checkInvoiceActions = () => {
    return this.state.filters.some(filter =>
        filter.name === 'Amount' &&
        filter.preDefinedValue && filter.preDefinedValue !== '' && filter.preDefinedValue !== '-' &&
        filter.preDefinedValue2 && filter.preDefinedValue2 !== '' &&
        filter.preDefinedValue3 && filter.preDefinedValue3 !== ''
    );
  }


  /**
   * Dynamically create the tags
   */
  render() {
    const displayInvoiceActions = this.checkInvoiceActions();
    return (
      <div>
        <ConditionDialog
          isOpen={this.state.isFilterDialogOpen}
          onClose={this.showFilterDialog}
          onApply={this.onFilterApply}
          tableType={TABLE_TYPES.FILTER}
          ruleCategory={this.props.ruleCategory}
        />
        <ConditionDialog
            isOpen={this.state.isActionDialogOpen}
            onClose={this.showActionDialog}
            onApply={this.onActionsApply}
            tableType={TABLE_TYPES.ACTION}
            hasSetPackageNumber={this.state.hasSetPackageNumber}
            ruleCategory={this.props.ruleCategory}
            showInvoiceActions={displayInvoiceActions}
        />
        <Paper className='automationRulesPaper' variant='outlined' style={{minWidth:950}}>
          <Toolbar className='conditionsToolbar'>
            <div className='conditionsToolbarDiv'>
              <Tooltip className='conditionIcons' title={DOWN}>
                <IconButton
                    aria-label='move down'
                    disabled={this.props.index === this.props.totalConditions - 1}
                    onClick={() => this.props.moveCondition(this.state.conditionId, DOWN)}
                >
                  <ArrowDownwardIcon variant='outlined' fontSize='small'/>
                </IconButton>
              </Tooltip>
              <Tooltip className='upArrow conditionIcons' title={UP}>
                <IconButton
                    aria-label='move up'
                    disabled={this.props.index === 0}
                    onClick={() => this.props.moveCondition(this.state.conditionId, UP)}
                >
                  <ArrowUpwardIcon variant='outlined' fontSize='small'/>
                </IconButton>
              </Tooltip>
              <Tooltip className='conditionIcons' title={DELETE_CONDITION}>
                <IconButton
                  aria-label={DELETE_CONDITION}
                  disabled={this.props.totalConditions === 1}
                  onClick={() => this.props.deleteCondition(this.state.conditionId)}
                >
                  <DeleteOutlineIcon variant='outlined' fontSize='small' />
                </IconButton>
              </Tooltip>
              <Tooltip className='copyButton conditionIcons' title={COPY_CONDITION}>
                <IconButton
                    aria-label={COPY_CONDITION}
                    onClick={() => this.props.copyCondition(this.state.conditionId)}>
                  <FileCopyIcon fontSize='small'/>
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <div>
            <DndProvider backend={HTML5Backend}>
              <DragAndDrop
                cards={this.state.filters}
                handleTableDisplayChange = {this.updateFilters}
                items = {this.state.filters.map((filter, i) => {
                switch (filter.type) {
                  case FILTER_TYPE_BOOLEAN_1:
                    return ({
                      id: filter.filterId,
                      component: <div>
                      <FilterBooleanSelect
                          filter={filter}
                          updateFilterWithParent={this.updateFilter}
                          deleteFilter={this.deleteFilter}
                          isEdit={this.props.isEdit}
                          copyFilter={this.copyFilter}
                          key={filter.filterId}
                      />
                    </div>
                    });
                  case FILTER_TYPE_BOOLEAN_2:
                    return ({
                      id: filter.filterId,
                      component: <div>
                        <FilterBooleanPredefinedSelect
                            filter={filter}
                            ruleCategory={this.props.ruleCategory}
                            updateFilterWithParent={this.updateFilter}
                            deleteFilter={this.deleteFilter}
                            isEdit={this.props.isEdit}
                            copyFilter={this.copyFilter}
                            key={filter.filterId}
                        />
                      </div>
                    });
                  case FILTER_TYPE_NUMERIC:
                    return ({
                      id: filter.filterId,
                      component: <div>
                      <FilterNumericField
                          filter={filter}
                          updateFilterWithParent={this.updateFilter}
                          deleteFilter={this.deleteFilter}
                          isEdit={this.props.isEdit}
                          copyFilter={this.copyFilter}
                          key={filter.filterId}
                      />
                    </div>
                    });
                  case FILTER_TYPE_TEXT_PREDEFINED:
                    return ({
                      id: filter.filterId,
                      component: <div>
                        <FilterTextFieldWithPredefined
                            filter={filter}
                            ruleCategory={this.props.ruleCategory}
                            updateFilterWithParent={this.updateFilter}
                            deleteFilter={this.deleteFilter}
                            isEdit={this.props.isEdit}
                            copyFilter={this.copyFilter}
                            key={filter.filterId}
                        />
                      </div>
                    });
                  case FILTER_TYPE_NUMERIC_PREDEFINED:
                    return ({
                      id: filter.filterId,
                      component: <div>
                        <FilterNumericWithPredefined
                            filter={filter}
                            updateFilterWithParent={this.updateFilter}
                            deleteFilter={this.deleteFilter}
                            isEdit={this.props.isEdit}
                            copyFilter={this.copyFilter}
                            key={filter.filterId}
                        />
                      </div>
                    });
                  case FILTER_TYPE_TEXT:
                    return ({
                      id: filter.filterId,
                      component:<div>
                    <FilterTextField
                        filter={filter}
                        updateFilterWithParent={this.updateFilter}
                        deleteFilter={this.deleteFilter}
                        isEdit={this.props.isEdit}
                        copyFilter={this.copyFilter}
                        key={filter.filterId}
                    />
                  </div>
                    });
                  case FILTER_TYPE_MATCH_CATEGORY:
                    return ({
                      id: filter.filterId,
                      component: <div>
                        <FilterMatchCategory
                            filter={filter}
                            updateFilterWithParent={this.updateFilter}
                            deleteFilter={this.deleteFilter}
                            isEdit={this.props.isEdit}
                            copyFilter={this.copyFilter}
                            key={filter.filterId}
                            getStateAndProvinceMapping={this.props.getStateAndProvinceMapping}
                            stateAndProvinceMapping={this.props.stateAndProvinceMapping}
                        />
                      </div>
                    });
                }
              })}
                />
            </DndProvider>
            <Button
              className='automationRulesFilterButton'
              variant='contained'
              color='primary'
              startIcon={<AddIcon />}
              onClick={() => this.showFilterDialog(true)}
            >
              {ADD_FILTER}
            </Button>
          </div>
          <div>
            <FormControlLabel
              className='anyAllSwitch'
              label={
                ANY_ALL_PART_1 +
                ' ' +
                (this.state.applyAllFilters ? ANY_ALL_PART_2B : ANY_ALL_PART_2A) +
                ' ' +
                ANY_ALL_PART_3
              }
              control={
                <Switch
                  color='primary'
                  checked={this.state.applyAllFilters}
                  onChange={this.handleAnyOrAllToggle}
                />
              }
            />
          </div>

          <Divider className='divider' />
          <div>
            <DndProvider backend={HTML5Backend}>
              <DragAndDrop
                  cards={this.state.actions.prePackageActions}
                  handleTableDisplayChange={this.updateActions}
                  items = {this.state.actions.prePackageActions.map((action, i) => {
                    switch (action.type) {
                      case COMPONENT_TYPES.BOOLEAN_1:
                        return ({
                          id: action.actionId,
                          component: <div>
                            <ActionBoolean
                                action={action}
                                updateActionWithParent={this.updateAction}
                                deleteAction={this.deleteAction}
                                isEdit={this.props.isEdit}
                                key={action.actionId}
                            />
                          </div>
                        });
                      case COMPONENT_TYPES.NUMERICAL_DROPDOWN_1:
                        return ({
                          id: action.actionId,
                          component: <div>
                            <ActionNumericDropdown
                                action={action}
                                updateActionWithParent={this.updateAction}
                                deleteAction={this.deleteAction}
                                isEdit={this.props.isEdit}
                                key={action.actionId}
                            />
                          </div>
                        });
                      case COMPONENT_TYPES.NUMERICAL_INPUT_1:
                        return ({
                          id: action.actionId,
                          component: <div>
                            <SingleNumericalAction
                                action={action}
                                updateActionWithParent={this.updateAction}
                                deleteAction={this.deleteAction}
                                isEdit={this.props.isEdit}
                                key={action.actionId}
                            />
                          </div>
                        });
                      case COMPONENT_TYPES.NUMERICAL_INPUT_2:
                        return ({
                          id: action.actionId,
                          component: <div>
                            <DoubleNumericalAction
                                action={action}
                                updateActionWithParent={this.updateAction}
                                deleteAction={this.deleteAction}
                                isEdit={this.props.isEdit}
                                key={action.actionId}
                            />
                          </div>
                        });
                      case COMPONENT_TYPES.NUMERICAL_INPUT_3:
                        return ({
                          id: action.actionId,
                          component: <div>
                            <TripleNumericalAction
                                action={action}
                                updateActionWithParent={this.updateAction}
                                deleteAction={this.deleteAction}
                                isEdit={this.props.isEdit}
                                key={action.actionId}
                            />
                          </div>
                        });
                      case COMPONENT_TYPES.DROPDOWN_1:
                        return ({
                          id: action.actionId,
                          component: <div>
                            <ActionDropdown
                                action={action}
                                name={action.name}
                                updateActionWithParent={this.updateAction}
                                deleteAction={this.deleteAction}
                                isEdit={this.props.isEdit}
                                key={action.actionId}
                            />
                          </div>
                        });
                      case COMPONENT_TYPES.TEXT_INPUT_1:
                        return ({
                          id: action.actionId,
                          component: <div>
                            <ActionText
                                action={action}
                                updateActionWithParent={this.updateAction}
                                deleteAction={this.deleteAction}
                                isEdit={this.props.isEdit}
                                key={action.actionId}
                            />
                          </div>
                        });
                    }
                  })}
              />
            </DndProvider>
          </div>
          {this.state.actions.packageActions.length > 0?
              <div>
                {this.state.actions.prePackageActions.length>0?
                    <Divider className='divider' />:null}
                  <PackageActions
                      deleteAction={this.deleteAction}
                      isEdit={this.props.isEdit}
                      packageActions={this.state.actions.packageActions}
                      deleteActions={this.deleteActions}
                      hasPredefinedPackageValue={this.state.hasPredefinedPackageValue}
                      numberOfPackages={this.state.numberOfPackages}
                      updateAction={this.updateAction}
                      updateActions={this.updateActions}
                      addActions={this.onActionsApply}
                  />
                {this.state.actions.postPackageActions.length>0?<Divider className='divider' />:null}
              </div>
              :null}
          {this.state.actions.postPackageActions.length > 0?
                <div>
                  <DndProvider backend={HTML5Backend}>
                    <DragAndDrop
                        cards={this.state.actions.postPackageActions}
                        handleTableDisplayChange={this.updateActions}
                        items = {this.state.actions.postPackageActions.map((action, i) => {
                          switch (action.type) {
                            case COMPONENT_TYPES.BOOLEAN_1:
                              return ({
                                id: action.actionId,
                                component: <div>
                                  <ActionBoolean
                                      action={action}
                                      updateActionWithParent={this.updateAction}
                                      deleteAction={this.deleteAction}
                                      isEdit={this.props.isEdit}
                                  />
                                </div>
                              });
                            case COMPONENT_TYPES.NUMERICAL_DROPDOWN_1:
                              return ({
                                id: action.actionId,
                                component: <div>
                                  <ActionNumericDropdown
                                      action={action}
                                      updateActionWithParent={this.updateAction}
                                      deleteAction={this.deleteAction}
                                      isEdit={this.props.isEdit}
                                      key={action.actionId}
                                  />
                                </div>
                              });
                            case COMPONENT_TYPES.NUMERICAL_INPUT_1:
                              return ({
                                id: action.actionId,
                                component: <div>
                                  <SingleNumericalAction
                                      action={action}
                                      updateActionWithParent={this.updateAction}
                                      deleteAction={this.deleteAction}
                                      isEdit={this.props.isEdit}
                                      key={action.actionId}
                                  />
                                </div>
                              });
                            case COMPONENT_TYPES.NUMERICAL_INPUT_2:
                              return ({
                                id: action.actionId,
                                component: <div>
                                  <DoubleNumericalAction
                                      action={action}
                                      updateActionWithParent={this.updateAction}
                                      deleteAction={this.deleteAction}
                                      isEdit={this.props.isEdit}
                                      key={action.actionId}
                                  />
                                </div>
                              });
                            case COMPONENT_TYPES.NUMERICAL_INPUT_3:
                              return ({
                                id: action.actionId,
                                component: <div>
                                  <TripleNumericalAction
                                      action={action}
                                      updateActionWithParent={this.updateAction}
                                      deleteAction={this.deleteAction}
                                      isEdit={this.props.isEdit}
                                      key={action.actionId}
                                  />
                                </div>
                              });
                            case COMPONENT_TYPES.DROPDOWN_1:
                              return ({
                                id: action.actionId,
                                component: <div>
                                  <ActionDropdown
                                      action={action}
                                      name={action.name}
                                      updateActionWithParent={this.updateAction}
                                      deleteAction={this.deleteAction}
                                      isEdit={this.props.isEdit}
                                      key={action.actionId}
                                  />
                                </div>
                              });
                            case COMPONENT_TYPES.TEXT_INPUT_1:
                              return ({
                                id: action.actionId,
                                component: <div>
                                  <ActionText
                                      action={action}
                                      updateActionWithParent={this.updateAction}
                                      deleteAction={this.deleteAction}
                                      isEdit={this.props.isEdit}
                                      key={action.actionId}
                                  />
                                </div>
                              });
                          }
                        })}
                    />
                  </DndProvider>
                  {this.state.actions.transactionsActions.length>0?<Divider className='divider' />:null}
                </div>
                :null}
          {this.state.actions.transactionsActions.length > 0?
              <div>
                <DndProvider backend={HTML5Backend}>
                  <DragAndDrop
                      cards={this.state.actions.transactionsActions}
                      handleTableDisplayChange={this.updateActions}
                      items = {this.state.actions.transactionsActions.map((action, i) => {
                        switch (action.type) {
                          case COMPONENT_TYPES.BOOLEAN_1:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionBoolean
                                    action={action}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.DROPDOWN_2:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionDoubleDropdown
                                    action={action}
                                    name={action.name}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.NUMERICAL_DROPDOWN_1:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionNumericDropdown
                                    action={action}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.NUMERICAL_INPUT_1:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <SingleNumericalAction
                                    action={action}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.NUMERICAL_INPUT_2:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <DoubleNumericalAction
                                    action={action}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.NUMERICAL_INPUT_3:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <TripleNumericalAction
                                    action={action}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.DROPDOWN_1:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionDropdown
                                    action={action}
                                    name={action.name}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.TEXT_INPUT_1:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionText
                                    action={action}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                        }
                      })}
                  />
                </DndProvider>
              </div>
              : null}

          {this.state.actions.listingsActions.length > 0?
            <div>
                <DndProvider backend={HTML5Backend}>
                    <DragAndDrop
                        cards={this.state.actions.listingsActions}
                        handleTableDisplayChange={this.updateActions}
                        items = {this.state.actions.listingsActions.map((action, i) => {
                            switch (action.type) {
                                case COMPONENT_TYPES.DROPDOWN_1:
                                    return ({
                                        id: action.actionId,
                                        component: <div>
                                            <ActionDropdown
                                                action={action}
                                                name={action.name}
                                                updateActionWithParent={this.updateAction}
                                                deleteAction={this.deleteAction}
                                                isEdit={this.props.isEdit}
                                                key={action.actionId}
                                            />
                                        </div>
                                    });
                              case COMPONENT_TYPES.DROPDOWN_AND_BOOLEAN:
                                return ({
                                  id: action.actionId,
                                  component: <div>
                                    <ActionDropdown
                                        action={action}
                                        name={action.name}
                                        updateActionWithParent={this.updateAction}
                                        deleteAction={this.deleteAction}
                                        isEdit={this.props.isEdit}
                                        key={action.actionId}
                                    />
                                  </div>
                                });
                                    case COMPONENT_TYPES.TEXT_INPUT_1:
                                      return ({
                                        id: action.actionId,
                                        component: <div>
                                          <ActionText
                                              action={action}
                                              updateActionWithParent={this.updateAction}
                                              deleteAction={this.deleteAction}
                                              isEdit={this.props.isEdit}
                                              key={action.actionId}
                                          />
                                        </div>
                                      });
                                      case COMPONENT_TYPES.NUMERICAL_INPUT_1:
                                        return ({
                                          id: action.actionId,
                                          component: <div>
                                            <SingleNumericalAction
                                                action={action}
                                                updateActionWithParent={this.updateAction}
                                                deleteAction={this.deleteAction}
                                                isEdit={this.props.isEdit}
                                                key={action.actionId}
                                            />
                                          </div>
                                        });
                            }
                        })}
                    />
                </DndProvider>
            </div>
              : null}

          {this.state.actions.pricingActions.length > 0?
              <div>
                <DndProvider backend={HTML5Backend}>
                  <DragAndDrop
                      cards={this.state.actions.pricingActions}
                      handleTableDisplayChange={this.updateActions}
                      items = {this.state.actions.pricingActions.map((action, i) => {
                        switch (action.type) {
                          case COMPONENT_TYPES.DROPDOWN_AND_TEXT_INPUT:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionDropdownsAndInput
                                    action={action}
                                    name={action.name}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                    filters={this.state.filters}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.DROPDOWN_1:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionDropdown
                                    action={action}
                                    name={action.name}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.DROPDOWN_AND_BOOLEAN:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionDropdown
                                    action={action}
                                    name={action.name}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.TEXT_INPUT_1:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <ActionText
                                    action={action}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                          case COMPONENT_TYPES.NUMERICAL_INPUT_1:
                            return ({
                              id: action.actionId,
                              component: <div>
                                <SingleNumericalAction
                                    action={action}
                                    updateActionWithParent={this.updateAction}
                                    deleteAction={this.deleteAction}
                                    isEdit={this.props.isEdit}
                                    key={action.actionId}
                                />
                              </div>
                            });
                        }
                      })}
                  />
                </DndProvider>
              </div>
              : null}
          <Button
              className='automationRulesActionButton'
              variant='contained'
              color='primary'
              startIcon={<AddIcon />}
              onClick={() => this.showActionDialog(true)}
          >
            {ADD_ACTION}
          </Button>

          <div>
            <Tooltip
              className='infoTooltipIcon'
              enterTouchDelay={0}
              leaveTouchDelay={30000}
              enterDelay={400}
              // leaveDelay={1000000} // for testing purposes
              title={
                <React.Fragment>
                  {EXIT_ON_MATCH}: {INFO_ON_EXIT_OPTION}{' '}
                  <br />
                  <br />
                  {CONTINUE_ON_MATCH}: {INFO_ON_CONTINUE_OPTION}
                </React.Fragment>
              }
            >
              <IconButton aria-label='info'>
                <InfoOutlinedIcon variant='outlined' fontSize='small' />
              </IconButton>
            </Tooltip>
            <FormControlLabel
              className='exitContinueSwitch'
              label={
                this.state.exitOnMatch ? EXIT_ON_MATCH : CONTINUE_ON_MATCH
              }
              control={
                <Switch
                  color='primary'
                  checked={this.state.exitOnMatch}
                  onChange={this.handleContinueOnMatch}
                />
              }
            />
          </div>
        </Paper>
      </div>
    );
  }
}

AutomationRulesCondition.propTypes = {
  condition: PropTypes.object,
  totalConditions: PropTypes.number,
  updateConditionWithParent: PropTypes.func,
  deleteCondition: PropTypes.func,
  moveCondition: PropTypes.func,
  copyCondition: PropTypes.func,
  isEdit: PropTypes.bool,
  index: PropTypes.number
}

AutomationRulesCondition.defaultProps = {
  condition: {},
  totalConditions: 0,
  updateConditionWithParent: ()=>{},
  deleteCondition: ()=>{},
  moveCondition: ()=>{},
  copyCondition: ()=>{},
  isEdit: false,
  index: -1
}
export default AutomationRulesCondition;
