import React from "react";

// material components
import {Select, MenuItem, Box, Tooltip, IconButton, TextField} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// import constants
import { FILTER_TYPE_BOOLEAN_2_OPTIONS,
  FILTERS
} from "./constants";
import {COPY_FILTER, COPY_RULE, DELETE_FILTER, DRAG} from "../constants";
import { COUNTRY_OPTIONS } from "../ConditionActions/constants";

// import styling
import "./FilterBooleanPredefinedSelect.css";

import PropTypes from "prop-types";
import FilterTextField from "./FilterTextField";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
  availableLocationsSelector,
  marketplacesSelector,
  shippingTemplatesSelector
} from "../../../redux/selectors/settings";
import {listAvailableLocations, listMarketplaces, listShippingTemplates} from "../../../redux/actions/settings";

import withShipment from "../../../withShipment";
import {listAllBankAccounts} from "../../../redux/actions/settings";
import {bankAccountsSelector} from "../../../redux/selectors/settings";
import {userInfoSelector} from "../../../redux/selectors/auth";
/**
 * Dropdown list for FILTER_TYPE_BOOLEAN_PREDEFINED with a secondary dropdown list of pre-defined options
 */
class FilterBooleanPredefinedSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterId: "",
      name: "",
      value1: "", // value for the first drop down
      value2: "", // value of the second drop down
      value3: "",
      secondaryOptions: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      // on component mount, the parent component stores this child's state.
      // We restore it here
      ...this.props.filter,
    });
    this.setState({
      secondaryOptions: this.getSecondaryOptions(),
    });
    this.props.listAvailableLocations()
    this.props.listAllBankAccounts()
    this.props.listShippingTemplates({id: this.props.userInfo.company})
    this.props.listMarketplaces({
      id: this.props.userInfo.company
    })
  };

    componentDidUpdate(prevProps) {
        if (prevProps.availableLocations !== this.props.availableLocations) {
            this.setState({
                secondaryOptions: this.getSecondaryOptions(),
            });
        }
        if (prevProps.bankAccounts !== this.props.bankAccounts) {
            this.setState({
                secondaryOptions: this.getSecondaryOptions(),
            });
        }
      if (prevProps.marketplaces !== this.props.marketplaces) {
          this.setState({
              secondaryOptions: this.getSecondaryOptions(),
          });
      }

    }
  getSecondaryOptions = () => {
    if (this.props.filter.name == "Ship to country") {
      return COUNTRY_OPTIONS.map(n => n.name)
    }
    if (this.props.filter.name === "Location") {
      let locationNames = []
      locationNames = this.props.availableLocations.filter((location) => location.enabled === true).map(location => location.name)
      return locationNames
    }
    if (this.props.filter.name === "Shipping Template") {
      let shippingTemplates = []
      shippingTemplates = this.props.shippingTemplates.map(template => template.shippingTemplateName)
      return shippingTemplates
    }
    if (this.props.filter.name == "Bank account") {
      return this.props.bankAccounts.map(n => n.name)
    }
    if (this.props.filter.name === "Marketplace") {
      let marketplaceNames = []

      if(this.props.ruleCategory == "Pricing"){
        marketplaceNames = this.props.marketplaces
            .filter(
                (marketplace) => marketplace.marketplaceCompany === "Amazon" && marketplace.enabled === true
            )
            .map((marketplace) => marketplace.marketplaceName)

      }else{
        marketplaceNames =  [...this.props.marketplaces.map(marketplace => marketplace.marketplaceName), "Other"]
      }

      return marketplaceNames

    }

    return FILTERS.find(filter => filter.name === this.props.filter.name).secondaryOptions
  }

  handleValue1Change = (event) => {
    const value1 = event.target.value;
    this.setState(
      {
        value1,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  handleValue2Change = (event) => {
    const value2 = event.target.value;
    this.setState(
      {
        value2,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  handleValue3Change= (event) => {
    const value3 = event.target.value;
    this.setState(
        {
          value3,
        },
        () => {
          this.props.updateFilterWithParent({ ...this.state });
        }
    );
  };

  additionalInput = (filterName, value1, value2) => {
    if (filterName == "Marketplace" && value2 == "Other") {
      return (
          <TextField
              variant="outlined"
              name="marketplaceName"
              value={this.state.value3}
              onChange={this.handleValue3Change}
              InputLabelProps={{ shrink: true }}
              label={"Marketplace"}
              InputProps={{
                style: {
                  // marginLeft: "10px",
                  height: "40px",
                  width: "138px"
                },
              }}
          />
      )
    }
  }

  styleForFirstSelect = (filterName) => {
    switch (filterName) {
      case "Marketplace":
        return "filterBooleanPredefinedMarketplaceSelect"
      default:
        return "filterBooleanPredefinedSelectOptions"
    }
  }

  styleForSecondSelect = (filterName) => {
    switch (filterName) {
      case "Shipping Template":
        return "filterBooleanPredefinedSecondarySelectOptionsShippingTemplate"
      case "Marketplace":
        return "filterBooleanPredefinedSecondaryMarketplaceSelect"
      default:
        return "filterBooleanPredefinedSecondarySelectOptions"
    }
  }

  render() {
    return (
      <Box
        className='filterBooleanPredefinedSelect'
        display='flex'
        flexDirection='row'
        justifyContent='left'
        alignItems='center'
      >
        <Box className='filterBooleanPredefinedSelectName'>
          {this.state.name}
        </Box>

        <Select
          className={this.styleForFirstSelect(this.props.filter.name)}
          variant='outlined'
          value={this.state.value1}
          onChange={this.handleValue1Change}
        >
          {FILTER_TYPE_BOOLEAN_2_OPTIONS.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>

        <Select
          className={this.styleForSecondSelect(this.props.filter.name)}
          variant='outlined'
          value={this.state.value2}
          onChange={this.handleValue2Change}
        >
          {this.state.secondaryOptions.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>

        {this.additionalInput(this.props.filter.name, this.state.value1, this.state.value2)}

        <div className='filterBooleanEmptyDiv'>
          <Tooltip title={COPY_FILTER}>
            <IconButton
                aria-label={COPY_FILTER}
                onClick={() => this.props.copyFilter(this.state.filterId)}>
              <FileCopyIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
          <Tooltip title={DELETE_FILTER}>
            <IconButton
              aria-label={DELETE_FILTER}
              onClick={() => this.props.deleteFilter(this.state.filterId)}
            >
              <DeleteOutlineIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title={DRAG}>
            <IconButton
                aria-label='drag'
            >
              <DehazeIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    );
  }
}

FilterBooleanPredefinedSelect.propTypes = {
  filter: PropTypes.object,
  updateFilterWithParent: PropTypes.func,
  deleteFilter: PropTypes.func,
  isEdit: PropTypes.bool,
  copyFilter: PropTypes.func,
  availableLocations: PropTypes.array,
}

FilterBooleanPredefinedSelect.defaultProps = {
  filter: {},
  updateFilterWithParent: ()=>{},
  deleteFilter: ()=>{},
  isEdit: false,
  copyFilter: ()=>{},
  availableLocations: [],
  bankAccounts: [],
}
const mapStateToProps = (state) => ({
  userInfo: userInfoSelector(state),
  bankAccounts: bankAccountsSelector(state),
  availableLocations: availableLocationsSelector(state),
  shippingTemplates: shippingTemplatesSelector(state),
  marketplaces: marketplacesSelector(state),
})

const actionCreators = {
    listAllBankAccounts,
    listAvailableLocations,
    listShippingTemplates,
    listMarketplaces,
}

export default withShipment({
  mapStateToProps,
  actionCreators
}, FilterBooleanPredefinedSelect);
