import {
    STORE_RULES,
    STORE_NEW_RULE,
    CLEAR_STORED_RULE, STORE_APPLIED_ORDERS,
} from "../../constants/automationRules";

const initialState = {
    rules: [],
    ruleToStore: {},
    ruleToEdit: {},
    isEdit: false,
    newOrders: [],
}

const separateActions = (ruleToStore) => {
    let rule = ruleToStore.rule
    if (rule) {
        rule.conditions.forEach(condition => {
            let prePackageActions = condition.actions.filter(action => action.step === "prePackageActions")
            let packageActions = condition.actions.filter(action => action.step === "packageActions")
            let postPackageActions = condition.actions.filter(action => action.step === "postPackageActions")
            let listingsActions = condition.actions.filter(action => action.step === "listingsActions")
            let transactionsActions = condition.actions.filter(action => action.step === "transactionsActions")
            let pricingActions = condition.actions.filter(action => action.step === "pricingActions")
            condition.actions = {
                prePackageActions: prePackageActions,
                packageActions: packageActions,
                postPackageActions: postPackageActions,
                listingsActions: listingsActions,
                transactionsActions: transactionsActions,
                pricingActions: pricingActions,
            }
        })
    }
    return ruleToStore
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_RULES:
            return {
                ...state,
                rules: action.rules
            }
        case STORE_NEW_RULE:
            return {
                ...state,
                ruleToStore: separateActions(action.ruleToStore),
                isEdit: action.isEdit,
            }
        case CLEAR_STORED_RULE:
            return {
                ...state,
                ruleToStore: {},
                ruleToEdit: {},
                isEdit: false,
            }
        case STORE_APPLIED_ORDERS:
            return {
                ...state,
                newOrders: action.newOrders
            }
        default:
            return state
    }
}