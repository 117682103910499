import {
    createSelector
} from 'reselect'

export const dashboardSelector = createSelector(state => state && state.dashboard && state.dashboard.dashboard, dashboardSelector => dashboardSelector);
export const scanResponseSelector = createSelector(state => state && state.dashboard && state.dashboard.scanResponse, scanResponseSelector => scanResponseSelector);
export const packedResponseSelector = createSelector(state => state && state.dashboard && state.dashboard.packedResponse, packedResponseSelector => packedResponseSelector);
export const locationsSelector = createSelector(state => state && state.dashboard && state.dashboard.locations, locationsSelector => locationsSelector)
export const scannedBarcodesSelector = createSelector(state => state && state.dashboard && state.dashboard.scannedBarcodes, scannedBarcodesSelector => scannedBarcodesSelector)
export const packedBarcodesSelector = createSelector(state => state && state.dashboard && state.dashboard.packedBarcodes, packedBarcodesSelector => packedBarcodesSelector)
export const pendingPackedBarcodesSelector = createSelector(state => state && state.dashboard && state.dashboard.pendingPacked, pendingPackedBarcodesSelector => pendingPackedBarcodesSelector)
export const allBarcodesSelector = createSelector(state => state && state.dashboard && state.dashboard.allBarcodes, allBarcodesSelector => allBarcodesSelector)
export const refreshingSelector = createSelector(state => state && state.dashboard && state.dashboard.refreshing, refreshingSelector => refreshingSelector)
export const dashboardRefreshSelector = createSelector(state => state && state.dashboard && state.dashboard.dashboardRefresh, dashboardRefreshSelector => dashboardRefreshSelector)
export const limboShipmentSelector = createSelector(state => state && state.dashboard && state.dashboard.limboShipments, limboShipmentSelector => limboShipmentSelector)
export const resolveLimboShipmentresponseMessageSelector = createSelector(state => state && state.dashboard && state.dashboard.resolveLimboShipmentresponseMessage, resolveLimboShipmentresponseMessageSelector => resolveLimboShipmentresponseMessageSelector)