import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {IconButton, TextField} from "@material-ui/core";
import DeleteStepper from "../../global/DeleteStepper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/Save";
import Divider from "@material-ui/core/Divider";
import withShipment from '../../../withShipment';
import { shippingSettingSelector } from '../../../redux/selectors/accounting';
import { listShippingSettings, saveShippingSummarySettings } from '../../../redux/actions/accounting';
import FeedbackBar from '../../feedbackBar/FeedbackBar';
import { clearStatus } from '../../../redux/actions/global';

class ExchangeRates extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            yearsAverage: Array.from(new Array(21), (val, index) => new Date().getFullYear() - index),
            years: Array.from(new Array(21), (val, index) => new Date().getFullYear() - index),
            isAverageDelete: {},
            isYearDelete: {},
            averageError: {},
            yearError: {},
            yearRates: [],
            averageRates: [],
            feedbackBarOpen: false,
        }
    }

    componentDidMount() {
        this.props.listShippingSettings()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.shippingSettings !== this.props.shippingSettings) {
            this.setState({
                averageRates: this.props.shippingSettings.averageRates,
                yearRates: this.props.shippingSettings.yearRates,
            })
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    addYearRateEntry = () => {
        let yearRates = this.state.yearRates ? this.state.yearRates : []
        yearRates.push({})
        this.setState({
            yearRates: yearRates
            })

    }

    addAverageRateEntry = () => {
        let averageRates = this.state.averageRates ? this.state.averageRates : []
        averageRates.push({})
        this.setState({
            averageRates: averageRates
        })

    }

    removeAverageRateEntry = (index) => {
        const { averageRates } = this.state
        averageRates.splice(index, 1)
        this.setState({
            averageRates: averageRates
        })
    }

    removeYearRateEntry = (index) => {
        const { yearRates } = this.state
        yearRates.splice(index, 1)
        this.setState({
            yearRates: yearRates
        })
    }

    handleDeleteAverageTrue = (index) => {
        let newIsDelete = this.state.isAverageDelete
        newIsDelete[index] = true
        this.setState({ isAverageDelete: newIsDelete })
    }

    handleDeleteYearTrue = (index) => {
        let newIsDelete = this.state.isYearDelete
        newIsDelete[index] = true
        this.setState({ isYearDelete: newIsDelete })
    }


    handleDeleteAverageFalse = (index) => {
        let newIsDelete = this.state.isAverageDelete
        newIsDelete[index] = false
        this.setState({ isAverageDelete: newIsDelete })
    }

    handleDeleteYearFalse = (index) => {
        let newIsDelete = this.state.isYearDelete
        newIsDelete[index] = false
        this.setState({ isYearDelete: newIsDelete })
    }

    handleAverageYearChange = (value, index) => {
        const { averageRates } = this.state

        let year = averageRates.map(product => product.year)
        if (year.includes(value?.year)) {
            let newError = this.state.averageError
            newError[index] = true
            this.setState({
                averageError: newError
            })
        } else if (this.state.averageError[index]) {
            let newError = this.state.averageError
            newError[index] = false
            this.setState({
                averageError: newError
            })
        }

        averageRates[index].year = value && value.year ? value.year : ""
        averageRates[index].rate = ""

        this.setState({
            averageRates: averageRates
        })
    }

    handleYearChange = (value, index) => {
        const { yearRates } = this.state

        let year = yearRates.map(product => product.year)
        if (year.includes(value?.year)) {
            let newError = this.state.yearError
            newError[index] = true
            this.setState({
                yearError: newError
            })
        } else if (this.state.yearError[index]) {
            let newError = this.state.yearError
            newError[index] = false
            this.setState({
                yearError: newError
            })
        }

        yearRates[index].year = value && value.year ? value.year : ""
        yearRates[index].rate = ""

        this.setState({
            yearRates: yearRates
        })
    }

    handleAverageRateChange = (e, index) => {
        const { averageRates } = this.state

        averageRates[index].rate = e.target.value

        this.setState({
            averageRates: averageRates
        })
    }

    handleYearRateChange = (e, index) => {
        const { yearRates } = this.state

        yearRates[index].rate = e.target.value

        this.setState({
            yearRates: yearRates
        })
    }

    conversionAverageRateContent = () => {
        return (
            <div className='summary-stepper-page-two-grid' key={'this'}>
                {this.state.averageRates?.map((product, index) => {
                    return (
                        <React.Fragment>
                            <div></div>
                            <div key={'year autocomplete' + index}>
                                <Autocomplete
                                    options={this.state.yearsAverage}
                                    getOptionLabel={(option) => option.toString()}
                                    autoHighlight={true}
                                    name="year"
                                    className='summary-input-text-field'
                                    value={product.year || ""}
                                    onChange={(event, value) => {
                                        this.handleAverageYearChange({year: value}, index)
                                    }
                                    }
                                    blurOnSelect={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={(this.state.averageError[index] ?? false) || !product.year}
                                            helperText={this.state.averageError[index] ? "Duplicated year" : null}
                                            label="Year"
                                            InputLabelProps={{shrink: true}}
                                            variant="outlined"
                                            name="year"
                                        />
                                    }
                                />

                            </div>
                            <div key={'rate field ' + index}>
                                <TextField
                                    className='summary-input-text-field'
                                    variant='outlined'
                                    label="Rate"
                                    error={!product.rate}
                                    InputLabelProps={{shrink: true}}
                                    name='rate'
                                    value={product.rate}
                                    onChange={(e) => this.handleAverageRateChange(e, index)}
                                />
                            </div>
                            <div className={!this.state.isAverageDelete[index] ? "summary-delete-row-step-one" : ''}>
                                <DeleteStepper
                                    handleDeleteFalse={this.handleDeleteAverageFalse}
                                    handleDeleteTrue={this.handleDeleteAverageTrue}
                                    handleDelete={() => this.removeAverageRateEntry(index)}
                                    index={index}
                                    isDelete={this.state.isAverageDelete[index]}
                                />
                            </div>
                        </React.Fragment>
                    )
                })}

                {/*default adding row*/}
                <div/>

                <div className='summary-add-button'>
                    <IconButton onClick={this.addAverageRateEntry}>
                        <AddCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                    Add average exchange rate
                </div>
            </div>
        )
    }

    conversionYearRateContent = () => {
        return (
            <div className='summary-stepper-page-two-grid' key={'this'}>
                {this.state.yearRates?.map((product, index) => {
                    return (
                        <React.Fragment>
                            <div></div>
                            <div key={'year autocomplete' + index}>
                                <Autocomplete
                                    options={this.state.years}
                                    getOptionLabel={(option) => option.toString()}
                                    autoHighlight={true}
                                    name="year"
                                    className='summary-input-text-field'
                                    value={product.year || ""}
                                    onChange={(event, value) => {
                                        this.handleYearChange({year: value}, index)
                                    }
                                    }
                                    blurOnSelect={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={(this.state.yearError[index] ?? false) || !product.year}
                                            helperText={this.state.yearError[index] ? "Duplicated year" : null}
                                            label="Year"
                                            InputLabelProps={{shrink: true}}
                                            variant="outlined"
                                            name="year"
                                        />
                                    }
                                />

                            </div>
                            <div key={'rate field ' + index}>
                                <TextField
                                    className='summary-input-text-field'
                                    variant='outlined'
                                    label="Rate"
                                    error={!product.rate}
                                    InputLabelProps={{shrink: true}}
                                    name='rate'
                                    value={product.rate}
                                    onChange={(e) => this.handleYearRateChange(e, index)}
                                />
                            </div>
                            <div className={!this.state.isYearDelete[index] ? "summary-delete-row-step-one" : ''}>
                                <DeleteStepper
                                    handleDeleteFalse={this.handleDeleteYearFalse}
                                    handleDeleteTrue={this.handleDeleteYearTrue}
                                    handleDelete={() => this.removeYearRateEntry(index)}
                                    index={index}
                                    isDelete={this.state.isYearDelete[index]}
                                />
                            </div>
                        </React.Fragment>
                    )
                })}

                {/*default adding row*/}
                <div/>

                <div className='summary-add-button'>
                    <IconButton onClick={this.addYearRateEntry}>
                        <AddCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                    Add year exchange rate
                </div>
            </div>
        )
    }

    getContextualMenuItems = () => {
        let menuItems = []
        menuItems.push(
             {
                title: "SAVE",
                icon: <SaveIcon />,
                onClick: () => {
                    const data = {
                        "averageRates": this.state.averageRates || [],
                        "yearRates": this.state.yearRates || [],
                    }
                    this.props.saveShippingSummarySettings(data)
                    this.openFeedbackBar()

                }
            })

        return menuItems
    }

    render() {

        return (
            <React.Fragment>

                <FeedbackBar
                  open={this.state.feedbackBarOpen}
                  handleClose={this.closeFeedbackBar}
                  severity={'success'}
                  message={"Saved"}
                />
                <TopToolbar
                    pageName="Exchange Rates"
                    menuItems={this.getContextualMenuItems()}
                />

                <h5 style={{textAlign: "center", marginTop: "24px"}}>Average Exchange Rates</h5>

                <div className='summary-stepper-align'>

                    {this.conversionAverageRateContent()}
                </div>

                <div style={{display: 'flex', justifyContent: 'center', margin: "10px"}}>
                    <Divider style={{width: "40%", alignSelf: "center"}}/>
                </div>

                <h5 style={{textAlign: "center", marginTop: "24px"}}>Year-End Exchange Rates</h5>

                <div className='summary-stepper-align'>

                    {this.conversionYearRateContent()}
                </div>


            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    shippingSettings: shippingSettingSelector(state),
})

const actionCreators = {
    clearStatus,
    saveShippingSummarySettings,
    listShippingSettings,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, ExchangeRates);


