import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
  IMPORT_MARKETPLACE_SUMMARY,
  MARKETPLACE_SUMMARY, MARKETPLACE_SUMMARY_FIELDS,
} from './constants';
import { Layout } from "../../global/Table/TablePageHelpers/LayoutObject";
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, TableCell } from '@mui/material';
import TablePage from "../../global/Table/TablePage";
import '../shippingSummary/ShippingSummary.css';
import {HourglassEmptyTwoTone} from "@material-ui/icons";
import { Upgrade, Upload } from '@mui/icons-material';
import ImportMarketplacePopper from './ImportMarketplacePopper';
import LinearProgressWithLabel from '../../../pages/Dashboard/LinearProgressWithLabel';
import RefreshIcon from '@material-ui/icons/Refresh';
import Utils from '../../Utils/Utils';
import { PURCHASE_SUMMARY_FIELDS } from '../purchaseSummary/constants';
import { SHIPPING_SUMMARY_FTL_FIELDS } from '../shippingSummary/constants';

class MarketplaceSummaryTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          selectedMarketplace: null,
          progress: this.props.progress,
          sessionId: this.props.sessionId,
          uploading: false,
        };
        this.hiddenMarketplaceInput = React.createRef();
        this.importMarketplace = this.importMarketplace.bind(this);
    }

  updateProgressBar = (progress) => {
    this.setState({ progress });
  };

  getTableHeaders = () => {
    let headers = [];
    headers.push(Layout.columnHead(null, null, null)); // Existing code

    // Conditionally render headers based on selected currency
    const { selectedCurrency } = this.props;

    // Add 'Description' header only once, regardless of currency
    if (selectedCurrency !== "Consolidated CAD" && selectedCurrency !== "Consolidated" ) {
      headers.push(Layout.columnHead('Description', null, 'left'));
    }

    // Filter headers based on selected currency
    const filteredHeaders = MARKETPLACE_SUMMARY_FIELDS.filter((headCell) => {
      if (selectedCurrency === "CAD" && headCell.id.includes("_ca")) {
        return true;
      }
      if (selectedCurrency === "USD" && (headCell.id.includes("_com") || headCell.id.includes("_us"))) {
        return true;
      }
      if (selectedCurrency === "Consolidated") {
        return true; // Show all columns
      }
      if (selectedCurrency === "Consolidated CAD") {
        return true; // Show all columns
      }
      return false;
    });

    // Add filtered headers
    filteredHeaders.forEach((headCell) => {
      headers.push(Layout.columnHead(headCell.label, null, headCell.align));
    });

    return headers;
  };




  formatDollar = (amount) => {
        if (amount === null || amount === undefined) {
            amount = 0;
        }

        if(amount === 0 || amount === "0"){
          return  <div style={{
            padding:'12px'
          }}>

          </div>
        }

        const amountStr = parseFloat(amount).toFixed(2).toString();

        // Check if amountStr is NaN after parsing
        if (isNaN(amountStr)) {
            return '';
        } else {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '3px',
                    marginRight: '15px',
                    padding:0
                }}>
                    <span style={{ flexShrink: 0, marginLeft: '16px', marginRight: '16px' }}>$</span>
                    <span style={{ marginLeft: 'auto' }}>
                    {amountStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
                </div>
            );
        }
    };


  tableRow = (data) => {
    if (!data?.name || data.name === 'Receivables' || data.name === 'Remittance') {
      return null
    }

    const { selectedCurrency } = this.props;

    // Function to determine if a column should be displayed based on the selected currency
    const shouldDisplayColumn = (columnId) => {
      if (selectedCurrency === "CAD" && columnId.includes("_ca")) {
        return true;
      }
      if (selectedCurrency === "USD" && (columnId.includes("_com") || columnId.includes("_us"))) {
        return true;
      }
      if (selectedCurrency === "Consolidated") {
        return true; // Show all columns
      }
      if (selectedCurrency === "Consolidated CAD") {
        return true; // Show all columns
      }
      return false;
    };

    // List of column IDs
    const columns = [
      'amazon_ca', 'amazon_com', 'walmart_ca', 'walmart_com',
      'ebay_ca', 'exponet_ca', 'exponet_us', 'customer_ca'
    ];

    // Check if the row should be bold
    const isBoldRow = [
      'Net Product Sales', 'Income (Gross Sales)', 'Total Expenses', 'Net Income (Net Sales)'
    ].includes(data.name);

    return (
      <React.Fragment>
        <TableCell style={{ width: '16px', height: '55px' }}></TableCell>
        <TableCell style={{ padding: 0, margin: 0, fontWeight: isBoldRow ? 'bold' : 'normal' }}>
          {data.name}
        </TableCell>
        {columns.map((col) =>
            shouldDisplayColumn(col) && (
              <TableCell key={col} style={{ fontWeight: isBoldRow ? 'bold' : 'normal' }} className="custom-cell">
                {this.formatDollar(data[col])}
              </TableCell>
            )
        )}
      </React.Fragment>
    );
  }

  handleExport = () => {
    const { marketplaceSummary } = this.props;
    if (!marketplaceSummary || marketplaceSummary.length === 0) {
      return;
    }

    const filteredSummary = marketplaceSummary.filter(row =>
      row.name !== 'Receivables' && row.name !== 'Remittance'
    );

    if (filteredSummary.length === 0) {
      return;
    }

    // Generate CSV content
    const headers = MARKETPLACE_SUMMARY_FIELDS.map(field => field.label).join(',');
    const rows = filteredSummary.map(row =>
      MARKETPLACE_SUMMARY_FIELDS.map(field => {
        let value = row[field.id] ?? '';
        value = value.toString().includes(',') ? `"${value}"` : value; // Wrap values with commas in quotes
        return value;
      }).join(',')
    );

    const cleanRows = rows.map(row =>
      row.split(',').map(value => (value.trim() === "0" || value.trim() === "0.00" ) ? '' : value).join(',')
    );


    const csvContent = [headers, ...cleanRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Marketplace Summary_${this.props.selectedYear}_${this.props.selectedCurrency}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getMonthsForQuarter = () => {
    const { selectedQuarters } = this.props;

    if (!selectedQuarters) {
      return [
        { name: 'January', value: '01' },
        { name: 'February', value: '02' },
        { name: 'March', value: '03' },
        { name: 'April', value: '04' },
        { name: 'May', value: '05' },
        { name: 'June', value: '06' },
        { name: 'July', value: '07' },
        { name: 'August', value: '08' },
        { name: 'September', value: '09' },
        { name: 'October', value: '10' },
        { name: 'November', value: '11' },
        { name: 'December', value: '12' },
      ]
    } else {
      switch (selectedQuarters) {
        case 1:
          return [  { name: 'January', value: '01' },
            { name: 'February', value: '02' },
            { name: 'March', value: '03' }]
        case 2:
          return [
            { name: 'April', value: '04' },
            { name: 'May', value: '05' },
            { name: 'June', value: '06' }]
        case 3:
          return [
            { name: 'July', value: '07' },
            { name: 'August', value: '08' },
            { name: 'September', value: '09'}]
        case 4:
          return [
            { name: 'October', value: '10' },
            { name: 'November', value: '11' },
            { name: 'December', value: '12' }]
        default:
          return [];
      }
    }
  }

    getContextualMenuItems = () => {
        let menuItems = []

        menuItems.push({
            title: IMPORT_MARKETPLACE_SUMMARY,
            icon: !this.props.processing ? <Upload /> : <HourglassEmptyTwoTone />,
            popper: (
              <ImportMarketplacePopper
                callback={(selectedMarketplace) =>
                  this.uploadImportMarketplaceFile(selectedMarketplace)
                }
              />
            ),
        });

      menuItems.push(
        {
        title: 'Refresh',
        icon: <RefreshIcon />,
        onClick: () => {
          this.props.setFetch(true)
        }
      },
        {
          title: 'Export',
          icon: <Upgrade />,
          onClick: () => {
            this.handleExport()
          }
        },)

        if (this.props.loadingData) {
            menuItems.push({
                icon: <HourglassEmptyTwoTone />,
                handler: () => null,
                disabled: true,
                title: "Loading data..."
            });
        }
        return menuItems
    }

    uploadImportMarketplaceFile = (selectedMarketplace) => {
        this.setState({ selectedMarketplace: selectedMarketplace });
        this.hiddenMarketplaceInput.current.click();
    }

    importMarketplace = (e) => {
        if (e.target.files.length === 0) return;
        let file = e.target.files[0];
        let fileReader = new FileReader();

        fileReader.onload = (e) => {
            let contents = new Uint8Array(e.target.result);
          this.setState({ uploading: true });
          this.props.importMarketplaceSummary(
            {
              fileType: file.type,
              content: Array.from(contents),
              marketplace: this.state.selectedMarketplace,
              filename: file.name
            },
            (status, data) => {
              if (status === 'success') {
                this.props.marketplaceProgressSummary({ sessionId: data }, (status, data, error, successMessage) => {
                  if (status === 'success') {
                    if(successMessage){
                      this.props.customFeedBackBar('success', successMessage)
                    }

                    if(error){
                      this.props.customFeedBackBar('error', error)
                    }

                    this.setState({ progress: data }, () => {
                      this.setState({ selectedMarketplace: null });


                      // Check if progress is 100, then add a delay of 5 seconds
                      if (this.state.progress === 100) {

                        setTimeout(() => {
                          this.setState({ uploading: false },()=>{
                            this.props.listMarketplaceAPI();
                          });
                        }, 2000); // Delay of 2 seconds
                      }
                    });
                  }else{
                    this.setState({ uploading: false });
                    this.setState({ selectedMarketplace: null });
                  }


                });
              } else {
                this.setState({ uploading: false });
              }
            }
          );
        };
        fileReader.readAsArrayBuffer(file);
    };


  searchStrings = (data) => {
    return [data.name]
  }

    render() {

      const currency = ['CAD', 'USD','Consolidated', 'Consolidated CAD']
      const currentYear = new Date().getFullYear()
      const years = Array.from(new Array(currentYear - 2022), (val, index) => 2023 + index)
      const { selectedCurrency, handleCurrencyChange, selectedMonth, handleMonthChange, selectedYear, selectedQuarters, handleYearChange, handleQuarterChange } = this.props


      return (
        <React.Fragment>
          <input
            type="file"
            accept=".zip, .csv"
            ref={this.hiddenMarketplaceInput}
            hidden
            onChange={this.importMarketplace}
          />

          <TopToolbar
            pageName={MARKETPLACE_SUMMARY}
            menuItems={this.getContextualMenuItems()}
          />

          {this.state.uploading && (
            <div
              style={{
                marginLeft: '24px',
                marginTop: '20px',
                marginRight: '24px',
              }}
            >
              <LinearProgressWithLabel
                value={this.state.progress}
                width={'100%'}
              />
            </div>
          )}

          <Box className="box">
            <FormControl variant="outlined" className="form-control">
              <InputLabel id="year-label">Year</InputLabel>
              <Select
                className="select-year"
                labelId="year-label"
                value={selectedYear}
                onChange={handleYearChange}
                label="Year"
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className="form-control">
              <InputLabel id="month-label">Month</InputLabel>
              <Select
                className="select-month"
                label="Month"
                labelId="month-label"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <MenuItem value="">None</MenuItem>
                {this.getMonthsForQuarter().map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className="form-control">
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                className="select"
                labelId="currency-label"
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                label="Currency"
              >
                {currency.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="box1-purchase-summary">
            <div className="checkbox-purchase-container">
              <div className="chip-container">
                {['QTR 1', 'QTR 2', 'QTR 3', 'QTR 4'].map((quarter, index) => (
                  <Chip
                    key={index}
                    label={quarter}
                    onClick={() => handleQuarterChange(quarter)}
                    className={`chip ${
                      selectedQuarters === index + 1 ? 'chip-selected' : ''
                    }`}
                  />
                ))}
                <span
                  style={{
                    marginLeft: '10px',
                    paddingTop: '2px',
                  }}
                >
                  {' '}
                  Last updated: {Utils.formatDateTime(
                    this.props.lastUpdatedAt
                  )}{' '}
                </span>
              </div>
            </div>
          </Box>

          <div className="table-page-purchase-summary">
            <TablePage
              type="purchaseSummary"
              tableName="purchaseTable"
              tableRows={this.props.marketplaceSummary}
              tableColumnHeads={this.getTableHeaders()}
              tableRow={this.tableRow}
              hasSearchField
              searchStrings={this.searchStrings}
            />
          </div>
          <div className="marketplace-divider"></div>
        </React.Fragment>
      );
    }
}

export default MarketplaceSummaryTable;
