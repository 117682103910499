import React from "react";

// material components
import {Select, MenuItem, Box, Tooltip, IconButton, TextField} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// import constants
import {
    ASIN_WARNING, FILTER_TYPE_BOOLEAN_2_OPTIONS,
    FILTERS
} from "./constants";
import {COPY_FILTER, COPY_RULE, DELETE_FILTER, DRAG} from "../constants";

// import styling
import "./FilterTextFieldWithPredefined.css";

import PropTypes from "prop-types";
import FilterTextField from "./FilterTextField";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
    pricingListingsSelector,
} from "../../../redux/selectors/settings";
import {
    fetchPricingListings,
} from "../../../redux/actions/settings";
import withShipment from "../../../withShipment";
import {Autocomplete} from "@mui/material";
import InfoIcon from "@material-ui/icons/Info";

class FilterTextFieldWithPredefined extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterId: "",
            name: "",
            value1: "", // value for the first drop down
            value2: "", // value of the second drop down
            value3: "", // value of the fourth drop down
            preDefinedValue: "",
            secondaryOptions: this.getSecondaryOptions(),
            preDefinedValues: this.getPredefinedValues(),
            thirdOptions: [],
        };
    }

    componentDidMount = () => {
        this.setState({
            ...this.props.filter,
        }, () => {
            this.props.fetchPricingListings();
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.listings !== this.props.listings) {
            this.setState({
                secondaryOptions: this.getSecondaryOptions(),
                thirdOptions: this.getThirdOptions(),
            });
        }
        if (prevState.preDefinedValue !== prevState.preDefinedValue) {
            this.setState({
                preDefinedValues: this.getPredefinedValues(),
            });
        }
        if (prevState.value2 !== this.state.value2) {
            this.setState({
                thirdOptions: this.getThirdOptions(),
            })
        }
}

    getPredefinedValues = () => {
        let preDefinedValues = FILTERS.find(filter =>
            filter.name === this.props.filter.name && filter.category === this.props.ruleCategory
        )?.predefinedValues;

        if (!preDefinedValues) {
            preDefinedValues = FILTERS.find(filter =>
                filter.name === this.props.filter.name && filter.category === this.props.ruleCategory
            )?.predefinedValues;
        }

        if (!preDefinedValues) {
            preDefinedValues = []
        }

        return preDefinedValues
    }
    getSecondaryOptions = () => {
        if(this.props.filter.name == "SKU"){
            if(this.props.listings){
                return this.props.listings.map(l => l.sku)
            }
        }

        let foundFilter = FILTERS.find(filter => filter.name === this.props.filter.name && filter.category === this.props.ruleCategory)

        return foundFilter ? foundFilter.secondaryOptions : []
    }

    getThirdOptions = () => {
        if(this.props.filter.name == "SKU"){
            if (this.props.listings && this.state.value2) {
                const matchedListing = this.props.listings.find(listing => listing.sku === this.state.value2);
                if (matchedListing && matchedListing.relatedProductsAsins) {
                    return matchedListing.relatedProductsAsins;
                }
            }
        }
        let foundFilter = FILTERS.find(filter => filter.name === this.props.filter.name && filter.category === this.props.ruleCategory)
        return foundFilter ? foundFilter.thirdOptions : []
    }

    handleValue1Change = (event) => {
        const value1 = event.target.value;
        this.setState(
            {
                value1,
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    };

    handleValue2Change = (event, value) => {
        this.setState(
            {
                value2: value,
                value3: ""
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    };

    handleValue3Change = (event, value) => {
        this.setState(
            {
                value3: value,
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    };

    handlePreDefinedValueChange = (e) => {
        this.setState({
            preDefinedValue: e.target.value,
            value3: ""
        }, () => {
            this.props.updateFilterWithParent({ ...this.state })})
    }

    render() {
        return (
            <Box
                className='filterTextFieldWithPredefinedSelect'
                display='flex'
                flexDirection='column'
                justifyContent='left'
                alignItems='center'
            >
                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                    <Box className='filterTextFieldWithPredefinedSelectName'>{this.state.name}</Box>

                    <Select
                        className='filterTextFieldWithPredefinedSelectOptions'
                        variant='outlined'
                        value={this.state.value1}
                        onChange={this.handleValue1Change}
                    >
                        {FILTER_TYPE_BOOLEAN_2_OPTIONS.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>

                    <Autocomplete
                        className='filterTextFieldWithPredefinedInput'
                        variant='outlined'
                        size='small'
                        label={this.state.name}
                        onChange={this.handleValue2Change}
                        autoHighlight={true}
                        options={this.state.secondaryOptions}
                        getOptionLabel={(option) => option}
                        value={this.state.value2}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                name={this.state.value2}
                                size='small'
                                style={{padding: "2px"}}
                            />

                        }
                    />

                    <div className='filterTextFieldWithPredefinedEmptyDiv'>
                        <Tooltip title={COPY_FILTER}>
                            <IconButton
                                aria-label={COPY_FILTER}
                                onClick={() => this.props.copyFilter(this.state.filterId)}>
                                <FileCopyIcon fontSize='small'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={DELETE_FILTER}>
                            <IconButton
                                aria-label={DELETE_FILTER}
                                onClick={() => this.props.deleteFilter(this.state.filterId)}
                            >
                                <DeleteOutlineIcon variant='outlined' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={DRAG}>
                            <IconButton
                                aria-label='drag'
                            >
                                <DehazeIcon variant='outlined' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Box>

                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                    <Box className='filterTextFieldWithPredefinedSelectName'>
                        {"trigger equals to"}
                    </Box>

                    <Select
                        variant="outlined"
                        name="preDefinedValue"
                        onChange={this.handlePreDefinedValueChange}
                        value={this.state.preDefinedValue}
                        className='filterTextFieldWithPredefinedSelectOptions'
                    >
                        {this.state.preDefinedValues.map((value, index) => (
                            <MenuItem value={value} key={index}>{value}</MenuItem>
                        ))
                        }
                    </Select>
                    {this.state.preDefinedValue === "Related product ASIN" ?
                        <Autocomplete
                            className='filterTextFieldWithPredefinedInput'
                            variant='outlined'
                            size='small'
                            label={this.state.name}
                            onChange={this.handleValue3Change}
                            autoHighlight={true}
                            options={this.state.thirdOptions}
                            getOptionLabel={(option) => option}
                            value={this.state.value3}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name={this.state.value3}
                                    size='small'
                                    style={{padding: "2px"}}
                                />

                            }
                        />
                        :
                        <></>
                    }
                </Box>

            </Box>

        );
    }
}

FilterTextFieldWithPredefined.propTypes = {
    filter: PropTypes.object,
    updateFilterWithParent: PropTypes.func,
    deleteFilter: PropTypes.func,
    isEdit: PropTypes.bool,
    copyFilter: PropTypes.func,
    availableLocations: PropTypes.array,
}

FilterTextFieldWithPredefined.defaultProps = {
    filter: {},
    updateFilterWithParent: ()=>{},
    deleteFilter: ()=>{},
    isEdit: false,
    copyFilter: ()=>{},
    listings:[],
}
const mapStateToProps = (state) => ({
    listings: pricingListingsSelector(state),
})

const actionCreators = {
    fetchPricingListings,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, FilterTextFieldWithPredefined);
