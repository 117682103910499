import React from 'react'

// Constants
import {ASC, DESC, TRACKING_ID_REGEX} from "../../global/constants";
import {
    DEFAULT_SORT_BY_HEAD_CELL,
    CARRIER_INVOICE_HEAD_CELLS,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_LABEL,
    NEEDS_REVIEW_STATUS,
    APPROVE_STATUS,
    PAID_STATUS,
    REPROCESSING_STATUS, CARRIER_TYPES, CHARGES_MAP, INFO_REQUIRED, DISPUTE_STATUS,
} from './constants';

// Table Components
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import {
    Collapse,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    TableHead,
    TablePagination,
    withStyles
} from "@material-ui/core"
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableBody from "@material-ui/core/TableBody";
import SearchableTable from "../../global/Search/SearchableTable";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";
import './CarrierInvoiceTable.css'
// Icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import InvoiceFiltersDialog from '../InvoiceFiltersDialog';
import FilterTagsBar from '../../shipments/FilterTagsBar';
import FilterIcon from "../../shipments/FilterIcon";
import { Slide } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
    fetchInvoiceByTrackingId,
    setInvoiceItemSmartSearchDetails,
    setInvoiceSmartSearchRows
} from "../../../redux/actions/invoices";
import withShipment from "../../../withShipment";
import {carrierInvoiceLoadingStatusSelector} from "../../../redux/selectors/invoices";
import CarrierInvoiceItemDetails from "./CarrierInvoiceItemDetails";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LocationSelectBox from "../../global/subcomponents/locationSelectBox/LocationSelectBox";
import {UNASSIGNED_LOCATION_NAME} from "../../shipments/constants";
import {COMPLETED_STATUS} from "../../returns/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CarrierInvoiceForm from "./CarrierInvoiceForm";
import DescriptionIcon from "@material-ui/icons/Description";
import {CreditCard} from "@material-ui/icons";
import PDFViewer from "../../global/PDFViewer";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";


const GreenRadio = withStyles({
    root: {
        color: "#acd685",
        '&$checked': {
            color: "#acd685",
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

class CarrierInvoicesTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            isSearch: false,
            searchedByTrackingId: false,
            searchValue: "",
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            shownIndex: -1,
            deleteId: "",
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            filtersMap: {},
            showFilters: false,
            filtersMapBeforeOpenDialog: {},
            deletedTagsWithCookies: [],
            selectedStatus: [NEEDS_REVIEW_STATUS, APPROVE_STATUS, PAID_STATUS, REPROCESSING_STATUS, INFO_REQUIRED, DISPUTE_STATUS],
            allSelected: false,
        }
        this.onSearchValueChange = this.onSearchValueChange.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleRequestSort = this.handleRequestSort.bind(this)
        this.getDisplayed = this.getDisplayed.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.selectAll = this.selectAll.bind(this)
    }

    async componentDidMount() {
        let changeBack = false
        this.setState({
            searchValue: this.props.searchText,
            filtersMap: this.props.tableSetting?.carrierInvoiceFilterSetting,
        })
        if (this.props.invoiceSmartSearchRows.length !==0 ) {
            this.setState({invoices: this.props.invoiceSmartSearchRows})
            return
        }
        if (this.props.data.length !== 0) {
            this.setState({invoices: this.props.data})
            return
        }
        let storage = JSON.parse(localStorage.getItem("carrierInvoices"))
        if (storage) {
            await this.getNewDates()
            const expiry = parseInt(JSON.parse(localStorage.getItem("expiry")))
            if (storage.fromDate !== null && storage.fromDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.onFiltersClose(true, false)
                } else {
                    changeBack = true
                }
            }
            if (storage.toDate !== null && storage.toDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.onFiltersClose(true, false)
                } else {
                    changeBack = true
                }
            }
            if (changeBack) {
                localStorage.removeItem("carrierInvoices")
                this.setState({
                    fromDate: this.getFromDate(),
                    toDate: this.getToDate(),
                    filtersMap: {}
                })
            }
        }

        if (this.props.invoiceSmartSearchRows.length > 0) {
            this.setState({ invoices: this.props.invoiceSmartSearchRows})
        } else if (this.props.data.length > 0 ) {
            this.setState({invoices: this.props.data})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.invoiceSmartSearchRows !== this.props.invoiceSmartSearchRows) {
            if (this.props.invoiceSmartSearchRows.length > 0) {
                this.setState({ invoices: this.props.invoiceSmartSearchRows})
            } else {
                this.setState({invoices: this.props.data})
            }
        } else if (prevProps.data !== this.props.data && this.props.invoiceSmartSearchRows.length === 0) {
            this.setState({invoices: this.props.data})
        }

        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.initializeSearchText()
            if (this.props.invoiceSmartSearchRows && this.props.invoiceSmartSearchRows.length > 0){
                if (!this.isSearchByTrackingId(this.state.searchValue)){
                    this.props.setInvoiceSmartSearchRows([])
                    this.props.setInvoiceItemSmartSearchDetails([])
                }
            }
        }

        if (prevProps.tableSetting?.carrierInvoiceFilterSetting !== this.props.tableSetting?.carrierInvoiceFilterSetting) {
            this.setState({
                filtersMap: this.addFilterDates(this.props.tableSetting?.carrierInvoiceFilterSetting),
                allSelected: false,
            })
            this.props.setSelected({}, !!this.numSelected({}))
        }
        if (this.state.searchedByTrackingId){
            this.searchByTrackingId()
            this.setState({
                searchedByTrackingId: false
            })
        }
    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.props.searchText })
    }

    getFromDate = () => {
        var date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        var date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() == defaultFromDate.getDate() &&
            someDate.getMonth() == defaultFromDate.getMonth() &&
            someDate.getFullYear() == defaultFromDate.getFullYear()
    }

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    getNewDates = () => {
        let filter = {...this.state.filtersMap}
        let storage = JSON.parse(localStorage?.getItem("carrierInvoices"))
        if (storage.fromDate !== null && storage.fromDate !== undefined ) {
            const temp = new Date(storage.fromDate)
            filter.fromDate = temp;
            this.setState({
                fromDate: temp
            })
        }
        if (storage.toDate !== null && storage.toDate !== undefined) {
            const temp = new Date(storage.toDate)
            filter.toDate = temp;
            this.setState({
                toDate: temp
            })
        }

        if (filter) {
            this.setState({
                filtersMap: filter,
            })
        }
    }

    addFilterDates = (filters) => {
        if (filters) {
            if (!this.isDefaultFromDate(this.state.fromDate)) {
                filters['fromDate'] = this.state.fromDate
            }
            if (!this.isToday(this.state.toDate)) {
                filters['toDate'] = this.state.toDate
            }
        }
        return filters
    }

    isSearchByTrackingId = (search) => {
        for (let i = 0; i < TRACKING_ID_REGEX.length; i++) {
            if (TRACKING_ID_REGEX[i].test(search)) {
                return true
            }
        }
    }

    onSearchValueChange(e) {
        if (this.isSearchByTrackingId(e.target.value)){
            this.setState({
                searchedByTrackingId: true,
            });
        }
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
        });

        this.props.handleSearchChange(e)
    };

    searchByTrackingId = () => {
        this.props.fetchInvoiceByTrackingId(this.state.searchValue)
    }

    handleChangePage = (event, newPage) => {
        this.props.setPage(newPage)
    };

    handleChangeRowsPerPage = (event) => {
        this.props.setRowsPerPage(event.target.value)
        this.props.setPage(0)
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.props.page * this.props.rowsPerPage, (this.props.page + 1) * this.props.rowsPerPage)]
    }

    handleDelete() {
        this.props.deleteInvoice({ carrierInvoiceId: this.state.deleteId , carrierType: this.props.carrierType})
        this.setState({
            deleteId: ""
        })
        this.props.clearSelectedInvoices()
    }

    handleCheck(e, invoice) {
        let newEntries = { ...this.props.checkedEntries, [invoice.carrierInvoiceId]: e.target.checked }
        this.props.setSelected(newEntries, !!this.numSelected(newEntries))
    }
    selectAll(e) {
        this.setState({
            allSelected: !this.state.allSelected
        })
        let newChecked = {}
        let allEntries = this.getDisplayed(this.stableSort(this.filterBySearch(this.applyOverviewFilters(this.state.invoices)), this.getComparator(this.state.order, this.state.orderBy)))
        allEntries.map((entry) => newChecked[entry.carrierInvoiceId] = this.state.allSelected)
        this.props.setSelected(newChecked, !!this.numSelected(newChecked))
    }
    numSelected(checkedEntries) {
        let rows = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.state.invoices)), this.getComparator(this.state.order, this.state.orderBy)))
        return rows.reduceRight((accum, curr) => accum + (checkedEntries[curr.carrierInvoiceId] ?? 0), 0)
    }

    toggleFiltersDialog = () => {
        this.setState({
            showFilters: !this.state.showFilters,
            filtersMapBeforeOpenDialog: { ...this.state.filtersMap }
        })
    }

    handleStatusChange = (event) => {
        let filters = { ...this.state.filtersMap };
        let checked = event.target.checked
        let name = event.target.name
        let statuses = []
        if (checked) {
            statuses = [...this.state.selectedStatus, name]

            this.setState({
                selectedStatus: statuses
            });
        }
        // remove from the list
        else {
            let index = this.state.selectedStatus.indexOf(name)
            let selected = this.state.selectedStatus
            selected.splice(index, 1)
            statuses = selected
            this.setState({
                selectedStatus: selected
            });
        }
        filters.selectedStatus = statuses

        this.saveUpdatedStatus(filters);

        // this.onFiltersInputChange(event);
    }

    onFiltersInputChange = (name, val) => {
        const filtersMap = { ...this.state.filtersMap };

        // if filter already exists, then update
        if (val !== "" && val.length !== 0) {
            filtersMap[name] = val;
        }
        else {
            delete filtersMap[name];
        }

        this.saveUpdatedStatus(filtersMap)

        this.setState({
            filtersMap: filtersMap,
        });

    }

    saveUpdatedStatus = (filters) => {
        delete filters['fromDate']
        delete filters['toDate']
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changeCarrierInvoiceFilters: true,
            carrierInvoiceFilterSetting: filters  ?? []
        })
    }

    handleDateChange = (date, key) => {
        const filtersMap = { ...this.state.filtersMap };
        if (key === 'fromDate' && !this.isDefaultFromDate(date) ||
            key === 'toDate' && !this.isToday(date)) {
            filtersMap[key] = date;
        } else {
            delete filtersMap[key]
        }
        this.setState({
            filtersMap: filtersMap,
            [key]: date
        })
    }

    onFiltersClose = (applyFilters, saveFilterSetting) => {
        // NOTE: changes are already applied as the user makes these changes,
        // but if the changes are cancelled, then the map is reverted to its
        // previous state
        this.setState({
            showFilters: false,
        })
        let filters = {}
        if (!applyFilters) {
            filters = { ...this.state.filtersMapBeforeOpenDialog }
            this.resetDateFilters(filters?.fromDate ?? this.getFromDate(), filters?.toDate ?? this.getToDate(), filters)
        } else {
            if (this.state.deletedTagsWithCookies.length > 0) {
                let storage = JSON.parse(localStorage.getItem("carrierInvoices"))
                this.state.deletedTagsWithCookies.forEach((tagName) => {
                    delete storage[tagName]
                })
                localStorage.setItem("carrierInvoices", JSON.stringify(storage))
                if(JSON.stringify(storage) === JSON.stringify({})) localStorage.removeItem("carrierInvoices")
                this.setState({deletedTagsWithCookies: []})
            }
            filters = { ...this.state.filtersMap }
            const expiry = new Date().setHours(23, 59, 59, 0);
            let needsRefresh = this.props.refetch
            if (filters.fromDate !== null && filters.fromDate !== undefined) {
                let existing = localStorage.getItem("carrierInvoices")
                existing = existing ? JSON.parse(existing) : {};
                if(existing.fromDate!==filters.fromDate.toISOString()) {
                    needsRefresh = true
                }
                existing.fromDate = filters.fromDate;
                localStorage.setItem("carrierInvoices", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.toDate !== null && filters.toDate !== undefined) {
                let existing = localStorage.getItem("carrierInvoices")
                existing = existing ? JSON.parse(existing) : {};
                if(existing.toDate!==filters.toDate.toISOString()) {
                    needsRefresh = true
                }
                existing.toDate = filters.toDate;
                localStorage.setItem("carrierInvoices", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }

            //fetching all the invoices
            if (this.props.data.length===0||needsRefresh) {
                this.fetchCarrierInvoices(this.state.fromDate, this.state.toDate)
                this.props.setRefetch(false)
            }


        }
        if (saveFilterSetting) {
            this.saveUpdatedStatus(filters)
        }
    }

    resetDateFilters = (fromDate, toDate, filters) => {
        if (!this.isDefaultFromDate(fromDate)) {
            filters['fromDate'] = fromDate
        } else {
            delete filters['fromDate']
            fromDate = this.getFromDate()
        }
        if (!this.isToday(toDate)) {
            filters['toDate'] = toDate
        } else {
            delete filters['toDate']
            toDate = this.getToDate()
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            deletedTagsWithCookies: [],
            filtersMap: filters
        })
    }

    onTagClickedWhenDialogueOpen = (event, tag) => {
        const filtersMap = { ...this.state.filtersMap };
        let tagValue = filtersMap[tag]
        let deletedTagsWithCookies = this.state.deletedTagsWithCookies
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('carrierInvoices'))
            if (storage) {
                if (storage.fromDate !== null && storage.fromDate !== undefined) {
                    if (new Date(storage.fromDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "fromDate"]})
                    }
                }
            }
            this.setState({
                fromDate: this.getFromDate()
            })
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('carrierInvoices'))
            if (storage) {
                if (storage.toDate !== null && storage.toDate !== undefined) {
                    if (new Date(storage.toDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "toDate"]})
                    }
                }
            }
            this.setState({
                toDate: this.getToDate()
            })
        }
        this.saveUpdatedStatus(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }

    onTagClicked = (event, tag) => {

        // remove the key from the filters map
        // clone the map, delete the key, set the state
        const filtersMap = { ...this.state.filtersMap };
        delete filtersMap[tag];

        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('carrierInvoices'))
            delete storage.fromDate
            this.setState({
                fromDate: this.getFromDate()
            }, () => this.fetchCarrierInvoices(this.state.fromDate, this.state.toDate))
            localStorage.setItem("carrierInvoices", JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem("carrierInvoices")
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('carrierInvoices'))
            delete storage.toDate
            this.setState({
                toDate: this.getToDate()
            }, () => this.fetchCarrierInvoices(this.state.fromDate, this.state.toDate))
            localStorage.setItem("carrierInvoices", JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem("carrierInvoices")
        }
        this.saveUpdatedStatus(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }

    fetchCarrierInvoices = (fromDate, toDate) => {
        this.props.fetchCarrierInvoices({
            fromDate: fromDate,
            toDate: toDate
        })
    }

    applyOverviewFilters = (rows) => {
        if (this.state.selectedStatus) {
            rows = rows.filter((item) => item &&this.state.selectedStatus.includes(item.status))
        }
        const carriers = this.state.filtersMap?.carriers;
        if (carriers) {
            rows = rows.filter(row => {
                let carrierFilterMap = this.props.carriers?.map(s => ({carrierString: s.name + ", " + s.accountNumber + " (" + s.alias + ")", carrierId: s.carrierId}))
                carrierFilterMap = carrierFilterMap.filter(carrierFilterMapEntry => this.state.filtersMap.carriers.includes(carrierFilterMapEntry.carrierString))
                return carrierFilterMap?.find(carrierFilterMapEntry => carrierFilterMapEntry.carrierId === row.carrierId)
            })
        }

        return rows;
    }

    formatChargesName = (charge) => {
        let res = CHARGES_MAP.find(({charge: mapCharge, mapsTo})=>(
            mapCharge === charge
        ))
        if (res)
            return res.mapsTo
        else {
            return charge
        }
    }

    extractWordInBrackets = (str) => {
        const regex = /Extra Charge \(([^)]+)\)/;
        const match = str.match(regex);
        if (match) {
            return this.formatChargesName(match[1]);
        }
        return str;
    };

    formatErrorMessage = (message) => {
        if (message.includes("difference of")) {
            const parts = message.split(/, /);
            const firstPart = parts[0].split(' summary total')[0];
            const details = parts.map((part, index) => {
                const [text, amount] = part.split(/(-?\$\d+\.\d+)/);
                return (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <span>{text?.replace(firstPart,"").replace("summary total", "- Summary Total").replace("calculated total", "- Calculated Total").replace("difference of", "- Difference of").trim()}</span>
                        <span>${this.formatAmount(amount?.replace("$",""))}</span>
                    </div>
                );
            });
            let chargeName = this.formatChargesName(firstPart.split(" charge")[0])
            return (
                <div style={{ display: 'block', margin: '5px', textAlign: 'left' }}>
                    <div>{chargeName + " Charge"}</div>
                    {details}
                </div>
            );
        }
        else if (message.includes("Failed") || message.includes("Error")) {
            return <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                <div>{message}</div>
            </div>
        }
        else {
            let [chargeName, amount] = message.split(/\$([0-9.]+)/);
            if (chargeName.includes("Extra Charge")) {
                let str = this.extractWordInBrackets(chargeName)
                return <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                    <div>{"Extra Charge (" + str + ")" }</div>
                    <div>${this.formatAmount(amount)}</div>
                </div>
            }
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                    <div>{chargeName.trim()}</div>
                    <div>${this.formatAmount(amount)}</div>
                </div>
            );
        }
    };

    removeStatusFilters = () => {
        let filters = { ...this.state.filtersMap }
        delete filters['selectedStatus']
        return filters
    }

    handleEdit = (invoice) => {
        this.props.setEditing(invoice)
    }

    formatAmount = (amount) => {
        const num = parseFloat(amount);
        // Ensure the conversion is successful before formatting
        if (!isNaN(num)) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(num);
        }
        return 'Invalid number';
    }

    render() {
        let viewOnly = !this.props.user.routes.includes('carrier-invoices-edit')
        return (
            <>
                {this.props.viewingPdf ?
                    <PDFViewer data={this.props.invoicePDF} scale={this.props.scale} />
                    : !this.props.editing ?
                    <div>
                        <div className='filter-tag-wrapper'>
                            <FilterTagsBar
                                filtersMap={this.removeStatusFilters()}
                                onClick={this.state.showFilters ? this.onTagClickedWhenDialogueOpen : this.onTagClicked}
                            />
                        </div>

                        <CustomTableHeader
                            searchText={this.props.searchText}
                            onSearchValueChange={this.onSearchValueChange}
                            pagination={
                                <TablePagination
                                    className="table-pagination"
                                    labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                    rowsPerPageOptions={[5, 25, 50, 100, {
                                        value: this.state.invoices.length,
                                        label: "All"
                                    }]}
                                    count={!this.state.invoices ? 0 : this.filterBySearch(this.applyOverviewFilters(this.state.invoices)).length}
                                    rowsPerPage={this.props.rowsPerPage}
                                    page={this.props.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            }
                            customFilterField={
                                <>
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(NEEDS_REVIEW_STATUS) : false}
                                                onChange={this.handleStatusChange} name={NEEDS_REVIEW_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={NEEDS_REVIEW_STATUS}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(APPROVE_STATUS) : false}
                                                onChange={this.handleStatusChange} name={APPROVE_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={APPROVE_STATUS}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(PAID_STATUS) : false}
                                                onChange={this.handleStatusChange} name={PAID_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={PAID_STATUS}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(REPROCESSING_STATUS) : false}
                                                onChange={this.handleStatusChange} name={REPROCESSING_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={REPROCESSING_STATUS}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(INFO_REQUIRED) : false}
                                                onChange={this.handleStatusChange} name={INFO_REQUIRED}
                                                style={{color: '#acd685'}}/>}
                                            label={INFO_REQUIRED}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(DISPUTE_STATUS) : false}
                                                onChange={this.handleStatusChange} name={DISPUTE_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={DISPUTE_STATUS}
                                        />
                                    </div>
                                </>
                            }
                            filterIcon={
                                <Tooltip title={'Filter'} style={{paddingTop: 10}}>
                                    <IconButton className="filterButton" size='small' aria-label={'Filter'}
                                                onClick={this.toggleFiltersDialog}>
                                        <FilterIcon/>
                                    </IconButton>
                                </Tooltip>
                            }
                            locationField={
                                <Autocomplete
                                    style={{width: '220px', marginLeft: '28px', marginBottom: '10px'}}
                                    options={CARRIER_TYPES}
                                    disableClearable={true}
                                    autoHighlight={true}
                                    className="carrierTypes"
                                    onChange={(event, value) => {
                                        this.props.changeCarrierType(value)
                                    }}
                                    value={this.props.carrierType}
                                    name="issue"
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Carrier Type"
                                            InputLabelProps={{shrink: true}}
                                            variant="outlined"
                                            name="issueType"
                                            required
                                        />
                                    }
                                />
                            }
                            showFilters={this.state.showFilters}
                            filtersComponent={
                                <Slide in={this.state.showFilters} mountOnEnter unmountOnExit timeout={300}>
                                    <InvoiceFiltersDialog
                                        open={this.state.showFilters}
                                        onClose={this.onFiltersClose}
                                        onChange={this.onFiltersInputChange}
                                        listItems={this.props.carriers.map(s => s.name + ", " + s.accountNumber + " (" + s.alias + ")")}
                                        handleDateChange={this.handleDateChange}
                                        filtersMap={this.state.filtersMap}
                                        clearFilters={this.clearFiltersMap}
                                        onTagClicked={this.onTagClicked}
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        fieldName={'carriers'}
                                        fieldTitle={'Carrier'}
                                    />
                                </Slide>}
                        />
                        {this.props.loadingTable?
                            <div className="getPrinterLoadingOuterBox">
                                <div className="getPrinterLoadingBox">
                                    <CircularProgress size={100} />
                                </div>
                            </div>
                            :
                            <TableContainer>
                                <Table size='medium'>
                                    <GlobalTableHead
                                        isCreatePurchaseOrderTable={false}
                                        isShipmentTable={false}
                                        isPurchaseOrderTable={false}
                                        headCells={CARRIER_INVOICE_HEAD_CELLS}
                                        onSelectAllClick={this.selectAll}
                                        numSelected={this.numSelected(this.props.checkedEntries)}
                                        isInvoiceTable={true} // might cause issues later since isInvoiceTable was intended for supplier invoices
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={!this.state.invoices ? 0 : this.state.invoices.length}
                                        disableCheckbox={viewOnly}
                                    />
                                    <TableBody>
                                        {this.getDisplayed(this.stableSort(this.filterBySearch(this.applyOverviewFilters(this.state.invoices)), this.getComparator(this.state.order, this.state.orderBy))).map((invoice, invoiceIndex) => (
                                            <TableRow hover onDoubleClick={() => this.props.handleView(invoice)}
                                                      key={"invoice-" + invoiceIndex}>
                                                <TableCell>
                                                    <IconButton size="small" disabled={viewOnly}
                                                                onClick={() => this.handleEdit(invoice)}>
                                                        <EditIcon/>
                                                        {/*<EditIcon />*/}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell style={{padding: "0.2rem"}}>
                                                    <Checkbox
                                                        checked={this.props.checkedEntries[invoice.carrierInvoiceId] ?? false}
                                                        onChange={(e) => this.handleCheck(e, invoice)}
                                                        disabled={viewOnly}
                                                    />
                                                </TableCell>
                                                {CARRIER_INVOICE_HEAD_CELLS.map((headCell, index) => (
                                                    <TableCell
                                                        style={{
                                                            textAlign: headCell.rightAlign ? "right" : (headCell.id === "totalShipments" || headCell.id === "previouslyBilledShipments" || headCell.id === "failedShipments" || headCell.id === "status" ? "center" : "left"),
                                                            padding: "5px",
                                                            paddingRight: headCell.rightAlign ? "50px" : "5px",
                                                            width: headCell.id === "status" ? "200px" : "auto",
                                                            color: (headCell.id === "status" && (invoice.status === NEEDS_REVIEW_STATUS) || (headCell.id === "failedShipments" && (invoice.failedShipments > 0))) ? "red"
                                                                : (headCell.id === "status" && invoice.status === REPROCESSING_STATUS) ? "grey" : "black",
                                                        }}
                                                        key={index}
                                                    >
                                                        {headCell.id === "total" ?
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                gap: '3px',
                                                                marginRight: '15px'
                                                            }}>
                                                                <span style={{flexShrink: 0}}>$</span>
                                                                <span style={{marginLeft: 'auto'}}>{
                                                                    this.formatAmount(invoice.summaryCharges?.total)
                                                                }</span>
                                                            </div>
                                                            :
                                                            invoice[headCell.id]
                                                        }
                                                        {(headCell.id === 'status' && invoice[headCell.id] === NEEDS_REVIEW_STATUS) && !viewOnly ?
                                                            <IconButton aria-label='info'>
                                                                <Tooltip
                                                                    title={invoice.errorMessage ? invoice.errorMessage.length > 0 ?
                                                                        invoice.errorMessage.map(errorText => (
                                                                            <div
                                                                                key={index}>{this.formatErrorMessage(errorText)}</div>
                                                                        ))
                                                                        : "Error Message Not Available" : "Error Message Not Available"
                                                                    }
                                                                    classes={{tooltip: 'custom-tooltip'}}
                                                                    enterDelay={50}
                                                                >
                                                                    <ErrorOutlineIcon variant='outlined'
                                                                                      fontSize='small'/>
                                                                </Tooltip>
                                                            </IconButton>
                                                            : null
                                                        }
                                                        {(headCell.id === 'paymentInfo' && invoice.referenceId && invoice.paymentDate && invoice?.bankAccount?.accountNumber) ?
                                                            <IconButton aria-label='info'>
                                                                <Tooltip
                                                                    title={
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                                            <div className="invoice-details">
                                                                                <div
                                                                                    className="invoice-details-label">Reference
                                                                                    ID:
                                                                                </div>
                                                                                <div className="invoice-details-value">{
                                                                                    invoice.referenceId
                                                                                }</div>
                                                                            </div>
                                                                            <div className="invoice-details">
                                                                                <div
                                                                                    className="invoice-details-label">Payment
                                                                                    Date:
                                                                                </div>
                                                                                <div className="invoice-details-value">{
                                                                                    invoice.paymentDate
                                                                                }</div>
                                                                            </div>
                                                                            <div className="invoice-details">
                                                                                <div
                                                                                    className="invoice-details-label">Bank
                                                                                    Account:
                                                                                </div>
                                                                                <div className="invoice-details-value">{
                                                                                    invoice.bankAccount.name + " " + invoice.bankAccount.accountNumber
                                                                                }</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    enterDelay={50}
                                                                >
                                                                    <CreditCard variant='outlined' fontSize='small'/>
                                                                </Tooltip>
                                                            </IconButton>
                                                            : null
                                                        }
                                                        {(headCell.id === 'note' && invoice.notes &&
                                                            <IconButton>
                                                                <Tooltip
                                                                    title={invoice.notes}
                                                                >
                                                                    <SpeakerNotesIcon/>
                                                                </Tooltip>
                                                            </IconButton>

                                                        )
                                                        }
                                                    </TableCell>
                                                ))}

                                                <TwoStepDelete
                                                    rowId={invoice.carrierInvoiceId}
                                                    handleDelete={(rowIdToDelete) => this.handleDelete()}
                                                    deleteId={this.state.deleteId}
                                                    setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
                                                    disabled={viewOnly}
                                                />
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                        {this.props.invoiceItemSmartSearchDetails.length > 0 ?
                            <CarrierInvoiceItemDetails
                                invoices={this.state.invoices}
                                invoiceItemSmartSearchDetails={this.props.invoiceItemSmartSearchDetails}
                                openEditInvoiceEntry={this.props.openEditInvoiceEntry}
                            />
                            : null}
                    </div>
                    :
                        <CarrierInvoiceForm
                            saveCarrierInvoice={this.props.saveCarrierInvoice}
                            updateCarrierInvoiceStatus={this.props.updateCarrierInvoiceStatus}
                            key={this.props.editingInvoice?.status}
                            editingInvoice={this.props.editingInvoice}
                            bankAccounts={this.props.bankAccounts}
                            editSelectedInvoice={this.props.editSelectedInvoice}
                        />
                }
                </>
        )
    }
}

const actionCreators = {
    fetchInvoiceByTrackingId,
    setInvoiceSmartSearchRows,
    setInvoiceItemSmartSearchDetails,
}

const mapStateToProps = (state) => ({
    loadingTable: carrierInvoiceLoadingStatusSelector(state),
})

export default withShipment({
    actionCreators,
    mapStateToProps
}, CarrierInvoicesTable);