import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Tooltip,
} from '@mui/material';
import TablePage from "../../global/Table/TablePage";
import {
    DEFAULT_SORT_BY_LEDGER_NAME_CELL,
    LEDGER_TABLE_CELLS,
    LEDGERS,
    LEDGERS_PAGES,
    MARKETPLACE_MAPPING
} from './constants';
import {IconButton, Tab} from "@material-ui/core";
import './ledger.css';
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import LedgerTableRow from "./LedgerTableRow";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Upgrade } from '@mui/icons-material';
import * as XLSX from 'xlsx';

class LedgerTable extends React.Component {
    constructor(props) {
        super(props)
        const savedTab = localStorage.getItem('selectedTab') || "1";
        this.state = {
            deleteId: "",
            isDeleted: false,
            listingIdBeingEdited: '',
            tableEvents: [],
            isEdit: false,
            ledgers: [],
            hideFilterBox : false,
            value: savedTab,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.ledgers !== this.props.ledgers) {
            this.setState({ledgers:this.props.ledgers})
        }
    }

    componentDidMount() {
        this.props.listLedgers({currency: this.props.selectedCurrency, year: this.props.selectedYear})
        const savedTab = localStorage.getItem('selectedTab') || "1";
        this.setState({ value: savedTab },()=>{
            this.props.handleLedgerType(savedTab)
        });
    }


    handleTabChange = (event, newValue) => {
        this.setState({ value: newValue },()=>{
            localStorage.setItem('selectedTab', newValue);
            this.props.handleLedgerType(newValue)
        });

    };

    setLedgers = (ledgers) => {
        this.setState({ledgers: ledgers})
    }


    getSearchStrings = (row) => {
        let searchStrings = []
        if (row.name)
            searchStrings.push(row.name)
        if (row.balance)
            searchStrings.push(row.balance)
        return searchStrings
    }

    handleExport = () => {
        const { exportAllLedgers, ledgerType } = this.props;
        const workbook = XLSX.utils.book_new();
        const formatAmount = (amount) => {
            const num = parseFloat(amount);
            return isNaN(num) || num === 0 ? '' : Number(num.toFixed(2))
        };

        const addSheet = (sheetName, headers, data) => {
            const MAX_SHEET_NAME_LENGTH = 31
            const truncatedSheetName = sheetName.substring(0, MAX_SHEET_NAME_LENGTH)

            if (headers) {
                const worksheetData = [headers, ...data.map(row => headers.map(field => row[field] ?? ''))]
                const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)
                XLSX.utils.book_append_sheet(workbook, worksheet, truncatedSheetName)
            } else {
                const worksheetData = [headers, ...data.map(row => ["name", "amount"].map(field => row[field] ?? ''))]
                const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)
                XLSX.utils.book_append_sheet(workbook, worksheet, truncatedSheetName)
            }
        }

        if (!exportAllLedgers) return;

        // Iterate over each ledger in exportAllLedgers
        Object.keys(exportAllLedgers).forEach(ledgerName => {
            const ledgerData = exportAllLedgers[ledgerName];

            // Special case for "Sales"
            if (ledgerName === "Sales") {
                const { salesSummary, customerSummary, ledgerEntries } = ledgerData;
                const totalSales = (salesSummary || []).filter(entry => entry.name !== "Total").reduce((sum, row) => sum + parseFloat(row.totalBalance), 0);
                const totalCustomer = (customerSummary || []).filter(entry => entry.name !== "Total").reduce((sum, row) => sum + parseFloat(row.totalBalance), 0);
                const totalLedgerEntries = (ledgerEntries || []).filter(entry => entry.description !== "Sales").reduce((sum, entry) => sum + parseFloat(entry.credit), 0);

                // Combine all data into a single array
                const combinedData = [
                    {  name: "Sales" ,
                        amount: "Amount"
                    },
                    ...(salesSummary || []).filter(row => row.name !== "Total").map(row => ({
                        name: MARKETPLACE_MAPPING[row.name],
                        amount: formatAmount(row.totalBalance)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalSales)
                    },
                    // Add an empty line after sales summary
                    {},
                    {  name: "Customer" ,
                        amount: "Amount"
                    },
                    ...(customerSummary || []).filter(row => row.name !== "Total").map(row => ({
                        name: row.name,
                        amount: formatAmount(row.totalBalance)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalCustomer)
                    },
                    // Add an empty line after customer summary
                    {},

                    {  name: "Other" ,
                        amount: "Amount"
                    },
                    ...(ledgerEntries || []).filter(entry => entry.description !== "Sales").map(entry => ({
                        name: entry.description,
                        amount: formatAmount(entry.credit)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalLedgerEntries)
                    },
                    // Add an empty line after ledger entries
                    {},
                ];



                // Combine all totals into one final total row
                const grandTotal = (totalSales + totalCustomer + totalLedgerEntries).toFixed(2);

                // Add the grand total row
                combinedData.push({
                    name: "Sales Total",
                    amount: formatAmount(grandTotal)
                });

                // Define headers
                const headers = null

                // Add the combined data to a single sheet
                addSheet('Sales', headers, combinedData);
            }
            else if (ledgerName === "Accounts Receivables") {
                const { marketplaceSummary, customerSummary, ledgerEntries } = ledgerData;

                const totalMarketplace = (marketplaceSummary || []).filter(entry => entry.name !== "Total").reduce((sum, row) => sum + parseFloat(row.totalBalance), 0);
                const totalCustomer = (customerSummary || []).filter(entry => entry.name !== "Total").reduce((sum, row) => sum + parseFloat(row.totalBalance), 0);
                const totalLedgerEntries = (ledgerEntries || []).filter(entry => entry.description !== "Accounts Receivables").reduce((sum, entry) => sum + parseFloat(entry.credit), 0);


                // Combine all data into a single array
                const combinedData = [
                    {  name: "Marketplace" ,
                        amount: "Amount"
                    },
                    ...(marketplaceSummary || []).filter(row => row.name !== "Total").map(row => ({
                        name: MARKETPLACE_MAPPING[row.name],
                        amount: formatAmount(row.totalBalance)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalMarketplace)
                    },
                    // Add an empty line after sales summary
                    {},
                    {  name: "Customer" ,
                        amount: "Amount"
                    },
                    ...(customerSummary || []).filter(row => row.name !== "Total").map(row => ({
                        name: row.name,
                        amount: formatAmount(row.totalBalance)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalCustomer)
                    },
                    // Add an empty line after customer summary
                    {},

                    {  name: "Other" ,
                        amount: "Amount"
                    },
                    ...(ledgerEntries || []).filter(entry => entry.description !== "Accounts Receivables").map(entry => ({
                        name: entry.description,
                        amount: formatAmount(entry.credit)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalLedgerEntries)
                    },
                    // Add an empty line after ledger entries
                    {},
                ];



                // Combine all totals into one final total row
                const grandTotal = (totalMarketplace + totalCustomer + totalLedgerEntries).toFixed(2);

                // Add the grand total row
                combinedData.push({
                    name: "Accounts Receivables Total",
                    amount: formatAmount(grandTotal)
                });

                // Define headers
                const headers = null

                // Add the combined data to a single sheet
                addSheet('Accounts Receivables', headers, combinedData);
            }
            else if (ledgerName === "Accounts Payable") {
                const { carrierSummary, ftlSummary, supplierSummary, ledgerEntries } = ledgerData;

                const totalCarrier = (carrierSummary || []).filter(entry => entry.carrierName !== "Total").reduce((sum, row) => sum + parseFloat(row.CY_SUBTOTAL_PAYABLE), 0);
                const totalFTL= (ftlSummary || []).filter(entry => entry.carrierName !== "Total").reduce((sum, row) => sum + parseFloat(row.CY_SUBTOTAL_PAYABLE), 0);
                const totalSupplier = (supplierSummary || []).filter(entry => entry.supplierName !== "Total").reduce((sum, row) => sum + parseFloat(row.CY_SUBTOTAL_PAYABLE), 0);
                const totalLedgerEntries = (ledgerEntries || []).filter(entry => entry.description !== "Accounts Payable").reduce((sum, entry) => sum + parseFloat(entry.credit), 0);


                // Combine all data into a single array
                const combinedData = [
                    {  name: "Standard Carrier" ,
                        amount: "Amount"
                    },
                    ...(carrierSummary || []).filter(row => row.carrierName !== "Total").map(row => ({
                        name: row.carrierName,
                        amount: formatAmount(row.CY_SUBTOTAL_PAYABLE)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalCarrier)
                    },
                    // Add an empty line after sales summary
                    {},
                    {  name: "LTL/FTL Carrier" ,
                        amount: "Amount"
                    },
                    ...(ftlSummary || []).filter(row => row.carrierName !== "Total").map(row => ({
                        name: row.carrierName,
                        amount: formatAmount(row.CY_SUBTOTAL_PAYABLE)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalFTL)
                    },
                    // Add an empty line after customer summary
                    {},
                    {  name: "Supplier" ,
                        amount: "Amount"
                    },
                    ...(supplierSummary || []).filter(row => row.supplierName !== "Total").map(row => ({
                        name: row.supplierName,
                        amount: formatAmount(row.CY_SUBTOTAL_PAYABLE)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalSupplier)
                    },
                    // Add an empty line after customer summary
                    {},

                    {  name: "Other" ,
                        amount: "Amount"
                    },
                    ...(ledgerEntries || []).filter(entry => entry.description !== "Accounts Payable").map(entry => ({
                        name: entry.description,
                        amount: formatAmount(entry.credit)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalLedgerEntries)
                    },
                    // Add an empty line after ledger entries
                    {},
                ];



                // Combine all totals into one final total row
                const grandTotal = (totalCarrier + totalFTL + totalSupplier + totalLedgerEntries).toFixed(2);

                // Add the grand total row
                combinedData.push({
                    name: "Accounts Payable Total",
                    amount: formatAmount(grandTotal)
                });

                // Define headers
                const headers = null

                // Add the combined data to a single sheet
                addSheet('Accounts Payable', headers, combinedData);
            }
            else if (ledgerName === "Cost of Sales") {
                const { stockSummary,carrierSummary, ftlSummary, supplierSummary, ledgerEntries } = ledgerData;

                const totalStock = (stockSummary || []).reduce((sum, row) => sum + parseFloat(row.balance), 0);
                const totalCarrier = (carrierSummary || []).filter(entry => entry.carrierName !== "Total").reduce((sum, row) => sum + parseFloat(row.CY_SUBTOTAL_PAYABLE), 0);
                const totalFTL= (ftlSummary || []).filter(entry => entry.carrierName !== "Total").reduce((sum, row) => sum + parseFloat(row.CY_SUBTOTAL_PAYABLE), 0);
                const totalSupplier = (supplierSummary || []).filter(entry => entry.supplierName !== "Total").reduce((sum, row) => sum + parseFloat(row.CY_SUBTOTAL_PAYABLE), 0);
                const totalLedgerEntries = (ledgerEntries || []).filter(entry => entry.description !== "Cost of Sales").reduce((sum, entry) => sum + parseFloat(entry.credit), 0);



                // Combine all data into a single array
                const combinedData = [
                    {  name: "Stock" ,
                        amount: "Amount"
                    },
                    ...(stockSummary || []).map(row => ({
                        name: row.name,
                        amount: formatAmount(row.balance)
                    })),
                    {},
                    {  name: "Standard Carrier" ,
                        amount: "Amount"
                    },
                    ...(carrierSummary || []).filter(row => row.carrierName !== "Total").map(row => ({
                        name: row.carrierName,
                        amount: formatAmount(row.CY_SUBTOTAL_PAYABLE)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalCarrier)
                    },
                    // Add an empty line after sales summary
                    {},
                    {  name: "LTL/FTL Carrier" ,
                        amount: "Amount"
                    },
                    ...(ftlSummary || []).filter(row => row.carrierName !== "Total").map(row => ({
                        name: row.carrierName,
                        amount: formatAmount(row.CY_SUBTOTAL_PAYABLE)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalFTL)
                    },
                    // Add an empty line after customer summary
                    {},
                    {  name: "Supplier" ,
                        amount: "Amount"
                    },
                    ...(supplierSummary || []).filter(row => row.supplierName !== "Total").map(row => ({
                        name: row.supplierName,
                        amount: formatAmount(row.CY_SUBTOTAL_PAYABLE)
                    })),
                    {  name: "Total" ,
                        amount: totalSupplier
                    },
                    // Add an empty line after customer summary
                    {},

                    {  name: "Other" ,
                        amount: "Amount"
                    },
                    ...(ledgerEntries || []).filter(entry => entry.description !== "Cost of Sales").map(entry => ({
                        name: entry.description,
                        amount: formatAmount(entry.credit)
                    })),
                    {  name: "Total" ,
                        amount: formatAmount(totalLedgerEntries)
                    },
                    // Add an empty line after ledger entries
                    {},
                ];



                // Combine all totals into one final total row
                const grandTotal = ( totalStock + totalCarrier + totalFTL + totalSupplier + totalLedgerEntries).toFixed(2);

                // Add the grand total row
                combinedData.push({
                    name: "Cost of Sales Total",
                    amount: formatAmount(grandTotal)
                });

                // Define headers
                const headers = null

                // Add the combined data to a single sheet
                addSheet('Cost of Sales', headers, combinedData);
            }
            // Handle other ledger types
            else {
                const ledgerEntries = ledgerData.ledgerEntries || [];

                const headers = ["date", "description", "debit", "credit", "balance"]

                const data = [
                    ...ledgerEntries.map(entry => ({
                        date: entry.date,
                        description: entry.description,
                        debit: formatAmount(entry.debit),
                        credit: formatAmount(entry.credit),
                        balance: formatAmount(entry.balance)
                    })),
                ]
                addSheet(ledgerName, headers, data);
            }
        });

        // Export and download the file
        XLSX.writeFile(workbook, `${ledgerType}_${this.props.selectedYear}_${this.props.selectedCurrency}_Ledger.xlsx`);
    };




    getContextualMenuItems = () => {
        const menuItems = [];
        // Always show the settings menu next to edit/cancel
        menuItems.push(
          {
              title: 'Add Ledger',
              icon: <AddCircleIcon />,
              onClick: () => {
                  this.props.setCurrentPage(LEDGERS_PAGES.FORM, {value: this.state.value})
              },
          }
         );

        if (this.props.exportAllLedgers) {
            menuItems.push({
                title: 'Export',
                icon: <Upgrade />,
                onClick: () => {
                    this.handleExport();
                }
            });
        }


        return menuItems.filter(Boolean);  // Ensure no `undefined` or `null` values
    };



    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        LEDGER_TABLE_CELLS.forEach((headCell, index) => {
            const alignment = index === LEDGER_TABLE_CELLS.length - 1 ? "left" : "left"
            headers.push(Layout.columnHead(headCell.label, headCell.id, alignment))
        })
        return headers
    }


    getTableRow = (row, getRowState, setRowState,ledgerType) => {
        return<LedgerTableRow
            row={row}
            categories={this.props.categories}
            isEdit={this.state.isEdit}
            allLedgers={this.state.ledgers}
            setLedgers={this.setLedgers}
            getRowState={getRowState}
            setRowState={setRowState}
            setCurrentPage={this.props.setCurrentPage}
            listingIdBeingEdited={this.state.listingIdBeingEdited}
            setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
            deleteLedgerByYearAndCurrency={this.props.deleteLedgerByYearAndCurrency}
            updateLedger={this.props.updateLedger}
            deleteLedger={this.props.deleteLedger}
            year={this.props.selectedYear}
            currency={this.props.selectedCurrency}
            ledgerType={ledgerType}
            setHideFilterBox={this.setHideFilterBox}
            settings={this.props.settings}
        />
    }

    setHideFilterBox = (isHide) =>{
        this.setState({hideFilterBox : isHide})
    }

    getBalanceForSelectedYear = (row,ledgerType) => {

        const yearRate =
          this.props.settings?.yearRates?.find(
            (setting) => setting.year === this.props.selectedYear.toString()
          )?.rate || 0

        const averageRate =
          this.props.settings?.averageRates?.find(
            (setting) => setting.year === this.props.selectedYear.toString()
          )?.rate || 0

        const applicableRate = ['Equity', 'Assets', 'Liabilities'].includes(ledgerType)
          ? yearRate
          : ['Expenses', 'Income'].includes(ledgerType)
            ? averageRate
            : 1

        if (row?.name === 'Accounts Payable' || row?.name === "Cost of Sales" || row?.name === "Sales") {

            let credits = 0

            if (this.props.selectedCurrency === "CAD") {
                // Calculate only CAD credits
                credits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Filter by year
                  ?.flatMap((entry) => entry?.entries) // Access nested entries
                  ?.filter((nestedEntry) => nestedEntry?.currency === "CAD") // Filter by CAD currency
                  ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)
            } else if (this.props.selectedCurrency === "USD") {
                // Calculate only USD credits
                credits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Filter by year
                  ?.flatMap((entry) => entry?.entries) // Access nested entries
                  ?.filter((nestedEntry) => nestedEntry?.currency === "USD") // Filter by USD currency
                  ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)
            } else if (this.props.selectedCurrency === "Consolidated CAD") {
                credits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Match year
                  ?.flatMap((entry) => entry?.entries || []) // Flatten entries, default to an empty array
                  ?.reduce((total, nestedEntry) => {
                      if (nestedEntry?.currency === 'CAD') {
                          // Add CAD credits directly
                          return total + (nestedEntry?.credit || 0);
                      } else if (nestedEntry?.currency === 'USD') {
                          // Convert USD credits to CAD
                          const conversionRate = applicableRate === yearRate ? yearRate : averageRate;
                          const convertedCredit = (nestedEntry?.credit || 0) * conversionRate;
                          return total + convertedCredit;
                      }
                      return total; // Skip if currency is neither CAD nor USD
                  }, 0);

            }

            credits = credits ?? 0
            return credits
        }else if( row?.name === 'Accounts Receivables'){

            let debits = 0
            let credit = 0

            if (this.props.selectedCurrency === "CAD") {
                // Calculate only CAD debit
                debits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Filter by year
                  ?.flatMap((entry) => entry?.entries) // Access nested entries
                  ?.filter((nestedEntry) => nestedEntry?.currency === "CAD") // Filter by CAD currency
                  ?.reduce((total, nestedEntry) => total + (nestedEntry?.debit || 0), 0)

                credit = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Filter by year
                  ?.flatMap((entry) => entry?.entries) // Access nested entries
                  ?.filter((nestedEntry) => nestedEntry?.currency === "CAD") // Filter by CAD currency
                  ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)

                debits = debits + credit

            } else if (this.props.selectedCurrency === "USD") {
                // Calculate only USD debit
                debits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Filter by year
                  ?.flatMap((entry) => entry?.entries) // Access nested entries
                  ?.filter((nestedEntry) => nestedEntry?.currency === "USD") // Filter by USD currency
                  ?.reduce((total, nestedEntry) => total + (nestedEntry?.debit || 0), 0)

                credit = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Filter by year
                  ?.flatMap((entry) => entry?.entries) // Access nested entries
                  ?.filter((nestedEntry) => nestedEntry?.currency === "USD") // Filter by USD currency
                  ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)

                debits = debits + credit

            } else if (this.props.selectedCurrency === "Consolidated CAD") {
                debits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Match year
                  ?.flatMap((entry) => entry?.entries || []) // Flatten entries, default to an empty array
                  ?.reduce((total, nestedEntry) => {
                      if (nestedEntry?.currency === 'CAD') {
                          // Add CAD debit directly
                          return total + (nestedEntry?.debit || 0);
                      } else if (nestedEntry?.currency === 'USD') {
                          // Convert USD debit to CAD
                          const conversionRate = applicableRate === yearRate ? yearRate : averageRate;
                          const convertedDebit = (nestedEntry?.debit || 0) * conversionRate;
                          return total + convertedDebit;
                      }
                      return total; // Skip if currency is neither CAD nor USD
                  }, 0);

                credit = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === this.props.selectedYear) // Match year
                  ?.flatMap((entry) => entry?.entries || []) // Flatten entries, default to an empty array
                  ?.reduce((total, nestedEntry) => {
                      if (nestedEntry?.currency === 'CAD') {
                          // Add CAD debit directly
                          return total + (nestedEntry?.credit || 0);
                      } else if (nestedEntry?.currency === 'USD') {
                          // Convert USD debit to CAD
                          const conversionRate = applicableRate === yearRate ? yearRate : averageRate;
                          const convertedDebit = (nestedEntry?.credit || 0) * conversionRate;
                          return total + convertedDebit;
                      }
                      return total; // Skip if currency is neither CAD nor USD
                  }, 0);

                debits = debits + credit
            }

            debits = debits ?? 0
            return debits
        }

        const balanceForSelectedYear = (() => {
            if (this.props.selectedCurrency === 'Consolidated CAD') {
                // Sum both CAD and USD balance
                const cadEntry = row?.ledgerEntries?.find(
                  (entry) => entry?.year === this.props.selectedYear && entry?.currency === 'CAD'
                );
                const usdEntry = row?.ledgerEntries?.find(
                  (entry) => entry?.year === this.props.selectedYear && entry?.currency === 'USD'
                );

                const cadAmount = cadEntry?.balance || 0; // Default to 0 if not found
                const usdAmount = usdEntry?.balance || 0;

                return { ...cadEntry, balance: cadAmount + usdAmount }; // Return consolidated result
            } else {
                return row?.ledgerEntries?.find(
                  (entry) => entry?.year === this.props.selectedYear && entry?.currency === this.props.selectedCurrency
                );
            }
        })();
        return balanceForSelectedYear?.balance ?? 0;
    }

    renderTablePanel = (ledgerType) => {
        const filteredLedgers = this.state.ledgers.filter((ledger) => ledger.type === ledgerType);

        const totalBalance = filteredLedgers.reduce((sum, ledger) => {
            return sum + this.getBalanceForSelectedYear(ledger, ledgerType);
        }, 0);

        let tableRows = [...filteredLedgers];
        if (totalBalance !== 0 && totalBalance !== null && totalBalance !== "") {
            const totalRow = {
                id: "total-row",
                name: "Total",
                balance: totalBalance,
                type: ledgerType,
            };
            tableRows.push(totalRow);
        }

        return (
            <div style={{ marginTop: "-80px", marginLeft: "-24px", marginRight: "-24px" }}>
                <TablePage
                    type={"ledgerTable"}
                    tableName="ledgerTable"
                    tableColumnHeads={this.getTableHeaders()}
                    tableRows={tableRows}
                    tableRow={(row, index, getRowState, setRowState) =>
                        this.getTableRow(row, getRowState, setRowState,ledgerType)
                    }
                    getRowId={(row) => row.ledgerId}
                    paginationText="Ledgers per page"
                    defaultSortBy={DEFAULT_SORT_BY_LEDGER_NAME_CELL}
                />
                {this.state.isEdit && (
                    <Tooltip title="Add Ledger" style={{marginLeft:'16px',marginTop:'8px'}}>
                        <IconButton onClick={() => {
                            this.setState({listingIdBeingEdited: ""},()=>{
                                const newLedgers = [
                                    ...this.state.ledgers,
                                    {
                                        ledgerId: '',
                                        name: '',
                                        sumRule: '',
                                        carryForwardBalance: '',
                                        source: '',
                                        balance: 0,
                                        type: ledgerType
                                    }
                                ];
                                this.setState({ ledgers: newLedgers })
                            })
                        }
                        }
                               >
                            <AddCircleIcon
                                fontSize="small"
                                color='secondary'
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        );
    };


    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={LEDGERS}
                    menuItems={this.getContextualMenuItems()}
                />

                    <Box className="box-ledger"   style={{ visibility: this.state.hideFilterBox ? 'hidden' : 'visible' }}>
                        <FormControl variant="outlined" className="form-control-ledger">
                            <InputLabel id="year-label">Year</InputLabel>
                            <Select
                                className="select-year-ledger"
                                labelId="year-label"
                                value={this.props.selectedYear}
                                onChange={this.props.handleYearChange}
                                label="Year"
                            >
                                {this.props.years.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className="form-control">
                            <InputLabel id="currency-label">Currency</InputLabel>
                            <Select
                                className="select-currency-ledger"
                                labelId="currency-label"
                                value={this.props.selectedCurrency}
                                onChange={this.props.handleChange}
                                label="Currency"
                            >
                                {this.props.currency.map((currency) => (
                                    <MenuItem key={currency} value={currency}>
                                        {currency}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>



                {/* Tab Context */}
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={this.state.value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={this.handleTabChange} aria-label="ledger tabs">
                                <Tab label="Assets" value="1" />
                                <Tab label="Liabilities" value="2" />
                                <Tab label="Income" value="3" />
                                <Tab label="Expenses " value="4" />
                                <Tab label="Equity" value="5" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">{this.renderTablePanel("Assets")}</TabPanel>
                        <TabPanel value="2">{this.renderTablePanel("Liabilities")}</TabPanel>
                        <TabPanel value="3">{this.renderTablePanel("Income")}</TabPanel>
                        <TabPanel value="4">{this.renderTablePanel("Expenses")}</TabPanel>
                        <TabPanel value="5">{this.renderTablePanel("Equity")}</TabPanel>
                    </TabContext>
                </Box>


            </React.Fragment>
        )
    }
}

export default LedgerTable;
