import React from "react";
import {
    fetchInvoiceByTrackingId,
    setInvoiceItemSmartSearchDetails,
    setInvoiceSmartSearchRows
} from "../../../redux/actions/invoices";
import withShipment from "../../../withShipment";
import { Checkbox, TableBody, TableHead, TextField, Typography, withStyles } from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import './CarrierInvoiceForm.css'
import Divider from "@material-ui/core/Divider";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styles from "../../../pages/Dashboard/DashboardFinal/DashboardFinal.module.css";
import { CANPAR_CHARGES, CHARGES_MAP, FedEx_CHARGES, GLS_CHARGES, PAID_STATUS } from './constants';
import {isValid, parseISO} from 'date-fns';
import format from "date-fns/format";
import { FormControlLabel } from '@mui/material';
import InputAdornment from '@material-ui/core/InputAdornment';

const StyledTextField = withStyles({
    root: {
        paddingLeft: "5px",
        paddingRight: "5px"
    }
})(TextField)

const StyledDateField = withStyles({
    root: {
        paddingLeft: "5px",
        paddingRight: "5px",
        width: "20%"
    }
})(KeyboardDatePicker)

class CarrierInvoiceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editingInvoice: {},
            tempStatus: '',
        }
    }

    componentDidMount() {
        this.setState({
            editingInvoice: this.handleSummaryCharges(this.props.editingInvoice)
        })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.editingInvoice !== this.props.editingInvoice) {
            this.setState({
                editingInvoice: this.handleSummaryCharges(this.props.editingInvoice)
            })
        }
    }

    handleSummaryCharges = (invoiceData) => {
        if (!invoiceData.summaryCharges)
            return invoiceData
        let summaryCharges = []
        Object.entries(invoiceData.summaryCharges).forEach(([key, charge]) => {
            if (typeof charge == "number") {
                summaryCharges[key] = charge
            }
            else{
                Object.entries(charge).map(([subCharge, subChargeAmount]) => {
                    if (!subCharge.includes("Total")) {
                        summaryCharges[subCharge]  = subChargeAmount
                    }
                })
            }
        });
        return {...invoiceData, summaryCharges: summaryCharges}
    }

    formatAmount = (amount) => {
        const num = parseFloat(amount);
        // Ensure the conversion is successful before formatting
        if (!isNaN(num)) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(num);
        }
        return 'Invalid number';
    }

    formatChargesName = (charge) => {
        let res = CHARGES_MAP.find(({charge: mapCharge, mapsTo})=>(
            mapCharge === charge
        ))
        if (res)
            return res.mapsTo
        else {
            return charge
        }
    }
  handleCheckBoxChange = (event) => {
    const checked = event.target.checked;

    this.setState((prevState) => {
      // If not checking, set previousStatus to tempStatus or retain its original value
      const previousStatus = checked
        ? prevState.editingInvoice.status
        : prevState.tempStatus || prevState.editingInvoice.previousStatus || prevState.editingInvoice.status;

      const updatedInvoice = {
        ...prevState.editingInvoice,
        disputeAmount: checked ? prevState.editingInvoice.disputeAmount : null,
        status: checked ? "Dispute" : previousStatus,
        previousStatus: checked ? prevState.editingInvoice.status : prevState.editingInvoice.previousStatus, // Preserve previousStatus when unchecking
      };

      return {
        editingInvoice: updatedInvoice,
        tempStatus: checked ? prevState.editingInvoice.status : prevState.tempStatus, // Only update tempStatus on check
      };
    }, () => {
      const { editingInvoice, tempStatus } = this.state;
      this.props.editSelectedInvoice("disputeAmount", editingInvoice?.disputeAmount);
      this.changeStatus(checked ? "Dispute" : tempStatus || editingInvoice.previousStatus || "Needs Review");
    });
  };


  changeStatus(status) {
    let carrierInvoiceIds = []
    if (this.state.editingInvoice) {
       carrierInvoiceIds.push(this.state.editingInvoice.carrierInvoiceId)
    }

    this.updateStatus(carrierInvoiceIds, status)
  }

  updateStatus(carrierInvoiceIds, status) {
     this.props.updateCarrierInvoiceStatus({ carrierInvoiceIds: carrierInvoiceIds, status: status, carrierType: "Standard Carrier"})

  }

  parseNumber = (string) => {
    // Check if the input is valid, return 0 if it's undefined, null, or an empty string
    if (!string) return 0;

    // Convert to string, remove commas, and parse as float
    return parseFloat(string?.toString().replaceAll(',', '')) || 0;
  }

  formatDollar = (amount, forceTwoDecimals) => { // Regex expression taken from https://stackoverflow.com/questions/2901102
    let value

    if (amount === 0) {
      return forceTwoDecimals ? "0.00" : "0";
    }

    if (!amount) {
      return "";
    }

    if (forceTwoDecimals) {
      value = this.parseNumber(amount).toFixed(2)
    } else {
      // if the amount has more than two decimal places, limit it to two
      if (amount.match(/.*\.\d{2}.*/)) {
        try {
          value = this.parseNumber(amount).toFixed(2)
        } catch {
          value = amount.replaceAll(',','')
        }
      } else {
        value = amount.replaceAll(',','')
      }
    }

    return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }


  render() {
        return (
          <>
            <div className={'invoiceWrapper'}>
              <div className="infoRow">
                <StyledTextField
                  variant="outlined"
                  label="Carrier Name"
                  InputLabelProps={{ shrink: true }}
                  name="carrierName"
                  value={this.state.editingInvoice.carrierName}
                  InputProps={{ readOnly: true }}
                  error={!this.state.editingInvoice.carrierName}
                  disabled={true}
                />
                <StyledTextField
                  variant="outlined"
                  label="Account Number"
                  InputLabelProps={{ shrink: true }}
                  name="accountNumber"
                  value={this.state.editingInvoice.accountNumber}
                  InputProps={{ readOnly: true }}
                  error={!this.state.editingInvoice.accountNumber}
                  disabled={true}
                />
                <StyledTextField
                  variant="outlined"
                  label="Invoice Number"
                  InputLabelProps={{ shrink: true }}
                  name="invoiceNumber"
                  value={this.state.editingInvoice.invoiceNumber}
                  InputProps={{ readOnly: true }}
                  error={!this.state.editingInvoice.invoiceNumber}
                  disabled={true}
                />
                <StyledDateField
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Date"
                  format="yyyy-MM-dd"
                  value={new Date(this.state.editingInvoice.invoiceDate)}
                  InputAdornmentProps={{ position: 'start' }}
                  disabled={true}
                />
                <StyledTextField
                  variant="outlined"
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                  name="status"
                  value={this.state.editingInvoice.status}
                  InputProps={{ readOnly: true }}
                  error={!this.state.editingInvoice.status}
                  disabled={true}
                />
              </div>
              <Divider
                style={{ marginTop: '1%', marginBottom: '1%', width: '100%' }}
              />
              <div className="mainContent">
                <div className="leftBox">
                  <h5>Summary</h5>
                  {this.state.editingInvoice?.summaryCharges &&
                    Object.entries(
                      this.state.editingInvoice.summaryCharges
                    ).map(([chargeName, chargeAmount]) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          fontSize: '16px',
                        }}
                      >
                        <p>{this.formatChargesName(chargeName)}</p>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '27%',
                          }}
                        >
                          <p>$</p>
                          <p>{this.formatAmount(chargeAmount)}</p>
                        </div>
                      </div>
                    ))}
                </div>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ width: 2, bgcolor: 'primary.main' }}
                />
                <div className="rightBox">
                  <div className="infoRow">
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      style={{ width: '33%' }}
                    >
                      <KeyboardDatePicker
                        name="paymentDate"
                        label="Payment Date"
                        onChange={(date) =>
                          this.props.editSelectedInvoice('paymentDate', date)
                        }
                        value={
                          this.state.editingInvoice?.paymentDate
                            ? parseISO(this.state.editingInvoice.paymentDate)
                            : null
                        }
                        format="yyyy-MM-dd"
                        variant="inline"
                        inputVariant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputAdornmentProps={{ position: 'start' }}
                        style={{ width: '33%', marginRight: '10px' }}
                        autoOk
                        error={
                          this.state.editingInvoice?.paymentDate
                            ? !isValid(
                                parseISO(this.state.editingInvoice.paymentDate)
                              ) // Invalid date
                            : false // No error if empty
                        }
                      />
                    </MuiPickersUtilsProvider>
                    <Autocomplete
                      style={{ width: '33%', marginRight: '10px' }}
                      options={this.props.bankAccounts}
                      getOptionLabel={(option) =>
                        option.name && option.accountNumber
                          ? `${option.name} (${option.accountNumber})`
                          : ``
                      }
                      value={
                        this.props.bankAccounts.find(
                          (account) =>
                            account.bankAccountId ===
                            this.state.editingInvoice?.bankAccount
                              ?.bankAccountId
                        ) || null
                      }
                      onChange={(e, value) =>
                        this.props.editSelectedInvoice('bankAccount', value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="bankAccount"
                          label="Bank Account"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <TextField
                      style={{ width: '33%' }}
                      variant="outlined"
                      name="referenceId"
                      label="Reference Id"
                      value={this.state.editingInvoice?.referenceId}
                      onChange={(e) =>
                        this.props.editSelectedInvoice(
                          e.target.name,
                          e.target.value
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>

                  <div  style={{ paddingTop: '10px' }}>
                    <div className="infoRow" style={{width:'32.5%'}}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.editingInvoice.status === 'Dispute'}
                                onChange={this.handleCheckBoxChange}
                              />
                            }
                            label="Dispute"
                          />
                        </div>

                      {this.state.editingInvoice.status === 'Dispute' && (
                        <TextField
                          variant="outlined"
                          name="disputeAmount"
                          label="Dispute Amount"
                          value={this.state.editingInvoice?.disputeAmount || ''}
                          onChange={(e) =>
                            this.props.editSelectedInvoice(e.target.name, e.target.value)
                          }
                          onBlur={(e) => {
                            const value = e.target.value;
                            const formattedValue = isNaN(parseFloat(value))
                              ? '0.00'
                              : this.formatDollar(value, true);
                            this.props.editSelectedInvoice(e.target.name, formattedValue);
                          }}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ style: { textAlign: 'right' } }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      )}

                    </div>
                  </div>

                  <div style={{ paddingBottom: '10px' }}>
                    <TextField
                      label="Notes"
                      multiline
                      rows={4}
                      rowsMax={8}
                      InputLabelProps={{ shrink: true }}
                      style={{ width: '100%', marginTop: '10px' }}
                      name="notes"
                      value={this.props.editingInvoice?.notes ?? ''}
                      onChange={(e) =>
                        this.props.editSelectedInvoice(
                          e.target.name,
                          e.target.value
                        )
                      }
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
              <Divider
                style={{ marginTop: '1%', marginBottom: '1%', width: '100%' }}
              />
              {
                <div
                  className="needs-review-container"
                  style={{ width: '100%', marginTop: '10px' }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    className="form-header"
                  ></Typography>
                  <div className="errorMessage-content">
                    <TableContainer>
                      <Table
                        size={'medium'}
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="cellPadding">
                              <p className={styles.cardTotalHeader}>Issues:</p>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.editingInvoice?.errorMessage?.map(
                            (report, index) => (
                              <TableRow hover key={index}>
                                <TableCell className="cellPadding">
                                  <p className={styles.cardCellItems}>
                                    {report}
                                  </p>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              }
            </div>
          </>
        );
    }
}

const actionCreators = {

}

export default withShipment({
    actionCreators
}, CarrierInvoiceForm);