import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import { INCOME_STATEMENT, INCOME_STATEMENT_PAGES } from './constants';
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import {userInfoSelector} from "../../../redux/selectors/auth";


import {
    incomeStatementSelector,
    incomeStatementTemplateSelector,
    ledgerSelector,
    loadingSelector,
    marketplaceSummarySelector,
} from '../../../redux/selectors/accounting';
import { tableSettingSelector } from '../../../redux/selectors/account';
import { getTableSetting, saveTableSetting } from '../../../redux/actions/account';
import IncomeStatementTable from './IncomeStatementTable';
import {
    fetchIncomeStatement,
    listAllIncomeStatement, listAllLedgers, listIncomeStatementMappingTemplate, listMarketplaceSummary,
    saveIncomeStatement, saveIncomeStatementMappingTemplate,
} from '../../../redux/actions/accounting';
import IncomeStatementSettings from './IncomeStatementSettings';
import { STATEMENT_TAXES_PAGES } from '../statementTaxes/constants';

class IncomeStatement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: INCOME_STATEMENT_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            incomeStatement: null,
            incomeStatementId: null,
            years: ['2023','2024','2025'],
            selectedYear: "2024",
            currency: ['CAD', 'USD', "Consolidated CAD"],
            selectedCurrency: 'CAD',
            isEdit : false,
            incomeStatementFilterSetting: null,
            marketplaceSummaryCategory: []
        }
    }


    componentDidMount() {
        this.props.listAllLedgers({currency: this.state.selectedCurrency, year: this.state.selectedYear})
        this.props.getTableSetting(this.props.user.userId)
        this.props.listIncomeStatementMappingTemplate()
        this.props.listMarketplaceSummary({
            "year": this.state.selectedYear,
            "quarter": [1,2,3,4],
            "month": "",
            "currency": this.state.selectedCurrency,
            "isFetch": false,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }

        if (prevProps.marketplaceSummary !== this.props.marketplaceSummary) {

            const names = this.props.marketplaceSummary.map(item => item.name);
            this.setState({
                marketplaceSummaryCategory: names || [],
            })
        }

        if (prevProps.incomeStatement !== this.props.incomeStatement) {
            this.setState({
                incomeStatement: this.props.incomeStatement?.rows || [],
                incomeStatementId : this.props.incomeStatement?.incomeStatementId || null
            })
        }

        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({ incomeStatementFilterSetting: this.props.tableSetting?.incomeStatementFilterSetting },()=>{
                this.setState({
                    selectedCurrency: this.state.incomeStatementFilterSetting.currency,
                    selectedYear: this.state.incomeStatementFilterSetting.year,

                },()=>{
                    this.listIncomeStatement()
                })
            })
        }

    }

    handleSave = (templates) => {
        this.props.saveIncomeStatementMappingTemplate(templates, ()=>{
            this.listIncomeStatementMappingTemplate()
        })
        this.setCurrentPage(INCOME_STATEMENT_PAGES.TABLE, {})
    }

    listIncomeStatement = () =>{
        this.props.listAllIncomeStatement({currency: this.state.selectedCurrency, year: this.state.selectedYear})
    }

    onFiltersInputChange = (name,event) => {
        let filtersMap = this.props.tableSetting?.incomeStatementFilterSetting
        filtersMap[name] = event.target.value;
        this.saveUpdatedFilters(filtersMap)
    }


    saveUpdatedFilters = (filters) => {
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changeIncomeStatementFilters: true,
            incomeStatementFilters: filters
        })
    }


    setIsEdit = (bool) =>{
        this.setState({ isEdit: bool })
    }


    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    handleCurrencyChange = (event) => {
            this.setState({ selectedCurrency: event.target.value },()=>{
                this.onFiltersInputChange("currency",event)
                this.setIsEdit(false)
            })
    }

    handleYearChange = (event) => {
            this.setState({ selectedYear: event.target.value },()=>{
                this.onFiltersInputChange("year",event)
            })
    }


    displaySetting = () =>{
        return (
          <IncomeStatementSettings
            allLedgers={this.props.ledgers}
            handleSave={this.handleSave}
            templates={this.props.templates}
            setCurrentPage={this.setCurrentPage}
            openFeedbackBar={this.openFeedbackBar}
            marketplaceCategory={this.state.marketplaceSummaryCategory}
          />
        )
    }

    displayTable = () => {
        if (this.state.incomeStatement) {
            return (
              <IncomeStatementTable
                loadingData={this.props.loadingData}
                fetchIncomeStatement={this.props.fetchIncomeStatement}
                saveIncomeStatement={this.props.saveIncomeStatement}
                sections={this.state.incomeStatement}
                incomeStatementId={this.state.incomeStatementId}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                selectedYear={this.state.selectedYear}
                handleYearChange={this.handleYearChange}
                currency={this.state.currency}
                selectedCurrency={this.state.selectedCurrency}
                handleCurrencyChange={this.handleCurrencyChange}
                years={this.state.years}
                isEdit={this.state.isEdit}
                setIsEdit={this.setIsEdit}
              />
            )
        }else{
            return null
        }
    }


    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case INCOME_STATEMENT_PAGES.TABLE:
                return this.displayTable()
            case INCOME_STATEMENT_PAGES.SETTINGS:
                return this.displaySetting()
            default:
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }


    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                  open={this.state.feedbackBarOpen}
                  handleClose={this.closeFeedbackBar}
                  severity={this.props.status?.success ? 'success' : 'error'}
                  message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    user: userInfoSelector(state),
    incomeStatement: incomeStatementSelector(state),
    tableSetting: tableSettingSelector(state),
    ledgers: ledgerSelector(state),
    templates: incomeStatementTemplateSelector(state),
    marketplaceSummary: marketplaceSummarySelector(state),
    loadingData: loadingSelector(state),
})


const actionCreators = {
    clearStatus,
    listAllIncomeStatement,
    fetchIncomeStatement,
    saveIncomeStatement,
    saveTableSetting,
    getTableSetting,
    listAllLedgers,
    listIncomeStatementMappingTemplate,
    saveIncomeStatementMappingTemplate,
    listMarketplaceSummary
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, IncomeStatement);