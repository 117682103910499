import React from "react";

//styling
import styles from "../cheques/ChequeForm.module.css"

// constants
import {
    BACK,
    CARRY_FORWARD_OPTIONS,
    LEDGERS_PAGES,
    SOURCE_OPTIONS,
    SUM_RULE_OPTIONS,
} from './constants';


import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Button, Checkbox, TextField } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import withShipment from "../../../withShipment";
import SaveIcon from "@material-ui/icons/Save";
import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { CANCEL } from '../../global/constants';
import InputAdornment from '@material-ui/core/InputAdornment';

class LedgerRuleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.ledger.name,
            source: this.props.ledger.source,
            category: this.props.ledger.category,
            sumRule: this.props.ledger.sumRule,
            carryForwardBalance: this.props.ledger.carryForwardBalance,
            isShowTrialBalance: this.props.ledger.isShowTrialBalance || false,
            ledgerId: this.props.ledger.ledgerId,
            ledgerType: this.props.ledger.type,
            ledgerIds: [],
            balance: "",
            open: false,
            isDialogOpen: false,
            selectedOption: '',
            requiredList: [
                "name",
                "carryForwardBalance",
                "sumRule",
            ],
        }
    }

    componentDidMount() {

       if(this.props.ledger.value){
           this.setState({ledgerType: this.getCategoryName(this.props.ledger.value)})
       }

    }


    handleOpen = () => {
        this.setState({ isDialogOpen: true });
    };

    handleClose = () => {
        this.setState({ isDialogOpen: false });
    };

    saveLedger= ()=>{
        const request = {
            ledgerId: this.state.ledgerId,
            name: this.state.name,
            source: this.state.source,
            sumRule: this.state.sumRule,
            carryForwardBalance: this.state.carryForwardBalance,
            isShowTrialBalance: this.state.isShowTrialBalance,
            category: this.state.category,
            year: this.props.year,
            currency: this.props.currency,
            type: this.state.ledgerType,
            balance: this.state.balance,
        };
        this.props.updateLedger(request,(data)=>{
            if(this.props.ledger.value){
                this.props.setCurrentPage(LEDGERS_PAGES.TABLE,{})
            }else{
                this.props.setCurrentPage(LEDGERS_PAGES.EDIT, data)
            }

        });


    }


    handleChange = (state) => {
        this.setState(state);
    };


    checkSave = () => {
        const { requiredList, source, category, ...fields } = this.state;

        // Check if any required field is empty
        let hasEmpty = requiredList.some(field => {
            if (field === "sumRule" && source !== "Transactions") {
                return false; // Skip sumRule check if source is not Transactions
            }
            return !fields[field]; // Return true if any field is empty
        });

        if (source === "Transactions" && category.length === 0) {
            hasEmpty = true;
        }

        if (source === "Ledgers" && category.length === 0) {
            hasEmpty = true;
        }

        return hasEmpty;
    };




    getCategoryName = (value) => {
        const categories = {
            1: "Assets",
            2: "Liabilities",
            3: "Income",
            4: "Expenses",
            5: "Equity"
        };

        return categories[value] || "Unknown"
    }

    handleDelete = () => {

        if(this.state.selectedOption === "deleteByYear"){

            const data = {
                ledgerId: this.props.ledger.ledgerId,
                year: this.props.year,
                currency: this.props.currency,
            };

            this.props.deleteLedgerByYear(data);

        }else if(this.state.selectedOption === "deleteEverything"){
            const data = {
                ledgerId: this.props.ledger.ledgerId,
                year: this.props.year,
                currency: this.props.currency
            }
           this.props.deleteLedger(data)
        }

       this.setState({ isDialogOpen: false },()=>{

           this.props.setCurrentPage(LEDGERS_PAGES.TABLE,{})
       });

    }


    handleRadioChange = (event) => {
        this.setState({selectedOption: event.target.value })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <TopToolbar
                    pageName={`${this.state.ledgerType} Ledger - ${this.props.ledger.name || 'New'}`}
                    menuItems={[
                        {
                            title: BACK,
                            icon: <KeyboardBackspaceIcon />,
                            onClick: () => {
                                if (this.props.ledger.value) {
                                    this.props.setCurrentPage(LEDGERS_PAGES.TABLE, {});
                                } else {
                                    this.props.setCurrentPage(LEDGERS_PAGES.EDIT, this.props.ledger);
                                }
                            }
                        },
                        {
                            title: "Save",
                            disabled: this.checkSave(),
                            icon: <SaveIcon />,
                            onClick: () => {
                                this.saveLedger();
                            }
                        },
                        this.props.ledger?.name && {
                            title: "Delete Ledger",
                            icon: <DeleteIcon />,
                            onClick: () => {
                                this.handleOpen();
                            }
                        }
                    ].filter(Boolean)}
                />


                <Dialog open={this.state.isDialogOpen} onClose={this.handleClose}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this ledger and also delete the entries associated with it?
                        </DialogContentText>

                        <div style={{marginTop:'16px'}}>

                            <RadioGroup value={this.state.selectedOption} onChange={this.handleRadioChange}>
                                <FormControlLabel
                                  value="deleteByYear"
                                  control={<Radio sx={{ color: '#acd685', '&.Mui-checked': { color: '#acd685' } }} />}
                                  label={`Delete current year (${this.props.year})`}
                                />
                                <FormControlLabel
                                  value="deleteEverything"
                                  control={<Radio  sx={{ color: '#acd685', '&.Mui-checked': { color: '#acd685' } }}/>}
                                  label="Delete all years"
                                />
                            </RadioGroup>

                        </div>

                    </DialogContent>
                    <DialogActions>



                        <div className='save-cancel-buttons'>
                            <Button
                              variant='contained'
                              onClick={this.handleClose}
                            >
                               NO
                            </Button>
                            <div className='save-spacing'/>
                            <Button
                              disabled={!this.state.selectedOption}
                              variant='contained'
                              color='primary'
                              onClick={this.handleDelete}
                            >
                                YES
                            </Button>
                        </div>

                    </DialogActions>
                </Dialog>


                <div className={styles.rowCarrier} style={{ marginTop: '24px' }}>
                    <div className={styles.rowSpec}>

                        <TextField
                          style={{ marginLeft: '-8px', padding: '0' }}
                          className={styles.formTwoInput}
                          variant="outlined"
                          name="name"
                          label="Name"
                          required={true}
                          value={this.state.name}
                          onChange={(e) => this.handleChange({ name: e.target.value })}
                          error={this.state.name === '' || this.state.name === null || this.state.name === undefined}
                        />


                        <Autocomplete
                          className={styles.formTwoInput}
                          options={SOURCE_OPTIONS}
                          getOptionLabel={(option) => option}
                          value={this.state.source || null}
                          onChange={(e, value) => {
                              this.handleChange({ source: value });
                              this.setState({ category: [] });
                          }


                          }
                          // onChange={(e, value) => this.props.setRowState({ source: value })}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              name="source"
                              label="Source"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          }
                        />
                    </div>

                    {this.state.source && (
                      <div className={styles.rowSpec}>
                          {this.state.source === 'Transactions' ?
                            <Autocomplete
                              className={styles.formInputsingle}
                              multiple
                              open={this.state.open}
                              value={
                                  Array.isArray(this.state.category)
                                    ? this.state.category.map((cat) =>
                                      this.props.categories.find((category) => category.name === cat), // Find category objects from names
                                    ).filter(Boolean) // Ensure no undefined values
                                    : [] // Fallback to an empty array if state.category is not an array
                              }
                              options={(this.props.categories || []).sort((a, b) => a.displayOrder - b.displayOrder)} // Sorting categories
                              getOptionLabel={(option) => option.name || ''}
                              autoHighlight={true}
                              renderTags={(value) => {
                                  return value.map((option) => (
                                    <Chip
                                      key={option.name}
                                      variant="outlined"
                                      size="small"
                                      style={{
                                          borderColor: '#acd685',
                                          margin: '1px 6px 1px 0',
                                          fontSize: '12px',
                                      }}
                                      label={option.name}
                                    />
                                  ));
                              }}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                              )}
                              onOpen={() => {
                                  this.setState({ open: true });
                              }}
                              onClose={(event, reason) => {
                                  reason === 'blur' ? this.setState({ open: false }) : this.setState({ open: true });
                              }}
                              name="category"
                              onChange={(event, value) => {
                                  this.handleChange({
                                      category: value.map((cat) => cat?.name), // Store just the category names (strings) in the state
                                  });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Category"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  name="category"
                                />
                              )}
                            /> :

                            <Autocomplete
                              className={styles.formInputsingle}
                              multiple
                              open={this.state.open}
                              value={
                                  Array.isArray(this.state.category)
                                    ? this.state.category.map((cat) =>
                                      this.props.allLedgers.find((ledger) => ledger.name === cat), // Find category objects from names
                                    ).filter(Boolean) // Ensure no undefined values
                                    : [] // Fallback to an empty array if state.category is not an array
                              }
                              options={(this.props.allLedgers || [])
                                .filter(ledger => ledger.isShowTrialBalance === false)
                                .sort((a, b) => a.displayOrder - b.displayOrder)}
                              getOptionLabel={(option) => option.name || ''}
                              autoHighlight={true}
                              renderTags={(value) => {
                                  return value.map((option) => (
                                    <Chip
                                      key={option.name}
                                      variant="outlined"
                                      size="small"
                                      style={{
                                          borderColor: '#acd685',
                                          margin: '1px 6px 1px 0',
                                          fontSize: '12px',
                                      }}
                                      label={option.name}
                                    />
                                  ));
                              }}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                              )}
                              onOpen={() => {
                                  this.setState({ open: true });
                              }}
                              onClose={(event, reason) => {
                                  reason === 'blur' ? this.setState({ open: false }) : this.setState({ open: true });
                              }}
                              name="category"

                              onChange={(event, value) => {
                                  // Get the selected ledgerIds based on the selected category names
                                  const selectedLedgerIds = value.map((cat) => {
                                      const ledger = this.props.allLedgers.find((ledger) => ledger.name === cat?.name);
                                      return ledger ? ledger.ledgerId : null; // Assuming ledger has an 'id' field
                                  }).filter(Boolean); // Remove any null values

                                  this.setState({
                                      category: value.map((cat) => cat?.name), // Store category names
                                      ledgerIds: selectedLedgerIds, // Store corresponding ledgerIds
                                  });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ledgers"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  name="category"
                                />
                              )}
                            />
                          }

                      </div>
                    )
                    }


                    <div className={styles.rowSpec}>

                        {this.state.source === 'Transactions' ?
                          <Autocomplete
                            className={styles.formTwoInput}
                            options={SUM_RULE_OPTIONS}
                            getOptionLabel={(option) => option}
                            value={this.state.sumRule || null}
                            onChange={(e, value) => this.handleChange({ sumRule: value })}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                name="sumRule"
                                label="Sum Rule"
                                variant="outlined"
                                required={true}
                                error={this.state?.sumRule === '' || this.state?.sumRule === null || this.state?.sumRule === undefined}
                                InputLabelProps={{ shrink: true }}
                              />
                            }
                          /> :

                              <Autocomplete
                                className={styles.formTwoInput}
                                options={CARRY_FORWARD_OPTIONS}
                                getOptionLabel={(option) => option}
                                value={this.state.carryForwardBalance || null}

                                onChange={(e, value) => this.handleChange({ carryForwardBalance: value })}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    name="carryForwardBalance"
                                    label="Carry Forward Balance"
                                    variant="outlined"
                                    required={true}
                                    error={this.state.carryForwardBalance === '' || this.state.carryForwardBalance === null || this.state.carryForwardBalance === undefined}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                }
                              />
                        }


                        <div className={styles.formTwoInput}>
                            <FormControlLabel
                              style={{ marginLeft: '0px', width: 225, whiteSpace: 'nowrap' }}
                              control={<Checkbox
                                checked={this.state.isShowTrialBalance}
                                onChange={(e) => this.setState({ isShowTrialBalance: e.target.checked })}
                              />}
                              label={'Show in Trial Balance'}
                            />
                        </div>


                    </div>


                    {this.state.source === 'Transactions' &&
                    <div className={styles.rowSpec}>
                          <Autocomplete
                            className={styles.formTwoInput}
                            options={CARRY_FORWARD_OPTIONS}
                            getOptionLabel={(option) => option}
                            value={this.state.carryForwardBalance || null}

                            onChange={(e, value) => this.handleChange({ carryForwardBalance: value })}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                name="carryForwardBalance"
                                label="Carry Forward Balance"
                                variant="outlined"
                                required={true}
                                error={this.state.carryForwardBalance === '' || this.state.carryForwardBalance === null || this.state.carryForwardBalance === undefined}
                                InputLabelProps={{ shrink: true }}
                              />
                            }
                          />

                        <div className={styles.formTwoInput}>

                        </div>
                    </div>
                    }


                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

const actionCreators = {}

export default withShipment({
    actionCreators,
    mapStateToProps
}, LedgerRuleForm);

