export const SHIPPING_SUMMARY = "Shipping Summary"
export const SETTINGS = "Settings"

export const SAVE = "Save"

export const SHIPPING_SUMMARY_FIELDS = [
    {id: 'carrierName', label: 'Standard Carrier', align: "center"},
    // {id: 'accountNumber', label: 'Account Number', align: "center"},
    // {id: 'location', label: 'Location', align: "center"},
    {id: 'PY_SUBTOTAL_PAYABLE', label: 'PY Subtotal Payable', align: "center"},
    {id: 'PY_TAX_PAYABLE', label: 'PY Tax Payable', align: "center"},
    {id: 'CY_SUBTOTAL', label: 'CY Subtotal', align: "center"},
    {id: 'CY_TAX', label: 'CY Tax', align: "center"},
    {id: 'CY_TOTAL', label: 'CY Total', align: "center"},
    {id: 'CY_SUBTOTAL_PAYABLE', label: 'CY Subtotal Payable', align: "center"},
    {id: 'CY_TAX_PAYABLE', label: 'CY Tax Payable', align: "center"},
    {id: 'CY_TOTAL_PAYABLE', label: 'CY Total Payable', align: "center"},
]

export const SHIPPING_SUMMARY_FTL_FIELDS = [
    {id: 'carrierName', label: 'LTL/FTL Carrier', align: "center"},
    {id: 'PY_SUBTOTAL_PAYABLE', label: 'PY Subtotal Payable', align: "center"},
    {id: 'PY_TAX_PAYABLE', label: 'PY Tax Payable', align: "center"},
    {id: 'CY_SUBTOTAL', label: 'CY Subtotal', align: "center"},
    {id: 'CY_TAX', label: 'CY Tax', align: "center"},
    {id: 'CY_TOTAL', label: 'CY Total', align: "center"},
    {id: 'CY_SUBTOTAL_PAYABLE', label: 'CY Subtotal Payable', align: "center"},
    {id: 'CY_TAX_PAYABLE', label: 'CY Tax Payable', align: "center"},
    {id: 'CY_TOTAL_PAYABLE', label: 'CY Total Payable', align: "center"},
]

export const SHIPPING_SUMMARY_PAGES = {
    TABLE: "table",
}

// tooltip names
export const BACK = "Back to Menu"
