import {
    STORE_DASHBOARD_INFO,
    STORE_SCAN_RESPONSE,
    CLEAR_SCAN_RESPONSE,
    STORE_PACKED_RESPONSE,
    CLEAR_PACKED_RESPONSE,
    STORE_SCANNED_BARCODES,
    UPDATE_SCANNED_BARCODE,
    STORE_PACKED_BARCODES,
    UPDATE_PACKED_BARCODE,
    STORE_PENDING_PACKED_BARCODES,
    UPDATE_PENDING_PACKED_BARCODE,
    STORE_TOTAL_BARCODES,
    SET_REFRESH,
    DASHBOARD_REFRESH, STORE_LIMBO_SHIPMENTS, HANDLE_RESOLVED_SHIPMENT, CLEAR_RESOLVE_LIMBO_SHIPMENT_RESPONSE_MESSAGE,
} from "../../constants/dashboard";

const initialState = {
    dashboard: {},
    scanResponse: {},
    packedResponse: {},
    locations: [],
    scannedBarcodes: [],
    packedBarcodes: [],
    pendingPacked: [],
    allBarcodes: [],
    refreshing: false,
    limboShipments: [],
    resolveLimboShipmentresponseMessage: "",
};

const updateBarcodesList = (newLabel, barcodesList) => {
    barcodesList.unshift(newLabel)
    return barcodesList;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_DASHBOARD_INFO:
            return {
                ...state,
                dashboard: action.dashboard[0]
            }
        case STORE_SCAN_RESPONSE:
            return {
                ...state,
                scanResponse: action.scanResponse
            }
        case CLEAR_SCAN_RESPONSE:
            return {
                ...state,
                scanResponse: {}
            }
        case STORE_PACKED_RESPONSE:
            return {
                ...state,
                packedResponse: action.packedResponse
            }
        case STORE_LIMBO_SHIPMENTS:
            return {
                ...state,
                limboShipments: action.limboShipments
            }
        case CLEAR_RESOLVE_LIMBO_SHIPMENT_RESPONSE_MESSAGE:
            return {
                ...state,
                resolveLimboShipmentresponseMessage: ""
            }
        case HANDLE_RESOLVED_SHIPMENT:
            let filteredLimboShipments = state.limboShipments
            if(action.message == "Update shipment status to Scanned successfully") {
                filteredLimboShipments = state.limboShipments.map((shipment) =>
                    shipment.shipmentId === action.shipmentId ? action.shipment : shipment
                )
            }
            else{
                filteredLimboShipments = state.limboShipments.filter((shipment) =>
                    shipment.shipmentId !== action.shipmentId
                )
            }
            return {
                ...state,
                limboShipments: filteredLimboShipments,
                resolveLimboShipmentresponseMessage: action.message
            }
        case CLEAR_PACKED_RESPONSE:
            return {
                ...state,
                packedResponse: {}
            }
        case STORE_SCANNED_BARCODES: {
            return {
                ...state,
                scannedBarcodes: action.barcodes
            }
        }
        case UPDATE_SCANNED_BARCODE: {
            return {
                ...state,
                scannedBarcodes: updateBarcodesList(action.label, state.scannedBarcodes)
            }
        }
        case STORE_PACKED_BARCODES: {
            return {
                ...state,
                packedBarcodes: action.barcodes
            }
        }
        case UPDATE_PACKED_BARCODE: {
            return {
                ...state,
                packedBarcodes: updateBarcodesList(action.label, state.packedBarcodes)
            }
        }
        case STORE_PENDING_PACKED_BARCODES: {
            return {
                ...state,
                pendingPacked: action.barcodes
            }
        }
        case UPDATE_PENDING_PACKED_BARCODE: {
            return {
                ...state,
                pendingPacked: updateBarcodesList(action.label, state.pendingPacked)
            }
        }
        case STORE_TOTAL_BARCODES: {
            return {
                ...state,
                allBarcodes: action.barcodes
            }
        }
        case SET_REFRESH: {
            return {
                ...state,
                refreshing: action.refreshing
            }
        }
        case DASHBOARD_REFRESH: {
            return {
                ...state,
                dashboardRefresh: action.needsRefresh
            }
        }
        default:
            return state
    }
};