import React from 'react'
import './EditCarrierInvoiceItemDetails.css'
import PropTypes from "prop-types";
import {Autocomplete, IconButton, Typography} from "@mui/material";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import {
    CHARGES, CHARGES_OPTIONS,
    ITEM_DETAILS, ITEM_DETAILS_OPTIONS, TOTAL_CHARGES,
    RECIPIENT_INFO, RECIPIENT_OPTIONS,
    SENDER_INFO, SENDER_OPTIONS, CHARGES_MAP,
} from "./constants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import CustomToolbar from "../../global/CustomToolbar";
import withShipment from "../../../withShipment";
import {saveCarrierInvoiceItemDetails} from "../../../redux/actions/invoices";

class EditCarrierInvoiceItemDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackingId: this.props.invoiceItemSmartSearchDetails?.trackingId || "",
            details: {...this.props.invoiceItemSmartSearchDetails?.details},
            charges: {...this.props.invoiceItemSmartSearchDetails?.charges},
            netTransportationCharges: {...this.props.invoiceItemSmartSearchDetails?.netTransportationCharges},
            subtotalCharges: {...this.props.invoiceItemSmartSearchDetails?.subtotalCharges},
            totalCharges: {...this.props.invoiceItemSmartSearchDetails?.totalCharges},
            sender: {...this.props.invoiceItemSmartSearchDetails?.sender},
            recipient: {...this.props.invoiceItemSmartSearchDetails?.recipient},
            disabledTextFields: [],
            addField: "",
            addFieldName: "",
            addFieldValue: "",
            isErrorItem: this.props.invoiceItemSmartSearchDetails?.error || false,
            isPreviouslyBilled: this.props.invoiceItemSmartSearchDetails?.isPreviouslyBilled || false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.addField !== this.state.addField) {
            this.setState({
                addFieldName: "",
                addFieldValue: ""
            })
        }

        if (this.state.addFieldName === null) {
            this.setState({
                addFieldName: ''
            })
        }
    }

    //function that takes the input headers and converts them from camel case to title case
    formatHeaders = (inputText) => {
        const result = inputText.replace(/([A-Z0-9])/g, " $1") // add space before uppercase letters and digits
        const resultWithSpaces = result.replace(/[0-9]+/g, " $&") // add space before numbers
        return resultWithSpaces.charAt(0).toUpperCase() + resultWithSpaces.slice(1) // capitalize the first letter
    }

    //function that takes newly added keys and converts them to camel case
    formatKeys = (inputText) => {
        return inputText.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    }

    renderTextFields = (field, data) => {
        return (
            <div>
                {Object.entries(data).map(([key, value]) => (
                    <div key={key} className="item-textfield-container">
                        <IconButton className="toggle-icon-button" onClick={() => this.deleteTextField(field, key)}>
                            <RemoveCircleIcon />
                        </IconButton>
                        <TextField
                            label={this.formatHeaders(key)}
                            variant="outlined"
                            value={value}
                            disabled={this.state.disabledTextFields.includes(key)}
                            onChange={(event) => {
                                const updatedField = { ...this.state[field] }
                                updatedField[key] = event.target.value
                                this.setState({ [field]: updatedField })
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            style={{ width: '80%', marginLeft: '10px'}}
                        />
                    </div>
                ))}
            </div>
        )
    }

    calculateTotalCharges = (charges) => {
        if (charges) {
            let total = 0;
            Object.entries(charges).forEach(([key, value]) => {
                if (key !== "total" && key !== "subtotal") {
                    total += Number(value);
                }
            });
            return parseFloat(total.toFixed(2));
        }
        return 0.00;
    }

    renderChargesTextFields = () => {
        return (
            <div>

                {Object.keys(this.state.charges).length > 0 ?
                    <>
                        {this.renderTextFields("charges", this.state.charges)}
                        <div className="item-textfield-container">
                            <TextField
                                label="Total"
                                variant="outlined"
                                value={this.calculateTotalCharges(this.state.charges)}
                                disabled={true}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                style={{ width: '80%', marginLeft: '45px'}}
                            />
                        </div>
                    </> : null }
            </div>
        )
    }

    deleteTextField = (field, key) => {
        const updatedField = { ...this.state[field] }
        delete updatedField[key]
        this.setState({ [field]: updatedField })

        if (field === 'netTransportationCharges' || field === 'subtotalCharges' || field === 'totalCharges') {
            const updatedChargesField = {...this.state.charges}
            delete updatedChargesField[key]
            this.setState({charges: updatedChargesField})
        }
    }

    renderAddTextField = (field, options = []) => {
        return (
            <div className="item-textfield-container">
                {(this.state.addField !== field) ?
                    <IconButton className="toggle-icon-button" onClick={() => this.setState({addField: field})}>
                        <AddCircleIcon/>
                    </IconButton>
                    :
                    <>
                        <IconButton className="toggle-icon-button" onClick={() => this.setState({addField: '', addFieldName: '', addFieldValue: ''})}>
                            <RemoveCircleIcon/>
                        </IconButton>
                        <Autocomplete
                            freeSolo
                            options={options}
                            inputValue={this.state.addFieldName || ''}
                            onInputChange={(event, newInputValue) => {
                                this.setState({ addFieldName: newInputValue })
                            }}
                            onChange={(event, newValue) => {
                                this.setState({ addFieldName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Add Field Name"
                                    variant="outlined"
                                    value={this.state.addFieldName}
                                    onChange={(e) => this.setState({ addFieldName: e.target.value })}
                                    InputLabelProps={{ shrink: true }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            this.state.addFieldName &&
                                            this.state.addFieldValue
                                        ) {
                                            this.handleAddTextField()
                                        }
                                    }}
                                    error={
                                        this.state.addFieldName &&
                                        this.state[field][this.formatKeys(this.state.addFieldName)]
                                    }
                                />
                            )}
                            style={{ width: "50%", marginLeft: '10px'}}
                            disablePortal={true}
                            ListboxProps={{
                                style: {
                                    whiteSpace: 'nowrap',
                                    maxHeight: '200px',
                                },
                            }}
                        />
                        <TextField
                            label="Add Field Value"
                            variant="outlined"
                            value={this.state.addFieldValue}
                            onChange={(e) => this.setState({addFieldValue: e.target.value})}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                type: "number",
                                style: {height: '15px'}
                            }}
                            style={{ marginLeft: '10px', marginRight: '2px', width: '50%'}}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && this.state.addFieldName && this.state.addFieldValue) {
                                    this.handleAddTextField()
                                }
                            }}
                            error={(field === 'charges' && isNaN(this.state.addFieldValue))}
                        />
                        <IconButton className="toggle-icon-button" onClick={this.handleAddTextField} disabled={this.state.addFieldName === "" || this.state.addFieldValue === ""}>
                            <CheckCircleIcon/>
                        </IconButton>
                    </>
                }
            </div>
        )
    }

    handleAddTextField = () => {
        const currentFields = this.state[this.state.addField]
        const updatedFields = {...currentFields, [this.state.addField == "charges" ? this.state.addFieldName : this.formatKeys(this.state.addFieldName)]: this.state.addFieldValue}
        let chargeFields, updatedChargeFields
        if (this.state.addField === 'charges') {
            if (this.state.addFieldName.includes('hst') || this.state.addFieldName.includes('gst') || this.state.addFieldName.includes('qst')){
                chargeFields = this.state.totalCharges
                updatedChargeFields = {...chargeFields, [this.formatKeys(this.state.addFieldName)]: this.state.addFieldValue}
                this.setState({totalCharges: updatedChargeFields})
            } else if (this.state.addFieldValue < 0){
                if (Object.keys(this.state.netTransportationCharges).length > 0) {
                    chargeFields = this.state.netTransportationCharges
                    updatedChargeFields = {...chargeFields, [this.formatKeys(this.state.addFieldName)]: this.state.addFieldValue}
                    this.setState({netTransportationCharges: updatedChargeFields})
                } else if (Object.keys(this.state.subtotalCharges).length > 0) {
                    chargeFields = this.state.subtotalCharges
                    updatedChargeFields = {...chargeFields, [this.formatKeys(this.state.addFieldName)]: this.state.addFieldValue}
                    this.setState({subtotalCharges: updatedChargeFields})
                } else {
                    chargeFields = this.state.totalCharges
                    updatedChargeFields = {...chargeFields, [this.formatKeys(this.state.addFieldName)]: this.state.addFieldValue}
                    this.setState({totalCharges: updatedChargeFields})
                }
            } else {
                if (Object.keys(this.state.subtotalCharges).length > 0) {
                    chargeFields = this.state.subtotalCharges
                    updatedChargeFields = {...chargeFields, [this.formatKeys(this.state.addFieldName)]: this.state.addFieldValue}
                    this.setState({subtotalCharges: updatedChargeFields})
                } else {
                    chargeFields = this.state.totalCharges
                    updatedChargeFields = {...chargeFields, [this.formatKeys(this.state.addFieldName)]: this.state.addFieldValue}
                    this.setState({totalCharges: updatedChargeFields})
                }
            }
            this.calculateTotalCharges()
        }
        this.setState({
            [this.state.addField]: updatedFields,
            addField: '',
            addFieldName: '',
            addFieldValue: ''
        })
    }

    transformCharges = (charges) => {
        return Object.fromEntries(
            Object.entries(charges).map(([key, value]) => {
                // Find the mapping entry where mapsTo matches the key
                const mapping = CHARGES_MAP.find(item => item.mapsTo === key);

                // If found, use `charge` as the new key; otherwise, keep the original key
                return [mapping ? mapping.charge : key, value];
            })
        );
    };

    saveInvoiceFields = () => {
        const mappedCharges = this.transformCharges(this.state.charges);

        const data = {
            invoiceNumber: this.props.invoiceNumber,
            originalTrackingId: this.props.invoiceItemSmartSearchDetails?.trackingId,
            updatedTrackingId: this.state.trackingId,
            details: this.state.details,
            charges: mappedCharges,
            sender: this.state.sender,
            recipient: this.state.recipient,
            error: this.state.isErrorItem,
            isPreviouslyBilled: this.state.isPreviouslyBilled,
            carrierInvoiceItemId: this.props.invoiceItemSmartSearchDetails?.carrierInvoiceItemId
        }
        this.props.saveCarrierInvoiceItemDetails(data)
        this.props.closeEditInvoiceEntry()
    }

    render() {
        let headerButtons =
            [{ icon: <KeyboardBackspaceIcon />, handler: () => this.props.closeEditInvoiceEntry(), disabled: false, title: "Back" },
                {icon: <SaveIcon />, handler: () => this.saveInvoiceFields(), disabled: this.state.trackingId === '', title: "Save Entry"}]
        return (
            <>
                <CustomToolbar
                    title="Edit Carrier Invoice Item"
                    createTitle="Process Carrier Invoice"
                    backTitle={""}
                    headerButtons={headerButtons}
                />
                <div className="item-container">
                    <div className="item-header">
                        <div>
                            <TextField
                                variant="outlined"
                                label="Invoice Number"
                                style={{ width: '300px', marginRight: '10px'}}
                                value={this.props.invoiceNumber || ''}
                                disabled
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                variant="outlined"
                                label="Tracking Id"
                                style={{ width: '300px'}}
                                value={this.state.trackingId}
                                onChange={(event) => this.setState({ trackingId: event.target.value })}
                                required
                                error={this.state.trackingId === ''}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={this.state.isErrorItem} onChange={() => this.setState({isErrorItem: !this.state.isErrorItem})}/>
                                }
                                label="Item Error"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={this.state.isPreviouslyBilled} onChange={() => this.setState({isPreviouslyBilled: !this.state.isPreviouslyBilled})}/>
                                }
                                label="Previously Billed Item"
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className="item-information">
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', overflowX: 'auto' }}>
                            <div style={{ flex: 1, marginRight: "20px"}}>
                                <Typography variant="h6">{ITEM_DETAILS}</Typography>
                                {this.renderTextFields("details", this.state.details)}
                                {this.renderAddTextField("details", ITEM_DETAILS_OPTIONS)}
                            </div>
                            <div style={{ flex: 1, marginRight: "20px"}}>
                                <Typography variant="h6">{CHARGES}</Typography>
                                {this.renderChargesTextFields()}
                                {this.renderAddTextField("charges", CHARGES_MAP.map(it => it.mapsTo))}
                            </div>
                            <div style={{ flex: 1, marginRight: "20px"}}>
                                <Typography variant="h6">{SENDER_INFO}</Typography>
                                {this.renderTextFields("sender", this.state.sender)}
                                {this.renderAddTextField("sender", SENDER_OPTIONS)}
                            </div>
                            <div style={{ flex: 1, marginRight: "20px"}}>
                                <Typography variant="h6">{RECIPIENT_INFO}</Typography>
                                {this.renderTextFields("recipient", this.state.recipient)}
                                {this.renderAddTextField("recipient", RECIPIENT_OPTIONS )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

EditCarrierInvoiceItemDetails.propTypes = {
    invoiceItemSmartSearchDetails: PropTypes.object
}

EditCarrierInvoiceItemDetails.defaultProps = {
    invoiceItemSmartSearchDetails: {}
}

const mapStateToProps = () => ({

})

const actionCreators = {
    saveCarrierInvoiceItemDetails,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, EditCarrierInvoiceItemDetails);
