
import {
    CARRIER_SERVICE_OPTIONS
} from "../ConditionFilters/constants";

export const CONDITION_ACTIONS_TITLE = 'Condition Actions';

export const BOOL_SELECT = [
    "True",
    "False"
]

export const COUNTRY_OPTIONS = [
    {name: "Canada", code: "Canada"},
    {name: "United States", code: "United States"},
]

export const SIGNATURE_OPTIONS = [
    "Service Default",
    "No Signature Required",
    "Direct Signature Required",
    "Adult Signature Required"
]

export const TEST_ADDRESSES_OBJECT = {
    HOUSTON: {
        address1: "2092 Afton Street",
        address2: "",
        city: "Houston",
        state: "TX",
        country: "US",
        ZIP: "77055"
    },
    MISSISSAUGA: {
        address1: "5035 Timberlea Blvd",
        address2: "Unit 5",
        city: "Mississauga",
        state: "ON",
        country: "CA",
        ZIP: "L4W2W9"
    }
}

export const TEST_ADDRESSES_ARRAY = [
    {
        address1: "2092 Afton Street",
        address2: "",
        city: "Houston",
        state: "TX",
        country: "US",
        ZIP: "77055"
    },
    {
        address1: "5035 Timberlea Blvd",
        address2: "Unit 5",
        city: "Mississauga",
        state: "ON",
        country: "CA",
        ZIP: "L4W2W9"
    }
]

export const ACCOUNT_IDS = [
    "510087445",
    "99999992",
]

export const TEST_ACCOUNTS = [
    {accountId: "510087445", carrier: "Fedex", name: "Exponet Canada Inc", address: TEST_ADDRESSES_OBJECT.MISSISSAUGA},
    {accountId: "510087445", carrier: "Fedex", name: "Exponet Canada Inc", address: TEST_ADDRESSES_OBJECT.HOUSTON},
    {accountId: "99999992", carrier: "Canpar", name: "Exponet Canada Inc", address: TEST_ADDRESSES_OBJECT.MISSISSAUGA},
]

export const CURRENCIES = [
    "USD",
    "CAD"
]

export const SHIPPING_TEMPLATES = [
    "SFP (Canada West), Amazon.ca",
    "SFP (Canada East), Amazon.ca",
    "Free Shipping Template, Amazon.ca",
    "FREE SHIPPING, Amazon.com"
]

export const TAGS = [
    "TAG"
]

export const CATEGORIES = [
    "CATEGORIES"
]
export const TAX = [
    "HST", "PST", "QST", "GST"
]

export const PACKAGING_OPTIONS = [
    "Box/Other"
]

export const BANK_OPTIONS = ["Associated Transaction Bank Account"]
export const STATUS_OPTIONS = ["Approved for Payment", "Duplicate", "Needs Review", "Paid", "Reprocessing"]
export const PERCENT_OR_DOLLAR = ["%", "$"]
export const ABOVE_OR_BELOW = ["above", "below"]
export const ASIN_OPTIONS = ["ASIN", "related product ASIN"]
export const HEAD_ACTION_CELLS = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: "Actions",
    },
    {
        id: 'step',
        numeric: false,
        disablePadding: true,
        label: "Action Type",
    },
];

// types of components to display
export const COMPONENT_TYPES = {
    DROPDOWN_1: 'dropdown',
    DROPDOWN_2: 'dropdown_2',
    DROPDOWN_AND_BOOLEAN: 'dropdown_and_boolean',
    NUMERICAL_DROPDOWN_1: 'numerical_dropdown_1',
    NUMERICAL_INPUT_1: 'numerical_input_1',
    NUMERICAL_INPUT_2: 'numerical_input_2',
    NUMERICAL_INPUT_3: 'numerical_input_3',
    TEXT_INPUT_1: 'text_input_1',
    BOOLEAN_1: 'boolean_1',
    PACKAGE_TABS: 'packages_1',
    DROPDOWN_AND_TEXT_INPUT: 'dropdown_and_text_input',
};

// specifics (for dropdowns)
export const COMPONENT_SPECS = {
    ACCOUNT: TEST_ACCOUNTS,
    ADDRESS: TEST_ADDRESSES_ARRAY,
    SERVICE: CARRIER_SERVICE_OPTIONS,
    BOOL_DROPDOWN: BOOL_SELECT,
    PACKAGING: PACKAGING_OPTIONS,
    PAYER: ACCOUNT_IDS,
    RULES: "RULES", // TODO ADD RULES
    SIGNATURE: SIGNATURE_OPTIONS,
    TAGS: TAGS,
    REMOVE_TAGS: TAGS,
    SHIPPING_TEMPLATE : SHIPPING_TEMPLATES,
    CATEGORIES: CATEGORIES,
    TAX: TAX,
    BANK_ACCOUNTS: BANK_OPTIONS,
    STATUS: STATUS_OPTIONS,
    PRICE_UNIT:PERCENT_OR_DOLLAR,
    INCREASE_DECREASE: ABOVE_OR_BELOW,
    ASINS: ASIN_OPTIONS,
}

export const PREPACKAGE_ACTIONS = "prePackageActions"
export const PACKAGE_ACTIONS = "packageActions"
export const POST_PACKAGE_ACTIONS = "postPackageActions"
export const LISTINGS_ACTIONS = "listingsActions"
export const TRANSACTIONS_ACTIONS = "transactionsActions"
export const PRICING_ACTIONS = "pricingActions"
export const PACKAGE_ACTIONS_MAP = {
    prePackageActions: "Pre-package",
    packageActions: "Package",
    postPackageActions: "Post-package",
    listingsActions: "Listings",
    transactionsActions: "Transactions",
    pricingActions: "Pricing"
}

export const SET_SHIPPING_ACCOUNT = "Set shipping account"
export const SET_SERVICE = "Set service"
export const SET_RESIDENTIAL = "Set residential"
export const SET_SIGNATURE_CONFIRMATION = "Set signature confirmation"
export const SET_NUMBER_OF_PACKAGES = "Set number of packages"
export const SET_PACKAGE_WEIGHT = "Set package weight"
export const SET_DECLARED_VALUE = "Set declared value"
export const SET_CURRENCY = "Set currency"
export const SET_REFERENCE_1 = "Set reference 1"
export const SET_REFERENCE_2 = "Set reference 2"
export const SET_REFERENCE_3 = "Set reference 3"
export const SET_PACKAGING = "Set packaging"
export const SET_PACKAGE_DIMENSIONS = "Set package dimensions"
export const ADD_TAG = "Add tag"
export const REMOVE_TAG = "Remove tag"
export const RATE_AND_SET_TO_CHEAPEST = "Rate and set to cheapest"
export const RATE_AND_APPLY_DEFAULT_SERVICE = "Rate and apply default service"
export const RUN_AUTOMATION_RULE = "Run automation rule"
export const SET_PACKAGE_ORDER_ITEM_QUANTITY = "Set package order item quantity"
export const SET_SHIP_TO_PHONE = "Set ship to phone"
export const SET_SHIPPING_TEMPLATE = "Set shipping template"
export const SET_HANDLING_TIME = "Set handling time"
export const SET_AMAZON_QUANTITY = "Set Amazon quantity"
export const AUTOMATIC_INVENTORY_ALLOCATION = "Automatic inventory allocation"
export const SEND_NOTIFICATION = "Send notification"
export const SET_CATEGORY = "Set category"
export const ADD_TAX = "Set tax province and type"
export const SET_TRANSACTION_REFERENCE_ID = "Set transaction reference Id"
export const SET_INVOICE_REFERENCE_ID = "Set invoice reference Id"
export const SET_INVOICE_BANK_ACCOUNT = "Set invoice bank account"
export const SET_INVOICE_STATUS = "Set invoice status"
export const SET_INVOICE_PAYMENT_DATE = "Set invoice payment date"
export const CHANGE_PRICE_TO = "Change price to"
export const SHIPMENTS_CATEGORY= "Shipments"
export const TRANSACTIONS_CATEGORY= "Transactions"
export const LISTINGS_CATEGORY= "Listings"
export const PRICING_CATEGORY= "Pricing"

// table contents
export const CONDITION_ACTIONS = [

    // each item contains the name that is displayed in the table, and the type (for rendering during selection)
    // Dropdown_1 components contain a specification key which defines the dropdown to be displayed during selection
    // Numerical_input_2 && 3 contain a label for each input, as well as a dropdown for secondary options

    {name: SET_SHIPPING_ACCOUNT, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.ACCOUNT, step: PREPACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    //{name: "Set ship from address", type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.ADDRESS},
    {name: SET_SERVICE, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.SERVICE, step: PREPACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    //{name: "Set payer", type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.PAYER},
    {name: SET_RESIDENTIAL, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.BOOL_DROPDOWN, step: PREPACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: SET_SIGNATURE_CONFIRMATION, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.SIGNATURE, step: PREPACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: ADD_TAG, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.TAGS, step: PREPACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: REMOVE_TAG, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.REMOVE_TAGS, step: PREPACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: SET_NUMBER_OF_PACKAGES, type: COMPONENT_TYPES.NUMERICAL_DROPDOWN_1, step: PREPACKAGE_ACTIONS, copyPackages: false, predefinedValues: ['-', "Order item quantity", "Number of line items"], category: SHIPMENTS_CATEGORY},
    {name: SET_PACKAGE_WEIGHT, type: COMPONENT_TYPES.NUMERICAL_INPUT_1, step: PACKAGE_ACTIONS, predefinedValues: ["-", "Calculate Automatically"], category: SHIPMENTS_CATEGORY},
    {name: SET_DECLARED_VALUE, type: COMPONENT_TYPES.NUMERICAL_INPUT_1, step: PACKAGE_ACTIONS, predefinedValues: ["-", "Calculate Automatically"], category: SHIPMENTS_CATEGORY},
    {name: SET_CURRENCY, type: COMPONENT_TYPES.DROPDOWN_1, spec: CURRENCIES, step: PACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: SET_REFERENCE_1, type: COMPONENT_TYPES.TEXT_INPUT_1, step: PACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: SET_REFERENCE_2, type: COMPONENT_TYPES.TEXT_INPUT_1, step: PACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: SET_REFERENCE_3, type: COMPONENT_TYPES.TEXT_INPUT_1, step: PACKAGE_ACTIONS, predefinedValues: ['-', "Order ID", "(Shortcode)-Quantity ..."], category: SHIPMENTS_CATEGORY},
    {name: SET_PACKAGING, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.PACKAGING, step: PACKAGE_ACTIONS, predefinedValues: ["-", "From Box Suggestions"], category: SHIPMENTS_CATEGORY},
    {name: SET_PACKAGE_DIMENSIONS, type: COMPONENT_TYPES.NUMERICAL_INPUT_3, label1: 'length', label2: 'width', label3: 'height', step: PACKAGE_ACTIONS, predefinedValues: ["-", "From Box Suggestions"], category: SHIPMENTS_CATEGORY},
    {name: SET_PACKAGE_ORDER_ITEM_QUANTITY, type: COMPONENT_TYPES.NUMERICAL_DROPDOWN_1, step: PACKAGE_ACTIONS, predefinedValues: ['-',"Order item quantity", "Separate line items"], category: SHIPMENTS_CATEGORY},
    {name: SET_SHIP_TO_PHONE, type: COMPONENT_TYPES.TEXT_INPUT_1, step: PACKAGE_ACTIONS, predefinedValues: [], category: SHIPMENTS_CATEGORY},
    {name: RATE_AND_SET_TO_CHEAPEST, type: COMPONENT_TYPES.BOOLEAN_1, step: POST_PACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: RATE_AND_APPLY_DEFAULT_SERVICE, type: COMPONENT_TYPES.BOOLEAN_1, step: POST_PACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: RUN_AUTOMATION_RULE, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.RULES, step: POST_PACKAGE_ACTIONS, category: SHIPMENTS_CATEGORY},
    {name: SET_CATEGORY, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.CATEGORIES, step: TRANSACTIONS_ACTIONS, predefinedValues: ['-', "Associated Cheque Category"], category: TRANSACTIONS_CATEGORY},
    {name: ADD_TAX, type: COMPONENT_TYPES.DROPDOWN_2, spec: COMPONENT_SPECS.TAX, label2: 'tax type', step: TRANSACTIONS_ACTIONS, category: TRANSACTIONS_CATEGORY},
    {name: SET_AMAZON_QUANTITY, type: COMPONENT_TYPES.NUMERICAL_INPUT_1, step: LISTINGS_ACTIONS, predefinedValues: [], category: LISTINGS_CATEGORY},
    {name: AUTOMATIC_INVENTORY_ALLOCATION, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.BOOL_DROPDOWN, step: LISTINGS_ACTIONS, category: LISTINGS_CATEGORY},
    {name: SET_SHIPPING_TEMPLATE, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.SHIPPING_TEMPLATE, step: LISTINGS_ACTIONS, category: LISTINGS_CATEGORY},
    {name: SET_HANDLING_TIME, type: COMPONENT_TYPES.NUMERICAL_INPUT_1, step: LISTINGS_ACTIONS, predefinedValues: [], category: LISTINGS_CATEGORY},
    {name: SET_TRANSACTION_REFERENCE_ID, type: COMPONENT_TYPES.TEXT_INPUT_1, predefinedValues:['-', "Reference Id of Invoice", "Invoice Number"], step: TRANSACTIONS_ACTIONS, category: TRANSACTIONS_CATEGORY},
    {name: SET_INVOICE_REFERENCE_ID, type: COMPONENT_TYPES.TEXT_INPUT_1, predefinedValues:['-', "Reference Id of Transaction", "Invoice Number"], step: TRANSACTIONS_ACTIONS, category: TRANSACTIONS_CATEGORY},
    {name: SET_INVOICE_BANK_ACCOUNT, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.BANK_ACCOUNTS, step: TRANSACTIONS_ACTIONS, predefinedValues: ['-', "Associated Transaction Bank Account"], category: TRANSACTIONS_CATEGORY},
    {name: SET_INVOICE_STATUS, type: COMPONENT_TYPES.DROPDOWN_1, spec: COMPONENT_SPECS.STATUS, step: TRANSACTIONS_ACTIONS, category: TRANSACTIONS_CATEGORY},
    {name: SET_INVOICE_PAYMENT_DATE, type: COMPONENT_TYPES.TEXT_INPUT_1, predefinedValues:['-', "Transaction date"], step: TRANSACTIONS_ACTIONS, category: TRANSACTIONS_CATEGORY},
    {name: CHANGE_PRICE_TO, type: COMPONENT_TYPES.DROPDOWN_AND_TEXT_INPUT, spec:COMPONENT_SPECS.PRICE_UNIT, spec2:COMPONENT_SPECS.INCREASE_DECREASE, spec3:COMPONENT_SPECS.ASINS, step: PRICING_ACTIONS, category: PRICING_CATEGORY},
]


// set up predefined values
export const PRE_DEFINED_VALUES = ['-',"Order ID"]
export const PACKAGE_PRE_DEFINED_VALUES = ['-',"Order item quantity"]
export const PACKAGE_PRE_DEFINED_WARNING = "This action will be applied to shipments with orders that have only one line item."
export const SET_PACKAGE_ORDER_ITEM_QUANTITY_SEPARATE_LINE_ITEMS_MESSAGE = "The order item quantity of each line item will be added to its own package with other line item quantities set to zero. It is recommended to have one package for each line item."
export const PACKAGE_WEIGHT_CALCULATE_AUTOMATICALLY = "Calculate Automatically"
export const PACKAGE_WEIGHT_CALCULATE_AUTOMATICALLY_MESSAGE = "Package weight is taken into account if it is set, otherwise it is assumed to be negligible."

