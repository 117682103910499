import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import { MARKETPLACE_SUMMARY_PAGES } from './constants';
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {
    lastUpdatedDateSummarySelector,
    loadingSelector, marketplaceSummaryProgressSelector,
    marketplaceSummarySelector, marketplaceSummarySessionIdSelector,
    shippingSettingSelector,
} from '../../../redux/selectors/accounting';
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import {userInfoSelector} from "../../../redux/selectors/auth";
import {getTableSetting, saveTableSetting} from "../../../redux/actions/account";
import MarketplaceSummaryTable from './MarketplaceTable';
import {
    importMarketplaceSummary,
    listMarketplaceSummary,
    listShippingSettings, marketplaceProgressSummary,
} from '../../../redux/actions/accounting';
import { tableSettingSelector } from '../../../redux/selectors/account';

class MarketplaceSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: MARKETPLACE_SUMMARY_PAGES.TABLE,
            feedbackBarOpen: false,
            selectedYear: new Date().getFullYear(),
            selectedQuarters: null,
            selectedCurrency: "Consolidated",
            selectedMonth:  " ",
            marketplaceSummaryFilterSetting: null,
            customFeedbackBarOpen: false,
            customMessage: '',
            customStatus: 'error',
            isFetch: false,

    }
    }


    async componentDidMount() {
        this.props.getTableSetting(this.props.user.userId)
        this.props.listShippingSettings()

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }

        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({ marketplaceSummaryFilterSetting: this.props.tableSetting?.marketplaceSummaryFilterSetting },()=>{
                this.setState({
                    selectedCurrency: this.state.marketplaceSummaryFilterSetting.currency,
                    selectedYear: this.state.marketplaceSummaryFilterSetting.year,
                    selectedMonth: this.state.marketplaceSummaryFilterSetting.month,
                    selectedQuarters: this.state.marketplaceSummaryFilterSetting.quarters,
                },()=>{
                    this.listMarketplaceAPI()
                })
            })
        }
    }

    handleYearChange = (event) => {
        this.setState({ selectedYear: event.target.value },() => {
            this.onFiltersInputChange("year",event)
        })

    }

    handleMonthChange = (event) => {
        this.setState({ selectedMonth: event.target.value },() => {
            this.onFiltersInputChange("month",event)
        })

    }

    handleCurrencyChange = (event) => {
        this.setState({ selectedCurrency: event.target.value },() => {
            this.onFiltersInputChange("currency",event)
        })


    }


    handleQuarterChange = (quarter) => {
        const quarterMapping = {
            "QTR 1": 1,
            "QTR 2": 2,
            "QTR 3": 3,
            "QTR 4": 4
        }
        const quarterNumber = quarterMapping[quarter]
        const selectedQuarters = this.state.selectedQuarters === quarterNumber ? null : quarterNumber
        this.setState({ selectedQuarters }, () => {
            this.onFiltersInputChange("quarters",this.state.selectedQuarters)
        })

    }

    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    onFiltersInputChange = (name,event) => {
        let filtersMap = this.props.tableSetting?.marketplaceSummaryFilterSetting

        if(name === "quarters"){
            filtersMap[name] = event;
        }else{
            filtersMap[name] = event.target.value;
        }

        this.saveUpdatedFilters(filtersMap)
    }


    saveUpdatedFilters = (filters) => {
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changeMarketplaceSummaryFilters: true,
            marketplaceSummaryFilters: filters
        })
    }

    displayTable = () => {
            return (
                <MarketplaceSummaryTable
                    loadingData={this.props.loadingData}
                    setCurrentPage={this.setCurrentPage}
                    customFeedBackBar={this.customFeedBackBar}
                    openFeedbackBar={this.openFeedbackBar}
                    marketplaceSummary={this.props.marketplaceSummary}
                    importMarketplaceSummary={this.props.importMarketplaceSummary}
                    listMarketplaceSummary={this.props.listMarketplaceSummary}
                    selectedQuarters={this.state.selectedQuarters}
                    selectedYear={this.state.selectedYear}
                    handleQuarterChange={this.handleQuarterChange}
                    handleYearChange={this.handleYearChange}
                    selectedCurrency={this.state.selectedCurrency}
                    handleCurrencyChange={this.handleCurrencyChange}
                    selectedMonth={this.state.selectedMonth}
                    handleMonthChange={this.handleMonthChange}
                    listMarketplaceAPI={this.listMarketplaceAPI}
                    progress={this.props.progress}
                    sessionId={this.props.sessionId}
                    setFetch={this.setFetch}
                    marketplaceProgressSummary={this.props.marketplaceProgressSummary}
                    lastUpdatedAt={this.props.lastUpdatedAt}
                />
            )
    }

    setFetch = (bool)=>{
        this.setState({isFetch: bool},()=>{
            this.listMarketplaceAPI()
        })

    }

    listMarketplaceAPI = () =>{
        let data
        if(this.state.selectedQuarters === null){
            data = {
                "year": this.state.selectedYear,
                "quarter": [1,2,3,4],
                "month": this.state.selectedMonth.trim(),
                "currency": this.state.selectedCurrency,
                "isFetch": this.state.isFetch,
            }
        }else{
            let newQuarter = [this.state.selectedQuarters]
            data = {
                "year": this.state.selectedYear,
                "quarter": newQuarter,
                "month": this.state.selectedMonth.trim(),
                "currency": this.state.selectedCurrency,
                "isFetch": this.state.isFetch,
            }
        }
        this.props.listMarketplaceSummary(data)
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case MARKETPLACE_SUMMARY_PAGES.TABLE:
                return this.displayTable()
            default:
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    customFeedBackBar = (status,message) =>{
        this.setState({customFeedbackBarOpen: true, customStatus: status, customMessage: message})
    }

    customCloseFeedbackBar = () => {
        this.setState({ customFeedbackBarOpen: false }, () => {
            this.setState({ customStatus: '', customMessage: ''})
        })
    }



    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }


    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                  open={this.state.customFeedbackBarOpen}
                  handleClose={this.customCloseFeedbackBar}
                  severity={this.state.customStatus ? 'success' : 'error'}
                  message={this.state.customMessage}
                />
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    user: userInfoSelector(state),
    loadingData: loadingSelector(state),
    marketplaceSummary: marketplaceSummarySelector(state),
    shippingSettings: shippingSettingSelector(state),
    tableSetting: tableSettingSelector(state),
    progress: marketplaceSummaryProgressSelector(state),
    sessionId: marketplaceSummarySessionIdSelector(state),
    lastUpdatedAt: lastUpdatedDateSummarySelector(state),
})


const actionCreators = {
    clearStatus,
    saveTableSetting,
    getTableSetting,
    importMarketplaceSummary,
    listMarketplaceSummary,
    listShippingSettings,
    marketplaceProgressSummary,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, MarketplaceSummary);