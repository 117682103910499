import React from "react";
import {Button, Paper, ClickAwayListener, TextField} from "@material-ui/core";
import "./Marketplace.css"
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import withShipment from "../../../withShipment";
import { LIST_MARKETPLACES } from '../../../redux/constants/settings';
import { MARKETPLACE_OPTIONS } from '../../automationrules/ConditionFilters/constants';

class ImportMarketplacePopper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMarketplace: null,
    };
  }
  componentDidMount() {
      console.log(this.props)
  }

  handleClose = () => {
    this.props.handleClosePopper();
  };

  handleSave = () => {
    this.props.callback(this.state.selectedMarketplace);
    this.props.handleClosePopper();
  };

  render() {
    return (
      <>
        <ClickAwayListener onClickAway={this.handleClose}>
          <Paper className="import-popper-container">
            <div className="import-popper-item">
              <Autocomplete
                options={MARKETPLACE_OPTIONS}
                getOptionLabel={(option) => option}
                value={this.state.selectedMarketplace}
                onChange={(event, value) => {
                  this.setState({ selectedMarketplace: value })
                }

                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="marketplace"
                    label="Select a Marketplace"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                style={{ width: '250px' }}
              />
            </div>
            <div className="supplier-payment-popper-buttons">
              <Button
                variant="contained"
                onClick={this.handleClose}
                style={{ width: '100px' }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={this.handleSave}
                disabled={!this.state.selectedMarketplace}
                color="primary"
                style={{ width: '100px' }}
              >
                Next
              </Button>
            </div>
          </Paper>
        </ClickAwayListener>
      </>
    );
  }
}


export default withShipment({
}, ImportMarketplacePopper);