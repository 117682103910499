import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

// Endpoints
import {
    SUPPLIER_INVOICES,
    SUPPLIER_INVOICE_TEMPLATES,
    CARRIER_INVOICES,
    CARRIER_INVOICE_TEMPLATES,
    FTL_CARRIER_INVOICES,
    FTL_CARRIER_INVOICE_TEMPLATES
} from "../../../components/global/Server/endpoints";
import { HTTP_SUCCESS } from "../../constants/global";

// Action constants
import {
    GET_ALL_SUPPLIER_INVOICES,
    PROCESS_SUPPLIER_INVOICE,
    GET_SUPPLIER_INVOICE_PDF,
    UPDATE_SUPPLIER_INVOICE,
    DELETE_SUPPLIER_INVOICE,
    UPDATE_SUPPLIER_INVOICE_STATUS,
    OVERWRITE_SUPPLIER_INVOICE,

    FETCH_SUPPLIER_INVOICE_TEMPLATES,
    FETCH_SUPPLIERS_WITHOUT_TEMPLATE,
    CREATE_SAVE_SUPPLIER_INVOICE_TEMPLATE,
    GET_SUPPLIER_INVOICE_TEMPLATE_PDF,
    DELETE_SUPPLIER_INVOICE_TEMPLATE,

    LIST_CARRIER_INVOICE_TEMPLATES,
    LIST_CARRIERS_WITHOUT_TEMPLATE,
    DELETE_CARRIER_INVOICE_TEMPLATE,
    CREATE_SAVE_CARRIER_INVOICE_TEMPLATE,
    GET_CARRIER_INVOICE_TEMPLATE_PDF,

    GET_ALL_CARRIER_INVOICES,
    GET_CARRIER_INVOICE_PDF,
    UPDATE_CARRIER_INVOICE_STATUS,
    PROCESS_CARRIER_INVOICE,
    GET_DATE_FORMATS,
    DELETE_CARRIER_INVOICE,
    GET_SUPPLIER_INVOICE_ATTACHMENT,
    DELETE_SUPPLIER_INVOICE_ATTACHMENT,
    REPROCESS_SUPPLIER_INVOICE,
    GET_SUPPLIER_INVOICE_PO,
    REPROCESS_CARRIER_INVOICE,
    FETCH_INVOICE_BY_TRACKING_ID,
    SAVE_CARRIER_INVOICE_ITEM_DETAILS,
    SAVE_SUPPLIER_INVOICE_PAYMENT_DETAILS,
    SAVE_CARRIER_INVOICE_PAYMENT_DETAILS,
    PROCESS_FTL_CARRIER_INVOICE,
    GET_FTL_CARRIER_INVOICES,
    SAVE_CARRIER_INVOICE,
    SAVE_FTL_CARRIER_INVOICE,
    REIMPORT_CARRIER_INVOICE_TEMPLATE, REIMPORT_SUPPLIER_INVOICE_TEMPLATE_PDF, REIMPORT_FTL_CARRIER_INVOICE_TEMPLATE
} from "../../constants/invoices";

// Secondary actions
import {
    storeAllSupplierInvoices,
    storeSupplierInvoicePDF,
    storeSupplierInvoiceTemplatePDF,
    storeSupplierInvoiceTemplates,
    storeSuppliersWithoutTemplate,
    storeCarrierInvoiceTemplates,
    storeCarriersWithoutTemplate,
    storeCarrierInvoiceTemplatePDF,
    storeAllCarrierInvoices,
    storeCarrierInvoicePDF,
    storeUpdatedSupplierInvoice,
    storeDateFormats,
    setProcessing,
    storeInvoicePurchaseOrder,
    storeReprocessedCarrierInvoices,
    setInvoiceSmartSearchRows,
    setInvoiceItemSmartSearchDetails,
    removeDeletedCarrierInvoices,
    storeUpdatedInvoiceSmartSearchRows,
    storeUpdatedInvoiceItemSmartSearchDetails,
    storeSupplierInvoiceOperationResponse,
    storeCarrierInvoiceOperationResponse,
    removeDeletedSupplierInvoices,
    storeFTLCarrierInvoices,
    storeProcessedFTLCarrierInvoice,
    removeDeletedFTLCarrierInvoice,
    updateFTLCarrierInvoiceStatus,
    storeReprocessedFTLCarrierInvoices,
    storeUpdatedCarrierInvoiceStatus,
    storeUpdatedCarrierInvoices, storeSupplierInvoice, updateCarrierInvoiceLoadingStatus, updateSmartSearchRows,
} from "../../actions/invoices"
import {
    clearStatus,
    requestStatus,
    showErrorMessage,
    hideError
} from "../../actions/global";
import { fetchAlerts } from "../../actions/alerts";
import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';
import {
    APPROVE_STATUS,
    NEEDS_REVIEW_STATUS,
    PAID_STATUS,
    SAVE_SUCCESS_MESSAGE,
    SUPPLIER_INVOICE_APPROVED_FOR_PAYMENT_MESSAGE,
    SUPPLIER_INVOICE_INVOICE_ADD_PAYMENT_METHOD_MESSAGE,
    SUPPLIER_INVOICE_INVOICE_REPROCESSED_MESSAGE,
    SUPPLIER_INVOICE_MARKED_AS_PAID_MESSAGE,
    SUPPLIER_INVOICE_MARKED_NEED_REVIEW_MESSAGE,
    SUPPLIER_INVOICE_SAVE_SUCCESS_MESSAGE,
    SUPPLIER_INVOICE_SAVED_AND_VALIDATE_MESSAGE
} from "../../../components/invoices/supplierInvoices/constants";
import {
    CARRIER_INVOICE_INVOICE_ADD_PAYMENT_METHOD_MESSAGE, LTL_FTL_CARRIER, STANDARD_CARRIER
} from "../../../components/invoices/carrierInvoices/constants";
import {storeFTLCarrierInvoiceTemplatePDF} from "../../actions/fullTruckloadCarriers";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}


function* getSupplierInvoices(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.GET_ALL_SUPPLIER_INVOICES, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (status.success) {
            yield put(storeAllSupplierInvoices(response.data))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* processSupplierInvoice(action) {
    yield put(hideError())
    try {
        yield put(setProcessing(true))
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.PROCESS_SUPPLIER_INVOICE, { content: action.invoice, priceCheck: true }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && response.data.success) {
            for (let a = 0; a < response.data.invoices.length; a++) {
                yield put(storeSupplierInvoice(action.fromDate, action.toDate, response.data.invoices[a]))
            }
            action.storeInvoice(response.data.invoices[0])
        } else {
            if (response.data?.message)
                yield put(showErrorMessage(response.data?.message))
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
        yield put(setProcessing(false))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(setProcessing(false))
    }
}

function* reprocessSupplierInvoice(action) {
    yield put(setProcessing(true))
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.REPROCESS_SUPPLIER_INVOICE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && response.data.success) {
            action.storeInvoice(response.data.invoices[0])
            yield put(storeUpdatedSupplierInvoice(response.data.invoices[0]))
            yield put(storeSupplierInvoiceOperationResponse(SUPPLIER_INVOICE_INVOICE_REPROCESSED_MESSAGE))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
        yield put(setProcessing(false))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(setProcessing(false))
    }
}

function* getSupplierInvoicePDF(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.GET_SUPPLIER_INVOICE_PDF, { supplierInvoiceId: action.supplierInvoiceId }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeSupplierInvoicePDF(response.data.invoice))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getSupplierInvoicePO(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.GET_SUPPLIER_INVOICE_PO, { poNumber: action.purchaseOrder }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeInvoicePurchaseOrder(response.data.purchaseOrder))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getSupplierInvoiceAttachment(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.PREVIEW_SUPPLIER_INVOICE_FILE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeSupplierInvoicePDF(response.data.bytes))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteSupplierInvoiceAttachment(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.DELETE_SUPPLIER_INVOICE_FILE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeUpdatedSupplierInvoice(response.data.invoice))
            action.update(response.data.invoice);
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteSupplierInvoice(action) {
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.DELETE_SUPPLIER_INVOICE, action.data))
        const status = {
                status: response.status,
                statusText: response.statusText,
                success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            yield put(removeDeletedSupplierInvoices(action.data?.supplierInvoiceId))
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateSupplierInvoice(action) {
    yield put(hideError())
    try {
        let response
        if (action.validate) {
            response = yield call(() => postRequest(SUPPLIER_INVOICES.UPDATE_VALIDATE_SUPPLIER_INVOICE, action.data))
        } else {
            response = yield call(() => postRequest(SUPPLIER_INVOICES.UPDATE_SUPPLIER_INVOICE, action.data))
        }
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            yield put(storeUpdatedSupplierInvoice(response.data.invoice))
            if (action.validate) {
                yield put(storeSupplierInvoiceOperationResponse(SUPPLIER_INVOICE_SAVED_AND_VALIDATE_MESSAGE))
            }
            else {
                yield put(storeSupplierInvoiceOperationResponse(SUPPLIER_INVOICE_SAVE_SUCCESS_MESSAGE))
            }
            action.reset();
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateSupplierInvoiceStatus(action) {
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.UPDATE_SUPPLIER_INVOICE_STATUS, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            for (let i = 0; i < response.data.invoices.length; i++){
                yield put(storeUpdatedSupplierInvoice(response.data.invoices[i]))
            }
            switch (action.data.status){
                case NEEDS_REVIEW_STATUS:
                    yield put(storeSupplierInvoiceOperationResponse(SUPPLIER_INVOICE_MARKED_NEED_REVIEW_MESSAGE))
                    break;
                case APPROVE_STATUS:
                    yield put(storeSupplierInvoiceOperationResponse(SUPPLIER_INVOICE_APPROVED_FOR_PAYMENT_MESSAGE))
                    break;
                case PAID_STATUS:
                    yield put(storeSupplierInvoiceOperationResponse(SUPPLIER_INVOICE_MARKED_AS_PAID_MESSAGE))
                    break;
            }
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* overwriteSupplierInvoice(action) {
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.OVERWRITE_SUPPLIER_INVOICE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            action.callback()
        } else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveCarrierInvoicePaymentDetailsSaga(action) {
    yield put(hideError())
    try {
        let response

            switch (action.carrierType){
                case STANDARD_CARRIER:
                    response = yield call(() => postRequest(CARRIER_INVOICES.SAVE_CARRIER_INVOICE_PAYMENT_DETAILS, action.data))
                    break
                case LTL_FTL_CARRIER:
                    response = yield call(() => postRequest(FTL_CARRIER_INVOICES.SAVE_FTL_CARRIER_INVOICE_PAYMENT_DETAILS, action.data))
                    break
            }
        if (response.data.success) {
            switch (action.carrierType){
                case STANDARD_CARRIER:
                    if (response.data.invoices){
                        yield put(storeUpdatedCarrierInvoices(response.data.invoices))
                        yield put(storeCarrierInvoiceOperationResponse(response.data.message))
                    }
                case LTL_FTL_CARRIER:
                    yield call(() => postRequest(FTL_CARRIER_INVOICES.SAVE_FTL_CARRIER_INVOICE_PAYMENT_DETAILS, action.data))
                    break
            }

            yield put(storeCarrierInvoiceOperationResponse(CARRIER_INVOICE_INVOICE_ADD_PAYMENT_METHOD_MESSAGE))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveSupplierInvoicePaymentDetailsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICES.SAVE_SUPPLIER_INVOICE_PAYMENT_DETAILS, action.data))
        if (response.data.success) {
            if (response.data.invoices){
                for (let i = 0; i < response.data.invoices.length; i++){
                    yield put(storeUpdatedSupplierInvoice(response.data.invoices[i]))
                }
            }
            yield put(storeSupplierInvoiceOperationResponse(SUPPLIER_INVOICE_INVOICE_ADD_PAYMENT_METHOD_MESSAGE))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchSupplierInvoiceTemplates() {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICE_TEMPLATES.FETCH_SUPPLIER_INVOICE_TEMPLATES))
        if (response.status === 200) {
            yield put(storeSupplierInvoiceTemplates(response.data))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* reImportSupplierInvoiceTemplatePDF(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICE_TEMPLATES.REIMPORT_SUPPLIER_INVOICE_TEMPLATE, action.data))
        if (response.status === 200 && response.data.success) {
            yield put(storeSupplierInvoiceTemplatePDF(response.data.bytes))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchSuppliersWithoutTemplate() {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICE_TEMPLATES.FETCH_SUPPLIER_WITHOUT_TEMPLATE))
        if (response.status === 200) {
            yield put(storeSuppliersWithoutTemplate(response.data))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createOrSaveSupplierInvoiceTemplate(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICE_TEMPLATES.CREATE_SAVE_SUPPLIER_INVOICE_TEMPLATE, action.data))
        if (response.status === 200 && response.data.success) {
            action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getSupplierInvoiceTemplatePDF(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICE_TEMPLATES.GET_SUPPLIER_INVOICE_TEMPLATE_PDF, { supplierId: action.supplierId }))
        if (response.status === 200 && response.data.success) {
            yield put(storeSupplierInvoiceTemplatePDF(response.data.bytes))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteSupplierInvoiceTemplate(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIER_INVOICE_TEMPLATES.DELETE_SUPPLIER_INVOICE_TEMPLATE, action.data))
        if (response.status === 200 && response.data.success) {
            action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* fetchCarrierInvoiceTemplates(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(CARRIER_INVOICE_TEMPLATES.LIST_CARRIER_INVOICE_TEMPLATES))
        if (response.status === 200) {
            yield put(storeCarrierInvoiceTemplates(response.data))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listCarriersWithoutTemplate(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(CARRIER_INVOICE_TEMPLATES.LIST_CARRIERS_WITHOUT_TEMPLATE))
        if (response.status === 200) {
            yield put(storeCarriersWithoutTemplate(response.data))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteCarrierInvoiceTemplate(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIER_INVOICE_TEMPLATES.DELETE_CARRIER_INVOICE_TEMPLATE, action.data))
        if (response.status === 200 && response.data.success) {
            action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createSaveCarrierInvoiceTemplate(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIER_INVOICE_TEMPLATES.CREATE_SAVE_CARRIER_INVOICE_TEMPLATE, action.data))
        if (response.status === 200 && response.data.success) {
            action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getCarrierInvoiceTemplatePDF(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIER_INVOICE_TEMPLATES.GET_CARRIER_INVOICE_TEMPLATE_PDF, action.data))
        if (response.status === 200 && response.data.success) {
            yield put(storeCarrierInvoiceTemplatePDF(response.data.bytes))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* reimportCarrierInvoiceTemplatePDF(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIER_INVOICE_TEMPLATES.REIMPORT_CARRIER_INVOICE_TEMPLATE, action.data))
        if (response.status === 200 && response.data.success) {
            yield put(storeCarrierInvoiceTemplatePDF(response.data.bytes))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* reimportFTLCarrierInvoiceTemplateSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(FTL_CARRIER_INVOICE_TEMPLATES.REIMPORT_FTL_CARRIER_INVOICE_TEMPLATE, action.data))
        if (response.status === 200 && response.data.success) {
            yield put(storeFTLCarrierInvoiceTemplatePDF(response.data.bytes))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* getAllCarrierInvoices(action) {
    yield put(hideError())
    try {
        // Mark that the table is loading
        yield put(updateCarrierInvoiceLoadingStatus(true))
        const response = yield call(() => postRequest(CARRIER_INVOICES.GET_ALL_CARRIER_INVOICES, action.data))
        if (response.status === 200 && response.data.success === true) {
            if (response.data.invoices)
                yield put(storeAllCarrierInvoices(response.data.invoices))
            else
                yield put(storeAllCarrierInvoices([]))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
        yield put(updateCarrierInvoiceLoadingStatus(false))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(updateCarrierInvoiceLoadingStatus(false))
    }
}

function* saveCarrierInvoice (action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIER_INVOICES.SAVE_CARRIER_INVOICE, action.data))
        if (response.status === 200 && response.data.success === true) {
            if (response.data.carrierInvoice) {
                let listOfInvoices = []
                listOfInvoices[0] = response.data.carrierInvoice
                yield put(storeUpdatedCarrierInvoices(listOfInvoices))
            }
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveFTLCarrierInvoice (action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(FTL_CARRIER_INVOICES.SAVE_FTL_CARRIER_INVOICE, action.data))
        if (response.status === 200 && response.data.success === true) {
            if (response.data.ftlCarrierInvoice) {
                let listOfInvoices = []
                listOfInvoices[0] = response.data.ftlCarrierInvoice
                yield put(storeReprocessedFTLCarrierInvoices(listOfInvoices))
            }
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getCarrierInvoicePDF(action) {
    yield put(hideError())
    try {
        let carrierType = action.data.carrierType
        let response
        switch (carrierType) {
            case STANDARD_CARRIER:
                response = yield call(() => postRequest(CARRIER_INVOICES.GET_CARRIER_INVOICE_PDF, action.data))
                break;
            case LTL_FTL_CARRIER:
                response = yield call(() => postRequest(FTL_CARRIER_INVOICES.GET_FTL_CARRIER_INVOICE_PDF, action.data))
                break;
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeCarrierInvoicePDF(response.data.pdf))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteCarrierInvoice(action) {
    try {
        let response
        switch (action.data.carrierType) {
            case STANDARD_CARRIER:
                response = yield call(() => postRequest(CARRIER_INVOICES.DELETE_CARRIER_INVOICE, action.data))
                break;
            case LTL_FTL_CARRIER:
                response = yield call(() => postRequest(FTL_CARRIER_INVOICES.DELETE_FTL_CARRIER_INVOICE, action.data))
                break;
        }
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (status.status === HTTP_SUCCESS && status.success) {
            switch (action.data.carrierType) {
                case STANDARD_CARRIER:
                    yield put(removeDeletedCarrierInvoices(response.data))
                    yield put(storeCarrierInvoiceOperationResponse(response.data.message))
                    break;
                case LTL_FTL_CARRIER:
                    yield put(removeDeletedFTLCarrierInvoice(response.data.carrierInvoiceId))
                    break;
            }
        }else {
            status.message = response.data?.message
            yield put(requestStatus(status))
        }
    } catch (e) {
        console.log(e)
    }
}

function* reprocessCarrierInvoiceSaga(action) {
    yield put(hideError())
    yield put(setProcessing(true))
    try {
        let response
        switch (action.carrierType) {
            case STANDARD_CARRIER:
                response = yield call(() => postRequest(CARRIER_INVOICES.REPROCESS_CARRIER_INVOICE, action.data))
                yield put(setProcessing(false))
                if (response.data.invoices) {
                    yield put(storeReprocessedCarrierInvoices(response.data))
                    yield put(storeCarrierInvoiceOperationResponse(response.data.message))
                }
                break
            case LTL_FTL_CARRIER:
                response = yield call(() => postRequest(FTL_CARRIER_INVOICES.REPROCESS_FTL_CARRIER_INVOICE, action.data))
                yield put(setProcessing(false))
                if (response.data.invoices) {
                    yield put(storeReprocessedFTLCarrierInvoices(response.data.invoices))
                }
                break
        }
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(setProcessing(false))
    }
}

function* updateCarrierInvoiceStatus(action) {
    yield put(hideError())
    try {
        let response;
        switch (action.data.carrierType) {
            case STANDARD_CARRIER :
                response = yield call(() => postRequest(CARRIER_INVOICES.UPDATE_CARRIER_INVOICE_STATUS, action.data))
                if (response.status === 200 && response.data.success) {
                    yield put(storeUpdatedCarrierInvoiceStatus(response.data))
                    yield put(storeCarrierInvoiceOperationResponse(response.data.message))
                    yield put(updateSmartSearchRows(action.data))
                } else {
                    yield put(showErrorMessage(response.data.message))
                }
                break;
            case LTL_FTL_CARRIER:
                response = yield call(() => postRequest(FTL_CARRIER_INVOICES.UPDATE_FTL_CARRIER_INVOICE_STATUS, action.data))
                if (response.status === 200 && response.data.success) {
                    yield put(updateFTLCarrierInvoiceStatus(action.data.carrierInvoiceIds,action.data.status))
                } else {
                    yield put(showErrorMessage(response.data.message))
                }
                break;
        }

    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* processCarrierInvoice(action) {
    yield put(hideError())
    yield put(setProcessing(true))
    try {
        const response = yield call(() => postRequest(CARRIER_INVOICES.PROCESS_CARRIER_INVOICE, {content: action.data.content, isSpreadsheet: action.isSpreadsheet}))
        yield put(setProcessing(false))
        if (response.status === 200 && response.data.success) {
            if (response.data.status == "Duplicate") {
                yield put(showErrorMessage("Duplicate invoice"))
            } else if (response.data.status == "Error") {
                yield put(showErrorMessage("Invoice failed to successfully process all shipments"))
            }
            action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(setProcessing(false))
    }
}

function* saveCarrierInvoiceItemDetailsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIER_INVOICES.SAVE_CARRIER_INVOICE_ITEM_DETAILS, action.data))
        if (response.data.success) {
            yield put(storeUpdatedInvoiceSmartSearchRows(response.data.invoice))
            yield put(storeUpdatedInvoiceItemSmartSearchDetails(response.data.invoiceItem))
            yield put(storeCarrierInvoiceOperationResponse(response.data.message))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getDateFormats() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(SUPPLIER_INVOICE_TEMPLATES.GET_DATE_FORMATS))
        yield put(storeDateFormats(response.data))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchInvoiceByTrackingIdSaga (action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIER_INVOICES.FETCH_INVOICE_BY_TRACKING_ID, {searchValue: action.searchValue}))
        if (response.status === 200 && response.data.success) {
            if (response.data.invoices)
                yield put(setInvoiceSmartSearchRows(response.data.invoices)) //saves the invoice information to invoiceSmartSearchRows
            if (response.data.invoiceItems)
                yield put(setInvoiceItemSmartSearchDetails(response.data.invoiceItems)) //saves the invoiceItem information to invoiceItemSmartSearchDetails
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* processFTLCarrierInvoice (action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(FTL_CARRIER_INVOICES.PROCESS_FTL_CARRIER_INVOICE, action.data))
        if (response.status === 200 && response.data.success) {
            yield put(storeProcessedFTLCarrierInvoice(response.data.ftlCarrierInvoice))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getFTLCarrierInvoices (action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(FTL_CARRIER_INVOICES.GET_FTL_CARRIER_INVOICES, action.data))
        if (response.status === 200 && response.data.success) {
            yield put(storeFTLCarrierInvoices(response.data.ftlCarrierInvoices))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* invoicesSagas() {
    yield takeLatest(GET_ALL_SUPPLIER_INVOICES, getSupplierInvoices);
    yield takeLatest(PROCESS_SUPPLIER_INVOICE, processSupplierInvoice);
    yield takeLatest(REPROCESS_SUPPLIER_INVOICE, reprocessSupplierInvoice);
    yield takeLatest(GET_SUPPLIER_INVOICE_PDF, getSupplierInvoicePDF);
    yield takeLatest(GET_SUPPLIER_INVOICE_PO, getSupplierInvoicePO);
    yield takeLatest(GET_SUPPLIER_INVOICE_ATTACHMENT, getSupplierInvoiceAttachment);
    yield takeLatest(DELETE_SUPPLIER_INVOICE_ATTACHMENT, deleteSupplierInvoiceAttachment);
    yield takeLatest(UPDATE_SUPPLIER_INVOICE, updateSupplierInvoice);
    yield takeLatest(DELETE_SUPPLIER_INVOICE, deleteSupplierInvoice);
    yield takeLatest(UPDATE_SUPPLIER_INVOICE_STATUS, updateSupplierInvoiceStatus);
    yield takeLatest(OVERWRITE_SUPPLIER_INVOICE, overwriteSupplierInvoice);
    yield takeLatest(SAVE_SUPPLIER_INVOICE_PAYMENT_DETAILS, saveSupplierInvoicePaymentDetailsSaga)

    yield takeLatest(FETCH_SUPPLIER_INVOICE_TEMPLATES, fetchSupplierInvoiceTemplates)
    yield takeLatest(REIMPORT_SUPPLIER_INVOICE_TEMPLATE_PDF, reImportSupplierInvoiceTemplatePDF)
    yield takeLatest(FETCH_SUPPLIERS_WITHOUT_TEMPLATE, fetchSuppliersWithoutTemplate)
    yield takeLatest(CREATE_SAVE_SUPPLIER_INVOICE_TEMPLATE, createOrSaveSupplierInvoiceTemplate)
    yield takeLatest(GET_SUPPLIER_INVOICE_TEMPLATE_PDF, getSupplierInvoiceTemplatePDF)
    yield takeLatest(DELETE_SUPPLIER_INVOICE_TEMPLATE, deleteSupplierInvoiceTemplate)

    yield takeLatest(LIST_CARRIER_INVOICE_TEMPLATES, fetchCarrierInvoiceTemplates)
    yield takeLatest(LIST_CARRIERS_WITHOUT_TEMPLATE, listCarriersWithoutTemplate)
    yield takeLatest(DELETE_CARRIER_INVOICE_TEMPLATE, deleteCarrierInvoiceTemplate)
    yield takeLatest(CREATE_SAVE_CARRIER_INVOICE_TEMPLATE, createSaveCarrierInvoiceTemplate)
    yield takeLatest(GET_CARRIER_INVOICE_TEMPLATE_PDF, getCarrierInvoiceTemplatePDF)
    yield takeLatest(REIMPORT_CARRIER_INVOICE_TEMPLATE, reimportCarrierInvoiceTemplatePDF)

    yield takeLatest(GET_ALL_CARRIER_INVOICES, getAllCarrierInvoices)
    yield takeLatest(SAVE_CARRIER_INVOICE, saveCarrierInvoice)
    yield takeLatest(GET_CARRIER_INVOICE_PDF, getCarrierInvoicePDF)
    yield takeLatest(UPDATE_CARRIER_INVOICE_STATUS, updateCarrierInvoiceStatus)
    yield takeLatest(DELETE_CARRIER_INVOICE, deleteCarrierInvoice)
    yield takeLatest(REPROCESS_CARRIER_INVOICE, reprocessCarrierInvoiceSaga)
    yield takeLatest(PROCESS_CARRIER_INVOICE, processCarrierInvoice)
    yield takeLatest(SAVE_CARRIER_INVOICE_ITEM_DETAILS, saveCarrierInvoiceItemDetailsSaga)
    yield takeLatest(GET_DATE_FORMATS, getDateFormats)
    yield takeLatest(FETCH_INVOICE_BY_TRACKING_ID, fetchInvoiceByTrackingIdSaga)
    yield takeLatest(SAVE_CARRIER_INVOICE_PAYMENT_DETAILS, saveCarrierInvoicePaymentDetailsSaga)

    yield takeLatest(GET_FTL_CARRIER_INVOICES, getFTLCarrierInvoices)
    yield takeLatest(PROCESS_FTL_CARRIER_INVOICE, processFTLCarrierInvoice)
    yield takeLatest(SAVE_FTL_CARRIER_INVOICE, saveFTLCarrierInvoice)
    yield takeLatest(REIMPORT_FTL_CARRIER_INVOICE_TEMPLATE, reimportFTLCarrierInvoiceTemplateSaga)
}