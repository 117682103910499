import {call, put, takeLatest, delay} from "redux-saga/effects";
import axios from "axios";
import {hideError, requestStatus, showErrorMessage} from "../../actions/global";
import {
    FIND_INVOICE_BY_INVOICE_NUMBER_ERROR_MESSAGE,
    SERVER_FAILED_ERROR_MESSAGE
} from "../../../pages/Dashboard/DashboardFinal/constants";
import {
    ADD_TAX_INFO,
    APPLY_TRANSACTIONS_RULES,
    ASSIGN_CATEGORY,
    DELETE_TRANSACTION_ATTACHMENT,
    DELETE_TRANSACTION_CATEGORY,
    DELETE_TRANSACTIONS,
    GET_INVOICE_BY_INVOICE_NUMBER,
    GET_SUPPLIER_REFERENCES,
    GET_TRANSACTION_TAX_AMOUNT,
    GET_TAXTYPE_WITH_STATE_PROVINCE,
    GET_TRANSACTION_ATTACHMENT,
    IMPORT_TRANSACTION,
    LIST_ALL_TRANSACTIONS,
    LIST_TRANSACTION_CATEGORIES,
    SAVE_TRANSACTION,
    SAVE_TRANSACTION_CATEGORY,
    SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION,
    SAVE_CHEQUE,
    LIST_ALL_CHEQUES,
    DELETE_CHEQUES,
    LIST_SHIPPING_SUMMARY,
    LIST_SHIPPING_SETTINGS,
    SAVE_SHIPPING_SETTINGS,
    SAVE_LEDGER,
    LIST_ALL_LEDGERS,
    SAVE_TRANSACTION_LEDGER,
    FETCH_TRANSACTIONS_LEDGER,
    SAVE_STATEMENT_TAXES_TEMPLATE,
    LIST_STATEMENT_TAXES,
    LIST_STATEMENT_TAXES_TEMPLATE,
    IMPORT_CHEQUE,
    VALIDATE_CHEQUE_NUMBER,
    DELETE_LEDGER_BY_YEAR,
    LEDGER_ENTRIES,
    LIST_PURCHASE_SUMMARY,
    UPDATE_LEDGER,
    DELETE_LEDGER,
    LIST_FTL_SUMMARY,
    UPDATE_ENTRY,
    DELETE_ENTRY,
    GET_NOTE,
    SAVE_NOTE,
    LIST_ALL_TRIAL_BALANCE,
    SAVE_TRIAL_BALANCE,
    FETCH_TRIAL_BALANCE,
    IMPORT_MARKETPLACE_SUMMARY,
    LIST_MARKETPLACE_SUMMARY,
    MARKETPLACE_PROGRESS_SUMMARY,
    SAVE_MARKETPLACE_LEDGER,
    FETCH_LEDGER_BALANCE,
    DELETE_LEDGER_BY_YEAR_AND_CURRENCY,
    LIST_ALL_INCOME_STATEMENT,
    FETCH_INCOME_STATEMENT,
    SAVE_INCOME_STATEMENT,
    SAVE_INCOME_STATEMENT_MAPPING_TEMPLATE, LIST_INCOME_STATEMENT_MAPPING_TEMPLATE, EXPORT_LEDGERS,
} from '../../constants/accounting';
import {
    CHEQUES, INCOME_STATEMENT,
    LEDGER, LISTINGS, MARKETPLACE_SUMMARY, NOTE, PURCHASE_SUMMARY,
    SHIPPING_SUMMARY, STATEMENT_OF_TAXES,
    TRANSACTION_CATEGORY,
    TRANSACTIONS,
    TRIAL_BALANCE,
} from '../../../components/global/Server/endpoints';
import {
    storeAllTransactions,
    storeUpdatedTransaction,
    removeDeletedTransaction,
    setProcessing,
    storeTransactionAttachment,
    storeAllTransactionCategories,
    storeSupplierReferences,
    getInvoiceByInvoiceNumber,
    storeFoundInvoice,
    applyTransactionsRules,
    storeUpdatedTaxInfo,
    getTaxTypeWithStateProvince,
    storetaxTypeWithStateProvince,
    storeUpdatedTransactions,
    storeEditingTransaction,
    storeUpdatedCheque,
    storeAllCheques,
    storeAllShippingSummary,
    storeShippingSummarySettings,
    storeStatementTaxesTemplate,
    storeAllStatementTaxesSummary,
    storeAllLedgers,
    storeTransactionsLedger,
    startLoading,
    stopLoading,
    storeAllPurchaseSummary,
    storeAllFTLSummary,
    storeLedgerEntry,
    storeNote,
    storePredefinedEntry,
    storeTrialBalance,
    storeMarketplaceSummary,
    storeMarketplaceProgressSummary,
    storeMarketplaceSession,
    storeIncomeStatement, storeIncomeStatementMappingTemplate, storeExportLedger, clearExportLedgers,
} from '../../actions/accounting';
import {fetchPricingListings, storeAllBankAccounts} from "../../actions/settings";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}


function* listAllLedgesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.LIST_ALL_LEDGER,action.data))
        if (response.data.success) {
            if (response.data.ledgers)
                yield put(storeAllLedgers(response.data.ledgers))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* fetchTransactionsLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.FETCH_TRANSACTION_LEDGER,action.data))
        if (response.data.success) {
            if (response.data.fetchTransactions){
                if (action.callback) {
                    action.callback(null, response.data.fetchTransactions);
                }
                yield put(storeTransactionsLedger(response.data.fetchTransactions))
            }

        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchLedgerBalanceSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.FETCH_LEDGER_BALANCE,action.data))
        if (response.data.success) {
                if (action.callback) {
                    action.callback(response.data.balance)
                }
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* deleteLedgerByYearSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.DELETE_LEDGER_BY_YEAR,action.data))
        if (response.data.success) {
            if (response.data.ledgers)
                yield put(storeAllLedgers(response.data.ledgers))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* deleteLedgerByYearAndCurrencySaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.DELETE_LEDGER_BY_YEAR_AND_CURRENCY,action.data))
        if (response.data.success) {
            if (response.data.ledgers)
                yield put(storeAllLedgers(response.data.ledgers))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}



function* saveLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.SAVE_LEDGER, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully saved ledgers",
                success: true
            }
            yield put(storeAllLedgers(response.data.ledgers))
            yield put(requestStatus(status))
            action.callback()


        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveMarketplaceLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.SAVE_MARKETPLACE_LEDGER, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully saved",
                success: true
            }
            yield put(storeTransactionsLedger(response.data))
            yield put(requestStatus(status))
            action.callback()


        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveTransactionLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.SAVE_TRANSACTION_LEDGER, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully saved",
                success: true
            }
            yield put(storeAllLedgers(response.data.ledgers))
            //yield put(requestStatus(status))


            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* listAllTransactionsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(TRANSACTIONS.LIST_ALL_TRANSACTIONS))
        if (response.data.success) {
            if (response.data.transactions) {
                let transactions = response.data.transactions
                if (action.data) {
                    // Only convert rates for 'Consolidated CAD' if the bank currency type is USD
                    if (action.data.currency === 'Consolidated CAD' &&
                        action.data.shippingSettings.yearRates &&
                        action.data.shippingSettings.yearRates.length > 0) {

                        const yearRates = action.data.shippingSettings.yearRates;

                        // Assuming transactions holds the unfiltered list.
                        const filteredTransactions = transactions
                          .filter(transaction => {
                              // If action.data.category is not null, filter by category
                              if (action.data.category && action.data.category.length > 0) {
                                  return action.data.category.includes(transaction.category);
                              }
                              // If action.data.category is null, include all transactions
                              return true;
                          })
                          .map(transaction => {
                              // Check if the transaction's bank currency is 'USD'
                              if (transaction.bankAccount.currency === 'USD') {
                                  // Extract the year from the transaction date
                                  const transactionYear = new Date(transaction.date).getFullYear();

                                  // Find matching year rate
                                  const matchingRate = yearRates.find(rate => rate.year === transactionYear.toString());

                                  if (matchingRate) {

                                      const updatedTaxInfos = transaction.taxInfos
                                        ? transaction.taxInfos.map(taxInfo => {
                                            return {
                                                ...taxInfo,
                                                taxAmount: (parseFloat(taxInfo.taxAmount) * parseFloat(matchingRate.rate)).toFixed(2),
                                            };
                                        })
                                        : null;


                                      const updatedAmount = (parseFloat(transaction.amount) * parseFloat(matchingRate.rate)).toFixed(2);

                                      return {
                                          ...transaction,
                                          taxInfos: updatedTaxInfos,
                                          amount: updatedAmount,
                                      };
                                  }
                              }

                              return transaction;
                          });

                        transactions = [...filteredTransactions];

                    } else if (action.data.currency) {

                        transactions = transactions.filter(trans => trans.bankAccount.currency === action.data.currency);
                    }

                }

                yield put(storeAllTransactions(transactions))
            }
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* listShippingSummarySaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => postRequest(SHIPPING_SUMMARY.LIST_SHIPPING_SUMMARY, action.data))
        if (response.data.success) {
            yield put(storeAllShippingSummary(response.data))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* listFTLSummarySaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => postRequest(SHIPPING_SUMMARY.LIST_SHIPPING_SUMMARY, action.data))
        if (response.data.success) {
            yield put(storeAllFTLSummary(response.data.summary))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* listStatementTaxesSaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => postRequest(STATEMENT_OF_TAXES.LIST_STATEMENT_TAXES, action.data))
        if (response.data.success) {
            yield put(storeAllStatementTaxesSummary(response.data.taxSummary))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* listAllChequesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.LIST_ALL_CHEQUES,action.data))
        if (response.data.success) {
            yield put(storeAllCheques(response.data.cheques))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listStatementTaxesTemplate() {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => getRequest(STATEMENT_OF_TAXES.LIST_STATEMENT_TAXES_TEMPLATE))
        if (response.data.success) {
            yield put(storeStatementTaxesTemplate(response.data.templates))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* listShippingSettings() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(SHIPPING_SUMMARY.LIST_SHIPPING_SETTINGS))
        if (response.data.success) {
            yield put(storeShippingSummarySettings(response.data.rates))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* findInvoiceByInvoiceNumberSaga(action){
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(TRANSACTIONS.GET_INVOICE_BY_INVOICE_NUMBER,action.invoice))
        if(response.data.success){
            if (response.data.invoice)
                yield put(storeFoundInvoice(response.data.invoice))
            else
                yield put(storeFoundInvoice(null))
        }
        else
            yield put(storeFoundInvoice(null))
    }catch (e) {
        yield put(showErrorMessage(FIND_INVOICE_BY_INVOICE_NUMBER_ERROR_MESSAGE))
    }
}

function* importChequeSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.IMPORT_CHEQUE, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Imported",
                success: true
            }
            yield put(storeUpdatedCheque(response.data.updatedCheques))
            yield put(requestStatus(status))
            action.callback(response)
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveChequeSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.SAVE_CHEQUE, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Saved Cheque",
                success: true
            }
            yield put(storeUpdatedCheque([response.data.updatedCheque]))
            yield put(requestStatus(status))
            action.callback(response)
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveStatementTaxesTemplate(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(STATEMENT_OF_TAXES.SAVE_STATEMENT_TAXES_TEMPLATE, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Template saved successfully",
                success: true
            }
            yield put(storeStatementTaxesTemplate(response.data.templates))
            yield put(requestStatus(status))
            action.callback(response)
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listPurchaseSummarySaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => postRequest(PURCHASE_SUMMARY.LIST_PURCHASE_SUMMARY, action.data))
        if (response.data.success) {
            yield put(storeAllPurchaseSummary(response.data))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* saveShippingSettings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SHIPPING_SUMMARY.SAVE_SHIPPING_SETTINGS, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Settings saved successfully",
                success: true
            }
            yield put(storeShippingSummarySettings(response.data.rates))
            yield put(requestStatus(status))
            action.callback(response)
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveTransactionSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.SAVE_TRANSACTION, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Saved Transaction",
                success: true
            }
            yield put(storeUpdatedTransactions(response.data.updatedTransactions))
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* assignCategoryToTransactionSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.ASSIGN_CATEGORY, {category:action.category, selectedEntries:action.selectedEntries}))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Assigned Category to Transaction(s)",
                success: true
            }
            for (let i = 0; i < response.data.updatedTransactions.length; i++) {
                yield put(storeUpdatedTransaction(response.data.updatedTransactions[i]))
            }
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* addTaxInfoSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.ADD_TAX_INFO, {taxInfo: action.taxInfo,
            selectedEntries: action.selectedEntries,}))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully Assigned tax info to Transaction(s)",
                success: true
            }
            for (let i = 0; i < response.data?.updatedTransactions?.length; i++) {
                yield put(storeUpdatedTransaction(response.data?.updatedTransactions[i]))
            }
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* deleteTransactionsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.DELETE_TRANSACTIONS, action.data))
        if (response.data.success) {
            action.callback()
            yield put(storeAllTransactions(response.data.allTransactions))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteChequesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.DELETE_CHEQUE, action.data))
        if (response.data.success) {
            action.callback()
            yield put(storeAllCheques(response.data.allCheques))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* importTransactionSaga(action) {
    yield put(hideError())
    yield put(setProcessing(true))
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.IMPORT_TRANSACTION, action.data))
        yield put(setProcessing(false))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: `Imported ${response.data.transactions.length} out of ${response.data.totalTransactions} Transactions`,
                success: true
            }
            for (let i = 0; i < response.data.transactions.length; i++) {
                yield put(storeUpdatedTransaction(response.data.transactions[i]))
            }
            action.callback()
            yield put(requestStatus(status))

            let successCount = 0
            for (let i = 0; i < response.data.transactions.length; i++) {
                let ruleResponse = yield call(() => (runTransactionRule(i, response.data.transactions)))
                if(ruleResponse.data.success) {
                    successCount++
                }
                let status = {
                    status: ruleResponse.status,
                    statusText: ruleResponse.data.success ? `Transaction rules run on ${successCount} out of ${response.data.transactions.length} Transactions`: ruleResponse.data.message,
                    success: ruleResponse.data.success
                }
                yield put(storeUpdatedTransaction(ruleResponse.data.transaction))
                yield put(requestStatus(status))
            }
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(setProcessing(false))
    }
}

function* runTransactionRule(index, transactions) {
    yield put(hideError())
    yield put(setProcessing(true))
    try {
        const transactionId = transactions[index].transactionId
        const response = yield call(() => postRequest(TRANSACTIONS.RUN_RULE_TO_TRANSACTION, {transactionId: transactionId}))
        yield put(setProcessing(false))
        return response

    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(setProcessing(false))
    }
}

function* getTransactionAttachmentSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.GET_TRANSACTION_ATTACHMENT, action.data))
        if (response.data.success) {
            yield put(storeTransactionAttachment(response.data.attachmentFile))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteTransactionAttachmentSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.DELETE_TRANSACTION_ATTACHMENT, action.data))
        if (response.data.success) {
            yield put(storeUpdatedTransaction(response.data.updatedTransaction))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getSupplierReferencesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.GET_SUPPLIER_REFERENCES, action.data))
        if (response.data.success) {
            yield put(storeSupplierReferences(response.data.references))
            yield put(storeUpdatedTaxInfo(response.data.updatedTaxInfo))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getTransactionTaxAmountSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.GET_TRANSACTION_TAX_AMOUNT, {taxInfo: action.data, transaction: action.transaction, index: action.index}))
        if (response.data.success) {

            yield put(storeUpdatedTaxInfo(response.data.updatedTaxInfo))

        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getTaxTypeWithStateProvinceSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRANSACTIONS.GET_TAXTYPE_WITH_STATE_PROVINCE))
        if (response.data.success) {
            yield put(storetaxTypeWithStateProvince(response.data.taxTypewithStateProvince))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listTransactionCategoriesSaga() {
    yield put(hideError())
    try {
        const response = yield call (() => getRequest(TRANSACTION_CATEGORY.LIST_TRANSACTION_CATEGORIES))
        if (response.data.success) {
            yield put(storeAllTransactionCategories(response.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveTransactionCategorySaga(action) {
    yield put(hideError())
    try {
        const response = yield call (() => postRequest(TRANSACTION_CATEGORY.SAVE_TRANSACTION_CATEGORY, action.data))
        if (response.data.success) {
            yield put(storeAllTransactionCategories(response.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveAndNavigateToChildTransaction(action) {
    yield put(hideError())
    try {
        const response = yield call (() => postRequest(TRANSACTIONS.SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION, {
            index: action.index,
            transactionEdited: action.transactionEdited
        }))
        if (response.data.success) {
            if (response.data.listOfCreatedTransactions)
                yield put(storeUpdatedTransactions(response.data.listOfCreatedTransactions))
            if (response.data.editingTransaction)
                yield put(storeEditingTransaction(response.data.editingTransaction))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        console.log(error)
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteTransactionCategorySaga(action) {
    yield put(hideError())
    try {
        const response = yield call (() => postRequest(TRANSACTION_CATEGORY.DELETE_TRANSACTION_CATEGORY, action.data))
        if (response.data.success) {
            yield put(storeAllTransactionCategories(response.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* validateChequeNumberSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CHEQUES.VALIDATE_CHEQUE,action.data))
        if (response.data.success) {
            action.callback(response)
        } else {
            action.callback(response)
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* ledgerEntriesSaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading())
        const response = yield call(() => postRequest(LEDGER.LEDGER_ENTRIES,action.data))
        if (response.data.success) {
            yield put(storeTransactionsLedger(response.data))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading())
    }
}

function* updateLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.UPDATE_LEDGER, action.data))
        if (response.data.success) {
            if (response.data.ledgers)
                action.callback(response.data.ledgers)
               // yield put(storeAllLedgers(response.data.ledgers))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteLedgerSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.DELETE_LEDGER, action.data))
        if (response.data.success) {
            if (response.data.ledgers)
                yield put(storeAllLedgers(response.data.ledgers))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateEntrySaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.UPDATE_ENTRY, action.data))
        if (response.data.success) {
            if(action.data.type === "carrier" || action.data.type === "supplier" || action.data.type === "ftl" || action.data.type === "stock" || action.data.type === "marketplace" || action.data.type === "customer" || action.data.type === "sales"){
                if (response.data)
                    yield put(storePredefinedEntry(response.data.ledgerEntries))
            }
            else{
                if (response.data.ledgerEntries)
                    yield put(storeLedgerEntry(response.data.ledgerEntries))
            }

        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteEntrySaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LEDGER.DELETE_ENTRY, action.data))
        if (response.data.success) {
            if(action.data.type === "carrier" || action.data.type === "supplier" || action.data.type === "ftl" || action.data.type === "customer" || action.data.type === "marketplace" || action.data.type === "sales"){
                yield put(storePredefinedEntry(response.data.ledgerEntries))
            }else{
                if (response.data.ledgerEntries)
                    yield put(storeLedgerEntry(response.data.ledgerEntries))
            }

        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getNoteSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(NOTE.GET_NOTE, action.data))
        if (response.data.success) {
            yield put(storeNote(response.data))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


function* saveNoteSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(NOTE.SAVE_NOTE, action.data))
        if (response.data.success) {

            let status = {
                status: response.status,
                statusText: response.data.message,
                success: true
            }
            yield put(storeNote(response.data))
            yield put(requestStatus(status))

        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listAllTrialBalanceSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRIAL_BALANCE.LIST_ALL_TRIAL_BALANCE,action.data))
        if (response.data.success) {
            if (response.data.trialBalance){
                yield put(storeTrialBalance(response.data.trialBalance))
            }
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveTrialBalanceSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRIAL_BALANCE.SAVE_TRIAL_BALANCE, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully saved",
                success: true
            }
            yield put(storeTrialBalance(response.data.trialBalance))
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchTrialBalanceSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TRIAL_BALANCE.FETCH_TRIAL_BALANCE,action.data))
        if (response.data.success) {
            if (response.data.trialBalance)
                yield put(storeTrialBalance(response.data.trialBalance))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listMarketplaceSummarySaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => postRequest(MARKETPLACE_SUMMARY.LIST_MARKETPLACE_SUMMARY, action.data))
        if (response.data.success) {
            yield put(storeMarketplaceSummary(response.data))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* importMarketplaceSummarySaga(action) {
    yield put(hideError())
    yield put(setProcessing(true))
    try {
        const response = yield call(() => postRequest(MARKETPLACE_SUMMARY.IMPORT_MARKETPLACE_SUMMARY, action.data))
        yield put(setProcessing(false))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: `Imported ${response.data.marketplaces?.summary.length} out of ${response.data.totalMarketplaces} entries`,
                success: true
            }
            yield put(storeMarketplaceSession(response.data.sessionId))
            marketplaceProgressSummarySaga({sessionId: response.data.sessionId})
            action.callback('success',response.data.sessionId)
            //yield put(requestStatus(status))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
            action.callback('error',response.data.message)
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        yield put(setProcessing(false))
    }
}




function* marketplaceProgressSummarySaga(action) {
    yield put(hideError());
    try {
        let progress = 0;

        // Start polling
        while (progress < 100) {
            const response = yield call(() => postRequest(MARKETPLACE_SUMMARY.MARKETPLACE_PROGRESS_SUMMARY, action.data));

            if (response.data.success) {
                progress = response.data.progress;  // Get the current progress from the API response
                action.callback('success',progress, response.data?.errorMessage, response.data?.successMessage)
                yield put(storeMarketplaceProgressSummary(progress));  // Dispatch the progress update to the store
            } else {
                const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE;
                yield put(showErrorMessage(message));
                break;  // Stop polling if there is an error
            }

            // Wait for 1 second before polling again
            if (progress < 100) {
                yield delay(1000);  // Poll again after 1 second
            }
        }

        // When progress reaches 100%, stop polling and hide the progress bar
        if (progress >= 100) {
            yield put(setProcessing(false));
            if (action.callback) {
                action.callback('success',progress)
            }
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* listAllIncomeStatementSaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => postRequest(INCOME_STATEMENT.LIST_ALL_INCOME_STATEMENT,action.data))
        if (response.data.success) {
            if (response.data.incomeStatement){
                yield put(storeIncomeStatement(response.data.incomeStatement))
            }
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* saveIncomeStatementSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(INCOME_STATEMENT.SAVE_INCOME_STATEMENT, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Successfully saved",
                success: true
            }
            yield put(storeIncomeStatement(response.data.incomeStatement))
            yield put(requestStatus(status))
            action.callback()
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchIncomeStatementSaga(action) {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => postRequest(INCOME_STATEMENT.FETCH_INCOME_STATEMENT,action.data))
        if (response.data.success) {
            if (response.data.incomeStatement)
                yield put(storeIncomeStatement(response.data.incomeStatement))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* saveIncomeStatementMappingTemplate(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(INCOME_STATEMENT.SAVE_INCOME_STATEMENT_TEMPLATE, action.data))
        if (response.data.success) {
            let status = {
                status: response.status,
                statusText: "Saved",
                success: true
            }
            yield put(storeIncomeStatementMappingTemplate(response.data.templates))
            yield put(requestStatus(status))
        } else {

            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listIncomeStatementMappingTemplate() {
    yield put(hideError())
    try {
        yield put(startLoading());
        const response = yield call(() => getRequest(INCOME_STATEMENT.LIST_INCOME_STATEMENT_TEMPLATE))
        if (response.data.success) {
            yield put(storeIncomeStatementMappingTemplate(response.data.templates))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(stopLoading());
    }
}

function* exportLedgersSaga(action) {
    yield put(hideError())
    try {
        yield put(clearExportLedgers());
        const response = yield call(() => postRequest(LEDGER.EXPORT_LEDGER,action.data))
        if (response.data.success) {
            if (response.data.exportLedgers)
                yield put(storeExportLedger(response.data.exportLedgers))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* accountingSagas() {
    yield takeLatest(LIST_ALL_TRANSACTIONS, listAllTransactionsSaga)
    yield takeLatest(LIST_ALL_LEDGERS, listAllLedgesSaga)
    yield takeLatest(FETCH_TRANSACTIONS_LEDGER, fetchTransactionsLedgerSaga)
    yield takeLatest(FETCH_LEDGER_BALANCE, fetchLedgerBalanceSaga)
    yield takeLatest(LIST_ALL_CHEQUES, listAllChequesSaga)
    yield takeLatest(VALIDATE_CHEQUE_NUMBER, validateChequeNumberSaga)
    yield takeLatest(LIST_SHIPPING_SETTINGS, listShippingSettings)
    yield takeLatest(LIST_STATEMENT_TAXES_TEMPLATE, listStatementTaxesTemplate)
    yield takeLatest(LIST_SHIPPING_SUMMARY, listShippingSummarySaga)
    yield takeLatest(LIST_FTL_SUMMARY, listFTLSummarySaga)
    yield takeLatest(SAVE_TRANSACTION, saveTransactionSaga)
    yield takeLatest(SAVE_LEDGER, saveLedgerSaga)
    yield takeLatest(SAVE_MARKETPLACE_LEDGER, saveMarketplaceLedgerSaga)
    yield takeLatest(SAVE_TRANSACTION_LEDGER, saveTransactionLedgerSaga)
    yield takeLatest(SAVE_CHEQUE, saveChequeSaga)
    yield takeLatest(IMPORT_CHEQUE, importChequeSaga)
    yield takeLatest(SAVE_SHIPPING_SETTINGS, saveShippingSettings)
    yield takeLatest(LIST_STATEMENT_TAXES, listStatementTaxesSaga)
    yield takeLatest(SAVE_STATEMENT_TAXES_TEMPLATE, saveStatementTaxesTemplate)
    yield takeLatest(DELETE_TRANSACTIONS, deleteTransactionsSaga)
    yield takeLatest(DELETE_CHEQUES, deleteChequesSaga)
    yield takeLatest(ASSIGN_CATEGORY, assignCategoryToTransactionSaga)
    yield takeLatest(ADD_TAX_INFO, addTaxInfoSaga)
    yield takeLatest(IMPORT_TRANSACTION, importTransactionSaga)
    yield takeLatest(GET_TRANSACTION_ATTACHMENT, getTransactionAttachmentSaga)
    yield takeLatest(DELETE_TRANSACTION_ATTACHMENT, deleteTransactionAttachmentSaga)
    yield takeLatest(GET_SUPPLIER_REFERENCES, getSupplierReferencesSaga)
    yield takeLatest(GET_TRANSACTION_TAX_AMOUNT, getTransactionTaxAmountSaga)
    yield takeLatest(GET_TAXTYPE_WITH_STATE_PROVINCE, getTaxTypeWithStateProvinceSaga)
    yield takeLatest(GET_INVOICE_BY_INVOICE_NUMBER,findInvoiceByInvoiceNumberSaga)
    yield takeLatest(LIST_TRANSACTION_CATEGORIES, listTransactionCategoriesSaga)
    yield takeLatest(SAVE_TRANSACTION_CATEGORY, saveTransactionCategorySaga)
    yield takeLatest(DELETE_TRANSACTION_CATEGORY, deleteTransactionCategorySaga)
    yield takeLatest(SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION, saveAndNavigateToChildTransaction)
    yield takeLatest(DELETE_LEDGER_BY_YEAR, deleteLedgerByYearSaga)
    yield takeLatest(DELETE_LEDGER_BY_YEAR_AND_CURRENCY, deleteLedgerByYearAndCurrencySaga)
    yield takeLatest(LEDGER_ENTRIES, ledgerEntriesSaga)
    yield takeLatest(UPDATE_LEDGER, updateLedgerSaga)
    yield takeLatest(DELETE_LEDGER, deleteLedgerSaga)
    yield takeLatest(UPDATE_ENTRY, updateEntrySaga)
    yield takeLatest(DELETE_ENTRY, deleteEntrySaga)
    yield takeLatest(LIST_PURCHASE_SUMMARY, listPurchaseSummarySaga)
    yield takeLatest(GET_NOTE, getNoteSaga)
    yield takeLatest(SAVE_NOTE, saveNoteSaga)
    //trialBalance
    yield takeLatest(LIST_ALL_TRIAL_BALANCE, listAllTrialBalanceSaga)
    yield takeLatest(SAVE_TRIAL_BALANCE, saveTrialBalanceSaga)
    yield takeLatest(FETCH_TRIAL_BALANCE, fetchTrialBalanceSaga)
    yield takeLatest(IMPORT_MARKETPLACE_SUMMARY, importMarketplaceSummarySaga)
    yield takeLatest(LIST_MARKETPLACE_SUMMARY, listMarketplaceSummarySaga)
    yield takeLatest(MARKETPLACE_PROGRESS_SUMMARY, marketplaceProgressSummarySaga)
    //income statement
    yield takeLatest(LIST_ALL_INCOME_STATEMENT, listAllIncomeStatementSaga)
    yield takeLatest(SAVE_INCOME_STATEMENT, saveIncomeStatementSaga)
    yield takeLatest(FETCH_INCOME_STATEMENT, fetchIncomeStatementSaga)
    yield takeLatest(SAVE_INCOME_STATEMENT_MAPPING_TEMPLATE, saveIncomeStatementMappingTemplate)
    yield takeLatest(LIST_INCOME_STATEMENT_MAPPING_TEMPLATE,listIncomeStatementMappingTemplate)
    //export
    yield takeLatest(EXPORT_LEDGERS, exportLedgersSaga)
}