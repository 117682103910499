import {
    UNSHIPPED,
    SHIPPED,
    CANCELLED, RETURN, CLONED
} from "../../../components/stages/constants";
import {
    CLEAR_RATES,
    FILTER_CANCELLED,
    FILTER_SHIPPED,
    FILTER_UNSHIPPED,
    LOADING_SHIPMENT_REQUEST,
    SET_RATE_LOADING,
    STORE_SHIPMENTS,
    STORE_RATES,
    TEST_PRINT,
    UPDATE_ORDERS,
    UPDATE_ORDERS_BY_LIST,
    UPDATE_PRINTED_COUNT,
    SET_IMPORT_STATUS,
    STORE_SHIPMENT_COMMERCIAL_INVOICE,
    FILTER_CLONED,
    FILTER_RETURNS,
    STORE_LINKED_SHIPMENT,
    STORE_SHIPMENT,
    STORE_BUY_SHIPPING_SERVICES,
    GET_SUGGESTED_BOX_TYPES,
    STORE_SUGGESTED_BOX_TYPES, STORE_MESSAGES, STORE_MESSAGES_FROM_SHIPMENTS, STORE_ALL_MESSAGES,
    SET_SHIPMENT_SUMMARY_ORDER,
    STORE_SAMPLE_COMMERCIAL_INVOICE_PDF,
    SET_SHIPMENT_SMART_SEARCH_ROWS,
    RESET_PDF_DATA,
    SET_SHIPMENTS_OPEN_EDIT_ROW,
    UPDATE_SHIPMENTS_OPEN_EDIT_ROW,
    REMOVE_SHIPMENT, STORE_MESSAGE, Mark_Orders_As_Unshipped, UPDATE_LATEST_SHIPPED_QUERY_DATE,
} from "../../constants/orders";
import {AMAZON, WALMART} from "../../../components/settings/ManageMarketplaces/constants";

const initialState = {
    curMessage:null,
    rows: [],
    printedCount: 0,
    unshipped: [],
    shipped: new Map(),
    latestShippedFromDate: "",
    latestShippedToDate: "",
    cancelled: [],
    returns: [],
    cloned: [],
    loadingShipmentRequest: false,
    rates: [],
    rateLoading: false,
    printedZpl: '',
    importOrderStatus: '',
    shipmentCommercialInvoice: new Uint8Array(0),
    linkedShipmentId: '',
    suggestedBoxTypes: [],
    messages: [],
    orderDirectionShipmentsSummary: 'desc',
    valueToOrderByShipmentsSummary: "printedQty",
    buyShippingServices: [],
    packageToListMap: {},
    commercialInvoicePDF: new Uint8Array(0),
    isSampleCommercialInvoice: false,
}

const updateListByShipments = (shipments, list, orderStatus) => {
    if (Array.isArray(list)) {
        // Handle array structure
        let newList = list;
        const shipmentIds = list.map(n => n.shipmentId);
        shipments.forEach(shipment => {
            if (shipmentIds.includes(shipment.shipmentId)) {
                newList = updateList(shipment, newList, orderStatus);
            }
        });
        return newList;
    } else {
        // Handle map structure
        const newMap = new Map(list); // Create a copy of the map to avoid mutating the original
        shipments.forEach(shipment => {
            const locationId = shipment.shipAddressId; // Assuming this is the key for the map
            if (newMap.has(locationId)) {
                let locationList = newMap.get(locationId);
                const shipmentIds = locationList.map(n => n.shipmentId);
                if (shipmentIds.includes(shipment.shipmentId)) {
                    // Update the shipment in the location's list
                    locationList = updateList(shipment, locationList, orderStatus);
                    newMap.set(locationId, locationList); // Update the map with the modified list
                }
            }
        });
        return newMap;
    }
    // Return the list/map as-is if it doesn't match the expected types
    return list;
};

const removeFromList = (shipmentIds, list) => {
    if (Array.isArray(list)) {
        return list.filter(curOrder => !shipmentIds.includes(curOrder?.shipmentId));
    }
    else {
        let newMap = new Map();
        list.forEach((subList, key) => {
            // Filter out the matching shipmentId
            let newList = subList.filter(curOrder => !shipmentIds.includes(curOrder?.shipmentId));
            newMap.set(key, newList);
        });
        return newMap;
    }
};

const updateList = (order, list, orderStatus) => {
    try {
        // if the data structure is a list
        if (Array.isArray(list)) {
            let newList = []
            let exists = list.findIndex(item => item.shipmentId === order.shipmentId)
            if (exists === -1) {
                return list;
            } else {
                list.forEach(item => {
                    if (item.shipmentId === order.shipmentId) {
                        if (orderStatus === "" || order.orderStatus === orderStatus) {
                            if (orderStatus === UNSHIPPED) {
                                attachModifiedShipByDate(order)
                            }
                            newList.push(order)
                        }
                    } else {
                        newList.push(item)
                    }
                })
                return newList
            }
        }
        // if the data structure is a hashmap, for shipped view specifically
        else {
            let newMap = new Map()
            let locationId = order.shipAddressId
            list.forEach((subList, key) => {
                if (key === locationId) {
                    newMap.set(key, updateList(order, subList, orderStatus))
                } else {
                    newMap.set(key, subList)
                }
            })
            return newMap
        }
    } catch (e) {
        console.log(e)
    }
}

const updateRates = (rate, rateList) => {
    let newList = [...rateList]
    if (newList.length === 2) {
        newList[1] = rate;
    }
    return newList;
}

const attachModifiedShipByDate = (shipment) => {
    let earliestShipDate = shipment.orders && shipment.orders[0]?.earliestShipDate
    let latestShipDate = shipment.orders && shipment.orders[0]?.latestShipDate
    let earliestDeliveryDate = shipment.orders && shipment.orders[0]?.earliestDeliveryDate
    let latestDeliveryDate = shipment.orders && shipment.orders[0]?.latestDeliveryDate
    let marketplace = shipment.marketplace
    if (!earliestShipDate || !latestShipDate || !earliestDeliveryDate || !latestDeliveryDate) {
        // make no change
    } else if (!marketplace) {
        shipment.modifiedEarliestShipDate = localDateConversion(earliestShipDate)
        shipment.modifiedLatestShipDate = localDateConversion(latestShipDate)
        shipment.modifiedEarliestDeliveryDate = localDateConversion(earliestDeliveryDate)
        shipment.modifiedLatestDeliveryDate = localDateConversion(latestDeliveryDate)
    } else {
        if (marketplace.includes(AMAZON) || marketplace.includes(WALMART)) {
            shipment.modifiedEarliestShipDate = pacificDateConversion(earliestShipDate)
            shipment.modifiedLatestShipDate = pacificDateConversion(latestShipDate)
            shipment.modifiedEarliestDeliveryDate = pacificDateConversion(earliestDeliveryDate)
            shipment.modifiedLatestDeliveryDate = pacificDateConversion(latestDeliveryDate)
        } else {
            shipment.modifiedEarliestShipDate = localDateConversion(earliestShipDate)
            shipment.modifiedLatestShipDate = localDateConversion(latestShipDate)
            shipment.modifiedEarliestDeliveryDate = localDateConversion(earliestDeliveryDate)
            shipment.modifiedLatestDeliveryDate = localDateConversion(latestDeliveryDate)
        }
    }
}

const pacificDateConversion = (date) => { // specifically to match dates with Amazon's displayed ship by and deliver by dates
    let MM_dd_yyyy = new Date(date).toLocaleString("en-US", {timeZone: "America/Los_Angeles"}).substr(0,10)
    let MM = MM_dd_yyyy.split("/")[0]
    if (MM.length === 1) {MM = "0" + MM}
    let dd = MM_dd_yyyy.split("/")[1]
    if (dd.length === 1) {dd = "0" + dd}
    let yyyy = MM_dd_yyyy.split("/")[2].substr(0,4)
    return yyyy + "-" + MM + "-" + dd
}

const localDateConversion = (date) => { // specifically to match dates with Amazon's displayed ship by and deliver by dates
    let MM_dd_yyyy = new Date(date).toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}).substr(0,10)
    let MM = MM_dd_yyyy.split("/")[0]
    if (MM.length === 1) {MM = "0" + MM}
    let dd = MM_dd_yyyy.split("/")[1]
    if (dd.length === 1) {dd = "0" + dd}
    let yyyy = MM_dd_yyyy.split("/")[2].substr(0,4)
    return yyyy + "-" + MM + "-" + dd
}

const attachModifiedDates = (shipments) => {
    if (shipments) {
        for (let i = 0; i < shipments.length; i++) {
            attachModifiedShipByDate(shipments[i])
        }
    }
    return shipments
}

const updateOrAddToList = (shipments, list, orderStatus) => {
    if (Array.isArray(list)) {
        // Handle array structure
        shipments.forEach(shipment => {
            if (list.findIndex(item => item.shipmentId === shipment.shipmentId) !== -1) {
                // Shipment exists, update shipment
                list = updateList(shipment, list, orderStatus);
            } else if (!orderStatus || orderStatus === shipment.orderStatus) {
                // Only add to the list if it is the correct order status or general list
                attachModifiedShipByDate(shipment);
                list.push(shipment);
            }
        });
        return list;
    } else {
        // Handle map structure
        shipments.forEach(shipment => {
            const locationId = shipment.shipAddressId; // Assuming this is the map key
            if (list.has(locationId)) {
                // Get the list for the current location
                let locationList = list.get(locationId);
                if (locationList.findIndex(item => item.shipmentId === shipment.shipmentId) !== -1) {
                    // Shipment exists, update it
                    locationList = updateList(shipment, locationList, orderStatus);
                } else if (!orderStatus || orderStatus === shipment.orderStatus) {
                    // Only add to the list if it is the correct order status or general list
                    attachModifiedShipByDate(shipment);
                    locationList.push(shipment);
                }
                // Update the map with the modified list
                list.set(locationId, locationList);
            } else {
                // No list exists for this location, create a new one
                if (!orderStatus || orderStatus === shipment.orderStatus) {
                    attachModifiedShipByDate(shipment);
                    list.set(locationId, [shipment]);
                }
            }
        });
        return list;
    }
    // Return the list/map as-is if it doesn't match the expected types
    return list;
}

const removeShipment = (state, action) => {
    let newState = {}
    newState["rows"] = state.rows?.filter(shipment => shipment.shipmentId !== action.shipmentId)
    switch (action.orderStatus) {
        case UNSHIPPED:
            newState["unshipped"] = state.unshipped?.filter(shipment => shipment.shipmentId !== action.shipmentId)
            break
        /* TODO: currently we don't support deleting shipped shipments, for future use, since we introduced storing shipped
                 data by location, state.shipped is changed to a map strucutre, so the following code for shipped has to change
        */
        // case SHIPPED:
        //     newState["shipped"] = state.shipped?.filter(shipment => shipment.shipmentId !== action.shipmentId)
        //     break
        case CANCELLED:
            newState["cancelled"] = state.cancelled?.filter(shipment => shipment.shipmentId !== action.shipmentId)
            break
        case RETURN:
            newState["returns"] = state.returns?.filter(shipment => shipment.shipmentId !== action.shipmentId)
            break
        case CLONED:
            newState["cloned"] = state.cloned?.filter(shipment => shipment.shipmentId !== action.shipmentId)
            break
    }
    if (state.shipmentsOpenEditRow?.shipmentId === action.shipmentId) {
        newState["shipmentsOpenEditRow"] = null
    }
    return {
        ...state,
        ...newState
    }
}

const updateShipmentsOpenEditRow = (shipmentsOpenEditRow, newRows) => {
    let currentId = shipmentsOpenEditRow?.shipmentId
    if (!currentId) {
        return null
    }
    for (let i = 0; i < newRows?.length; i++) {
        if (newRows[i].shipmentId === currentId) {
            attachModifiedShipByDate(newRows[i])
            return newRows[i]
        }
    }
    return shipmentsOpenEditRow
}

const updateShipmentsSmartSearchRows = (shipmentsSmartSearchRows, newRows) => {

    if (!shipmentsSmartSearchRows)
    {
        shipmentsSmartSearchRows=[]
    }

    let newRowIds = newRows ? newRows.map(row => row.shipmentId) : []
    let currentRows = [...shipmentsSmartSearchRows]
    let newSmartSearchRows = []

    for (let i = 0; i < currentRows.length; i++) {
        if (newRowIds.includes(currentRows[i].shipmentId)) {
            newSmartSearchRows.push(newRows.find(row => row.shipmentId === currentRows[i].shipmentId))
        } else {
            newSmartSearchRows.push(currentRows[i])
        }
    }
    return newSmartSearchRows
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_SHIPMENTS:
            return {
                ...state,
                rows: [...action.rows]
            }
        case UPDATE_LATEST_SHIPPED_QUERY_DATE:
            // if the date filter changed then previously queried data for all the locations is no longer respecting the filter, remove them
            if (action.fromDate?.toString() != state.latestShippedFromDate?.toString() || action.toDate?.toString() != state.latestShippedToDate?.toString()) {
                return {
                    ...state,
                    latestShippedFromDate: action.fromDate,
                    latestShippedToDate: action.toDate,
                    shipped: new Map()
                }
            }
        case UPDATE_PRINTED_COUNT:
            return {
                ...state,
                printedCount: action.clear ? 0 : state.printedCount + 1
            }
        case FILTER_UNSHIPPED:
            return  {
                ...state,
                unshipped: [...attachModifiedDates(action.unshipped)]
            }
        case FILTER_SHIPPED:
            // Update the `shipped` map with the new location's data
            state.shipped.set(
                action.locationId,
                attachModifiedDates(action.shipped)
            );
            return {
                ...state,
                shipped: new Map(state.shipped), // Create a new Map to maintain immutability
            };
        case FILTER_CANCELLED:
            return {
                ...state,
                cancelled: [...attachModifiedDates(action.cancelled)]
            }
        case FILTER_RETURNS:
            return {
                ...state,
                returns: [...attachModifiedDates(action.returns)]
            }
        case FILTER_CLONED:
            return {
                ...state,
                cloned: [...attachModifiedDates(action.cloned)]
            }
        case UPDATE_ORDERS_BY_LIST:
            return {
                ...state,
                rows: updateListByShipments(action.orders, state.rows, ""),
                unshipped: updateListByShipments(action.orders, state.unshipped, UNSHIPPED),
                shipped: updateListByShipments(action.orders, state.shipped, SHIPPED),
                cancelled: updateListByShipments(action.orders, state.cancelled, CANCELLED),
                returns: updateListByShipments(action.orders, state.returns, RETURN),
                cloned: updateListByShipments(action.orders, state.cloned, CLONED)
            }
        case UPDATE_ORDERS:
            return {
                ...state,
                rows: updateList(action.order, state.rows, ""),
                unshipped: updateList(action.order, state.unshipped, UNSHIPPED),
                shipped: updateList(action.order, state.shipped, SHIPPED),
                cancelled: updateList(action.order, state.cancelled, CANCELLED),
                returns: updateList(action.order, state.returns, RETURN),
                cloned: updateList(action.order, state.cloned, CLONED),
            }
        case Mark_Orders_As_Unshipped:
            return {
                ...state,
                rows: removeFromList(action.shipmentIds, state.rows),
                shipped: removeFromList(action.shipmentIds, state.shipped),
            }
        case LOADING_SHIPMENT_REQUEST:
            return {
                ...state,
                loadingShipmentRequest: action.loading
            }
        case STORE_RATES:
            return {
                ...state,
                rates: action.rates
            }
        case SET_RATE_LOADING:
            return {
                ...state,
                rateLoading: action.rateLoading
            }
        case CLEAR_RATES:
            return {
                ...state,
                rates: [],
                buyShippingServices: [],
                packageToListMap: {},
            }
        case TEST_PRINT:
            return {
                ...state,
                printedZpl: action.printedZpl
            }
        case SET_IMPORT_STATUS:
            return {
                ...state,
                importOrderStatus: action.status
            }
        case STORE_SHIPMENT_COMMERCIAL_INVOICE:
            return {
                ...state,
                commercialInvoicePDF: Uint8Array.from(action.bytes),
                isSampleCommercialInvoice: false
            }
        case STORE_SAMPLE_COMMERCIAL_INVOICE_PDF:
            return {
                ...state,
                commercialInvoicePDF: Uint8Array.from(action.bytes),
                isSampleCommercialInvoice: true

            }
        case STORE_LINKED_SHIPMENT:
            return {
                ...state,
                linkedShipmentId: action.shipmentId
            }
        case STORE_SHIPMENT:
            return {
                ...state,
                rows: updateOrAddToList(action.shipments, state.rows, ""),
                unshipped: updateOrAddToList(action.shipments, state.unshipped, UNSHIPPED),
                shipped: updateOrAddToList(action.shipments, state.shipped, SHIPPED),
                cancelled: updateOrAddToList(action.shipments, state.cancelled, CANCELLED),
                returns: updateOrAddToList(action.shipments, state.returns, RETURN),
                cloned: updateOrAddToList(action.shipments, state.cloned, CLONED),
            }
        case STORE_SUGGESTED_BOX_TYPES:
            return { ...state, suggestedBoxTypes: action.boxTypes }
        case STORE_MESSAGES:
            return {...state, messages: action.data}
        case STORE_MESSAGES_FROM_SHIPMENTS:
            return{...state, shipments: action.data}
        case STORE_BUY_SHIPPING_SERVICES:
            return {
                ...state,
                buyShippingServices: action.buyShippingServices,
                packageToListMap: action.packageToListMap
            }
        case STORE_ALL_MESSAGES:
            return{...state, allMessages: action.data}
        case STORE_MESSAGE:
            return{...state, curMessage: action.data}
        case SET_SHIPMENT_SUMMARY_ORDER:
            return {
                ...state,
                orderDirectionShipmentsSummary: action.direction,
                valueToOrderByShipmentsSummary: action.property,
            }
        case RESET_PDF_DATA:
            return {
                ...state,
                commercialInvoicePDF: new Uint8Array(0),
                isSampleCommercialInvoice: false
            }
        case SET_SHIPMENT_SMART_SEARCH_ROWS:
            return  {
                ...state,
                shipmentsSmartSearchRows: action.newRows
            }
        case SET_SHIPMENTS_OPEN_EDIT_ROW:
            return {
                ...state,
                shipmentsOpenEditRow: action.editRow
            }
        case UPDATE_SHIPMENTS_OPEN_EDIT_ROW:
            return {
                ...state,
                shipmentsOpenEditRow: updateShipmentsOpenEditRow(state.shipmentsOpenEditRow, action.newRows),
                shipmentsSmartSearchRows: updateShipmentsSmartSearchRows(state.shipmentsSmartSearchRows, action.newRows)
            }
        case REMOVE_SHIPMENT:
            return removeShipment(state, action)
        default:
            return state;
    }
}
