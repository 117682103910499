import React from 'react';
import {
  Box, FormControl,
  IconButton, InputLabel, MenuItem, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import './IncomeStatement.css';
import TopToolbar from '../../global/subcomponents/topToolbar/TopToolbar';
import { INCOME_STATEMENT, INCOME_STATEMENT_PAGES } from './constants';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import { TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import { PURCHASE_SUMMARY_FIELDS } from '../purchaseSummary/constants';
import { Upgrade } from '@mui/icons-material';
import { HourglassEmptyTwoTone } from '@material-ui/icons';

class IncomeStatementTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draggedRow: { rowIndex: null },
      sections: this.props.sections,
      hideFilterBox: false,
      editableRow: null,
      updatedName: '',
      dragDisabled: false,
    };
  }


  componentDidUpdate(prevProps) {
    // Check if sections prop has changed
    if (prevProps.sections !== this.props.sections) {
      this.setState({ sections: this.props.sections });
    }
  }

  handleNameChange = (event) => {
    this.setState({ updatedName: event.target.value });
  };

  handleEditClick = (rowIndex, name) => {
    this.setState({
      editableRow: { rowIndex },
      updatedName: name,
    });
  };

  handleSaveClick = () => {
    const { editableRow, updatedName, sections } = this.state;
    if (editableRow) {
      const updatedSections = [...sections];
      updatedSections[editableRow.rowIndex].name = updatedName;
      this.setState(
        { sections: updatedSections, editableRow: null, updatedName: '' },
        () => {
          this.props.saveIncomeStatement({
            incomeStatementId: this.props.incomeStatementId,
            rows: updatedSections,
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency,
          });
        }
      );
    }
  };

  handleExport = () => {
    const { sections } = this.props;
    if (!sections || sections.length === 0) {
      return;
    }

    // Generate CSV content
    const headers = 'name,amount';

    const excludeDebitNames = [
      'Operating Income',
      'Cost of Sales',
      'Operating Expenses',
      'Other Income',
      'Taxes',
      ''
    ];

    const rows = sections.map(row => {
      let name = row.name ?? ''
      if (name.includes(',')) {
        name = `"${name}"`
      }
      const amount = excludeDebitNames.includes(name) ? '' : row.debit ?? '';
      return `${name},${amount}`;
    });

    const csvContent = [headers, ...rows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Income Statement_${this.props.selectedYear}_${this.props.selectedCurrency}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  onDragStart = (rowIndex) => {
    const draggedRow = this.state.sections[rowIndex];
    this.draggedRow = { rowIndex, data: draggedRow };
  };

  onDrop = (event, targetRowIndex) => {
    const draggedRow = this.draggedRow;
    const { sections } = this.state;
    const { rowIndex: draggedRowIndex } = draggedRow;


    if (!draggedRow) return;


    const targetSection = this.state.sections;
    const targetRow = targetSection[targetRowIndex];

    const isTargetValid = targetRow && targetRow.type !== 'total';


    if (isTargetValid) {
      if (targetRow.shiftCredit === '0.00' && targetRow.shiftDebit === '0.00') {
        // Swap rows
        const updatedSections = [...sections];
        const draggedDataRow =
          updatedSections[draggedRowIndex];
        // Remove dragged row
        updatedSections.splice(draggedRowIndex, 1);
        // Insert dragged row at the target position
        updatedSections.splice(
          targetRowIndex,
          0,
          draggedDataRow
        );

        this.setState({ sections: updatedSections });
        return;
      } else {


        let updatedSection = [...this.state.sections];

        // Remove the dragged row from its original position
        const originalRowIndex = draggedRow.rowIndex;

        if (originalRowIndex !== -1) {
          updatedSection.splice(originalRowIndex, 1);
        }

        // Update the dragged row's shift values
        const draggedData = { ...draggedRow.data }; // Copy dragged row data

        draggedData.shiftDebit = draggedData.debit;
        draggedData.debit = '0.00';

        draggedData.shiftCredit = draggedData.credit;
        draggedData.credit = '0.00';

        targetRowIndex = targetRowIndex - 1;

        if (targetRowIndex === -1) {
          targetRowIndex = 1;
        }

        let targetTotalRow = null;
        for (let i = targetRowIndex; i < updatedSection.length; i++) {
          const row = updatedSection[i];
          if (row.type === 'total') {
            targetTotalRow = row;
            break; // Found the first 'total' row below
          }
        }

        if (targetTotalRow) {
          if (!targetTotalRow.totalIds.includes(draggedData.ledgerId)) {
            targetTotalRow.totalIds.push(draggedData.ledgerId);
          }

          const totalRowIndex = updatedSection.findIndex(
            (row) =>
              row.type === 'total' &&
              row.totalIds &&
              row.totalIds.includes(draggedRow.data.ledgerId)
          );

          if (totalRowIndex !== -1) {
            updatedSection.splice(totalRowIndex, 0, draggedData);
          }

          // Recalculate totals for all "Total" in the section
          updatedSection.forEach((row) => {
            if (row.type === 'total') {
              let totalShiftDebit = 0;
              let totalShiftCredit = 0;

              row.totalIds.forEach((id) => {
                const matchingRow = updatedSection.find(
                  (r) => r.ledgerId === id
                );
                if (matchingRow) {
                  totalShiftDebit += parseFloat(matchingRow.shiftDebit || 0);
                  totalShiftCredit += parseFloat(matchingRow.shiftCredit || 0);
                }
              });

              // Update total row values
              row.shiftDebit = '0.00';
              row.debit = totalShiftDebit.toFixed(2);
              row.shiftCredit = '0.00';
              row.credit = totalShiftCredit.toFixed(2);
            }
          });

          // Update the state

          this.setState({ sections: updatedSection });
          this.draggedRow = null;
        }
        return;
      }
    }

    // Make a deep copy of sections to update
    let updatedSection = [...this.state.sections];


    // Remove the dragged row from its original position
    const originalRowIndex = draggedRow.rowIndex;

    if (originalRowIndex !== -1) {
      updatedSection.splice(originalRowIndex, 1);
    }

    // Update the dragged row's shift values
    const draggedData = { ...draggedRow.data }; // Copy dragged row data
    draggedData.shiftDebit = draggedData.debit;
    draggedData.debit = '0.00';

    draggedData.shiftCredit = draggedData.credit;
    draggedData.credit = '0.00';

    // Ensure the dragged row's ledgerId is added to the correct total row's totalIds
    if (!targetRow.totalIds.includes(draggedData.ledgerId)) {
      targetRow.totalIds.push(draggedData.ledgerId);
    }

    const totalRowIndex = updatedSection.findIndex(
      (row) =>
        row.type === 'total' &&
        row.totalIds &&
        row.totalIds.includes(draggedRow.data.ledgerId)
    );

    if (totalRowIndex !== -1) {
      updatedSection.splice(totalRowIndex, 0, draggedData);
    }

    // Recalculate totals for all "Total" in the section
    updatedSection.forEach((row) => {
      if (row.type === 'total') {
        let totalShiftDebit = 0;
        let totalShiftCredit = 0;

        row.totalIds.forEach((id) => {
          const matchingRow = updatedSection.find(
            (r) => r.ledgerId === id
          );
          if (matchingRow) {
            totalShiftDebit += parseFloat(matchingRow.shiftDebit || 0);
            totalShiftCredit += parseFloat(matchingRow.shiftCredit || 0);
          }
        });

        // Update total row values
        row.shiftDebit = '0.00';
        row.debit = totalShiftDebit.toFixed(2);
        row.shiftCredit = '0.00';
        row.credit = totalShiftCredit.toFixed(2);
      }
    });


    this.setState({ sections: updatedSection });
    this.draggedRow = null;
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  handleShift = ( rowIndex, direction, type) => {
    const updatedSections = [...this.state.sections];
    const section = updatedSections;
    const row = section[rowIndex];

    if (type === 'debit') {
      if (direction === 'shift') {
        // Shift left: Add the current row's debit to shiftDebit and reset debit
        if (row.debit !== '0.00') {
          row.shiftDebit = (Number(row.shiftDebit) + Number(row.debit))
            .toFixed(2)
            .toString();
          row.debit = '0.00';
          row.type = 'manual';


          const totalRow = {
            totalIds: [row.ledgerId],
            name: 'Total',
            shiftDebit: '0.00',
            debit: row.shiftDebit,
            shiftCredit: '0.00',
            credit: row.shiftCredit,
            type: 'total',
          };

          updatedSections.splice(rowIndex + 1, 0, totalRow); // Insert totalRow below the current row
        }
      } else if (direction === 'back') {
        // Shift right: Move shiftDebit back to debit
        if (row.shiftDebit !== '0.00') {
          row.debit = (Number(row.debit) + Number(row.shiftDebit))
            .toFixed(2)
            .toString();
          row.shiftDebit = '0.00';
          row.type = '';

          // Find the relevant group where the totalRow exists
          let startIndex = 0;
          let endIndex = section.length;

          for (let i = rowIndex - 1; i >= 0; i--) {
            if (Array.isArray(section[i].totalIds)) {
              startIndex = i + 1;
              break;
            }
          }
          for (let i = rowIndex + 1; i < section.length; i++) {
            if (Array.isArray(section[i].totalIds)) {
              endIndex = i;
              break;
            }
          }

          // Remove the total row if its debit is zero
          const relevantTotal = section[endIndex];
          if (relevantTotal && Array.isArray(relevantTotal.totalIds)) {
            const index = relevantTotal.totalIds.indexOf(row.ledgerId);
            if (index > -1) {
              relevantTotal.totalIds.splice(index, 1); // Removes the element at the found index
            }

            // Update the debit for the relevant total row
            relevantTotal.debit = (
              Number(relevantTotal.debit) - Number(row.debit)
            )
              .toFixed(2)
              .toString();

            // Remove the Total row if debit is zero
            if (relevantTotal.debit === '0.00') {
              section.splice(endIndex, 1); // Remove the total row if the debit is zero
            }
          }

          // Find the position of the Total row
          let totalRowIndex = -1;
          for (let i = rowIndex + 1; i < section.length; i++) {
            if (Array.isArray(section[i].totalIds)) {
              totalRowIndex = i;
              break;
            }
          }

          // If we found the Total row, insert the row right below it
          if (totalRowIndex !== -1) {
            section.splice(rowIndex, 1);
            section.splice(totalRowIndex, 0, row); // Insert after the Total row
          }
        }
      }
    } else if (type === 'credit') {
      if (direction === 'shift') {
        // Shift left: Add the current row's credit to shiftCredit and reset credit
        if (row.credit !== '0.00') {
          row.shiftCredit = (Number(row.shiftCredit) + Number(row.credit))
            .toFixed(2)
            .toString();
          row.credit = '0.00';
          row.type = 'manual';


          const totalRow = {
            totalIds: [row.ledgerId],
            name: 'Total',
            shiftDebit: '0.00',
            debit: row.shiftDebit,
            shiftCredit: '0.00',
            credit: row.shiftCredit,
            type: 'total',
          };

          updatedSections.splice(rowIndex + 1, 0, totalRow); // Insert totalRow below the current row
        }
      } else if (direction === 'back') {
        if (row.shiftCredit !== '0.00') {
          row.credit = (Number(row.credit) + Number(row.shiftCredit))
            .toFixed(2)
            .toString();
          row.shiftCredit = '0.00';
          row.type = '';

          // Find the relevant group where the totalRow exists
          let startIndex = 0;
          let endIndex = section.length;

          for (let i = rowIndex - 1; i >= 0; i--) {
            if (Array.isArray(section[i].totalIds)) {
              startIndex = i + 1;
              break;
            }
          }
          for (let i = rowIndex + 1; i < section.length; i++) {
            if (Array.isArray(section[i].totalIds)) {
              endIndex = i;
              break;
            }
          }

          // Remove the total row if its credit is zero
          const relevantTotal = section[endIndex];
          if (relevantTotal && Array.isArray(relevantTotal.totalIds)) {
            const index = relevantTotal.totalIds.indexOf(row.ledgerId);
            if (index > -1) {
              relevantTotal.totalIds.splice(index, 1); // Removes the element at the found index
            }

            // Update the credit for the relevant total row
            relevantTotal.credit = (
              Number(relevantTotal.credit) - Number(row.credit)
            )
              .toFixed(2)
              .toString();

            // Remove the Total row if the credit is zero
            if (relevantTotal.credit === '0.00') {
              section.splice(endIndex, 1); // Remove the total row if the credit is zero
            }
          }

          // Find the position of the Total row
          let totalRowIndex = -1;
          for (let i = rowIndex + 1; i < section.length; i++) {
            if (Array.isArray(section[i].totalIds)) {
              totalRowIndex = i;
              break;
            }
          }

          // If we found the Total row, insert the row right below it
          if (totalRowIndex !== -1) {
            section.splice(rowIndex, 1);
            section.splice(totalRowIndex, 0, row); // Insert after the Total row
          }
        }
      }
    }

    // Update the state with the modified rows
    this.setState({ sections: updatedSections });
  };

  formatBalance = (balance) => {
    return parseFloat(balance)
      .toFixed(2)
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  };

  setHideFilterBox = (isHide) => {
    this.setState({ hideFilterBox: isHide });
  };

  getContextualMenuItems = () => {
    const { incomeStatementId, saveIncomeStatement, fetchIncomeStatement } =
      this.props;
    const { sections } = this.state;

    // Check if the selected currency is "Consolidated CAD"
    if (this.props.selectedCurrency === 'Consolidated CAD') {

      const menuItems = [
        {
          title: 'Export',
          icon: <Upgrade />,
          onClick: () => {
            this.handleExport();
          }
        }
      ];

      if (this.props.loadingData) {
        menuItems.push({
          icon: <HourglassEmptyTwoTone />,
          handler: () => null,
          disabled: true,
          title: "Loading data..."
        });
      }

      return menuItems;
    }

    return [

      {
        title: 'Settings',
        icon: <SettingsIcon />,
        onClick: () => {
          this.props.setCurrentPage(INCOME_STATEMENT_PAGES.SETTINGS)
        }
      },

      this.props.isEdit
        ? {
            title: 'Cancel Editing',
            icon: <CloseIcon />,
            onClick: () => {
              this.setHideFilterBox(false);
              this.props.setIsEdit(false);
            },
          }
        : {
            title: 'Edit',
            icon: <EditIcon />,
            onClick: () => {
              this.props.setIsEdit(true);
            },
          },
      {
        title: 'Save',
        icon: <SaveIcon />,
        onClick: () => {
          saveIncomeStatement({
            incomeStatementId,
            rows: sections,
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency,
          });

          this.props.setIsEdit(false);
        },
      },
      {
        title: 'Refresh',
        icon: <RefreshIcon />,
        onClick: () => {
          fetchIncomeStatement({
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency,
          });
        },
      },
      {
        title: 'Export',
        icon: <Upgrade />,
        onClick: () => {
          this.handleExport()
        }
      },
      this.props.loadingData &&
      {
        icon: <HourglassEmptyTwoTone />,
        handler: () => null,
        disabled: true,
        title: "Loading data..."
      }

    ];
  };

  render() {
    const { sections, editableRow, updatedName } = this.state;

    return (
      <React.Fragment>
        <TopToolbar
          pageName={INCOME_STATEMENT}
          menuItems={this.getContextualMenuItems()}
        />

        <Box
          className="box-trialBalance"
          style={{
            visibility: this.state.hideFilterBox ? 'hidden' : 'visible',
          }}
        >
          <FormControl variant="outlined" className="form-control-ledger">
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              className="select-year-ledger"
              labelId="year-label"
              value={this.props.selectedYear}
              onChange={this.props.handleYearChange}
              label="Year"
            >
              {this.props.years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className="form-control">
            <InputLabel id="currency-label">Currency</InputLabel>
            <Select
              className="select-currency-ledger"
              labelId="currency-label"
              value={this.props.selectedCurrency}
              onChange={this.props.handleCurrencyChange}
              label="Currency"
            >
              {this.props.currency.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TableContainer className="MuiTable-root-trail">
          <Table>
            <TableHead className="MuiTableHead-root-trail" style={{ visibility: 'hidden',border:'none' }}>
              <TableRow>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'left', width: '60px' }}
                ></TableCell>
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'left' }}
                >
                  Name
                </TableCell>
                {/*<TableCell*/}
                {/*  className="MuiTableCell-root-trail"*/}
                {/*  style={{ textAlign: 'center' }}*/}
                {/*></TableCell>*/}
                <TableCell
                  className="MuiTableCell-root-trail"
                  style={{ textAlign: 'center' }}
                >
                  Amount
                </TableCell>
                {/*<TableCell*/}
                {/*  className="MuiTableCell-root-trail"*/}
                {/*  style={{ textAlign: 'center' }}*/}
                {/*></TableCell>*/}

                {/*<TableCell*/}
                {/*  className="MuiTableCell-root-trail"*/}
                {/*  style={{ textAlign: 'center' }}*/}
                {/*>*/}
                {/*  Credit*/}
                {/*</TableCell>*/}
                {/*<TableCell*/}
                {/*  className="MuiTableCell-root-trail"*/}
                {/*  style={{ textAlign: 'center' }}*/}
                {/*></TableCell>*/}
              </TableRow>
            </TableHead>

            <TableBody className="MuiTableBody-root-trail">
              {this.state.sections?.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      style={{
                        backgroundColor: (row.name === 'Operating Income' || row.name === 'Cost of Sales' ||  row.name === 'Taxes' ||
                          row.name === 'Operating Expenses' || row.name === 'Other Income')
                          ? 'rgb(245, 245, 245)' : 'transparent',

                        cursor:
                          row?.ledgerId != null &&
                          row.shiftDebit === '0.00' &&
                          row.shiftCredit === '0.00' &&
                          row.name !== 'Net Income' &&
                          row.name !== 'Cost of Sales' &&
                          row.name !== 'Other Income' &&
                          row.name !== 'Taxes' &&
                          row.name !== 'Net Operating Income' &&
                          row.name !== 'Operating Income' &&
                          row.name !== 'Operating Expenses' &&
                          row.name !== 'Gross Profit' &&
                          row.blankLine !== 'header' &&
                          !row.name.includes('Total') &&
                          this.props.isEdit
                            ? 'grab'
                            : 'default',
                      }}
                      draggable={
                        row?.ledgerId != null &&
                        row.shiftDebit === '0.00' &&
                        row.shiftCredit === '0.00' &&
                        row.name !== 'Net Income' &&
                        row.blankLine !== 'header' &&
                        row.name !== 'Cost of Sales' &&
                        row.name !== 'Other Income' &&
                        row.name !== 'Taxes' &&
                        row.name !== 'Net Operating Income' &&
                        row.name !== 'Operating Income' &&
                        row.name !== 'Operating Expenses' &&
                        row.name !== 'Gross Profit' &&
                        !row.name.includes('Total') &&
                        this.props.isEdit
                      }
                      onDragStart={(event) =>
                        this.onDragStart(rowIndex)
                      }
                      onDragOver={this.onDragOver}
                      onDrop={(event) =>
                        this.onDrop(event, rowIndex)
                      }
                    >

                      {row?.ledgerId != null &&
                      row.shiftDebit === '0.00' &&
                      row.shiftCredit === '0.00' &&
                      row.name !== 'Net Income' &&
                      row.name !== 'Cost of Sales' &&
                      row.name !== 'Other Income' &&
                      row.name !== 'Taxes' &&
                      row.name !== 'Net Operating Income' &&
                      row.name !== 'Operating Income' &&
                      row.name !== 'Operating Expenses' &&
                      row.blankLine !== 'header' &&
                      row.name !== 'Gross Profit' &&
                      !row.name.includes('Total') &&
                      this.props.isEdit  ? (
                        <TableCell className="MuiTableCell-root-trail">
                          <MenuIcon style={{ cursor: 'grab', marginRight: 8 }} />
                        </TableCell>
                      ) : (
                        <TableCell className="MuiTableCell-root-trail">
                          {row?.ledgerId == null && this.props.isEdit ? (
                            <Tooltip title={'Edit Entry'}>
                              <IconButton
                                onClick={() => {
                                  this.handleEditClick(rowIndex, row.name);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <DragHandleIcon
                              style={{
                                cursor: 'grab',
                                marginRight: 8,
                                visibility: 'hidden',
                              }}
                            />
                          )}
                        </TableCell>
                      )}


                      <TableCell
                        className="MuiTableCell-root-trail"
                        style={{
                          // height: '75px',
                          fontWeight:
                            (Array.isArray(row?.totalIds) && row.totalIds.length > 0) ||
                            row?.name === 'Net Income' ||  row?.name === 'Net Operating Income' || row?.name === 'Operating Expenses' ||
                            row?.name === 'Other Income' ||   row?.name === 'Taxes' ||  row?.name === 'Operating Income' ||  row?.name === 'Cost of Sales' ||
                            row?.name.includes('Total') || row?.name === 'Gross Profit'
                              ? 'bold'
                              : 'normal',
                        }}
                      >
                        {editableRow &&
                        editableRow.rowIndex === rowIndex ? (
                          <TextField
                            variant="outlined"
                            name="name"
                            label="Name"
                            value={updatedName}
                            onChange={this.handleNameChange}
                            style={{ width: '100%' }}
                            error={
                              updatedName === '' ||
                              updatedName === null ||
                              updatedName === undefined
                            }
                          />
                        ) : (
                          row.name
                        )}
                      </TableCell>

                      {/*<TableCell*/}
                      {/*  style={{*/}
                      {/*    fontWeight:*/}
                      {/*      (Array.isArray(row?.totalIds) && row.totalIds.length > 0) ||*/}
                      {/*      row?.name === 'Net Income' ||  row?.name === 'Net Operating Income'*/}
                      {/*        ? 'bold'*/}
                      {/*        : 'normal',*/}
                      {/*    textAlign: 'right',*/}
                      {/*    paddingRight: '50px',*/}
                      {/*    width: '15%',*/}
                      {/*  }}*/}
                      {/*  className="MuiTableCell-root-trail"*/}
                      {/*>*/}
                      {/*  {row.debit !== '0.00' &&*/}
                      {/*  !Array.isArray(row?.totalIds) &&*/}
                      {/*  row.name !== 'Net Income' &&*/}
                      {/*  row.name !== 'Net Operating Income' &&*/}
                      {/*  this.props.isEdit ? (*/}
                      {/*    <div style={{ height: '40px', marginRight: '40px' }}>*/}
                      {/*      <IconButton*/}
                      {/*        onClick={() =>*/}
                      {/*          this.handleShift(*/}
                      {/*            rowIndex,*/}
                      {/*            'shift',*/}
                      {/*            'debit'*/}
                      {/*          )*/}
                      {/*        }*/}
                      {/*      >*/}
                      {/*        <ArrowBackIcon />*/}
                      {/*      </IconButton>*/}
                      {/*    </div>*/}
                      {/*  ) : (*/}
                      {/*    <div style={{ height: '0px' }}>*/}
                      {/*      <IconButton*/}
                      {/*        style={{ visibility: 'hidden' }}*/}
                      {/*        onClick={null}*/}
                      {/*      >*/}
                      {/*        <ArrowBackIcon />*/}
                      {/*      </IconButton>*/}
                      {/*    </div>*/}
                      {/*  )}*/}

                      {/*  {row.shiftDebit !== '0.00' ? (*/}
                      {/*    <div*/}
                      {/*      style={{*/}
                      {/*        display: 'flex',*/}
                      {/*        justifyContent: 'flex-start',*/}
                      {/*        alignItems: 'center',*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <span style={{ flexShrink: 0 }}>$</span>*/}
                      {/*      <span*/}
                      {/*        style={{*/}
                      {/*          marginLeft: '55px',*/}
                      {/*          minWidth: '80px',*/}
                      {/*          textAlign: 'right',*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        {this.formatBalance(row.shiftDebit)}*/}
                      {/*      </span>*/}
                      {/*    </div>*/}
                      {/*  ) : (*/}
                      {/*    ''*/}
                      {/*  )}*/}
                      {/*</TableCell>*/}

                      <TableCell
                        style={{
                          fontWeight:
                            (Array.isArray(row?.totalIds) && row.totalIds.length > 0) ||
                            row?.name === 'Net Income' ||  row?.name === 'Net Operating Income' || row?.name === 'Operating Expenses' ||
                            row?.name === 'Other Income' ||   row?.name === 'Taxes' ||  row?.name === 'Operating Income' ||  row?.name === 'Cost of Sales' ||
                            row?.name.includes('Total') || row?.name === 'Gross Profit'
                              ? 'bold'
                              : 'normal',
                          textAlign: 'right',
                          paddingRight: '50px',
                          width: '15%',
                        }}
                        className="MuiTableCell-root-trail"
                      >


                        {row.name === 'Net Operating Income' || row.debit !== '0.00' ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ flexShrink: 0 }}>$</span>
                            <span
                              style={{
                                marginLeft: '55px',
                                minWidth: '80px',
                                textAlign: 'right',
                              }}
                            >
                              {this.formatBalance(row.debit)}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}

                        {/*{row.debit === '0.00' ? (*/}
                        {/*  ''*/}
                        {/*) : (*/}
                        {/*  <div*/}
                        {/*    style={{*/}
                        {/*      display: 'flex',*/}
                        {/*      justifyContent: 'flex-start',*/}
                        {/*      alignItems: 'center',*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    <span style={{ flexShrink: 0 }}>$</span>*/}
                        {/*    <span*/}
                        {/*      style={{*/}
                        {/*        marginLeft: '55px',*/}
                        {/*        minWidth: '80px',*/}
                        {/*        textAlign: 'right',*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      {this.formatBalance(row.debit)}*/}
                        {/*    </span>*/}
                        {/*  </div>*/}
                        {/*)}*/}

                        {/*{!Array.isArray(row?.totalIds) &&*/}
                        {/*row.shiftDebit !== '0.00' &&*/}
                        {/*row.name !== 'Net Income' &&*/}
                        {/*row.name !== 'Net Operating Income' &&*/}
                        {/*this.props.isEdit ? (*/}
                        {/*  <div style={{ marginRight: '40px' }}>*/}
                        {/*    <IconButton*/}
                        {/*      onClick={() =>*/}
                        {/*        this.handleShift(*/}
                        {/*          rowIndex,*/}
                        {/*          'back',*/}
                        {/*          'debit'*/}
                        {/*        )*/}
                        {/*      }*/}
                        {/*    >*/}
                        {/*      <ArrowForwardIcon />*/}
                        {/*    </IconButton>*/}
                        {/*  </div>*/}
                        {/*) : (*/}
                        {/*  <div>*/}
                        {/*    <IconButton></IconButton>*/}
                        {/*  </div>*/}
                        {/*)}*/}
                      </TableCell>

                      {/*<TableCell*/}
                      {/*  style={{*/}
                      {/*    fontWeight:*/}
                      {/*      (Array.isArray(row?.totalIds) && row.totalIds.length > 0) ||*/}
                      {/*      row?.name === 'Net Income' ||  row?.name === 'Net Operating Income'*/}
                      {/*        ? 'bold'*/}
                      {/*        : 'normal',*/}
                      {/*    textAlign: 'right',*/}
                      {/*    paddingRight: '50px',*/}
                      {/*    width: '15%',*/}
                      {/*  }}*/}
                      {/*  className="MuiTableCell-root-trail"*/}
                      {/*>*/}
                      {/*  {row.credit !== '0.00' &&*/}
                      {/*  !Array.isArray(row?.totalIds) &&*/}
                      {/*  this.props.isEdit ? (*/}
                      {/*    <div style={{ height: '40px', marginRight: '40px' }}>*/}
                      {/*      <IconButton*/}
                      {/*        onClick={() =>*/}
                      {/*          this.handleShift(*/}
                      {/*            rowIndex,*/}
                      {/*            'shift',*/}
                      {/*            'credit'*/}
                      {/*          )*/}
                      {/*        }*/}
                      {/*      >*/}
                      {/*        <ArrowBackIcon />*/}
                      {/*      </IconButton>*/}
                      {/*    </div>*/}
                      {/*  ) : (*/}
                      {/*    <div style={{ height: '0px' }}>*/}
                      {/*      <IconButton></IconButton>*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*  {row.shiftCredit === '0.00' ? (*/}
                      {/*    ''*/}
                      {/*  ) : (*/}
                      {/*    <div*/}
                      {/*      style={{*/}
                      {/*        display: 'flex',*/}
                      {/*        justifyContent: 'flex-start',*/}
                      {/*        alignItems: 'center',*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <span style={{ flexShrink: 0 }}>$</span>*/}
                      {/*      <span*/}
                      {/*        style={{*/}
                      {/*          marginLeft: '55px',*/}
                      {/*          minWidth: '80px',*/}
                      {/*          textAlign: 'right',*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        {this.formatBalance(row.shiftCredit)}*/}
                      {/*      </span>*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*</TableCell>*/}

                      {/*<TableCell*/}
                      {/*  style={{*/}
                      {/*    fontWeight:*/}
                      {/*      (Array.isArray(row?.totalIds) && row.totalIds.length > 0) ||*/}
                      {/*      row?.name === 'Net Income' ||  row?.name === 'Net Operating Income'*/}
                      {/*        ? 'bold'*/}
                      {/*        : 'normal',*/}
                      {/*    textAlign: 'right',*/}
                      {/*    paddingRight: '50px',*/}
                      {/*    width: '15%',*/}
                      {/*  }}*/}
                      {/*  className="MuiTableCell-root-trail"*/}
                      {/*>*/}
                      {/*  {row.credit === '0.00' ? (*/}
                      {/*    ''*/}
                      {/*  ) : (*/}
                      {/*    <div*/}
                      {/*      style={{*/}
                      {/*        display: 'flex',*/}
                      {/*        justifyContent: 'flex-start',*/}
                      {/*        alignItems: 'center',*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <span style={{ flexShrink: 0 }}>$</span>*/}
                      {/*      <span*/}
                      {/*        style={{*/}
                      {/*          marginLeft: '55px',*/}
                      {/*          minWidth: '80px',*/}
                      {/*          textAlign: 'right',*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        {this.formatBalance(row.credit)}*/}
                      {/*      </span>*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*  {!Array.isArray(row?.totalIds) &&*/}
                      {/*  row.shiftCredit !== '0.00' &&*/}
                      {/*  this.props.isEdit ? (*/}
                      {/*    <div style={{ height: '40px', marginRight: '40px' }}>*/}
                      {/*      <IconButton*/}
                      {/*        onClick={() =>*/}
                      {/*          this.handleShift(*/}
                      {/*            rowIndex,*/}
                      {/*            'back',*/}
                      {/*            'credit'*/}
                      {/*          )*/}
                      {/*        }*/}
                      {/*      >*/}
                      {/*        <ArrowForwardIcon />*/}
                      {/*      </IconButton>*/}
                      {/*    </div>*/}
                      {/*  ) : (*/}
                      {/*    <div style={{ height: '0px' }}>*/}
                      {/*      <IconButton></IconButton>*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*</TableCell>*/}

                      {/*<TableCell>*/}
                      {/*  {editableRow &&*/}
                      {/*    editableRow.rowIndex === rowIndex && (*/}
                      {/*      <>*/}
                      {/*        <Tooltip*/}
                      {/*          title="Save Entry"*/}
                      {/*          style={{ marginLeft: '-8px' }}*/}
                      {/*        >*/}
                      {/*          <IconButton onClick={this.handleSaveClick}>*/}
                      {/*            <CheckCircleIcon*/}
                      {/*              fontSize="small"*/}
                      {/*              color="secondary"*/}
                      {/*            />*/}
                      {/*          </IconButton>*/}
                      {/*        </Tooltip>*/}
                      {/*      </>*/}
                      {/*    )}*/}
                      {/*</TableCell>*/}
                    </TableRow>


              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }
}

export default IncomeStatementTable;
