import React from 'react';

// material UI
import {
    Paper,
    Toolbar,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    RadioGroup,
    Radio,
} from "@material-ui/core";

// constants
import {
    MAP_ALL_LABEL,
    MAP_SKU_LABEL,
    MAP_ALL,
    MAP_SKU,
    MAP_SKU_SELECT_OPTIONS,
    RULE_CATEGORY_LISTINGS,
    RULE_CATEGORY_PRICING,
    RULE_CATEGORY_TRANSACTIONS,
    MAP_ALL_LABEL_LISTINGS,
    MAP_SKU_LABEL_LISTINGS,
} from "./constants";

// styling
import './AutomationRulesStepper.css';
import './AutomationRulesMapping.css';
import PropTypes from "prop-types";

class AutomationRulesMapping extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mapTo: '',
            skus: [],
            conditions: [],
            selectedSKUs: [],
            selectAll: true,
            applyToAllShipments: this.props.isEdit ? this.props.applyToAllShipments : true,
            applyToAllListings: this.props.isEdit ? this.props.applyToAllListings : true,
            applyToAllPricing: this.props.isEdit ? this.props.applyToAllPricing : true,
            autoApply: this.props.isEdit ? this.props.autoApply : false
        }
    }

    componentDidMount() {
        this.setState({
            mapTo: this.props.ruleCategory === RULE_CATEGORY_LISTINGS
                ? (this.props.applyToAllListings ? MAP_ALL : MAP_SKU)
                : this.props.ruleCategory === RULE_CATEGORY_PRICING
                    ? (this.props.applyToAllPricing ? MAP_ALL : MAP_SKU)
                    : (this.props.applyToAllShipments ? MAP_ALL : MAP_SKU),

            conditions: [...this.props.conditions],
        })
        let skusToAdd = new Set();
        let selectedSKUs = new Set();
        this.props.conditions.map(condition => {
            if (condition.filters) {
                condition.filters.map(filter => {
                    if (filter.name === 'Order item sku' || filter.name == 'SKU') {
                        skusToAdd.add(filter.value2)
                        selectedSKUs.add(filter.value2)
                    }
                })
            }
        })
        let propSkus = [...this.props.skus].filter(sku => skusToAdd.has(sku))

        const mappings = {
            skus: this.props.isEdit ? propSkus : [...selectedSKUs],
            applyToAllShipments: this.state.applyToAllShipments,
            applyToAllListings: this.state.applyToAllListings,
            applyToAllPricing: this.state.applyToAllPricing,
            autoApply: this.state.autoApply
        }

        this.setState({
            skus: [...skusToAdd],
            selectedSKUs: this.props.isEdit ? propSkus : [...selectedSKUs],
            applyToAllShipments: this.state.skus.length === 0,
            applyToAllListings: this.state.skus.length === 0,
            applyToAllPricing: this.state.skus.length === 0,
            mapTo:this.props.ruleCategory === RULE_CATEGORY_LISTINGS
                ? (this.props.applyToAllListings ? MAP_ALL : MAP_SKU)
                : this.props.ruleCategory === RULE_CATEGORY_PRICING
                    ? (this.props.applyToAllPricing ? MAP_ALL : MAP_SKU)
                    : (this.props.applyToAllShipments ? MAP_ALL : MAP_SKU),
            autoApply: this.props.autoApply
        }, () => {
            this.props.updateMappingWithParent(mappings)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.skus.length === 0 && this.state.mapTo !== MAP_ALL) {
            this.setState({ mapTo: MAP_ALL });
        }
    }

    isSelected = (sku) => {
        return this.state.selectedSKUs.includes(sku)
    }

    handleCheck = (e) => {
        this.setState({
            mapTo: e.target.value
        }, () => {
            const mapping = {
                skus: this.state.selectedSKUs,
                autoApply: this.state.autoApply,
                applyToAllShipments: this.state.mapTo === MAP_ALL,
                applyToAllListings: this.state.mapTo === MAP_ALL,
                applyToAllPricing: this.state.mapTo === MAP_ALL,
            };
            this.props.updateMappingWithParent(mapping);
        })
    }

    handleAutoApplyCheck = (e) => {
        const checked = e.target.checked
        this.setState({
            autoApply: checked
        })
        const mapping = {
            skus: this.state.selectedSKUs,
            autoApply: checked,
            applyToAllShipments: this.state.mapTo === MAP_ALL,
            applyToAllListings: this.state.mapTo === MAP_ALL,
            applyToAllPricing: this.state.mapTo === MAP_ALL,
        };
        this.props.updateMappingWithParent(mapping);
    }

    handleSelect = (e) => {

        const name = e.target.value;
        if (this.state.selectedSKUs.includes(name)) {
            this.setState({
                selectedSKUs: [...this.state.selectedSKUs.filter(n => n !== name)],
            }, () => {
                let mapping = {
                    skus: this.state.selectedSKUs,
                    autoApply: this.state.autoApply,
                    applyToAllShipments: this.state.mapTo === MAP_ALL,
                    applyToAllListings: this.state.mapTo === MAP_ALL,
                    applyToAllPricing: this.state.mapTo === MAP_ALL,
                };
                this.props.updateMappingWithParent(mapping);
            })
        } else {
            this.setState({
                selectedSKUs: [...this.state.selectedSKUs, name]
            }, () => {
                let mapping = {
                    skus: this.state.selectedSKUs,
                    autoApply: this.state.autoApply,
                    applyToAllShipments: this.state.mapTo === MAP_ALL,
                    applyToAllListings: this.state.mapTo === MAP_ALL,
                    applyToAllPricing: this.state.mapTo === MAP_ALL,
                };
                this.props.updateMappingWithParent(mapping);
            })
        }

    }

    handleSelectAll = () => {

        let newSelectedSkus = [];

        if (this.state.selectedSKUs.length === this.state.skus.length) {
            this.setState({
                selectedSKUs: [...newSelectedSkus],
                selectAll: false
            }, () => {
                let mapping = {
                    skus: this.state.selectedSKUs,
                    autoApply: this.state.autoApply,
                    applyToAllShipments: this.state.mapTo === MAP_ALL,
                    applyToAllListings: this.state.mapTo === MAP_ALL,
                    applyToAllPricing: this.state.mapTo === MAP_ALL,
                };
                this.props.updateMappingWithParent(mapping);
            })
        } else {
            this.state.skus.map(sku => {
                newSelectedSkus.push(sku)
            })

            this.setState({
                selectedSKUs: [...newSelectedSkus],
                selectAll: true
            }, () => {
                let mapping = {
                    skus: this.state.selectedSKUs,
                    autoApply: this.state.autoApply,
                    applyToAllShipments: this.state.mapTo === MAP_ALL,
                    applyToAllListings: this.state.mapTo === MAP_ALL,
                    applyToAllPricing: this.state.mapTo === MAP_ALL,
                };
                this.props.updateMappingWithParent(mapping);
            })
        }

    }

    render() {
        return (
            this.props.ruleCategory != RULE_CATEGORY_LISTINGS ?
            <div>
                <Paper className='automationRulesPaper' variant='outlined'>
                    <Toolbar className='conditionsToolbar'>
                        <div className='conditionsToolbarDiv'/>
                    </Toolbar>
                    {this.props.ruleCategory == RULE_CATEGORY_TRANSACTIONS ?
                        <div className="mappingSelections">
                            <FormGroup>
                                <FormControlLabel
                                    className="autoApply-label"
                                    key={"autoApply"}
                                    control={
                                        <Checkbox
                                            checked={this.state.autoApply}
                                            onChange={this.handleAutoApplyCheck}
                                        />
                                    }
                                    label={"Auto apply"}
                                />
                            </FormGroup>
                        </div>
                        : <div className="mappingSelections">
                            <FormGroup>
                                <RadioGroup aria-label="mappings" name="mapping" value={this.state.mapTo} onChange={this.handleCheck}>
                                    <FormControlLabel value={MAP_ALL} control={<Radio />} label={MAP_ALL_LABEL} />
                                    <FormControlLabel disabled={this.state.skus.length === 0} value={MAP_SKU} control={<Radio />} label={MAP_SKU_LABEL} />
                                </RadioGroup>
                                <div className={this.state.mapTo === MAP_SKU ? "skuList" : null}>
                                    {this.state.mapTo === MAP_SKU && this.state.skus.length > 0 ?
                                        <FormControl>
                                            <FormControlLabel
                                                id="select-all-skus"
                                                control={
                                                    <Checkbox
                                                        checked={this.state.selectedSKUs.length === this.state.skus.length}
                                                        value={this.state.selectAll}
                                                        onChange={this.handleSelectAll}
                                                    />
                                                }
                                                label={MAP_SKU_SELECT_OPTIONS.ALL}
                                            />
                                            <FormGroup>
                                                {this.state.skus.map((sku, i) => (
                                                    <FormControlLabel
                                                        className="sku-label"
                                                        key={`select-sku-${i}`}
                                                        control={
                                                            <Checkbox
                                                                checked={this.isSelected(sku)}
                                                                // checked={sku.checked}
                                                                onChange={this.handleSelect}
                                                                value={sku}
                                                            />
                                                        }
                                                        label={sku}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl> : null
                                    }
                                </div>
                                <FormControlLabel
                                    className="autoApply-label"
                                    key={"autoApply"}
                                    control={
                                        <Checkbox
                                            checked={this.state.autoApply}
                                            onChange={this.handleAutoApplyCheck}
                                        />
                                    }
                                    label={"Auto apply"}
                                />
                            </FormGroup>
                        </div>}
                </Paper>
            </div> :
                <div>
                    <Paper className='automationRulesPaper' variant='outlined'>
                        <Toolbar className='conditionsToolbar'>
                            <div className='conditionsToolbarDiv'/>
                        </Toolbar>
                        <div className="mappingSelections">
                            <FormGroup>
                                <RadioGroup aria-label="mappings" name="mapping" value={this.state.mapTo} onChange={this.handleCheck}>
                                    <FormControlLabel value={MAP_ALL} control={<Radio />} label={MAP_ALL_LABEL_LISTINGS} />
                                    <FormControlLabel disabled={this.state.skus.length === 0} value={MAP_SKU} control={<Radio />} label={MAP_SKU_LABEL_LISTINGS} />
                                </RadioGroup>
                                <div className={this.state.mapTo === MAP_SKU ? "skuList" : null}>
                                    {this.state.mapTo === MAP_SKU && this.state.skus.length > 0 ?
                                        <FormControl>
                                            <FormControlLabel
                                                id="select-all-skus"
                                                control={
                                                    <Checkbox
                                                        checked={this.state.selectedSKUs.length === this.state.skus.length}
                                                        value={this.state.selectAll}
                                                        onChange={this.handleSelectAll}
                                                    />
                                                }
                                                label={MAP_SKU_SELECT_OPTIONS.ALL}
                                            />
                                            <FormGroup>
                                                {this.state.skus.map((sku, i) => (
                                                    <FormControlLabel
                                                        className="sku-label"
                                                        key={`select-sku-${i}`}
                                                        control={
                                                            <Checkbox
                                                                checked={this.isSelected(sku)}
                                                                // checked={sku.checked}
                                                                onChange={this.handleSelect}
                                                                value={sku}
                                                            />
                                                        }
                                                        label={sku}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl> : null
                                    }
                                </div>
                                <FormControlLabel
                                    className="autoApply-label"
                                    key={"autoApply"}
                                    control={
                                        <Checkbox
                                            checked={this.state.autoApply}
                                            onChange={this.handleAutoApplyCheck}
                                        />
                                    }
                                    label={"Auto apply"}
                                />
                            </FormGroup>
                        </div>
                    </Paper>
                </div>
        );
    }

}

AutomationRulesMapping.propTypes = {
    key: PropTypes.string,
    updateMappingWithParent: PropTypes.func,
    conditions: PropTypes.array,
    ruleName: PropTypes.string,
    isEdit: PropTypes.bool
}

AutomationRulesMapping.defaultProps = {
    key: "",
    updateMappingWithParent: ()=>{},
    conditions: [],
    ruleName: "",
    isEdit: false
}

export default AutomationRulesMapping;