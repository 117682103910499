import React from "react";
import '../../../pricingDashboard/pricingDashboardPages/PricingDashboardTableRow.css';
import Tooltip from "@material-ui/core/Tooltip";
import {CANCEL_EDIT_ENTRY} from "../../../pricingDashboard/constants";
import EditIcon from "@material-ui/icons/Edit";
import { FormHelperText, IconButton, TableCell, TextField, Button, Checkbox } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CARRY_FORWARD_OPTIONS, EDIT_LEDGER, SOURCE_OPTIONS, SUM_RULE_OPTIONS } from '../constants';
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TwoStepDelete from "../../../global/subcomponents/twoStepDelete/TwoStepDelete";
import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {CANCEL, SAVE} from "../../../global/constants";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteIcon from '@material-ui/icons/Delete';



class StockEntryTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            deleteId: "",
            isDeleted: false,
            isDialogOpen: false,
            id: "",
        };
    }

    componentDidMount() {

        let newState = {
            deleteListing: false,
            name: '',
            balance: '0.00',
        }
        this.props.setRowState(newState)


    }

    handleOpen = () => {
        this.setState({ isDialogOpen: true });
    };

    handleClose = () => {
        this.setState({ isDialogOpen: false });
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listingIdBeingEdited !== this.props.listingIdBeingEdited) {
            if (this.props.listingIdBeingEdited === this.props.row.id) {
                this.props.setRowState({
                    id: this.props.row.id,
                    name: this.props.row.name,
                    balance: this.props.row.balance,
                })
            }
        }
    }


    handleCancel = (row) => {
        if (row.id === '') {
            const newEntry = this.props.stockSummary.filter(stock => stock.id !== '');

            // Update the carrierSummary state with the filtered list
            this.setState(prevState => {
                this.props.setStockEntries(newEntry);
            });
        }

        this.props.setListingIdBeingEdited("");
        this.props.setRowState({
            id: '',
            name: '',
            balance: '',
        });

    }


    handleConfirm = (row) => {
        let state = this.props.getRowState()
        if (!state.name || !state.balance) {
            return;
        }

        this.props.setListingIdBeingEdited("")
        const request = {
            id: row.id,
            name: state.name,
            balance: String(state.balance).replace(/,/g, ''),
            ledgerId: this.props.ledgerId,
            currency: this.props.currency,
            year: this.props.year,
            ledgerType: this.props.ledgerType,
            type: "stock"
        };
        this.props.setRowState({
            name: '',
            balance: '',
        })

      this.props.updateEntry(request);
    }


    getEditingCancelConfirm = (row) => {
        return <>
            <div style={{display:"flex", justifyContent:"left"}}>
                <Tooltip title={CANCEL_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleCancel(row)}>
                        <ClearIcon
                            fontSize='small'
                            color='error'
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </>
    }



    formatBalance = (balance) => {
        const validBalance = parseFloat(balance);
        if (isNaN(validBalance)) {
            return '0.00';
        }
        return validBalance
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    };



    onBlurHandle = (name, value) => {
        const formattedValue = this.formatBalance(value);
        if (name === 'balance') {
            this.props.setRowState({ balance: formattedValue });
        } else if (name === 'balance') {
            this.props.setRowState({ balance: formattedValue });
        }
    };

    displayBalanceEditingTextField = () => {
        const state = this.props.getRowState();

        return (
          <TextField
            style={{ marginLeft: "-8px", padding: "0" }}
            className="full-width-ledger"
            variant="outlined"
            name={state.row === "Opening Balance" ? "balance" : "balance"}
            label="Amount"
            value={state.row === "Opening Balance" ? state.balance : state.balance || ''}
            onBlur={(e) => this.onBlurHandle(e.target.name, e.target.value)}
            onChange={(e) => {
                const value = e.target.value.replace(/,/g, ''); // Remove commas
                this.props.setRowState({ balance: value });
            }}
            onPaste={(e) => {
                e.preventDefault(); // Prevent default paste action
                const pastedValue = e.clipboardData.getData('Text').replace(/,/g, ''); // Remove commas
                this.props.setRowState({ balance: pastedValue }); // Update state with cleaned value
            }}
            error={
              state.row !== "Opening Balance" &&
              (state.balance === '' || state.balance === null || state.balance === undefined)
            }
          />
        );
    };


    displayAmountEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            style={{marginLeft:"-8px",padding:"0"}}
            className="full-width-ledger"
            variant="outlined"
            name="balance"
            label="Amount"
            value={state.balance}
            onChange={(e) => this.props.setRowState({name: e.target.value})}
            error={state.balance === '' || state.balance === null || state.balance === undefined}
        />
    }



    displayEditingRow = (row) => {
        let state = this.props.getRowState();


        if (row.name === "Total") {
            return null
        }

        return (
          <React.Fragment>
            <TableCell
              style={{
                width: '70px',
                height: '77px',
                padding: '0',
                margin: '0',
              }}
            >
              {this.getEditingCancelConfirm(row)}
            </TableCell>

            <TableCell>
              <div className="rowSpecLedger">
                {state.name}
              </div>
            </TableCell>

            <TableCell>
              <div className="rowSpecLedger">
                  {this.displayBalanceEditingTextField()}
              </div>
            </TableCell>

            <TableCell>
              <Tooltip title="Save Entry">
                <IconButton onClick={() => this.handleConfirm(row)}>
                  <CheckCircleIcon fontSize="small" color="secondary" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </React.Fragment>
        );
    }

    displayNonEditingRow = (row) => {

        return (
            <React.Fragment>
                {this.props.isEditPayable && row.name !== "Total" ? (
                    <TableCell>
                        <Tooltip title={"Edit Entry"}>
                            <IconButton
                                onClick={() => {
                                    this.props.setHideFilterBox(true);
                                    this.props.setListingIdBeingEdited(row.id);
                                }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                ) : (
                  <TableCell>
                      <IconButton  style={{ visibility: 'hidden' }}
                      >
                          <DeleteIcon fontSize="small" />
                      </IconButton>
                  </TableCell>
                )}


                <TableCell style={{ textAlign: "right", padding: "5px", paddingRight: "50px", width: "100%", fontWeight: row.name === "Total" ? "bold" : "normal"}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        {row.name}
                    </div>
                </TableCell>


                <TableCell style={{  textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto",
                    fontWeight: row.name === "Total" ? "bold" : "normal"}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <span style={{flexShrink: 0}}>$</span>
                        <span style={{marginLeft: '55px', minWidth: '80px', textAlign: 'right'}}>
                 {row.name === "Opening Balance" ? this.formatBalance(row.balance) :  this.formatBalance(row.balance)}
                </span>
                    </div>
                </TableCell>

                <TableCell>
                    <IconButton  style={{ visibility: 'hidden' }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </TableCell>

            </React.Fragment>
        );
    };


    displayListingRow = (listingIdBeingEdited) => {
        if (listingIdBeingEdited === this.props.row.id) {
            return this.displayEditingRow(this.props.row)
        } else {
            return <>
                {this.displayNonEditingRow(this.props.row)}
            </>
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.displayListingRow(this.props.listingIdBeingEdited)}
            </React.Fragment>
        )
    }
}


export default StockEntryTableRow;