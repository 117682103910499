import React from 'react';

//styling

// constants
import {
  BACK, COST_OF_SALES_OPTIONS, INCOME_CURRENCY_OPTIONS,
  INCOME_SOURCE_OPTIONS,
  INCOME_STATEMENT_PAGES,
  MAPPING_OPTIONS,
} from './constants';

import TopToolbar from '../../global/subcomponents/topToolbar/TopToolbar';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import { Chip, IconButton } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuIcon from '@mui/icons-material/Menu';

class IncomeStatementSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      category: [],
      requiredList: ['name', 'section','category'],
      templates: this.props.templates || [],
      open: false,
      anchorEl: null,
      activeIndex: null,
      draggedIndex: null,
    };
  }

  componentDidMount() {
    if (this.props.templates && this.props.templates.length > 0) {
      this.setState({ templates: [...this.props.templates] });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.templates !== this.props.templates ||
      nextState.templates !== this.state.templates ||
      nextState.anchorEl !== this.state.anchorEl ||
      nextState.activeIndex !== this.state.activeIndex ||
      nextState.draggedIndex !== this.state.draggedIndex
    );
  }

  onDragStart = (index) => {
    this.setState({ draggedIndex: index });
  };

  onDragOver = (index) => {
    const { draggedIndex, templates } = this.state;
    if (draggedIndex === index) return;

    const updatedTemplates = [...templates];
    const [draggedItem] = updatedTemplates.splice(draggedIndex, 1);
    updatedTemplates.splice(index, 0, draggedItem);

    this.setState({ templates: updatedTemplates, draggedIndex: index });
  };

  onDragEnd = () => {
    this.setState({ draggedIndex: null });
  };

  handleChange = (state) => {
    this.setState(state);
  };

  handleTemplateChange = (e, index) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const updatedTemplates = [...prevState.templates];
      updatedTemplates[index] = {
        ...updatedTemplates[index],
        [name]: value,
      };

      return { templates: updatedTemplates };
    });
  };

  removeTemplateRow = (index) => {
    this.setState((prevState) => {
      const updatedTemplates = prevState.templates.filter(
        (_, curIndex) => curIndex !== index
      );
      return { templates: updatedTemplates };
    });
  };

  addTemplateRow = () => {
    this.setState((prevState) => {
      const updatedTemplates = [
        ...prevState.templates,
        {
          name: '',
          section: '',
          source: '',
          category: [],
          amount: '',
          currency: ''
        },
      ];
      return { templates: updatedTemplates };
    });
  };

  handleOpen = (open) => {
    this.setState({ open: open });
  };

  handleSave = () => {
    this.props.handleSave(this.state.templates);
  };

  isSaveDisabled = () => {
    return this.state.templates.some((template) => {
      // Check if name or section is missing
      if (!template.name || !template.section) {
        return true;
      }

      // If source is not selected, check currency and amount
      if (!template.source && (!template.currency || !template.amount)) {
        return true;
      }

      return false;
    });
  };


  render() {
    const { templates } = this.state;
    const { allLedgers, marketplaceCategory } = this.props;

    return (
      <React.Fragment>
        <TopToolbar
          pageName="Income Statement Settings"
          menuItems={[
            {
              title: BACK,
              icon: <KeyboardBackspaceIcon />,
              onClick: () => {
                this.props.setCurrentPage(INCOME_STATEMENT_PAGES.TABLE, {});
              },
            },
            {
              title: 'Save',
              icon: <SaveIcon />,
              onClick: this.handleSave,
              disabled: this.isSaveDisabled(),
            },
          ].filter(Boolean)}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            marginTop: '16px',
            marginLeft: '24px',
            marginRight: '24px',
          }}
        >
          <div className="transaction-form-section">
            {templates.map((template, index) => (
              <SettingRow
                key={index}
                index={index}
                template={template}
                allLedgers={allLedgers}
                handleTemplateChange={this.handleTemplateChange}
                removeTemplateRow={this.removeTemplateRow}
                draggedIndex={this.state.draggedIndex}
                onDragStart={this.onDragStart}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                anchorEl={this.state.anchorEl}
                marketplaceCategory={marketplaceCategory}
              />
            ))}
            <div style={{ marginTop: '30px' }}>
              <IconButton onClick={this.addTemplateRow}>
                <AddCircleIcon fontSize="small" color="secondary" />
              </IconButton>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const SettingRow = React.memo(
  ({
    index,
    template,
    allLedgers,
    handleTemplateChange,
    removeTemplateRow,
     draggedIndex, onDragStart, onDragOver, onDragEnd,marketplaceCategory
  }) => {


    const formatBalance = (balance) => {
      const validBalance = parseFloat(balance);
      if (isNaN(validBalance)) {
        return '0.00';
      }
      return validBalance
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    const onBlurHandle = (name, value) => {
      const formattedValue= formatBalance(value);
      handleTemplateChange({ target: { name: "amount", value: formattedValue } }, index)
    };

    const displayNameEditingTextField = () => {
      return <TextField
        style={{marginLeft:"-8px",padding:"0"}}
        className="full-width-source-statement"
        variant="outlined"
        name="name"
        label="Name"
        value={template.name || ""}
        onChange={(e) => handleTemplateChange({ target: { name: "name", value: e.target.value } }, index)}
        required
        error={!template.name}
      />
    }

    const displaySectionEditingTextField = () => {
      return (
        <Autocomplete
          className="full-width-source-statement"
          options={MAPPING_OPTIONS}
          getOptionLabel={(option) => option}
          value={template.section || null}
          onChange={(e, value) =>
            handleTemplateChange(
              { target: { name: 'section', value: value } },
              index
            )
          }
          renderInput={(params) =>
            <TextField
              {...params}
              name="section"
              label="Section"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          }
        />
      );
    };

    const displaySourceEditingDropdown = () => {

      if(template.section){
        return (
          <Autocomplete
            className="full-width-source-statement"
            options={INCOME_SOURCE_OPTIONS}
            getOptionLabel={(option) => option}
            value={template.source || null}
            onChange={(e, value) => handleTemplateChange({
              target: { name: "source", value: value } }, index)
            }
            renderInput={(params) =>
              <TextField
                {...params}
                name="source"
                label="Source"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            }
          />
        );
      }else{
        return null
      }

    }

    const displayCategoryEditingDropdown = () => {

      if(template.section){
        if (template.source === 'Ledgers') {
          return (
            <div>
              <Autocomplete
                className="full-width-ledger-category"
                multiple
                value={
                  Array.isArray(template.category)
                    ? template.category
                      .map(
                        (cat) =>
                          allLedgers.find(
                            (ledger) => ledger.name === cat
                          ) // Find category objects from names
                      )
                      .filter(Boolean) // Ensure no undefined values
                    : [] // Fallback to an empty array if state.category is not an array
                }
                options={(allLedgers || [])
                  .sort((a, b) => a.displayOrder - b.displayOrder)}
                getOptionLabel={(option) => option.name || ''}
                autoHighlight={true}
                renderTags={(value) => {
                  return value.map((option) => (
                    <Chip
                      key={option.name}
                      variant="outlined"
                      size="small"
                      style={{
                        borderColor: '#acd685',
                        margin: '1px 6px 1px 0',
                        fontSize: '12px',
                      }}
                      label={option.name}
                    />
                  ));
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                name="category"

                onChange={(e, value) =>
                  handleTemplateChange({
                    target: { name: "category", value: value.map((cat) => cat?.name) },
                  }, index)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ledgers"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="category"
                    required
                    error={!template.category}
                  />
                )}
              />
            </div>
          )
        }else if(template.source === 'Marketplace Summary'){
          return (
            <div>
              <Autocomplete
                className="full-width-ledger-category"
                multiple
                value={
                  Array.isArray(template.category)
                    ? template.category.map((cat) =>
                      marketplaceCategory.find((marketplace) => marketplace === cat)
                    ).filter(Boolean) // Ensure no undefined values
                    : [] // Fallback to an empty array if marketplaceCategory is not an array
                }
                options={(marketplaceCategory || [])
                  .sort((a, b) => a.displayOrder - b.displayOrder)}
                getOptionLabel={(option) => option || ''}
                autoHighlight={true}
                renderTags={(value) =>
                  value.map((option) => (
                    <Chip
                      key={option}
                      variant="outlined"
                      size="small"
                      style={{
                        borderColor: '#acd685',
                        margin: '1px 6px 1px 0',
                        fontSize: '12px',
                      }}
                      label={option}
                    />
                  ))
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </React.Fragment>
                )}
                name="category"
                onChange={(e, value) =>
                  handleTemplateChange({
                    target: { name: "category", value: value.map((cat) => cat) },
                  }, index)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Marketplace Summary"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="category"
                    required
                    error={!template.category}
                  />
                )}
              />
            </div>

          )
        } else if(template.source === 'Cost of Sales') {
          return (
            <div>
              <Autocomplete
                className="full-width-ledger-category"
                multiple
                value={
                  Array.isArray(template.category)
                    ? template.category.map((cat) =>
                      COST_OF_SALES_OPTIONS.find((sales) => sales === cat)
                    ).filter(Boolean) // Ensure no undefined values
                    : [] // Fallback to an empty array if marketplaceCategory is not an array
                }
                options={(COST_OF_SALES_OPTIONS || [])
                  .sort((a, b) => a.displayOrder - b.displayOrder)}
                getOptionLabel={(option) => option || ''}
                autoHighlight={true}
                renderTags={(value) =>
                  value.map((option) => (
                    <Chip
                      key={option}
                      variant="outlined"
                      size="small"
                      style={{
                        borderColor: '#acd685',
                        margin: '1px 6px 1px 0',
                        fontSize: '12px',
                      }}
                      label={option}
                    />
                  ))
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </React.Fragment>
                )}
                name="category"
                onChange={(e, value) =>
                  handleTemplateChange({
                    target: { name: "category", value: value.map((cat) => cat) },
                  }, index)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cost of Sales"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="category"
                    required
                    error={!template.category}
                  />
                )}
              />
            </div>
          )
        }
      }else{
        return null
      }
    }

    const displayBalanceEditingTextField = () => {
      if(!template.source){
        return (

          <div  className="full-width-ledger-category">
            <div   style={{marginRight:'10px', width:'150px'}}>
              <Autocomplete
                options={INCOME_CURRENCY_OPTIONS}
                getOptionLabel={(option) => option}
                value={template.currency || null}
                onChange={(e, value) => handleTemplateChange({
                  target: { name: "currency", value: value } }, index)
                }
                renderInput={(params) =>
                  <TextField
                    {...params}
                    name="currency"
                    label="Currency"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                }
              />
            </div>


            <div     style={{ width:'150px'}}>
              <TextField
                variant="outlined"
                name="amount"
                label="Amount"
                value={template.amount || ''}
                onBlur={(e) => onBlurHandle(e.target.name, e.target.value)}
                onChange={(e) => handleTemplateChange({ target: { name: "amount", value: e.target.value } }, index)}
              />
            </div>


          </div>

        );
      }
      return null

    };


    return (
      <div className="transaction-form-details"
           draggable
           onDragStart={() => onDragStart(index)}
           onDragOver={() => onDragOver(index)}
           onDragEnd={onDragEnd}
           style={{ background: draggedIndex === index ? "#f0f0f0" : "white" }}
      >

        <div style={{ width: '4.5%', height: '60px', display: 'flex' }}>
          <IconButton onClick={() => removeTemplateRow(index)}>
            <RemoveCircleIcon fontSize="small" color="error" />
          </IconButton>
        </div>

        <div className="rowSpecLedger full-width-name">

          {displayNameEditingTextField()}
          {displaySectionEditingTextField()}
          {displaySourceEditingDropdown()}
          {displayCategoryEditingDropdown()}
          {displayBalanceEditingTextField()}
          <MenuIcon
            style={{
              marginTop: '-10px',
              cursor: 'grab',
              opacity: '0.6',
              alignSelf: 'center',
            }}
          />
        </div>
      </div>
    );
  }
  )
;


export default IncomeStatementSettings;

