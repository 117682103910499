export const INCOME_STATEMENT = "Income Statement"
export const SAVE = "Save"


export const INCOME_STATEMENT_PAGES = {
    TABLE: "table",
    SETTINGS: "settings",
}


// tooltip names
export const BACK = "Back to Menu"

export const MAPPING_OPTIONS = [
    "Operating Income",
    "Cost of Sales",
    "Operating Expenses",
    "Other Income",
    "Taxes",
];

export const COST_OF_SALES_OPTIONS = [
    "Opening Inventory",
    "Closing Inventory",
    "Freight & Shipping Costs",
    "Purchases",
    "Other Costs"
];

export const INCOME_SOURCE_OPTIONS = [
    "Marketplace Summary",
    "Ledgers",
    "Cost of Sales",
];

export const INCOME_CURRENCY_OPTIONS = [
    "CAD",
    "USD",
];